import React, { Component } from "react";

import Notiflix from "notiflix";
import PostApiCall from "../components/helper/Api";

import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Row, Col, Card, CardBody } from "reactstrap";

import Searchicon from "./images/search.png";
// import InvoiceForm from "../InvoiceForm"

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderHistoryData: [],
      searchInput: "",
    };
  }

  componentDidMount() {
    Notiflix.Loading.init({
      svgColor: "#ee1b2f",
    });

    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);

    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${CustomerDetails[0].fld_customerid} order by fld_order_id desc`,
      },
      "GetCustomerOrderDetails"
    ).then((results) =>
      results.json().then((obj) => {
        // console.log(obj.data);
        if (results.status == 200 || results.status == 201) {
          this.setState({
            orderHistoryData: obj.data,
          });
          Notiflix.Loading.remove();
        }
      })
    );
  }
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };

  DownloadInvoicePdf() {
    var html2pdf = require("html2pdf.js");
    var element = document.getElementById("invoice");
    var opt = {
      margin: 1,
      filename: "invoice.pdf",
      image: { type: "png", quality: 0.98 },
      html2canvas: {
        scale: 1.5,
        useCORS: true,
        allowTaint: true,
        proxy: null,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait",compress: true, },
      //   pagebreak: {avoid: ‘div’}
    };
    // ////console.log(opt)
    // New Promise-based usage:
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        Notiflix.Loading.remove();
      });
  }

  render() {
    //console.log(this.state.orderHistoryData)
    const data = {
      columns: [
        {
          label: "S.No.",
          field: "serial",
          sort: "disabled",
          width: 75,
        },
        {
          label: "Order Number",
          field: "number",
          sort: "disabled",
          width: 600,
        },

        {
          label: "Order Date",
          field: "date",
          sort: "disabled",
          width: 600,
        },

        {
          label: "Order Value",
          field: "ordervalue",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Payment Status",
          field: "paystatus",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Order Status",
          field: "status",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Invoice",
          field: "invoice",
          sort: "disabled",
          width: 200,
        },

        // {
        //     label: "Action",
        //     field: "action",
        //     sort: "disabled",
        //     width: 100,
        // },
      ],
      rows: this.state.orderHistoryData
        .filter((data) => {
          if (this.state.searchInput == "") {
            return data;
          }
          if (
            this.state.searchInput !== "" &&
            data.fld_order_status
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase())
          ) {
            return data;
          }
          if (
            this.state.searchInput !== "" &&
            data.fld_order_number
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase())
          ) {
            return data;
          }
          if (
            this.state.searchInput !== "" &&
            data.fld_payment_status
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase())
          ) {
            return data;
          }
        })
        .map((data, i) => {
          return {
            serial: i + 1,
            number: data.fld_order_number,
            date: data.fld_order_date,

            // item: (data.total_items),
            ordervalue: data.fld_order_amount,
            paystatus:
              data.fld_payment_mode == "COD"
                ? "Cash On Delivery"
                : data.fld_payment_status,
            status: data.fld_order_status,
            invoice: (
              <>
                {data.fld_order_status == "Delivered" ? (
                  <button
                    onClick={() => {
                      window.location.href = `/view-invoice-form/${data.fld_order_id}`;
                    }}
                    className="btn shopnowbtn"
                    style={{
                      background: "#59c5d1",
                      borderRadius: "3px",
                      color: "white",
                    }}
                  >
                    View Invoice
                  </button>
                ) : (
                  <button
                    className="btn shopnowbtn"
                    style={{
                      background: "#b5bbbc",
                      borderRadius: "3px",
                      color: "white",
                    }}
                  >
                    View Invoice
                  </button>
                )}
              </>
            ),
          };
        }),
    };

    return (
      <React.Fragment>
        <Card className="mini-stats-wid mt-3">
          <div className="row">
            <div className="col-md-12">
              <div className="overflow-hidden website-card">
                <div
                  className="globalTrendzEcomm-bg-soft-pink"
                  style={{ height: "85px", background: "#59c5d1" }}
                >
                  <h4 className="mb-0 productTitle">Order History</h4>
                </div>
              </div>
            </div>
          </div>
          <CardBody>
            <Row>
              <MDBCol
                md="12"
                style={{ marginBottom: "15px", marginTop: "15px" }}
                className="search-box"
              >
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      <span className="">
                        <img
                          src={Searchicon}
                          alt="block user"
                          className="btnicons"
                        ></img>
                      </span>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Type the search phrase you wish to search within the grid"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    onChange={(e) => this.seachBarHandler(e)}
                    value={this.state.searchInput}
                  />
                </div>
              </MDBCol>

              <Col md="12">
                {this.state.orderHistoryData.length != 0 ? (
                  <MDBDataTable
                    hover
                    // scrollY
                    striped
                    bordered
                    data={data}
                    seachTop={false}
                    entriesOptions={[10, 25, 50, 100]}
                    responsive
                  >
                    <MDBTableHead columns={data.columns} />
                    <MDBTableBody rows={data.rows} />
                  </MDBDataTable>
                ) : (
                  <p>No Data Found</p>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default OrderList;
