import Parser from "html-react-parser";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import BestViewImage from "../../assets/image/bestviewedindesktop.webp";
import PostApiCall from "../../components/helper/Api";
import Footer from "../../Footer";
import Header from "../../Header";
// import Recommended from "../../assets/image/Pio_arrow2.png"
// import Recommended from "../../assets/image/recommended.jpg"
import Recommended from "../../assets/image/recommended.png";

import MOQ from "../../MOQ.jpeg";

// import RecommendedForFood from "../../assets/image/Pio_arrow.png"
import { TiLocationArrow } from "react-icons/ti";
import { BackgroundImage } from "react-image-and-background-image-fade";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import {
  FacebookIcon,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { store } from "../../components/context/store";
import "./shop.css";

const Receivedreferralskelton = () => {
  return (
    <>
      <div className="row productskeleton">
        <div className="col-3 leftSkeleton">
          <Skeleton className="mb-2 smallSkeleton  " size="4" />

          <Skeleton className="mb-2 smallSkeleton  " size="4" />
          <Skeleton className="mb-2 smallSkeleton  " size="4" />
          <Skeleton className="mb-2 smallSkeleton  " size="4" />
        </div>
        <div className="col-12 col-lg-9 col-md-9 rightSkeleton">
          <Skeleton className="largeSkeleton " />
        </div>
      </div>
    </>
  );
};

const Receivedreferralskeltonphoneview = () => {
  return (
    <>
      <div className="row">
        <div className="col-6 pr-0 pb-2">
          <Skeleton className="h-100" style={{ height: "100%" }} />
        </div>
        <div className="col-6 leftSkeleton">
          <div className="row">
            <div className="col-6 pl-0 ">
              <Skeleton className="mb-2 smallSkeleton  " size="4" />
            </div>
            <div className="col-6 pl-0">
              <Skeleton className="mb-2 smallSkeleton  " size="4" />
            </div>
            <div className="col-6 pl-0">
              <Skeleton className="mb-2 smallSkeleton  " size="4" />
            </div>
            <div className="col-6 pl-0">
              <Skeleton className="mb-2 smallSkeleton  " size="4" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1190 },
    items: 5,
  },
  tablet1: {
    breakpoint: { max: 1190, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const responsivephn = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile1: {
    breakpoint: { max: 464, min: 400 },
    items: 3,
  },
  mobile2: {
    breakpoint: { max: 400, min: 300 },
    items: 2,
  },
  mobile3: {
    breakpoint: { max: 300, min: 200 },
    items: 2,
  },
};

const responsive1 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

class ProductDetailPage extends Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      PickUpTypeData: [
        { label: "Self Pickup", value: "SelfPickup" },
        // { label: "Get Delivery Cost", value: "GetDeliveryCost" },
        // { label: "Standard Shipping", value: "StandardShipping" },
      ],
      // PickUpType: "",
      PickUpType: "SelfPickup",
      Pincode: null,
      NumRegex: /^[0-9]*$/,
      Checkbox: false,
      VerticleData: [],
      imagePreviewUrl:
        "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png",
      ImageApiUrl: "https://api.grocery4uretail.in/AddImage",
      BoxSizeData: [],
      DesignData: [],
      Viewlargeimage: "",
      CoatingData: [],
      OuterCoatingDropLable: [],
      InnerCoatingDropLable: [],
      BoardData: [],
      BoardDataLabel: [],
      Customquantity: null,
      QuantityData: [
        { Quantity: 500 },
        { Quantity: 1000 },
        { Quantity: 2000 },
        { Quantity: 3000 },
      ],
      // InnerCoatingData: [],
      InnerCoatingData: 0,
      InnerCoating: [],
      AllProductId: [],
      NeedInnerCoating: "No",
      PriceData: [],
      weightForWhatsapp: [],
      VariantImages: [],
      SelectedDesign: [],
      ViewBoxIcon: "",
      ViewDesignIcon: "",
      ViewCoatingIcon: "",
      ViewBoardIcon: "",
      ViewQuantityIcon: "",
      PincodeData: [],
      Deliverycharge: [],
      NetAmount: [],
      GST: 0,
      GstAmount: 0.0,
      TotalAmount: 0.0,
      OuterCoatingId: 0,
      Quantity: 1,
      BoardId: 0,
      VariantId: 0,

      DesignType: "StandardDesign",
      selectboard: "",
      SelectedBoard: null,
      SelectedDesign2: [],
      Deliverycharge1: [],
      NetAmount1: [],
      GstAmount1: 0,

      TotalAmount1: 0.0,
      Deliverycharge2: [],
      NetAmount2: [],
      GstAmount2: 0,

      TotalAmount2: 0.0,
      Deliverycharge3: [],
      NetAmount3: [],
      GstAmount3: 0,

      TotalAmount3: 0.0,
      Deliverycharge4: [],
      NetAmount4: [],
      GstAmount4: 0,

      TotalAmount4: 0.0,
      Deliverycharge5: [],
      NetAmount5: [],
      GstAmount5: 0,

      TotalAmount5: 0.0,
      PriceData1: [],
      PriceData2: [],
      PriceData3: [],

      PriceData4: [],
      PriceData5: [],
      selectedBoxColor: "",
      selectedDesignColor: "",
      CustomQuantity_Deliverycharge: 0,
      CustomQuantity_NetAmount: 0,
      CustomQuantity_GstAmount: 0,
      CustomQuantity_TotalAmount: 0,
      PriceData6: 0,
      ProductDetails: "",
      CardSelected: "",
      NoCoating: false,
      selectDesignName: null,
      selectOuterCoating: null,
      selectedBoardDesign: null,
      ViewPriceSection: "No",
      selectedBoardDesign2: "",
      SelectedBoardid: "",
      ChangePriceData: [],
      PricechangeData: [],

      OrderDiscription: "",
      VerticalName: "",
      selectedBoardDesign3: "",
      termscondition: "No",
      open: false,
      innerCoatingOpen: false,
      pincodepopup: false,
      GetDeliverCoastModel: false,
      orderSummaryOpen: false,
      innerLiquidBarriermodal: false,
      innercoatingcontent: [],
      VerticalImage: "",
      innercoatingimage: "",
      BestViewedModalClose: false,
      innerBarrierCoating: "No",
      CustomItemDescription: "",
      designid: null,
      onlineStoreBtn: false,
      loadingimg: true,
      errormessage: "",
      // loadingSelect:true,
      // loadingDesign:true,
      // NumRegex: /^[0-9]*$/,
      outerchipimgdata: "",
      mobileProduct: true,
      pickuptypeactive: true,
      deliverycoast: "",
      Loader: "",
      name: "",
      mobile: "",
      totalamount: "",
      NumRegex: /^[0-9]*$/,
      closesharetoggle: false,
      shareProductunicicon: null,
      wspItemName: "",
    };
  }

  componentDidMount() {
    //console.log("asdsad/asdasda/pppeelle?asdsadjvh?".toString().replaceAll("/", "bech0fy").replaceAll("?", "bechofy").toString())
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    Notiflix.Notify.init({
      width: "350px",
      position: "center-top",
      distance: "20px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
    });

    {
      isMobile
        ? this.setState({
          onlineStoreBtn: true,
        })
        : this.setState({
          onlineStoreBtn: false,
        });
    }

    const store = this.context;

    this.setState({
      BestViewedModalClose: isMobile ? true : false,
    });

    localStorage.removeItem("Ordertype");

    localStorage.removeItem("OrderData");

    if (
      this.props.match.params.variantname != null &&
      this.props.match.params.variantname != undefined
    ) {
      PostApiCall.postRequest(
        {
          recordCount: "*",
          whereClause: `where fld_status = 'Active' and fld_variant_route = '${this.props.match.params.variantname}' `,
        },
        "GetVariantData"
      ).then((resultdes) =>
        resultdes.json().then((obj) => {
          this.context.setSelectedBoxName(obj.data[0].fld_variantname);
          this.setState(
            {
              BoxSizeData: obj.data,
              VariantId: obj.data[0].fld_variantid,
              selectBoxSize: obj.data[0].fld_variantname,
              GST: obj.data[0].fld_Gstrate,
              selectedBoxColor: this.props.match.params.variantid,
              ProductDetails: obj.data[0].fld_short_description,

              // ViewBoxIcon: obj.data[0].fld_variantid,
            },
            () => {
              // =======design api=========

              this.onPostPrice();

              PostApiCall.postRequest(
                {
                  recordCount: "*",
                  variantid: obj.data[0].fld_variantid,
                },
                "GetVariantImage"
              ).then((resultdes) =>
                resultdes.json().then((obj2) => {
                  if (resultdes.status == 200 || resultdes.status == 201) {
                    // console.log(obj2.data);
                    this.setState(
                      {
                        loadingDesign: false,
                        loadingimg: false,
                        SelectedDesign: obj2.data,
                      },
                      () => {
                        this.setState({
                          SelectedDesign2: obj2.data,
                          Viewlargeimage: obj2.data[0].fld_imageurl,
                          selectedDesignColor: obj2.data[0].fld_design_id,
                          selectDesignName: obj2.data[0].fld_design_name,
                        });
                      }
                    );
                  }
                })
              );
            }
          );
        })
      );
    } else {
      PostApiCall.postRequest(
        {
          recordCount: "*",
          // whereClause: `where fld_status = 'Active' and fld_productid=${parseInt(
          //   this.props.match.params.verticalid
          // )} `,
          whereClause: `where fld_status = 'Active' and fld_route = '/${this.props.match.params.produtname}'`,
          // whereClause: `where fld_status = 'Active' and fld_itemname like '%${window.location.pathname.replace(/-/g,' ').replace('/','')}%'`,
        },
        "GetVariantData"
      ).then((resultdes) =>
        resultdes.json().then((obj) => {
          console.log(obj.data);
          this.context.setSelectedBoxName(obj.data[0].fld_variantname);
          this.setState(
            {
              BoxSizeData: obj.data,
              VariantId: obj.data[0].fld_variantid,
              selectBoxSize: obj.data[0].fld_variantname,
              GST: obj.data[0].fld_Gstrate,
              selectedBoxColor: obj.data[0].fld_variantid,
              ProductDetails: obj.data[0].fld_short_description,
              VerticalName: obj.data[0].fld_variantname,
              wspItemName: obj.data[0].fld_itemname,
              // ViewBoxIcon: obj.data[0].fld_variantid,
            },
            () => {
              // =======design api=========

              this.onPostPrice();

              PostApiCall.postRequest(
                {
                  recordCount: "*",
                  variantid: obj.data[0].fld_variantid,
                },
                "GetVariantImage"
              ).then((resultdes) =>
                resultdes.json().then((obj2) => {
                  if (resultdes.status == 200 || resultdes.status == 201) {
                    // console.log(obj2.data);
                    this.setState(
                      {
                        loadingDesign: false,
                        loadingimg: false,
                        SelectedDesign: obj2.data,
                      },
                      () => {
                        this.setState({
                          SelectedDesign2: obj2.data,
                          Viewlargeimage: obj2.data[0].fld_imageurl,
                          selectedDesignColor: obj2.data[0].fld_design_id,
                          selectDesignName: obj2.data[0].fld_design_name,
                        });
                      }
                    );
                  }
                })
              );
            }
          );
        })
      );
    }
    this.onPostBoard();
    this.onGetInnerCoating();
    this.getInnerCoating();
    this.getCoating();
    // this.onPostPincode()
  }

  getCoating = () => {
    PostApiCall.postRequest(
      {
        recordCount: "*",
        whereClause: `where fld_status='Active' and fld_coatingtype ='Outer coating' or fld_coatingtype ='No coating'`,
      },
      "GetCoatingdata"
    ).then((resultdes) =>
      resultdes.json().then((obj3) => {
        this.setState(
          {
            CoatingData: obj3.data,
            OuterCoatingDropLable: obj3.data[0].fld_coating_name,
            OuterCoatingId: obj3.data[0].fld_coating_id,
            selectOuterCoating: obj3.data[0].fld_coating_name,
          },
          () => {
            this.onPostPrice();
          }
        );
      })
    );
  };
  getInnerCoating = () => {
    PostApiCall.postRequest(
      {
        recordCount: "*",
        whereClause: `where fld_status='Active' and fld_coating_name in ('Inner PE Coating')`,
      },
      "GetCoatingdata"
    ).then((resultdes) =>
      resultdes.json().then((obj3) => {
        // console.log(obj3.data);
        this.setState(
          {
            // innercoatingimage: obj3.data[0].fld_image,
          },
          () => {
            this.onPostPrice();
          }
        );
      })
    );
  };

  onPostBoard = () => {
    PostApiCall.postRequest(
      {
        recordCount: "*",
        whereClause: `where fld_status='Active'`,
      },
      "GetBoard"
    ).then((resultdes) =>
      resultdes.json().then((obj4) => {
        this.setState(
          {
            BoardData: obj4.data.sort(
              (a, b) => b.fld_board_id - a.fld_board_id
            ),
            SelectedBoard: obj4.data[0].fld_board_id,
            selectedBoardDesign2: obj4.data[0].fld_board_name,
            BoardDataLabel: obj4.data[0].fld_board_name,
          },
          () => {
            this.onPostPrice();
          }
        );
      })
    );
  };

  // =====GetInnerCoating========
  onGetInnerCoating = () => {
    PostApiCall.postRequest(
      {
        recordCount: "*",
        whereClause: `where fld_status='Active' and fld_coatingtype ='Inner coating' or fld_coatingtype ='No Inner Coating'`,
      },
      "GetCoatingdata"
    ).then((resultdes) =>
      resultdes.json().then((obj3) => {
        // console.log(obj3.data);
        this.setState(
          {
            InnerCoating: obj3.data,
            InnerCoatingDropLable: obj3.data[0].fld_coating_name,
            InnerCoatingData: obj3.data[0].fld_coating_id,
          },
          () => {
            this.onPostPrice();
          }
        );
      })
    );
  };

  onPostPrice = () => {
    var ardt = [];
    for (var i = 0; i <= 5; i++) {
      if (i === 0) {
        PostApiCall.postRequest(
          {
            quantity: 500,
            outercoatingid: this.state.OuterCoatingId,
            innercoatingid: this.state.InnerCoatingData,
            // innercoatingid:
            //   this.state.NeedInnerCoating == "No"
            //     ? null
            //     : this.state.InnerCoatingData,
            varientid: this.state.VariantId,
            boardid: this.state.SelectedBoard,
          },
          "GetPrice"
        ).then((resultdes) =>
          resultdes.json().then((obj) => {
            ardt.push(obj.data);
            this.setState(
              {
                PriceData: obj.data,
              },
              () => {
                PostApiCall.postRequest(
                  {
                    destination_pin:
                      this.state.PickUpType != "StandardShipping"
                        ? 0
                        : this.state.Pincode,
                    source_pin: 600001,
                    weight: obj.data[0].weight,
                    invoice_amount: obj.data[0].price,
                    Gst: this.state.GST,
                  },
                  "GetDeliveryCharge"
                ).then((resultdes) =>
                  resultdes.json().then((obj5) => {
                    this.setState({
                      Deliverycharge: obj5.data[0].delivery_charges,
                      NetAmount: obj5.data[0].total_amount,
                      GstAmount: obj5.data[0].gst_amount,
                      TotalAmount: obj5.data[0].total_amount_with_gst,
                    });
                  })
                );
              }
            );
          })
        );
      } else if (i === 1) {
        PostApiCall.postRequest(
          {
            quantity: 1000,
            outercoatingid: this.state.OuterCoatingId,
            innercoatingid: this.state.InnerCoatingData,
            // innercoatingid:
            //   this.state.NeedInnerCoating == "No"
            //     ? null
            //     : this.state.InnerCoatingData,
            varientid: this.state.VariantId,
            boardid: this.state.SelectedBoard,
          },
          "GetPrice"
        ).then((resultdes) =>
          resultdes.json().then((obj1) => {
            ardt.push(obj1.data);
            this.setState(
              {
                PriceData1: obj1.data,
              },
              () => {
                PostApiCall.postRequest(
                  {
                    destination_pin:
                      this.state.PickUpType != "StandardShipping"
                        ? 0
                        : this.state.Pincode,

                    source_pin: 600001,
                    weight: obj1.data[0].weight,
                    invoice_amount: obj1.data[0].price,
                    Gst: this.state.GST,
                  },
                  "GetDeliveryCharge"
                ).then((resultdes) =>
                  resultdes.json().then((obj5) => {
                    this.setState({
                      Deliverycharge1: obj5.data[0].delivery_charges,
                      NetAmount1: obj5.data[0].total_amount,
                      GstAmount1: obj5.data[0].gst_amount,
                      TotalAmount1: obj5.data[0].total_amount_with_gst,
                    });
                  })
                );
              }
            );
          })
        );
      } else if (i === 2) {
        PostApiCall.postRequest(
          {
            quantity: 2000,
            outercoatingid: this.state.OuterCoatingId,
            innercoatingid: this.state.InnerCoatingData,
            // innercoatingid:
            //   this.state.NeedInnerCoating == "No"
            //     ? null
            //     : this.state.InnerCoatingData,
            varientid: this.state.VariantId,
            boardid: this.state.SelectedBoard,
          },
          "GetPrice"
        ).then((resultdes) =>
          resultdes.json().then((obj2) => {
            ardt.push(obj2.data);
            this.setState(
              {
                PriceData2: obj2.data,
              },
              () => {
                PostApiCall.postRequest(
                  {
                    destination_pin:
                      this.state.PickUpType != "StandardShipping"
                        ? 0
                        : this.state.Pincode,

                    source_pin: 600001,
                    weight: obj2.data[0].weight,
                    invoice_amount: obj2.data[0].price,
                    Gst: this.state.GST,
                  },
                  "GetDeliveryCharge"
                ).then((resultdes) =>
                  resultdes.json().then((obj5) => {
                    this.setState({
                      Deliverycharge2: obj5.data[0].delivery_charges,
                      NetAmount2: obj5.data[0].total_amount,
                      GstAmount2: obj5.data[0].gst_amount,
                      TotalAmount2: obj5.data[0].total_amount_with_gst,
                    });
                  })
                );
              }
            );
          })
        );
      } else if (i === 3) {
        PostApiCall.postRequest(
          {
            quantity: 3000,
            outercoatingid: this.state.OuterCoatingId,
            innercoatingid: this.state.InnerCoatingData,
            // innercoatingid:
            //   this.state.NeedInnerCoating == "No"
            //     ? null
            //     : this.state.InnerCoatingData,
            varientid: this.state.VariantId,
            boardid: this.state.SelectedBoard,
          },
          "GetPrice"
        ).then((resultdes) =>
          resultdes.json().then((obj3) => {
            ardt.push(obj3.data);
            this.setState(
              {
                PriceData3: obj3.data,
              },
              () => {
                PostApiCall.postRequest(
                  {
                    destination_pin:
                      this.state.PickUpType != "StandardShipping"
                        ? 0
                        : this.state.Pincode,

                    source_pin: 600001,
                    weight: obj3.data[0].weight,
                    invoice_amount: obj3.data[0].price,
                    Gst: this.state.GST,
                  },
                  "GetDeliveryCharge"
                ).then((resultdes) =>
                  resultdes.json().then((obj5) => {
                    this.setState({
                      Deliverycharge3: obj5.data[0].delivery_charges,
                      NetAmount3: obj5.data[0].total_amount,
                      GstAmount3: obj5.data[0].gst_amount,
                      TotalAmount3: obj5.data[0].total_amount_with_gst,
                    });
                  })
                );
              }
            );
          })
        );
      } else if (i === 4) {
        PostApiCall.postRequest(
          {
            quantity: 4000,
            outercoatingid: this.state.OuterCoatingId,
            innercoatingid: this.state.InnerCoatingData,
            // innercoatingid:
            //   this.state.NeedInnerCoating == "No"
            //     ? null
            //     : this.state.InnerCoatingData,
            varientid: this.state.VariantId,
            boardid: this.state.SelectedBoard,
          },
          "GetPrice"
        ).then((resultdes) =>
          resultdes.json().then((obj4) => {
            ardt.push(obj4.data);
            this.setState(
              {
                PriceData4: obj4.data,
              },
              () => {
                PostApiCall.postRequest(
                  {
                    destination_pin:
                      this.state.PickUpType != "StandardShipping"
                        ? 0
                        : this.state.Pincode,

                    source_pin: 600001,
                    weight: obj4.data[0].weight,
                    invoice_amount: obj4.data[0].price,
                    Gst: this.state.GST,
                  },
                  "GetDeliveryCharge"
                ).then((resultdes) =>
                  resultdes.json().then((obj5) => {
                    this.setState({
                      Deliverycharge4: obj5.data[0].delivery_charges,
                      NetAmount4: obj5.data[0].total_amount,
                      GstAmount4: obj5.data[0].gst_amount,
                      TotalAmount4: obj5.data[0].total_amount_with_gst,
                    });
                  })
                );
              }
            );
          })
        );
      } else {
        PostApiCall.postRequest(
          {
            quantity: 5000,
            outercoatingid: this.state.OuterCoatingId,
            innercoatingid: this.state.InnerCoatingData,
            // innercoatingid:
            //   this.state.NeedInnerCoating == "No"
            //     ? null
            //     : this.state.InnerCoatingData,
            varientid: this.state.VariantId,
            boardid: this.state.SelectedBoard,
          },
          "GetPrice"
        ).then((resultdes) =>
          resultdes.json().then((obj5) => {
            ardt.push(obj5.data);
            this.setState(
              {
                PriceData5: obj5.data,
                ViewPriceSection: "Yes",
              },
              () => {
                PostApiCall.postRequest(
                  {
                    destination_pin:
                      this.state.PickUpType != "StandardShipping"
                        ? 0
                        : this.state.Pincode,

                    source_pin: 600001,
                    weight: obj5.data[0].weight,
                    invoice_amount: obj5.data[0].price,
                    Gst: this.state.GST,
                  },
                  "GetDeliveryCharge"
                ).then((resultdes) =>
                  resultdes.json().then((obj6) => {
                    this.setState({
                      Deliverycharge5: obj6.data[0].delivery_charges,
                      NetAmount5: obj6.data[0].total_amount,
                      GstAmount5: obj6.data[0].gst_amount,
                      TotalAmount5: obj6.data[0].total_amount_with_gst,
                    });
                  })
                );
              }
            );
          })
        );
      }
      this.setState({
        PricechangeData: ardt,
      });
      Notiflix.Loading.remove();
    }
  };

  // onPostPrice = () => {

  //     if (this.state.SelectedBoard != null) {
  //         if (this.state.PickUpType != '') {
  //             if (this.state.PickUpType == 'StandardShipping') {
  //                 if (this.state.Pincode != null) {
  //                     if (this.state.Pincode.length == 6) {
  //                         this.getPrice()
  //                     }
  //                     else {
  //                         Notiflix.Notify.failure('Please enter valid pincode')
  //                     }

  //                 }
  //                 else {
  //                     Notiflix.Notify.failure('Please enter pincode')
  //                 }

  //             }
  //             else {
  //                 this.getPrice()

  //             }
  //         }
  //         else {
  //             Notiflix.Notify.failure('Please Select Pickup type')
  //         }
  //     }
  //     else {
  //         Notiflix.Notify.failure('Please Select Quality of Board')
  //     }

  // }

  onPostPrice2 = () => {
    PostApiCall.postRequest(
      {
        quantity: this.state.Customquantity,
        outercoatingid: this.state.OuterCoatingId,
        innercoatingid: this.state.InnerCoatingData,
        // innercoatingid:
        //   this.state.NeedInnerCoating == "No"
        //     ? null
        //     : this.state.InnerCoatingData,
        varientid: this.state.VariantId,
        boardid: this.state.SelectedBoard,
      },
      "GetPrice"
    ).then((resultdes1) =>
      resultdes1.json().then((obj6) => {
        this.setState(
          {
            PriceData6: obj6.data[0].price_per_peace,
            weightForWhatsapp: obj6.data[0].weight,
          },
          () => {
            PostApiCall.postRequest(
              {
                destination_pin:
                  this.state.PickUpType != "StandardShipping"
                    ? 0
                    : this.state.Pincode,

                source_pin: 600001,
                weight: obj6.data[0].weight,
                invoice_amount: obj6.data[0].price,
                Gst: this.state.GST,
              },
              "GetDeliveryCharge"
            ).then((resultdes2) =>
              resultdes2.json().then((obj7) => {
                // console.log(obj7.data[0])
                if (obj7.data[0].error != null) {
                  Notiflix.Notify.failure(obj7.data[0].error);
                  this.setState({
                    errormessage: obj7.data[0].error,
                  });
                } else {
                  this.setState({
                    errormessage: obj7.data[0].error,
                    CustomQuantity_Deliverycharge:
                      obj7.data[0].delivery_charges,
                    CustomQuantity_NetAmount: obj7.data[0].total_amount,
                    CustomQuantity_GstAmount: obj7.data[0].gst_amount,
                    CustomQuantity_TotalAmount:
                      obj7.data[0].total_amount_with_gst,
                  });
                }
              })
            );
          }
        );
      })
    );
  };

  toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 0) {
      // setVisible(false)
    } else if (scrolled <= 0) {
      // setVisible(true)
    }
  };

  scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
    });
  };

  onClickPriceChange = () => {
    PostApiCall.postRequest(
      {
        quantity: 500,
        outercoatingid: this.state.OuterCoatingId,
        innercoatingid: this.state.InnerCoatingData,
        // innercoatingid:
        //   this.state.NeedInnerCoating == "No"
        //     ? null
        //     : this.state.InnerCoatingData,
        varientid: this.state.VariantId,
        boardid: this.state.SelectedBoard,
      },
      "GetPrice"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        // window.location.href = this.props.match.url + '#viewPrice'
        this.setState(
          {
            ChangePriceData: obj.data,
          },
          () => {
            PostApiCall.postRequest(
              {
                destination_pin:
                  this.state.PickUpType != "StandardShipping"
                    ? 0
                    : this.state.Pincode,
                source_pin: 600001,
                weight: obj.data[0].weight,
                invoice_amount: obj.data[0].price,
                Gst: this.state.GST,
              },
              "GetDeliveryCharge"
            ).then((resultdes) =>
              resultdes.json().then((obj5) => {
                this.setState({
                  Deliverycharge: obj5.data[0].delivery_charges,
                  NetAmount: obj5.data[0].total_amount,
                  GstAmount: obj5.data[0].gst_amount,
                  TotalAmount: obj5.data[0].total_amount_with_gst,
                });
              })
            );
          }
        );
      })
    );
  };

  onOrderNow = () => {
    Notiflix.Loading.circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var pickuptype =
      this.state.PickUpType == "StandardShipping"
        ? this.state.Pincode
        : this.state.PickUpType;

    this.setState(
      {
        OrderDiscription:
          '<thead class="orderheader"><tr>' +
          '<th class="ordersummarylist2" colSpan="2">Item Description</th>' +
          '<th class="ordersummarylist">Quantity</th>' +
          '<th class="ordersummarylist">Unit Price</th>' +
          '<th class="ordersummarylist">Shipping Cost</th>' +
          '<th class="ordersummarylist">GST (' +
          this.state.GST +
          "%)</th>" +
          '<th class="ordersummarylist">Total Amount</th>' +
          "</tr></thead>" +
          '<tbody><tr><td class="item-summary-image" style="width: 5%;">' +
          `<img style="width: 100%" src='${this.state.Viewlargeimage.split("$")[0]
          }'></img>` +
          "</td>" +
          '<td style="border-left: hidden">' +
          '<p style="text-align:justify">' +
          this.state.VerticalName +
          "</p>" +
          '<table class="innertable">' +
          "<tr>" +
          "<td>Box</td><td>: " +
          this.state.selectBoxSize +
          "</td></tr>" +
          "<tr><td>Outer Coating</td><td>: " +
          this.state.selectOuterCoating +
          "</td></tr><tr>" +
          "<td>Inner Coating</td><td>: " +
          this.state.NeedInnerCoating +
          "</td>" +
          "</tr>" +
          "</table>" +
          "</td>" +
          '<td class="ordersummarylist" style="text-align:center">' +
          this.state.Customquantity +
          "</td>" +
          '<td class="ordersummarylist" style="text-align: right">₹' +
          parseFloat(this.state.PriceData6).toFixed(2) +
          "</td>",
        CustomItemDescription: `<table style='text-align:left'>
          <tbody>
              <tr>
                  <td style='width:20%'>
                      <img style='width:100%' src=${this.state.Viewlargeimage} alt= />
                  </td>
              <td>
                  <table>
                      <tr>
                          <td>
                              <p style='text-align:justify'>${this.state.VerticalName}</p>
                          </td>
                      </tr>
                  <tr>
                      <td><strong>Box</strong></td>
                      <td> : </td>
                      <td>${this.state.selectBoxSize}</td>
                  </tr>
                  <tr>
                      <td><strong>Outer Coating</strong></td>
                      <td> : </td>
                      <td>${this.state.selectOuterCoating}</td>
                  </tr>
                  <tr>
                      <td><strong>Inner Coating</strong></td>
                      <td> : </td>
                      <td>${this.state.NeedInnerCoating}</td>
                  </tr>
                  </table>
              </td>

              </td>
              </tr>
          </tbody>
      </table>`,

        // '<td class="ordersummarylist" style="text-align: right">₹' + this.state.CustomQuantity_Deliverycharge.toLocaleString(undefined, { maximumFractionDigits: 2 }) + '</td>'
      },
      () => {
        PostApiCall.postRequest(
          {
            orderdate: moment().format("lll"),
            itemdetails: `
                                          [
                                          {
                                              "Item":${this.state.VariantId},
                                              "Quantity":${this.state.Customquantity}
                                          }
                                          ]
                                          `,
            orderdescription: this.state.OrderDiscription,
            shippingcharge: this.state.CustomQuantity_Deliverycharge,
            status: "INCART",
            customerid:
              localStorage.getItem("CustomerData") == null
                ? null
                : logindetails[0].fld_customerid,
            createdon: moment().format("lll"),
            updatedon: moment().format("lll"),
            orderid: 0,
            gst: this.state.CustomQuantity_GstAmount,
            totalamount: this.state.CustomQuantity_TotalAmount,
            updatedby:
              localStorage.getItem("CustomerData") == null
                ? null
                : logindetails[0].fld_customerid,
            designType: this.state.DesignType,
            outercoatingid: this.state.OuterCoatingId,
            innercoatingid: this.state.InnerCoatingData,
            // innercoatingid:
            //   this.state.NeedInnerCoating == "No"
            //     ? null
            //     : this.state.InnerCoatingData,
            boardid: this.state.SelectedBoard,
            deliverytype: this.state.PickUpType,
            CustomItemDescription: this.state.CustomItemDescription,
            designid: this.state.selectedDesignColor,
          },
          "AddToCartWebsite"
        ).then((result) =>
          result.json().then((obj) => {
            if (result.status == 200 || result.status == 201) {
              Notiflix.Loading.remove();
              // Notiflix.Notify.success('Product added to Cart.')
              // console.log(obj.data)
              if (localStorage.getItem("CustomerData") != null) {
                if (localStorage.getItem("OrderData") == null) {
                  localStorage.setItem("OrderData", JSON.stringify(obj.data));

                  window.location.href = "/selectaddress";
                } else {
                  localStorage.setItem("OrderData", JSON.stringify(obj.data));

                  window.location.href = "/selectaddress";
                }
              } else {
                localStorage.setItem("OrderData", JSON.stringify(obj.data));
                localStorage.setItem("currentPage", "Cart");
                window.location.href = "/login";
              }
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(obj.data);
            }
          })
        );
      }
    );
  };

  closeModalEscapeInnerLiquiedBarrel = (event) => {
    if (event.keyCode === 27) {
      this.closeModalInnerLiquiedBarrel();
    }
  };

  closeModalInnerLiquiedBarrel = () => {
    this.setState({
      innerLiquidBarriermodal: false,
    });
  };

  outerEscCoatingModal = (event) => {
    if (event.keyCode === 27) {
      this.outerCoatingModal();
    }
  };

  outerCoatingModal = () => {
    this.setState({
      innerCoatingOpen: false,
    });
  };

  pincodePopupModal = (event) => {
    if (event.keyCode === 27) {
      this.pincodePopup();
    }
  };

  pincodePopup = () => {
    this.setState({
      pincodepopup: false,
    });
  };

  qualityESCBoardModal = (event) => {
    if (event.keyCode === 27) {
      this.qualityBoardModal();
    }
  };

  qualityBoardModal = () => {
    this.setState({
      open: false,
    });
  };

  GetDeliverCoastModel = () => {
    this.setState({
      GetDeliverCoastModel: false,
      PickUpType: "SelfPickup",
    });
  };

  GetDeliveryCoastModelPopupModal = (event) => {
    if (event.keyCode === 27) {
      this.GetDeliverCoastModel();
    }
  };

  onSubmitWhatsapp = () => {
    if (this.state.weightForWhatsapp != null) {
      if (this.state.Pincode != null) {
        if (this.state.Pincode.length == 6) {
          window.open(
            `https://api.whatsapp.com/send?phone=919677027016&text= Requesting exact price for delivery to Pin Code : ${this.state.Pincode
            } and Weight: ${this.state.weightForWhatsapp
            } kg.The name of the item is: ${this.state.wspItemName
            } with a quantity of ${this.state.Customquantity
            }. The total billing value of selected product including GST is : ${this.state.CustomQuantity_TotalAmount == ""
              ? "0"
              : this.state.CustomQuantity_TotalAmount
            }`
          );
        } else {
          Notiflix.Notify.failure("Please enter valid pin code");
        }
      } else {
        Notiflix.Notify.failure("Please enter pin code");
      }
    } else {
      Notiflix.Notify.failure("Please select product Quantity");
    }
  };

  // **** Get Delivery Cost for desktop view ******

  onSubmitGetDeliveryCost = () => {
    if (this.state.weightForWhatsapp != null) {
      if (this.state.name != "") {
        if (this.state.mobile != "") {
          if (this.state.mobile.length == 10) {
            if (this.state.Pincode != null) {
              if (this.state.Pincode.length == 6) {
                console.log(this.state.Pincode);
                Notiflix.Loading.circle();
                PostApiCall.postRequest(
                  {
                    name: this.state.name,
                    mobile: this.state.mobile,
                    pincode: this.state.Pincode,
                    weight: this.state.weightForWhatsapp,
                    totalamount: this.state.CustomQuantity_TotalAmount,
                  },
                  "DeliveryCostMailer"
                ).then((results2) =>
                  results2.json().then((obj2) => {
                    if (results2.status == 200 || results2.status == 201) {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.success(
                        "Thank you, our team will contact you shortly!"
                      );
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                );
              } else {
                Notiflix.Notify.failure("Please enter valid pin code");
              }
            } else {
              Notiflix.Notify.failure("Please enter pin code");
            }
          } else {
            Notiflix.Notify.failure("Please enter valid mobile number");
          }
        } else {
          Notiflix.Notify.failure("Please enter your mobile number");
        }
      } else {
        Notiflix.Notify.failure("Please enter your name");
      }
    } else {
      Notiflix.Notify.failure("Please select product Quantity");
    }
  };

  // ***** share icon ****

  sharesocialicon = (id) => {
    this.setState({
      closesharetoggle: !this.state.closesharetoggle,
      shareProductunicicon: id,
    });
    document.getElementById("shareicon").classList.toggle("active");
  };

  // For Go Back Button
  handleGoBack = () => {
    const { history, location } = this.props;
    const path = location.pathname;

    // Check if the current path matches the specific routes
    if (path.startsWith("/customizemybox/")) {
      history.push("/onlinestore");
    } else {
      // Go back to the previous page
      history.goBack();
    }
  };

  // For mobile view

  handleGoBackMobile = () => {
    const { pathname } = this.props.history.location;

    if (this.state.mobileProduct) {
      if (pathname.startsWith("/customizemybox/")) {
        // Navigate to /onlinestore if mobileProduct mode and path matches
        this.props.history.push("/onlinestore");
      } else {
        this.props.history.goBack();
      }
    } else {
      // for show div mobile view
      this.setState({ mobileProduct: true });
    }
  };

  render() {
    window.addEventListener("scroll", this.state.toggleVisible);

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            initialSlide: 1,
          },
        },
      ],
    };

    const outerCoating = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1,
            //infinite: false,
            mobileFirst: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            //infinite: false,
            initialSlide: 1,
            mobileFirst: true,
          },
        },
      ],
    };

    return (
      <React.Fragment>
        {/* <div
          class="col-md-12 m-show online-ordering"
          id='onilne-store'
          style={{
            display: this.state.onlineStoreBtn == true ? "block" : "none",
          }}
        >
          <a
            class="mx-lg-3 btn shopnowbtn"
            href="/ordercustomboxes"
            style={{
              background: "#59c5d1",
              borderRadius: "3px",
              color: "white",
              width: "85%",
              boxShadow: "0px 0px 12px rgba(0,0,0,.5)",
              fontSize: "22px",
            }}
          >
            Online Store
          </a>
        </div> */}
        <Header />

        <Modal
          open={this.state.BestViewedModalClose == false ? false : false}
          center
        >
          <img src={BestViewImage}></img>
          <button
            className="btn designbtn active"
            onClick={() => {
              this.setState({
                BestViewedModalClose: false,
              });
            }}
          >
            Close
          </button>
        </Modal>

        {/* **** pincode modal ***** */}

        <Modal
          class="modal-content"
          open={this.state.pincodepopup}
          center
          onClose={this.pincodePopup}
          onEscKeyDown={this.pincodePopupModal}
        >
          <div class="modal-content modelcontent2">
            <div class="modal-body">
              <div class="row text-center align-content-center justify-content-center">
                <div className="col-5 d-flex align-items-center pl-1 pr-0">
                  <p className="p-0 m-0" style={{ fontSize: "14px" }}>
                    Enter Pin Code
                  </p>
                </div>
                <div className="col-7 pl-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Pin Code"
                    value={this.state.Pincode}
                    style={{ fontSize: "12px" }}
                    onChange={(text) => {
                      if (
                        this.state.NumRegex.test(text.target.value) &&
                        text.target.value.length <= 6
                      ) {
                        this.setState(
                          {
                            Pincode: text.target.value,
                          },
                          () => {
                            if (this.state.Pincode.length == 6) {
                              this.setState(this.onPostPrice2());
                            }
                          }
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-12 mt-2">
                  <p className="content-whtasapp-font">
                    After you submit a message will appear on your whatsapp
                    press send{" "}
                    <span>
                      <TiLocationArrow className="mx-1 whtasapp-send-message-font" />
                    </span>{" "}
                    for us to receive this message
                  </p>
                </div>
                <div className="col-12 mt-2 text-center justify-content-center">
                  <button
                    className="btn border-0 text-white submit-pincode-whatsapp"
                    onClick={() => {
                      this.onSubmitWhatsapp();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* // =======Outer Coating========== */}

        <Modal
          class="modal-content"
          open={this.state.innerCoatingOpen}
          center
          onClose={this.outerCoatingModal}
          onEscKeyDown={this.outerEscCoatingModal}
        >
          <div class="modal-content modelcontent2">
            <div class="modal-body">
              <div class="row text-center align-content-center justify-content-center">
                {this.state.innercoatingcontent.map((data, i) => (
                  <>
                    <div className="col-8 mb-2">
                      <img
                        src={this.state.outerchipimgdata}
                        className="img-drop-item-coating"
                      />
                    </div>
                    <div class="col-md-12">
                      <h5 className="rightCardtext">
                        {" "}
                        {data.fld_coating_name}
                      </h5>
                      <hr />
                      <p className="innerpara">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: data.fld_description,
                          }}
                        ></span>
                      </p>
                      <button
                        className="btn designbtn active"
                        onClick={() => {
                          this.setState({
                            innerCoatingOpen: false,
                          });
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          class="modal-content"
          open={this.state.innerLiquidBarriermodal}
          onClose={this.closeModalInnerLiquiedBarrel}
          onEscKeyDown={this.closeModalInnerLiquiedBarrel}
          center
        >
          <div class="modal-content modelcontent2">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <h5 className="rightCardtext">Inner Acrylic Coating </h5>
                  <hr />
                  <p className="innerpara">
                    This is an aqueous based acrylic coating with high water,
                    oil and grease resistance. 100% food safe and direct food
                    contact approved. This coating is 100% bio-degradable,
                    anti-bacterial & anti-fungal with zero plastic content. This
                    coasting is also approved for deep freeze packaging.
                  </p>
                  <button
                    onKeyDown={(event) => {
                      this.closeModalEscapeInnerLiquiedBarrel(event);
                    }}
                    className="btn designbtn active"
                    onClick={() => {
                      this.closeModalInnerLiquiedBarrel();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          class="modal-content"
          open={this.state.open}
          center
          onClose={this.qualityBoardModal}
          onEscKeyDown={this.qualityESCBoardModal}
        >
          <div class="modal-content modelcontent2">
            <div class="modal-body">
              <div class="row text-center align-content-center justify-content-center">
                <div className="col-8 mb-2">
                  <img
                    src={this.state.outerchipimgdata}
                    className="img-drop-item-coating"
                  />
                </div>
                <div class="col-md-12">
                  <div
                    style={{
                      display:
                        this.state.selectedBoardDesign3 == "Folding Box Board"
                          ? "block"
                          : "none",
                    }}
                  >
                    <h5 className="rightCardtext">What is Folding Box board</h5>
                    <hr />
                    <p className="innerpara">
                      Folding Box Board (FBB) is made a superior mix of
                      strength, print and conversion properties. The sustainably
                      sourced virgin fiber mix imparts a good bulk and bending
                      resistance The virgin fiber composition makes these boards
                      the best choice for food, pharmaceutical, personal care
                      and premium packaging.
                    </p>
                    <button
                      className="btn designbtn active"
                      onClick={() => {
                        this.setState({
                          selectedBoardDesign3: "",
                          SelectedBoardid: "",
                          open: false,
                        });
                      }}
                    >
                      Close
                    </button>
                  </div>
                  <div
                    style={{
                      display:
                        this.state.selectedBoardDesign3 == "W/B Duplex Board"
                          ? "block"
                          : "none",
                    }}
                  >
                    <h5 className="rightCardtext">
                      What is White Back Duplex Board
                    </h5>
                    <hr />
                    <p className="innerpara">
                      {" "}
                      White back duplex boards are made from recycled pulp. It
                      is coated on both sides and has a very low dirt count,
                      leading to superior surface smoothness and excellent print
                      reproduction. These boards have a coated smooth finish on
                      the outer side and a rough semi white finish on the inner
                      side. These boards are mostly recommended for blister pack
                      and for packaging cartons carrying light weight products.
                    </p>
                    <button
                      className="btn designbtn active"
                      onClick={() => {
                        this.setState({
                          selectedBoardDesign3: "",
                          SelectedBoardid: "",
                          open: false,
                        });
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* ***** For Desktop View Get Delivery Cost ****** */}

        <Modal
          class="modal-content"
          open={this.state.GetDeliverCoastModel}
          center
          onClose={this.GetDeliverCoastModel}
          onEscKeyDown={this.GetDeliveryCoastModelPopupModal}
          backdrop="static"
          keyboard={false}
        >
          <div class="modal-content modelcontent2">
            <div class="modal-body">
              <div class="row">
                <div className="col-12">
                  {/* <form class="row g-3"> */}
                  <div class="col-12">
                    <label class="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={this.state.name}
                      onChange={(text) => {
                        this.setState({
                          name: text.target.value,
                        });
                      }}
                    />
                  </div>
                  <div class="col-12 mt-3">
                    <label class="form-label">
                      Mobile Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={this.state.mobile}
                      onChange={(mobile) => {
                        if (
                          this.state.NumRegex.test(mobile.target.value) &&
                          mobile.target.value.length <= 10
                        ) {
                          this.setState({
                            mobile: mobile.target.value,
                          });
                        }
                      }}
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <label class="form-label">
                      Pin Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.Pincode}
                      style={{ fontSize: "12px" }}
                      onChange={(text) => {
                        if (
                          this.state.NumRegex.test(text.target.value) &&
                          text.target.value.length <= 6
                        ) {
                          this.setState(
                            {
                              Pincode: text.target.value,
                            },
                            () => {
                              if (this.state.Pincode.length == 6) {
                                this.setState(this.onPostPrice2());
                              }
                            }
                          );
                        }
                      }}
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <button
                      class="btn designbtn2 active mx-0"
                      onClick={() => {
                        this.onSubmitGetDeliveryCost();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* // =======Order Summary========== */}
        <div
          class="order-summary-modal"
          style={{
            marginBottom: this.state.orderSummaryOpen == true ? "0%" : "-250%",
          }}
        >
          <div class="d-flex justify-content-center align-content-center order-summary-close-top">
            <button
              class="btn-modal-close"
              title=""
              onClick={() => {
                this.setState({ orderSummaryOpen: false });
              }}
            >
              <i class="fa-regular fa-circle-xmark"></i>
            </button>
          </div>

          <div class="order-summary-modal-body">
            <div class="">
              <div class="row">
                <div class="col-md-6">
                  <div className="rightCard shipping-card">
                    <h5 className="rightCardtext">Select Shipping</h5>
                    <hr />
                    <h5 className="shippingheading">
                      <b>
                        How would you like your product to be delivered to you?
                      </b>
                    </h5>
                    <p className="shippingtext">
                      You can either pick up the product from our factory or we
                      can ship it to you with an added shipping cost calculated
                      on actuals.
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <select
                          class="shipping js-states form-control"
                          name="type"
                          value={this.state.PickUpType}
                          onChange={(text) => {
                            this.setState(
                              {
                                PickUpType: text.target.value,
                              },
                              () => {
                                if (
                                  this.state.PickUpType == "StandardShipping" &&
                                  this.state.Pincode != null
                                ) {
                                  this.setState(
                                    {
                                      orderSummaryOpen: true,
                                    },
                                    () => {
                                      this.onPostPrice2();
                                    }
                                  );
                                } else {
                                  if (
                                    this.state.PickUpType != "StandardShipping"
                                  ) {
                                    this.setState(
                                      {
                                        orderSummaryOpen: true,
                                      },
                                      () => {
                                        this.onPostPrice2();
                                      }
                                    );
                                  }
                                }
                              }
                            );
                          }}
                        >
                          <option value=""> Select Pickup Type </option>
                          {this.state.PickUpTypeData.map((data, i) => (
                            <option value={data.value} key={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        className="col-md-6 mt-2"
                        style={{
                          display:
                            this.state.PickUpType == "StandardShipping"
                              ? "block"
                              : "none",
                        }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your Pin Code"
                          value={this.state.Pincode}
                          onChange={(text) => {
                            if (
                              this.state.NumRegex.test(text.target.value) &&
                              text.target.value.length <= 6
                            ) {
                              this.setState(
                                {
                                  Pincode: text.target.value,
                                },
                                () => {
                                  if (this.state.Pincode.length == 6) {
                                    this.setState(
                                      {
                                        orderSummaryOpen: true,
                                      },
                                      () => {
                                        this.onPostPrice2();
                                      }
                                    );
                                  }
                                }
                              );
                            }
                          }}
                        ></input>
                      </div>
                    </div>
                  </div>

                  <div className="custome-box-srame">
                    <div class="style-box-top">
                      <div class="style-box-gray">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="style-box-blue">
                              Check out the product price
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="rightCardOuter2"
                      style={{ marginBottom: "0px", padding: "5px" }}
                    >
                      <div class="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              {/* <th>Quantity</th> */}
                              <th
                                className={
                                  this.state.CardSelected == "zero"
                                    ? "selectedRow"
                                    : "selectedRow1"
                                }
                              >
                                500 <span className="small">NOS</span>
                              </th>
                              <th
                                className={
                                  this.state.CardSelected == "1st"
                                    ? "selectedRow"
                                    : "selectedRow1"
                                }
                              >
                                1000 <span className="small">NOS</span>
                              </th>
                              <th
                                className={
                                  this.state.CardSelected == "2nd"
                                    ? "selectedRow"
                                    : "selectedRow1"
                                }
                              >
                                2000 <span className="small">NOS</span>
                              </th>
                              <th
                                className={
                                  this.state.CardSelected == "3rd"
                                    ? "selectedRow"
                                    : "selectedRow1"
                                }
                              >
                                3000 <span className="small">NOS</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {/* <td className="unitcost">
                                <b>Unit Cost</b>
                              </td> */}

                              {this.state.PriceData.map((data, i) => (
                                <td
                                  className={
                                    this.state.CardSelected == "zero"
                                      ? "selectedRow"
                                      : "selectedRow1"
                                  }
                                  onClick={() => {
                                    this.setState(
                                      {
                                        Customquantity: 500,
                                        CardSelected: "zero",
                                      },
                                      () => {
                                        this.onPostPrice2();
                                      }
                                    );
                                  }}
                                >
                                  <b className="unitcost">
                                    ₹{" "}
                                    {parseFloat(data.price_per_peace).toFixed(
                                      2
                                    )}{" "}
                                    <span className="small">Each</span>
                                  </b>
                                  <br />
                                </td>
                              ))}

                              {this.state.PriceData1.map((data, i) => (
                                <td
                                  onClick={() => {
                                    this.setState(
                                      {
                                        Customquantity: 1000,
                                        CardSelected: "1st",
                                      },
                                      () => {
                                        this.onPostPrice2();
                                      }
                                    );
                                  }}
                                  className={
                                    this.state.CardSelected == "1st"
                                      ? "selectedRow"
                                      : "selectedRow1"
                                  }
                                >
                                  <b className="unitcost">
                                    ₹{" "}
                                    {parseFloat(data.price_per_peace).toFixed(
                                      2
                                    )}{" "}
                                    <span className="small">Each</span>
                                  </b>
                                </td>
                              ))}
                              {this.state.PriceData2.map((data, i) => (
                                <td
                                  className={
                                    this.state.CardSelected == "2nd"
                                      ? "selectedRow"
                                      : "selectedRow1"
                                  }
                                  onClick={() => {
                                    this.setState(
                                      {
                                        Customquantity: 2000,
                                        CardSelected: "2nd",
                                      },
                                      () => {
                                        this.onPostPrice2();
                                      }
                                    );
                                  }}
                                >
                                  <b className="unitcost">
                                    ₹{" "}
                                    {parseFloat(data.price_per_peace).toFixed(
                                      2
                                    )}{" "}
                                    <span className="small">Each</span>
                                  </b>
                                </td>
                              ))}
                              {this.state.PriceData3.map((data, i) => (
                                <td
                                  className={
                                    this.state.CardSelected == "3rd"
                                      ? "selectedRow"
                                      : "selectedRow1"
                                  }
                                  onClick={() => {
                                    this.setState(
                                      {
                                        Customquantity: 3000,
                                        CardSelected: "3rd",
                                      },
                                      () => {
                                        this.onPostPrice2();
                                      }
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <b className="unitcost">
                                    ₹{" "}
                                    {parseFloat(data.price_per_peace).toFixed(
                                      2
                                    )}{" "}
                                    <span className="small">Each</span>
                                  </b>
                                </td>
                              ))}
                            </tr>
                            {/* <button>Click me</button> */}
                            <tr>
                              {/* <td className="unitcost">
                                <b></b>
                              </td> */}
                              <td className="unitcost">
                                <button
                                  className={
                                    this.state.CardSelected == "zero"
                                      ? "selectedRow btn shopnowbtn"
                                      : "selectedRow1 btn shopnowbtn"
                                  }
                                  onClick={() => {
                                    this.setState(
                                      {
                                        Customquantity: 500,
                                        CardSelected: "zero",
                                      },
                                      () => {
                                        this.onPostPrice2();
                                      }
                                    );
                                  }}
                                  style={{
                                    background: "#59c5d1",
                                    borderRadius: "3px",
                                    color: "white",
                                  }}
                                >
                                  Select
                                </button>
                              </td>
                              <td className="unitcost">
                                <button
                                  className={
                                    this.state.CardSelected == "1st"
                                      ? "selectedRow btn shopnowbtn"
                                      : "selectedRow1 btn shopnowbtn"
                                  }
                                  onClick={() => {
                                    this.setState(
                                      {
                                        Customquantity: 1000,
                                        CardSelected: "1st",
                                      },
                                      () => {
                                        this.onPostPrice2();
                                      }
                                    );
                                  }}
                                  style={{
                                    background: "#59c5d1",
                                    borderRadius: "3px",
                                    color: "white",
                                  }}
                                >
                                  Select
                                </button>
                              </td>
                              <td className="unitcost">
                                <button
                                  className={
                                    this.state.CardSelected == "2nd"
                                      ? "selectedRow btn shopnowbtn"
                                      : "selectedRow1 btn shopnowbtn"
                                  }
                                  onClick={() => {
                                    this.setState(
                                      {
                                        Customquantity: 2000,
                                        CardSelected: "2nd",
                                      },
                                      () => {
                                        this.onPostPrice2();
                                      }
                                    );
                                  }}
                                  style={{
                                    background: "#59c5d1",
                                    borderRadius: "3px",
                                    color: "white",
                                  }}
                                >
                                  Select
                                </button>
                              </td>
                              <td className="unitcost">
                                <button
                                  className={
                                    this.state.CardSelected == "3rd"
                                      ? "selectedRow btn shopnowbtn"
                                      : "selectedRow1 btn shopnowbtn"
                                  }
                                  onClick={() => {
                                    this.setState(
                                      {
                                        Customquantity: 3000,
                                        CardSelected: "3rd",
                                      },
                                      () => {
                                        this.onPostPrice2();
                                      }
                                    );
                                  }}
                                  style={{
                                    background: "#59c5d1",
                                    borderRadius: "3px",
                                    color: "white",
                                  }}
                                >
                                  Select
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      display:
                        this.state.ViewPriceSection == "Yes" ? "block" : "none",
                      marginTop: "10px",
                    }}
                    id="viewPrice"
                  >
                    <div className="col-md-12">
                      <div className="gridCard strength2">
                        <div className="table-responsive">
                          <table className="table mb-lg-3 mb-0">
                            <thead className="gridcardhead">
                              <tr className="headerrow">
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Shipping</th>
                                <th>GST Amount</th>
                                <th>Total Cost</th>
                              </tr>
                            </thead>

                            <tbody style={{ fontSize: "13px" }}>
                              <tr>
                                <td style={{ width: "45%" }}>
                                  <input
                                    className="form-control-custom-quantity"
                                    value={this.state.Customquantity}
                                    onChange={(text) => {
                                      if (
                                        this.state.NumRegex.test(
                                          text.target.value
                                        ) &&
                                        text.target.value.length <= 4
                                      ) {
                                        this.setState(
                                          {
                                            Customquantity: text.target.value,
                                          },
                                          () => {
                                            this.onPostPrice2();
                                          }
                                        );
                                      }
                                    }}
                                  />
                                  <b className="customInput customInput2">
                                    Enter Custom Qty
                                  </b>
                                  <p className="customInput hide-mobile">
                                    For bulk quantity / custom sizes{" "}
                                    <b style={{ color: "#59c5d1" }}>
                                      <a href="mailto:info@piopackaging.com">
                                        Click here
                                      </a>
                                    </b>
                                  </p>
                                </td>
                                <td style={{ width: "30%" }}>
                                  <b>
                                    ₹{" "}
                                    {this.state.PriceData6 != null
                                      ? parseFloat(
                                        this.state.PriceData6
                                      ).toFixed(2)
                                      : "0.00"}
                                  </b>
                                </td>
                                {this.state.CustomQuantity_Deliverycharge !==
                                  0 ? (
                                  <td>
                                    ₹{" "}
                                    {this.state.Customquantity != null &&
                                      this.state.Customquantity != ""
                                      ? parseFloat(
                                        this.state
                                          .CustomQuantity_Deliverycharge
                                      ).toFixed(2)
                                      : "0.00"}
                                  </td>
                                ) : (
                                  <td>₹ 0.00</td>
                                )}
                                <td>
                                  ₹
                                  {this.state.Customquantity != null &&
                                    this.state.Customquantity != ""
                                    ? parseFloat(
                                      this.state.CustomQuantity_GstAmount
                                    ).toFixed(2)
                                    : "0.00"}
                                </td>
                                <td>
                                  ₹
                                  {this.state.Customquantity != null &&
                                    this.state.Customquantity != ""
                                    ? this.state.CustomQuantity_TotalAmount.toLocaleString(
                                      undefined,
                                      { maximumFractionDigits: 2 }
                                    ) + ".00"
                                    : "0.00"}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div>
                            <p className="customInput hide-desktop">
                              For bulk quantity / custom sizes{" "}
                              <b style={{ color: "#59c5d1" }}>
                                <a href="mailto:info@piopackaging.com">
                                  Click here
                                </a>
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{ marginTop: "10px", marginBottom: "20px" }}
                  >
                    <div className="col-md-12">
                      <div className="gridCard strength2">
                        <div className="">
                          <div className="gridcardcheckbox">
                            <input
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              value={this.state.termscondition}
                              checked={
                                this.state.termscondition == "Yes"
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                if (this.state.termscondition == "Yes") {
                                  this.setState({
                                    termscondition: "No",
                                  });
                                } else {
                                  this.setState({
                                    termscondition: "Yes",
                                  });
                                }
                              }}
                            />
                            <p for="vehicle1" className="m-0">
                              {" "}
                              &nbsp;I agree to the{" "}
                              <a href="/termsandcondition">
                                Terms & Conditions
                              </a>
                            </p>
                          </div>
                          <button
                            className="btn designbtn2 mx-0 mb-2 active"
                            onClick={() => {
                              if (this.state.PickUpType != "") {
                                if (
                                  this.state.PickUpType == "StandardShipping"
                                ) {
                                  if (this.state.Pincode != null) {
                                    if (this.state.Pincode.length == 6) {
                                      if (
                                        this.state.Customquantity != null &&
                                        this.state.Customquantity != ""
                                      ) {
                                        if (this.state.termscondition == "Yes")
                                          if (this.state.errormessage == null) {
                                            this.onOrderNow();
                                          } else {
                                            Notiflix.Notify.failure(
                                              `${this.state.errormessage}`
                                            );
                                          }
                                        else {
                                          Notiflix.Notify.failure(
                                            "Please select terms and condition"
                                          );
                                        }
                                      } else {
                                        Notiflix.Notify.failure(
                                          "Please enter / select the quantity"
                                        );
                                      }
                                    } else {
                                      Notiflix.Notify.failure(
                                        "Please enter valid pin code"
                                      );
                                    }
                                  } else {
                                    Notiflix.Notify.failure(
                                      "Please enter pin code"
                                    );
                                  }
                                } else {
                                  if (
                                    this.state.Customquantity != null &&
                                    this.state.Customquantity != ""
                                  ) {
                                    if (this.state.termscondition == "Yes") {
                                      this.onOrderNow();
                                    } else {
                                      Notiflix.Notify.failure(
                                        "Please select terms and condition"
                                      );
                                    }
                                  } else {
                                    Notiflix.Notify.failure(
                                      "Please enter / select the quantity"
                                    );
                                  }
                                }
                              } else {
                                Notiflix.Notify.failure(
                                  "Please Select Pickup type"
                                );
                              }
                            }}
                          >
                            Order Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section style={{ paddingTop: "55px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 order-lg-1 order-2">
                <div className="section-title rightcarttoptext hide-mobile-second-section">
                  <h2 className="leftcardText">
                    {this.context.SelectedBoxName}
                  </h2>
                </div>
                {this.state.loadingimg == true ? (
                  <div class="skeletondesktop">
                    <Receivedreferralskelton />
                  </div>
                ) : (
                  <div className="row mx-lg-3 mx-0 hide-mobile-second-section mb-2">
                    <div className="col-md-3 smallimageflex order-lg-1 order-2 px-lg-0 px-0">
                      <div
                        className="row"
                        style={{ height: "100%", width: "75%" }}
                      >
                        {this.state.SelectedDesign2 != 0
                          ? this.state.SelectedDesign2.map((url, index) => (
                            <div className="col-sm-3 col-3 col-md-12 col-lg-12 px-lg-0">
                              <div
                                className="smallImage"
                                onClick={() => {
                                  this.setState({
                                    Viewlargeimage: url.fld_imageurl,
                                  });
                                }}
                              >
                                <img
                                  onClick={() => {
                                    this.setState({
                                      Viewlargeimage: url.fld_imageurl,
                                    });
                                  }}
                                  id={index}
                                  src={url.fld_imageurl}
                                  alt=""
                                  className="leftcardimg"
                                ></img>
                              </div>
                            </div>
                          ))
                          : ""}
                      </div>
                    </div>
                    <div className="col-md-9 col-12 col-sm-12 col-lg-9 viewlargeimg order-lg-2 order-1 px-0">
                      <div className="leftCard" style={{ height: "100%" }}>
                        <div className="largeImage" style={{ height: "100%" }}>
                          <img
                            src={this.state.Viewlargeimage}
                            alt=""
                            className="leftcardimg"
                            style={{ height: "auto" }}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="custome-box-srame hide-mobile">
                  <div class="style-box-top">
                    <div class="style-box-gray">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="style-box-blue">Product Description</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rightCardOuter2">
                    <div>
                      <p className="productdesc-para">
                        {Parser(
                          ("<p>" + this.state.ProductDetails + "</p>")
                            .replace(/font-family/g, "")
                            .replace(/\n/g, "")
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="custome-box-srame hide-mobile mt-2">
                  <div class="style-box-top">
                    <div class="style-box-gray">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="style-box-blue">
                            Check out the product price{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rightCardOuter2"
                    style={{ marginBottom: "0px", padding: "10px" }}
                  >
                    <div class="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Quantity</th>
                            {/* <th
                              className={
                                this.state.CardSelected == "zero"
                                  ? "selectedRow"
                                  : "selectedRow1"
                              }
                            >
                              500 nos
                            </th> */}
                            <th
                              className={
                                this.state.CardSelected == "1st"
                                  ? "selectedRow"
                                  : "selectedRow1"
                              }
                            >
                              1000 nos
                            </th>
                            <th
                              className={
                                this.state.CardSelected == "2nd"
                                  ? "selectedRow"
                                  : "selectedRow1"
                              }
                            >
                              2000 nos
                            </th>
                            <th
                              className={
                                this.state.CardSelected == "3rd"
                                  ? "selectedRow"
                                  : "selectedRow1"
                              }
                            >
                              3000 nos
                            </th>
                          </tr>{" "}
                        </thead>
                        <tbody>
                          <tr>
                            <td className="unitcost ">
                              <b>Unit Price</b>
                            </td>

                            {/* {this.state.PriceData.map((data, i) => (
                              <td
                                className={
                                  this.state.CardSelected == "zero"
                                    ? "selectedRow"
                                    : "selectedRow1"
                                }
                                onClick={() => {
                                  this.setState(
                                    {
                                      Customquantity: 500,
                                      CardSelected: "zero",
                                    },
                                    () => {
                                      this.onPostPrice2();
                                    }
                                  );
                                }}
                              >
                                <b className="unitcost">
                                  ₹{" "}
                                  {parseFloat(data.price_per_peace).toFixed(2)}
                                </b>
                                <br />
                              </td>
                            ))} */}

                            {this.state.PriceData1.map((data, i) => (
                              <td
                                onClick={() => {
                                  this.setState(
                                    {
                                      Customquantity: 1000,
                                      CardSelected: "1st",
                                    },
                                    () => {
                                      this.onPostPrice2();
                                    }
                                  );
                                }}
                                className={
                                  this.state.CardSelected == "1st"
                                    ? "selectedRow"
                                    : "selectedRow1"
                                }
                              >
                                <b className="unitcost">
                                  ₹{" "}
                                  {parseFloat(data.price_per_peace).toFixed(2)}
                                </b>
                              </td>
                            ))}
                            {this.state.PriceData2.map((data, i) => (
                              <td
                                className={
                                  this.state.CardSelected == "2nd"
                                    ? "selectedRow"
                                    : "selectedRow1"
                                }
                                onClick={() => {
                                  this.setState(
                                    {
                                      Customquantity: 2000,
                                      CardSelected: "2nd",
                                    },
                                    () => {
                                      this.onPostPrice2();
                                    }
                                  );
                                }}
                              >
                                <b className="unitcost">
                                  ₹{" "}
                                  {parseFloat(data.price_per_peace).toFixed(2)}
                                </b>
                              </td>
                            ))}
                            {this.state.PriceData3.map((data, i) => (
                              <td
                                className={
                                  this.state.CardSelected == "3rd"
                                    ? "selectedRow"
                                    : "selectedRow1"
                                }
                                onClick={() => {
                                  this.setState(
                                    {
                                      Customquantity: 3000,
                                      CardSelected: "3rd",
                                    },
                                    () => {
                                      this.onPostPrice2();
                                    }
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <b className="unitcost">
                                  ₹{" "}
                                  {parseFloat(data.price_per_peace).toFixed(2)}
                                </b>
                              </td>
                            ))}
                          </tr>
                          {/* <button>Click me</button> */}
                          <tr>
                            <td className="unitcost">
                              <b></b>
                            </td>
                            {/* <td className="unitcost">
                              <button
                                className={
                                  this.state.CardSelected == "zero"
                                    ? "selectedRow btn shopnowbtn"
                                    : "selectedRow1 btn shopnowbtn"
                                }
                                onClick={() => {
                                  this.setState(
                                    {
                                      Customquantity: 500,
                                      CardSelected: "zero",
                                    },
                                    () => {
                                      this.onPostPrice2();
                                    }
                                  );
                                }}
                                style={{
                                  background: "#59c5d1",
                                  borderRadius: "3px",
                                  color: "white",
                                }}
                              >
                                Select
                              </button>
                            </td> */}
                            <td className="unitcost">
                              <button
                                className={
                                  this.state.CardSelected == "1st"
                                    ? "selectedRow btn shopnowbtn"
                                    : "selectedRow1 btn shopnowbtn"
                                }
                                onClick={() => {
                                  this.setState(
                                    {
                                      Customquantity: 1000,
                                      CardSelected: "1st",
                                    },
                                    () => {
                                      this.onPostPrice2();
                                    }
                                  );
                                }}
                                style={{
                                  background: "#59c5d1",
                                  borderRadius: "3px",
                                  color: "white",
                                }}
                              >
                                Select
                              </button>
                            </td>
                            <td className="unitcost">
                              <button
                                className={
                                  this.state.CardSelected == "2nd"
                                    ? "selectedRow btn shopnowbtn"
                                    : "selectedRow1 btn shopnowbtn"
                                }
                                onClick={() => {
                                  this.setState(
                                    {
                                      Customquantity: 2000,
                                      CardSelected: "2nd",
                                    },
                                    () => {
                                      this.onPostPrice2();
                                    }
                                  );
                                }}
                                style={{
                                  background: "#59c5d1",
                                  borderRadius: "3px",
                                  color: "white",
                                }}
                              >
                                Select
                              </button>
                            </td>
                            <td className="unitcost">
                              <button
                                className={
                                  this.state.CardSelected == "3rd"
                                    ? "selectedRow btn shopnowbtn"
                                    : "selectedRow1 btn shopnowbtn"
                                }
                                onClick={() => {
                                  this.setState(
                                    {
                                      Customquantity: 3000,
                                      CardSelected: "3rd",
                                    },
                                    () => {
                                      this.onPostPrice2();
                                    }
                                  );
                                }}
                                style={{
                                  background: "#59c5d1",
                                  borderRadius: "3px",
                                  color: "white",
                                }}
                              >
                                Select
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div
                  className="row hide-mobile"
                  style={{
                    display:
                      this.state.ViewPriceSection == "Yes" ? "block" : "none",
                    marginTop: "10px",
                  }}
                  id="viewPrice"
                >
                  <div className="col-md-12">
                    <div className="gridCard strength2">
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="gridcardhead">
                            <tr className="headerrow">
                              <th className="unit-price-desktop">Quantity</th>
                              <th className="unit-price-desktop">Unit Price</th>
                              <th className="unit-price-desktop">Shipping</th>
                              <th className="unit-price-desktop">GST Amount</th>
                              <th className="unit-price-desktop">Total Cost</th>
                            </tr>
                          </thead>

                          <tbody style={{ fontSize: "15px" }}>
                            <tr>
                              <td
                                style={{ width: "35%" }}
                                className="pb-0 mb-0"
                              >
                                {" "}
                                <input
                                  className="form-control-custom-quantity"
                                  value={this.state.Customquantity}
                                  onChange={(text) => {
                                    if (
                                      this.state.NumRegex.test(
                                        text.target.value
                                      ) &&
                                      text.target.value.length <= 4
                                    ) {
                                      this.setState(
                                        {
                                          Customquantity: text.target.value,
                                        },
                                        () => {
                                          this.onPostPrice2();
                                        }
                                      );
                                    }
                                  }}
                                />
                                {/* <b className="customInput">
                                  Enter Custom Quantity
                                </b> */}
                                {/* <p className="customInput">
                                  For bulk quantity / custom sizes{" "}
                                  <b style={{ color: "#59c5d1" }}>
                                    <a href="mailto:info@piopackaging.com">
                                      Click here
                                    </a>
                                  </b>
                                </p> */}
                              </td>
                              <td>
                                <b>
                                  ₹{" "}
                                  {this.state.PriceData6 != null
                                    ? parseFloat(this.state.PriceData6).toFixed(
                                      2
                                    )
                                    : "0.00"}
                                </b>
                              </td>
                              {this.state.CustomQuantity_Deliverycharge !==
                                0 ? (
                                <td>
                                  ₹{" "}
                                  {this.state.Customquantity != null &&
                                    this.state.Customquantity != ""
                                    ? parseFloat(
                                      this.state.CustomQuantity_Deliverycharge
                                    ).toFixed(2)
                                    : "0.00"}
                                </td>
                              ) : (
                                <td>₹ 0.00</td>
                              )}
                              <td>
                                ₹
                                {this.state.Customquantity != null &&
                                  this.state.Customquantity != ""
                                  ? parseFloat(
                                    this.state.CustomQuantity_GstAmount
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                              <td>
                                ₹
                                {this.state.Customquantity != null &&
                                  this.state.Customquantity != ""
                                  ? this.state.CustomQuantity_TotalAmount.toLocaleString(
                                    undefined,
                                    { maximumFractionDigits: 2 }
                                  ) + ".00"
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr className="border-0 pt-0 mt-0">
                              <td colSpan="4" className="border-0 pt-0 mt-0">
                                <b className="customInput px-0">
                                  Enter Custom Quantity
                                </b>
                                <p className="customInput pt-0 mt-0 px-0">
                                  For bulk quantity / custom sizes{" "}
                                  <b style={{ color: "#59c5d1" }}>
                                    <a href="mailto:info@piopackaging.com">
                                      Click here
                                    </a>
                                  </b>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row hide-mobile"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <div className="col-12 col-md-7 pr-0">
                    <div className="rightCard hide-mobile m-0 shipping-option-tab">
                      <h5 className="rightCardtext">Select Shipping</h5>
                      <hr />
                      <h5 className="shippingheading">
                        <b>
                          How would you like your product to be delivered to
                          you?
                        </b>
                      </h5>
                      <p className="shippingtext">
                        You can either pick up the product from our factory or
                        we can ship it to you with an added shipping cost
                        calculated on actuals.
                      </p>
                      <div className="row">
                        <div className="col-md-12">
                          <select
                            class="shipping js-states form-control"
                            name="type"
                            value={this.state.PickUpType}
                            onChange={(text) => {
                              this.setState(
                                {
                                  PickUpType: text.target.value,
                                },
                                () => {
                                  if (
                                    this.state.PickUpType ==
                                    "StandardShipping" &&
                                    this.state.Pincode != null
                                  ) {
                                    this.setState(
                                      {
                                        orderSummaryOpen: true,
                                      },
                                      () => {
                                        this.onPostPrice2();
                                      }
                                    );
                                  } else {
                                    if (
                                      this.state.PickUpType !=
                                      "StandardShipping"
                                    ) {
                                      this.setState(
                                        {
                                          orderSummaryOpen: true,
                                        },
                                        () => {
                                          this.onPostPrice2();
                                        }
                                      );
                                    }
                                  }
                                }
                              );
                              if (text.target.value == "Get Delivery Cost") {
                                this.setState({
                                  GetDeliverCoastModel: true,
                                });
                              }
                            }}
                          >
                            <option value=""> Select Pickup Type </option>
                            {this.state.PickUpTypeData.map((data, i) => (
                              <option value={data.value} key={data.value}>
                                {data.label}
                              </option>
                            ))}
                            <option
                              className="getdeliverycoast"
                              value="Get Delivery Cost"
                            >
                              Get Delivery Cost
                            </option>
                          </select>
                        </div>
                        <div
                          className="col-md-6 mt-lg-0 mt-2"
                          style={{
                            display:
                              this.state.PickUpType == "StandardShipping"
                                ? "block"
                                : "none",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your Pin Code"
                            value={this.state.Pincode}
                            onChange={(text) => {
                              if (
                                this.state.NumRegex.test(text.target.value) &&
                                text.target.value.length <= 6
                              ) {
                                this.setState(
                                  {
                                    Pincode: text.target.value,
                                  },
                                  () => {
                                    if (this.state.Pincode.length == 6) {
                                      this.setState(
                                        {
                                          orderSummaryOpen: true,
                                        },
                                        () => {
                                          this.onPostPrice2();
                                        }
                                      );
                                    }
                                  }
                                );
                              }
                            }}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  ***** Payment Mode **** */}
                  <div className="col-12 col-md-5 pl-lg-2">
                    <div className="rightCard hide-mobile m-0 payment-mode-height payment-option-tab">
                      <h5 className="rightCardtext">Payment Options</h5>
                      <hr />
                      <ul className="px-2">
                        <li>Full Payment</li>
                        <li>Half Payment</li>
                        <li>Pay Later</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="row hide-mobile"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <div className="col-md-12">
                    <div className="gridCard strength2">
                      {/* <div className="d-flex justify-content-between p-2"> */}
                      <div className="agree-checkbox-order justify-content-between p-2">
                        <div className="gridordercardcheckbox ">
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={this.state.termscondition}
                            checked={
                              this.state.termscondition == "Yes" ? true : false
                            }
                            onChange={() => {
                              if (this.state.termscondition == "Yes") {
                                this.setState({
                                  termscondition: "No",
                                });
                              } else {
                                this.setState({
                                  termscondition: "Yes",
                                });
                              }
                            }}
                          />

                          <p for="vehicle1" className="m-0">
                            {" "}
                            &nbsp;I agree to the{" "}
                            <a href="/termsandcondition">Terms & Conditions</a>
                          </p>
                          <br />
                        </div>
                        <div className="check-btn">
                          <button
                            className="btn designbtn2 active"
                            onClick={() => {
                              if (this.state.PickUpType != "") {
                                if (
                                  this.state.PickUpType == "StandardShipping"
                                ) {
                                  if (this.state.Pincode != null) {
                                    if (this.state.Pincode.length == 6) {
                                      if (
                                        this.state.Customquantity != null &&
                                        this.state.Customquantity != ""
                                      ) {
                                        if (
                                          this.state.termscondition == "Yes"
                                        ) {
                                          if (this.state.errormessage == null) {
                                            this.onOrderNow();
                                          } else {
                                            Notiflix.Notify.failure(
                                              `${this.state.errormessage}`
                                            );
                                          }
                                        } else {
                                          Notiflix.Notify.failure(
                                            "Please select terms and condition"
                                          );
                                        }
                                      } else {
                                        Notiflix.Notify.failure(
                                          "Please enter / select the quantity"
                                        );
                                      }
                                    } else {
                                      Notiflix.Notify.failure(
                                        "Please enter valid pin code"
                                      );
                                    }
                                  } else {
                                    Notiflix.Notify.failure(
                                      "Please enter pin code"
                                    );
                                  }
                                } else {
                                  if (
                                    this.state.Customquantity != null &&
                                    this.state.Customquantity != ""
                                  ) {
                                    if (this.state.termscondition == "Yes") {
                                      this.onOrderNow();
                                    } else {
                                      Notiflix.Notify.failure(
                                        "Please select terms and condition"
                                      );
                                    }
                                  } else {
                                    Notiflix.Notify.failure(
                                      "Please enter / select the quantity"
                                    );
                                  }
                                }
                              } else {
                                Notiflix.Notify.failure(
                                  "Please Select Pickup type"
                                );
                              }
                            }}
                          >
                            Order Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-7 mt-3 rightcardbg order-lg-2 order-1 px-md-2 px-1">
                <button
                  className="btn designbtn2tab active go-back desktop-view-shop-order"
                  style={{ marginTop: "6px" }}
                  // onClick={() => {
                  //   this.props.history.goBack("/ordercustomboxes");
                  // }}
                  onClick={this.handleGoBack}
                >
                  Go Back
                </button>

                {/* ====Start Choose Box size card */}

                <div className="rightCardOuter rightcardshadow desktop-view-shop-order">
                  <div className="jss5">
                    <h4>Customize your Box & Check Prices</h4>
                  </div>

                  <div className="section-title rightcarttoptext">
                    <h4 className="rightcardheading">
                      Ordering Online is Easy!
                    </h4>
                    <p className="rightcardparag">
                      Explore different combinations & prices for your
                      Customised Boxes.
                    </p>
                  </div>
                  <div className="rightCard">
                    <h5 className="rightCardtext">Select your Box</h5>
                    <hr />

                    <Carousel responsive={responsive}>
                      {this.state.BoxSizeData.map((data, i) => (
                        <div className="col-md-12">
                          <div
                            className="position-relative"
                            onClick={() => {
                              this.context.setselectedBox(data.VariantImage);
                              this.context.setSelectedBoxName(
                                data.fld_variantname
                              );
                              this.setState(
                                {
                                  VariantId: data.fld_variantid,
                                  selectBoxSize: data.fld_variantname,
                                  GST: data.fld_Gstrate,
                                  selectedBoxColor: data.fld_variantid,
                                  ProductDetails: data.fld_short_description,
                                },
                                () => {
                                  this.onPostPrice();
                                  this.onPostPrice2();
                                  PostApiCall.postRequest(
                                    {
                                      recordCount: "*",
                                      variantid: data.fld_variantid,
                                    },
                                    "GetVariantImage"
                                  ).then((resultdes) =>
                                    resultdes.json().then((obj2) => {
                                      if (
                                        resultdes.status == 200 ||
                                        resultdes.status == 201
                                      ) {
                                        // console.log(obj2.data);
                                        this.setState(
                                          {
                                            loading: false,
                                            SelectedDesign: obj2.data,
                                          },
                                          () => {
                                            this.setState({
                                              SelectedDesign2: obj2.data,
                                              Viewlargeimage:
                                                obj2.data[0].fld_imageurl,
                                            });
                                          }
                                        );
                                      }
                                    })
                                  );
                                }
                              );
                            }}
                          >
                            <BackgroundImage
                              src={data.VariantImage}
                              width="131px"
                              height="131px"
                              isResponsive
                              alt=""
                              style={{
                                backgroundSize: "cover",
                                backgroundPosition: "center top",
                                cursor: "pointer",
                              }}
                              lazyLoad
                            />
                            {/* <img
                              src={data.VariantImage}
                              alt=""
                              style={{ cursor: "pointer" }}
                            ></img> */}
                          </div>
                          <div
                            className={
                              this.state.selectedBoxColor == data.fld_variantid
                                ? "cardtextactive"
                                : "cardtext"
                            }
                          >
                            <h6>{data.fld_variantname} </h6>
                          </div>
                          <button
                            className="btn designbtn active order mr-0"
                            style={{
                              display:
                                this.state.selectedBoxColor ==
                                  data.fld_variantid
                                  ? "block"
                                  : "none",
                            }}
                            onClick={() => {
                              window.location.href = `/ordersample/${data.fld_variantid}/${data.fld_variantname}`;
                            }}
                          >
                            Order Sample
                          </button>
                          {/* **** For social icon ***** */}
                          <a
                            style={{
                              display:
                                this.state.shareProductunicicon ==
                                  data.fld_variantid
                                  ? "block"
                                  : "none",
                            }}
                            href="javascript:void(0)"
                            onClick={() => this.sharesocialicon(null)}
                            className="product-share-icon-img"
                          >
                            <i class="fa fa-xmark"></i>
                          </a>
                          <a
                            style={{
                              display:
                                this.state.shareProductunicicon ==
                                  data.fld_variantid
                                  ? "none"
                                  : "block",
                            }}
                            href="javascript:void(0)"
                            onClick={() =>
                              this.sharesocialicon(data.fld_variantid)
                            }
                            className="product-share-icon-img"
                          >
                            <i class="fa fa-share-alt"></i>
                          </a>
                          <div
                            className="product-share-icon"
                            style={{
                              display:
                                this.state.shareProductunicicon ==
                                  data.fld_variantid
                                  ? "block"
                                  : "none",
                            }}
                            id="shareicon"
                          >
                            <ul>
                              <li>
                                <a href="JavaScript:void(0)">
                                  <i>
                                    <FacebookShareButton
                                      url={`https://www.piopackaging.com/pm/${data.fld_variant_route}`}
                                      quote={data.fld_variantname}
                                    >
                                      <FacebookIcon size={31} round />
                                    </FacebookShareButton>
                                  </i>
                                </a>
                              </li>
                              <li>
                                <a href="JavaScript:void(0)">
                                  <i>
                                    <InstapaperShareButton
                                      url={`https://www.piopackaging.com/pm/${data.fld_variant_route}`}
                                      quote={data.fld_variantname}
                                    >
                                      <InstapaperIcon size={31} round />
                                    </InstapaperShareButton>
                                  </i>
                                </a>
                              </li>
                              <li>
                                <a href="JavaScript:void(0)">
                                  <i>
                                    <WhatsappShareButton
                                      url={`https://www.piopackaging.com/pm/${data.fld_variant_route}`}
                                      // url={`http://localhost:3001/customizemybox/${data.fld_variantid}`}

                                      quote={data.fld_variantname}
                                    >
                                      <WhatsappIcon size={31} round />
                                    </WhatsappShareButton>
                                  </i>
                                </a>
                              </li>
                              <li>
                                <div
                                  class="fa fa-link link"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      `https://www.piopackaging.com/pm/${data.fld_variant_route}`
                                    );
                                    Notiflix.Notify.success(
                                      "Link Copied to Clipboard"
                                    );
                                  }}
                                ></div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </div>

                  {/* ====End Choose Box size card */}

                  <div className="rightCard hide-desktop ">
                    <div className="section-title rightcarttoptext hide-desktop">
                      <h2 className="leftcardText">
                        {this.context.SelectedBoxName}
                      </h2>
                    </div>
                    {this.state.loadingimg == true ? (
                      <div className="skeletonmobile">
                        <Receivedreferralskelton />
                      </div>
                    ) : (
                      <div className="row mx-lg-3 mx-0 hide-desktop bg-white">
                        <div className="col-lg-3 smallimageflex order-lg-1 order-2 px-lg-0 px-0 mb-2">
                          <div className="row">
                            {this.state.SelectedDesign2 != 0
                              ? this.state.SelectedDesign2.map((url, index) => (
                                <div className="col-sm-3 col-3 col-md-3 col-lg-12 px-lg-0">
                                  <div
                                    className="smallImage"
                                    onClick={() => {
                                      this.setState({
                                        Viewlargeimage: url.fld_imageurl,
                                      });
                                    }}
                                  >
                                    <img
                                      src={url.fld_imageurl}
                                      id={index}
                                      onClick={() => {
                                        this.setState({
                                          Viewlargeimage: url.fld_imageurl,
                                        });
                                      }}
                                      alt=""
                                      className="leftcardimg"
                                    ></img>
                                  </div>
                                </div>
                              ))
                              : ""}
                          </div>
                        </div>
                        <div className="col-lg-9 col-12 col-sm-12 col-lg-9 viewlargeimg order-lg-2 order-1 pr-lg-3 px-0">
                          <div className="leftCard">
                            <div className="largeImage">
                              <img
                                src={this.state.Viewlargeimage}
                                alt=""
                                className="leftcardimg"
                              ></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <div className="custome-box-srame hide-desktop mt-2"> */}
                    <div className="custome-box-srame hide-mobile-second-section mt-2">
                      <div class="style-box-top">
                        <div class="style-box-gray">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="style-box-blue">
                                Product Description
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="rightCardOuter2">
                        <div>
                          <p className="productdesc-para">
                            {Parser(
                              ("<p>" + this.state.ProductDetails + "</p>")
                                .replace(/font-family/g, "")
                                .replace(/\n/g, "")
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* =======Outer Coating */}

                  <div className="rightCard">
                    <h5 className="rightCardtext">Select Outer Coating</h5>
                    <hr />

                    <Carousel responsive={responsive1}>
                      {this.state.CoatingData.map((data, i) => (
                        <div
                          className="col-md-12"
                          onClick={() => {
                            this.context.setselectedOuterCoating(
                              data.fld_image
                            );
                            this.setState(
                              {
                                OuterCoatingId: data.fld_coating_id,
                                selectOuterCoating: data.fld_coating_name,
                              },
                              () => {
                                this.onPostPrice();
                                this.onPostPrice2();
                              }
                            );
                          }}
                        >
                          <div>
                            <BackgroundImage
                              src={data.fld_image}
                              width="243px"
                              height="212px"
                              isResponsive
                              alt=""
                              style={{
                                backgroundSize: "cover",
                                backgroundPosition: "center top",
                                cursor: "pointer",
                              }}
                              lazyLoad
                            />
                          </div>
                          <div
                            className={
                              this.state.OuterCoatingId == data.fld_coating_id
                                ? "cardtextactive"
                                : "cardtext"
                            }
                          >
                            <h6>
                              {data.fld_coating_name}{" "}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                style={{
                                  cursor: "pointer",
                                  float: "right",
                                  fontSize: "20px",
                                }}
                                onClick={() => {
                                  PostApiCall.postRequest(
                                    {
                                      recordCount:
                                        "fld_coating_name,fld_description",
                                      whereClause: `where fld_coating_id=${data.fld_coating_id}`,
                                    },
                                    "GetCoatingdata"
                                  ).then((resultdes) =>
                                    resultdes.json().then((obj3) => {
                                      this.setState(
                                        {
                                          innercoatingcontent: obj3.data,
                                        },
                                        () => {
                                          this.setState({
                                            innerCoatingOpen: true,
                                          });
                                        }
                                      );
                                    })
                                  );
                                }}
                              ></i>
                            </h6>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </div>

                  {/* ==========end outer coating======= */}

                  {/* ==========inner coating start====== */}
                  <div className="rightCard">
                    <h5 className="rightCardtext">
                      Select Inner Liquid / Oil Barrier Coating
                    </h5>
                    <hr />
                    <Carousel responsive={responsive1}>
                      {this.state.InnerCoating.length != 0 ? (
                        this.state.InnerCoating.map((data, i) => (
                          <div
                            className="col-md-12"
                            onClick={() => {
                              this.setState(
                                {
                                  innerBarrierCoating: "Yes",
                                  NeedInnerCoating: "Yes",
                                  InnerCoatingData: data.fld_coating_id,
                                },
                                () => {
                                  this.onPostPrice();
                                  this.onPostPrice2();
                                }
                              );
                            }}
                          >
                            <div>
                              <BackgroundImage
                                src={data.fld_image}
                                width="243px"
                                height="212px"
                                isResponsive
                                alt=""
                                style={{
                                  backgroundSize: "cover",
                                  backgroundPosition: "center top",
                                  cursor: "pointer",
                                }}
                                lazyLoad
                              />
                            </div>
                            <div
                              className={
                                this.state.InnerCoatingData ==
                                  data.fld_coating_id
                                  ? "cardtextactive"
                                  : "cardtext"
                              }
                            >
                              <h6>
                                {data.fld_coating_name}{" "}
                                <i
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                  style={{
                                    cursor: "pointer",
                                    float: "right",
                                    fontSize: "20px",
                                  }}
                                  onClick={() => {
                                    PostApiCall.postRequest(
                                      {
                                        recordCount:
                                          "fld_coating_name,fld_description",
                                        whereClause: `where fld_coating_id=${data.fld_coating_id}`,
                                      },
                                      "GetCoatingdata"
                                    ).then((resultdes) =>
                                      resultdes.json().then((obj3) => {
                                        this.setState(
                                          {
                                            innercoatingcontent: obj3.data,
                                          },
                                          () => {
                                            this.setState({
                                              innerCoatingOpen: true,
                                            });
                                          }
                                        );
                                      })
                                    );
                                  }}
                                ></i>
                              </h6>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </Carousel>
                  </div>
                  {/* =========inner coating end======= */}
                  {/* ======quality of board */}

                  <div className="rightCard">
                    <h5 className="rightCardtext">Select Quality of Board</h5>
                    <hr />
                    <Carousel responsive={responsive1}>
                      {this.state.BoardData.map((data, i) => {
                        return (
                          <>
                            <div
                              class="col-md-12 mb-3"
                              tabindex="-1"
                              style={{
                                width: "100%",
                                display: "inline-block",
                              }}
                            >
                              <div
                                className=" position-relative"
                                onClick={() => {
                                  this.context.setselectedQualityBoard(
                                    data.fld_image
                                  );
                                  this.setState(
                                    {
                                      SelectedBoard: data.fld_board_id,
                                      selectedBoardDesign: data.fld_gsm,
                                      selectedBoardDesign2: data.fld_board_name,
                                    },
                                    () => {
                                      this.onPostPrice();
                                      this.onPostPrice2();
                                    }
                                  );
                                }}
                              >
                                <BackgroundImage
                                  src={data.fld_image}
                                  width="243px"
                                  height="212px"
                                  isResponsive
                                  alt=""
                                  style={{
                                    backgroundSize: "cover",
                                    backgroundPosition: "center top",
                                    cursor: "pointer",
                                  }}
                                  lazyLoad
                                />
                                {/* <img
                                  src={data.fld_image}
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                /> */}
                                {data.fld_board_name == "Folding Box Board" ? (
                                  <div className="w-50 recommendedimage-badge">
                                    <img src={Recommended}></img>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div
                                className={
                                  this.state.SelectedBoard == data.fld_board_id
                                    ? "cardtextactive"
                                    : "cardtext"
                                }
                              >
                                <h6>
                                  {data.fld_board_name}{" "}
                                  <i
                                    class="fa fa-info-circle"
                                    aria-hidden="true"
                                    style={{
                                      cursor: "pointer",
                                      float: "right",
                                      fontSize: "20px",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        selectedBoardDesign3:
                                          data.fld_board_name,
                                        SelectedBoardid: data.fld_board_id,
                                        open: true,
                                      });
                                    }}
                                  ></i>
                                </h6>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Carousel>
                  </div>

                  {/* ==========End Quality of board */}

                  {/* ======Select Shipping */}

                  <div className="get-quote-section hide-desktop-quote">
                    <button
                      className="btn designbtn2 active go-back m-0"
                      onClick={() => {
                        this.setState(
                          {
                            orderSummaryOpen: true,
                          },
                          () => {
                            this.onPostPrice2();
                          }
                        );
                      }}
                    >
                      Get Quote
                    </button>
                  </div>
                </div>

                {/* ********************* Mobile view changes ********************************** */}

                {/* ====Start Choose Box size card for mobile view*/}

                <div className="container-fluid mt-1 phn-view-shop-order phn-goback-bottom mx-0 px-0">
                  <button
                    className="btn designbtn2 active go-back"
                    style={{ marginTop: "6px" }}
                    // onClick={() => {
                    //   this.state.mobileProduct == true
                    //     ? this.props.history.goBack("/ordercustomboxes")
                    //     : this.setState({
                    //       mobileProduct: true,
                    //     });
                    // }}
                    onClick={this.handleGoBackMobile}
                  >
                    Go Back
                  </button>
                </div>

                {/* **** For social icon ***** */}
                {/* <div className="position-absolute">
                  <a
                    style={{
                      display:
                        this.state.shareProductunicicon == data.fld_variantid
                          ? "block"
                          : "none",
                    }}
                    href="javascript:void(0)"
                    onClick={() => this.sharesocialicon(null)}
                    className="product-share-icon-img"
                  >
                    <i class="fa fa-xmark"></i>
                  </a>
                  <a
                    style={{
                      display:
                        this.state.shareProductunicicon == data.fld_variantid
                          ? "none"
                          : "block",
                    }}
                    href="javascript:void(0)"
                    onClick={() => this.sharesocialicon(data.fld_variantid)}
                    className="product-share-icon-img"
                  >
                    <i class="fa fa-share-alt"></i>
                  </a>
                </div> */}
                <div
                  className="container-fluid px-0 phn-view-shop-order"
                  style={{
                    display:
                      this.state.mobileProduct == true ? "block" : "none",
                  }}
                >
                  <div className="jss5">
                    <h4 className="text-center">Select Item</h4>
                  </div>
                  <div className="row mt-4 mx-0">
                    {this.state.BoxSizeData.map((data, i) => (
                      <div className="col-6 mb-4 px-0">
                        {/* **** For social icon ***** */}
                        <div>
                          <a
                            style={{
                              display:
                                this.state.shareProductunicicon ==
                                  data.fld_variantid
                                  ? "block"
                                  : "none",
                            }}
                            href="javascript:void(0)"
                            onClick={() => this.sharesocialicon(null)}
                            className="product-share-icon-img fa-share-icon"
                          >
                            <i class="fa fa-xmark fa-share-icon"></i>
                          </a>
                          <a
                            style={{
                              display:
                                this.state.shareProductunicicon ==
                                  data.fld_variantid
                                  ? "none"
                                  : "block",
                            }}
                            href="javascript:void(0)"
                            onClick={() =>
                              this.sharesocialicon(data.fld_variantid)
                            }
                            className="product-share-icon-img fa-share-icon"
                          >
                            <i class="fa fa-share-alt fa-share-icon"></i>
                          </a>
                        </div>

                        <div
                          className="mx-1"
                          onClick={() => {
                            this.setState({ mobileProduct: false });
                          }}
                        >
                          <div
                            onClick={() => {
                              this.context.setselectedBox(data.VariantImage);
                              this.context.setSelectedBoxName(
                                data.fld_variantname
                              );
                              this.setState(
                                {
                                  VariantId: data.fld_variantid,
                                  selectBoxSize: data.fld_variantname,
                                  GST: data.fld_Gstrate,
                                  selectedBoxColor: data.fld_variantid,
                                  ProductDetails: data.fld_long_description,
                                },
                                () => {
                                  this.onPostPrice();
                                  this.onPostPrice2();
                                  PostApiCall.postRequest(
                                    {
                                      recordCount: "*",
                                      variantid: data.fld_variantid,
                                    },
                                    "GetVariantImage"
                                  ).then((resultdes) =>
                                    resultdes.json().then((obj2) => {
                                      if (
                                        resultdes.status == 200 ||
                                        resultdes.status == 201
                                      ) {
                                        this.setState(
                                          {
                                            loading: false,
                                            SelectedDesign: obj2.data,
                                          },
                                          () => {
                                            this.setState({
                                              SelectedDesign2: obj2.data,
                                              Viewlargeimage:
                                                obj2.data[0].fld_imageurl,
                                            });
                                          }
                                        );
                                      }
                                    })
                                  );
                                }
                              );
                            }}
                          >
                            <BackgroundImage
                              src={data.VariantImage}
                              width="131px"
                              height="131px"
                              isResponsive
                              alt=""
                              style={{
                                backgroundSize: "cover",
                                backgroundPosition: "center top",
                                cursor: "pointer",
                              }}
                              lazyLoad
                            />
                          </div>
                          <div
                            className={
                              this.state.selectedBoxColor == data.fld_variantid
                                ? "cardtextactive new-product-page-item-content"
                                : "cardtext new-product-page-item-content"
                            }
                          >
                            <h6 className="select-variant-name-font">
                              {data.fld_variantname}{" "}
                            </h6>
                          </div>
                          <button
                            className="btn designbtn active order mr-0"
                            style={{
                              display:
                                this.state.selectedBoxColor ==
                                  data.fld_variantid
                                  ? "block"
                                  : "block",
                            }}
                            onClick={() => {
                              window.location.href = `/ordersample/${data.fld_variantid}/${data.fld_variantname}`;
                            }}
                          >
                            Order Sample
                          </button>

                          <div
                            className="product-share-icon"
                            style={{
                              display:
                                this.state.shareProductunicicon ==
                                  data.fld_variantid
                                  ? "block"
                                  : "none",
                            }}
                            id="shareicon"
                          >
                            <ul>
                              <li>
                                <a href="JavaScript:void(0)">
                                  <i>
                                    <FacebookShareButton
                                      url={`https://www.piopackaging.com/pm/${data.fld_variant_route}`}
                                      quote={data.fld_variantname}
                                    >
                                      <FacebookIcon size={31} round />
                                    </FacebookShareButton>
                                  </i>
                                </a>
                              </li>
                              <li>
                                <a href="JavaScript:void(0)">
                                  <i>
                                    <InstapaperShareButton
                                      url={`https://www.piopackaging.com/pm/${data.fld_variant_route}`}
                                      quote={data.fld_variantname}
                                    >
                                      <InstapaperIcon size={31} round />
                                    </InstapaperShareButton>
                                  </i>
                                </a>
                              </li>
                              <li>
                                <a href="JavaScript:void(0)">
                                  <i>
                                    <WhatsappShareButton
                                      url={`https://www.piopackaging.com/pm/${data.fld_variant_route}`}
                                      // url={`http://localhost:3001/customizemybox/${data.fld_variantid}`}

                                      quote={data.fld_variantname}
                                    >
                                      <WhatsappIcon size={31} round />
                                    </WhatsappShareButton>
                                  </i>
                                </a>
                              </li>
                              <li>
                                <div
                                  class="fa fa-link link"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      `https://www.piopackaging.com/pm/${data.fld_variant_route}`
                                    );
                                    Notiflix.Notify.success(
                                      "Link Copied to Clipboard"
                                    );
                                  }}
                                ></div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* test */}

                <div
                  className="rightCardOuter card-grey-bg-box rightcardshadow phn-view-shop-order"
                  style={{
                    display:
                      this.state.mobileProduct == true ? "none" : "block",
                  }}
                >
                  <div className="jss5">
                    <h4>Customize your Box & Check Prices </h4>
                  </div>

                  <div className="section-title rightcarttoptext">
                    <h4 className="rightcardheading">
                      Ordering Online is Easy!
                    </h4>
                    <p className="rightcardparag">
                      Explore different combinations & prices for your
                      Customised Boxes.
                    </p>
                  </div>
                  <div className="rightCard pricecard-space">
                    <h2 className="leftcardText">
                      {this.context.SelectedBoxName}
                    </h2>
                    <hr />
                    <div className="container-fluid px-0">
                      {this.state.loadingimg == true ? (
                        <div className="skeletonmobile">
                          <Receivedreferralskeltonphoneview />
                        </div>
                      ) : (
                        <div className="row mx-lg-3 mx-0  bg-white">
                          <div className="col-6 pb-1 px-0 mb-4 card-left-img-shop-outer">
                            <img
                              src={this.state.Viewlargeimage}
                              className="main-img-size main-img-size-height"
                            />
                          </div>
                          <div className="col-6">
                            <div className="row card-right-img-shop-outer">
                              {this.state.SelectedDesign2 != 0
                                ? this.state.SelectedDesign2.map(
                                  (url, index) => (
                                    <div className="col-6 px-0 right-product-image-main-box">
                                      <div
                                        className="ml-1 pb-1 small-rigth-product-images-size"
                                        onClick={() => {
                                          this.setState({
                                            Viewlargeimage: url.fld_imageurl,
                                          });
                                        }}
                                      >
                                        <img
                                          src={url.fld_imageurl}
                                          alt=""
                                          style={{
                                            backgroundSize: "cover",
                                            backgroundPosition: "center top",
                                            cursor: "pointer",
                                          }}
                                        ></img>
                                      </div>
                                    </div>
                                  )
                                )
                                : ""}
                            </div>
                          </div>
                        </div>
                      )}

                      {/* ********* Product description *********** */}
                      <div className="custome-box-srame hide-desktop my-3">
                        <div class="style-box-top">
                          <div class="style-box-gray">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="style-box-blue bg-white pb-0 product-description-color px-2">
                                  Product Description
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rightCardOuter2 px-2 productdetailspoints pt-0">
                          <div>
                            <p className="productdesc-para">
                              {Parser(
                                ("<p>" + this.state.ProductDetails + "</p>")
                                  .replace(/font-family/g, "")
                                  .replace(/\n/g, "")
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ****** Select Outer Coating ******** */}

                    <div class="row align-items-center">
                      <div class="col-12">
                        <label>Select Outer Coating</label>
                      </div>
                      <div class="col-12">
                        <div className="row main-parent-outer-chips mx-0">
                          {this.state.CoatingData.map((data, i) => (
                            <div className="col-3 px-0">
                              <div
                                className={
                                  this.state.OuterCoatingId ==
                                    data.fld_coating_id
                                    ? "coat-drop-menu-active-bg mb-2 px-1 mr-1 inner-coat-drop chips-fontsize detailsicon chips-position product-content-main-box"
                                    : "mb-2 inner-coat-drop mr-1 px-1 chips-position product-content-main-box"
                                }
                                onClick={() => {
                                  this.context.setselectedOuterCoating(
                                    data.fld_image
                                  );
                                  this.setState(
                                    {
                                      OuterCoatingId: data.fld_coating_id,
                                      selectOuterCoating: data.fld_coating_name,
                                      OuterCoatingDropLable:
                                        data.fld_coating_name,
                                    },
                                    () => {
                                      this.onPostPrice();
                                      this.onPostPrice2();
                                    }
                                  );
                                }}
                              >
                                <h6
                                  className={
                                    this.state.OuterCoatingId ==
                                      data.fld_coating_id
                                      ? "chips-fontsize justify-content-between product-phn-img-heading"
                                      : "justify-content-between product-phn-img-heading"
                                  }
                                >
                                  {data.fld_coating_name}
                                </h6>
                                <div className="info-icon-product-outer">
                                  <i
                                    class="fa fa-info-circle info-icon-card mx-2 icon-outer-details"
                                    aria-hidden="true"
                                    onClick={() => {
                                      this.setState({
                                        outerchipimgdata: data.fld_image,
                                      });
                                      PostApiCall.postRequest(
                                        {
                                          recordCount:
                                            "fld_coating_name,fld_description",
                                          whereClause: `where fld_coating_id=${data.fld_coating_id}`,
                                        },
                                        "GetCoatingdata"
                                      ).then((resultdes) =>
                                        resultdes.json().then((obj3) => {
                                          this.setState(
                                            {
                                              innercoatingcontent: obj3.data,
                                            },
                                            () => {
                                              this.setState({
                                                innerCoatingOpen: true,
                                              });
                                            }
                                          );
                                        })
                                      );
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* ****** Select Inner Coating ******** */}

                    <div class="row align-items-center mt-2">
                      <div class="col-12">
                        <label>Select Inner Liquid / Oil Barrier Coating</label>
                      </div>
                      <div class="col-12">
                        <div className="row main-parent-outer-chips mx-0">
                          {/* **** Take no coating from outer coating ***** */}
                          {this.state.InnerCoating.length != 0 ? (
                            this.state.InnerCoating.map((data, i) => (
                              <div className="col-4 px-0">
                                <div
                                  className={
                                    this.state.InnerCoatingData ==
                                      data.fld_coating_id
                                      ? "coat-drop-menu-active-bg mr-1 mb-2 px-2 inner-coat-drop chips-fontsize detailsicon chips-position product-content-main-box"
                                      : "mr-1 mb-2 inner-coat-drop px-2 chips-position product-content-main-box"
                                  }
                                  onClick={() => {
                                    this.setState(
                                      {
                                        innerBarrierCoating: "Yes",
                                        NeedInnerCoating: "Yes",
                                        InnerCoatingData: data.fld_coating_id,
                                        InnerCoatingDropLable:
                                          data.fld_coating_name,
                                      },
                                      () => {
                                        this.onPostPrice();
                                        this.onPostPrice2();
                                      }
                                    );
                                  }}
                                >
                                  <h6
                                    className={
                                      this.state.InnerCoatingData ==
                                        data.fld_coating_id
                                        ? "chips-fontsize justify-content-between product-phn-img-heading"
                                        : "justify-content-between product-phn-img-heading"
                                    }
                                  >
                                    {data.fld_coating_name}
                                  </h6>
                                  <div className="info-icon-product-outer">
                                    <i
                                      class="fa fa-info-circle info-icon-card mx-2 icon-outer-details"
                                      aria-hidden="true"
                                      onClick={() => {
                                        this.setState({
                                          outerchipimgdata: data.fld_image,
                                        });
                                        PostApiCall.postRequest(
                                          {
                                            recordCount:
                                              "fld_coating_name,fld_description",
                                            whereClause: `where fld_coating_id=${data.fld_coating_id}`,
                                          },
                                          "GetCoatingdata"
                                        ).then((resultdes) =>
                                          resultdes.json().then((obj3) => {
                                            this.setState(
                                              {
                                                innercoatingcontent: obj3.data,
                                              },
                                              () => {
                                                this.setState({
                                                  innerCoatingOpen: true,
                                                });
                                              }
                                            );
                                          })
                                        );
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* **** Select quality of board ***** */}

                    <div class="row align-items-center mt-2">
                      <div class="col-12">
                        <label>Select Quality of Board</label>
                      </div>

                      <div class="col-12">
                        <div className="row main-parent-outer-chips mx-0">
                          {this.state.BoardData.map((data, i) => {
                            return (
                              <div className="col-6 px-0">
                                <div
                                  className={
                                    this.state.SelectedBoard ==
                                      data.fld_board_id
                                      ? "coat-drop-menu-active-bg mr-1 mb-2 px-2 inner-coat-drop chips-fontsize detailsicon chips-position product-content-main-box"
                                      : "mr-1 mb-2 inner-coat-drop px-2 chips-position product-content-main-box"
                                  }
                                  onClick={() => {
                                    this.context.setselectedQualityBoard(
                                      data.fld_image
                                    );
                                    this.setState(
                                      {
                                        SelectedBoard: data.fld_board_id,
                                        selectedBoardDesign: data.fld_gsm,
                                        selectedBoardDesign2:
                                          data.fld_board_name,
                                        BoardDataLabel: data.fld_board_name,
                                      },
                                      () => {
                                        this.onPostPrice();
                                        this.onPostPrice2();
                                      }
                                    );
                                  }}
                                >
                                  <h6
                                    className={
                                      this.state.OuterCoatingId ==
                                        data.fld_coating_id
                                        ? "chips-fontsize justify-content-between product-phn-img-heading"
                                        : "justify-content-between product-phn-img-heading"
                                    }
                                  >
                                    {data.fld_board_name}
                                  </h6>
                                  <div className="info-icon-product-outer">
                                    <i
                                      class="fa fa-info-circle info-icon-card mx-2 icon-outer-details"
                                      aria-hidden="true"
                                      onClick={() => {
                                        this.setState({
                                          outerchipimgdata: data.fld_image,
                                          selectedBoardDesign3:
                                            data.fld_board_name,
                                          SelectedBoardid: data.fld_board_id,
                                          open: true,
                                        });
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    {/* ***** Select Shipping ******* */}
                    <div className="row">
                      <div class="col-md-12 mt-2">
                        <div className="rightCard shipping-card px-1">
                          {/* ******* Product Price ********** */}

                          <div class="row align-items-center px-0 mx-0">
                            <div class="col-12 px-0">
                              <label>Product price</label>
                            </div>
                            <div
                              class="col-12 px-0"
                              style={{ marginTop: "-30px" }}
                            >
                              <div className="row main-parent-div-chips pb-3 mx-1">
                                {/* ***** 1000 NOS ***** */}

                                {this.state.PriceData1.map((data, i) => (
                                  <>
                                    <div className="col-3 pl-0 pr-1">
                                      <div className="col-12 text-end price-shop-new extra-price-div-quantity"></div>
                                      <div
                                        className={
                                          this.state.CardSelected == "1st"
                                            ? "selectedRow unitcost px-1 py-3 col-12 price-shop-new price-seperate-box d-flex align-items-center"
                                            : "selectedRow1 unitcost px-1 py-3 col-12 price-shop-new price-seperate-box d-flex align-items-center"
                                        }
                                        onClick={() => {
                                          this.setState(
                                            {
                                              Customquantity: 1000,
                                              CardSelected: "1st",
                                            },
                                            () => {
                                              this.onPostPrice2();
                                            }
                                          );
                                        }}
                                      >
                                        1000 nos
                                      </div>
                                      <div className="col-12 px-1 py-3 mt-2 price-seperate-box price-shop-new d-flex align-items-center">
                                        ₹
                                        {parseFloat(
                                          data.price_per_peace
                                        ).toFixed(2)}{" "}
                                        {/* <span className="small eachsmall">Each</span> */}
                                      </div>
                                    </div>
                                    {/* </div>
                                    </div> */}
                                  </>
                                ))}

                                {/* ***** 2000 NOS ***** */}

                                {this.state.PriceData2.map((data, i) => (
                                  <>
                                    <div className="col-3 pl-0 pr-1">
                                      <div className="col-12 text-end price-shop-new extra-price-div-quantity"></div>
                                      <div
                                        className={
                                          this.state.CardSelected == "2nd"
                                            ? "selectedRow unitcost px-1 py-3 col-12 price-seperate-box price-shop-new d-flex align-items-center"
                                            : "selectedRow1 unitcost px-1 py-3 col-12 price-seperate-box price-shop-new d-flex align-items-center"
                                        }
                                        onClick={() => {
                                          this.setState(
                                            {
                                              Customquantity: 2000,
                                              CardSelected: "2nd",
                                            },
                                            () => {
                                              this.onPostPrice2();
                                            }
                                          );
                                        }}
                                      >
                                        2000 nos
                                      </div>
                                      <div className="col-12 px-1 py-3 mt-2 price-seperate-box price-shop-new d-flex align-items-center">
                                        ₹{" "}
                                        {parseFloat(
                                          data.price_per_peace
                                        ).toFixed(2)}{" "}
                                        {/* <span className="small eachsmall">Each</span> */}
                                      </div>
                                    </div>
                                  </>
                                ))}

                                {/* ***** Quantity NOS ***** */}

                                {this.state.PriceData3.map((data, i) => (
                                  <>
                                    <div className="col-3 pl-0 pr-1">
                                      <div className="col-12 text-end price-shop-new extra-price-div-quantity"></div>
                                      <div
                                        className={
                                          this.state.CardSelected == "3rd"
                                            ? "selectedRow unitcost px-1 py-3 col-12 price-seperate-box price-shop-new d-flex align-items-center"
                                            : "selectedRow1 unitcost px-1 py-3 col-12 price-seperate-box price-shop-new d-flex align-items-center"
                                        }
                                        onClick={() => {
                                          this.setState(
                                            {
                                              Customquantity: 3000,
                                              CardSelected: "3rd",
                                            },
                                            () => {
                                              this.onPostPrice2();
                                            }
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        3000 nos
                                      </div>
                                      <div className="col-12 px-1 py-3 mt-2 price-shop-new price-seperate-box d-flex align-items-center">
                                        ₹{" "}
                                        {parseFloat(
                                          data.price_per_peace
                                        ).toFixed(2)}{" "}
                                        {/* <span className="small eachsmall">Each</span> */}
                                      </div>
                                    </div>
                                  </>
                                ))}

                                {/* *** Quantity *** */}
                                <div className="col-3 pl-0 pr-1">
                                  <div className="col-12 px-1 py-3 d-flex align-items-center price-shop-new extra-price-div-quantity">
                                    Enter Quantity
                                  </div>
                                  <div
                                    className="col-12 selected-row-price-border px-1 py-3 price-seperate-box d-flex align-items-center quantity-input-new-box
                                  "
                                  >
                                    <input
                                      type="text"
                                      class="form-control price-shop-new quantity-input-phn px-0 "
                                      value={this.state.Customquantity}
                                      onChange={(text) => {
                                        if (
                                          this.state.NumRegex.test(
                                            text.target.value
                                          ) &&
                                          text.target.value.length <= 4
                                        ) {
                                          this.setState(
                                            {
                                              Customquantity: text.target.value,
                                            },
                                            () => {
                                              this.onPostPrice2();
                                            }
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="col-12 px-1 py-3 mt-2 price-seperate-box price-shop-new d-flex align-items-center">
                                    ₹{" "}
                                    {this.state.PriceData6 != null
                                      ? parseFloat(
                                        this.state.PriceData6
                                      ).toFixed(2)
                                      : "0.00"}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* ****Unit price ***** */}
                            <div className="col-12 mt-2">
                              <div className="row d-flex align-items-center">
                                <div className="col-3 pl-1 pr-1">
                                  <p className="text-left price-heading-product price-shop-new mb-1">
                                    Unit Price
                                  </p>
                                  <h6 className="price-shop-new  price-seperate-box-bottom price-shop-new  px-1 ">
                                    <b>
                                      ₹{" "}
                                      {this.state.PriceData6 != null
                                        ? parseFloat(
                                          this.state.PriceData6
                                        ).toFixed(2)
                                        : "0.00"}
                                    </b>
                                  </h6>
                                </div>

                                {/* ****Shipping price ***** */}
                                <div className="col-3 pl-0 pr-1">
                                  <p className="text-left price-heading-product price-shop-new mb-1">
                                    Shipping
                                  </p>
                                  {this.state.CustomQuantity_Deliverycharge !==
                                    0 ? (
                                    <h6 className="price-shop-new price-shop-new price-seperate-box-bottom  px-1 ">
                                      <b>
                                        ₹{" "}
                                        {this.state.Customquantity != null &&
                                          this.state.Customquantity != ""
                                          ? parseFloat(
                                            this.state
                                              .CustomQuantity_Deliverycharge
                                          ).toFixed(2)
                                          : "0.00"}
                                      </b>
                                    </h6>
                                  ) : (
                                    <h6 className="price-shop-new price-shop-new price-shop-new price-seperate-box-bottom  px-1 ">
                                      <b>₹ 0.00 </b>
                                    </h6>
                                  )}
                                </div>

                                {/* **** GST AMount ***** */}
                                <div className="col-3 pl-0 pr-1">
                                  <p className="text-left price-heading-product price-shop-new mb-1">
                                    GST
                                  </p>
                                  <h6 className="price-shop-new  price-seperate-box-bottom px-1 ">
                                    <b>
                                      ₹
                                      {this.state.Customquantity != null &&
                                        this.state.Customquantity != ""
                                        ? parseFloat(
                                          this.state.CustomQuantity_GstAmount
                                        ).toFixed(2)
                                        : "0.00"}
                                    </b>
                                  </h6>
                                </div>

                                {/* **** Total Cost ***** */}
                                <div className="col-3 pl-0 pr-1">
                                  <p className="text-left price-heading-product price-shop-new mb-1">
                                    Total Cost
                                  </p>
                                  <h4 className="price-shop-new  price-seperate-box-bottom price-shop-new px-1 ">
                                    <b>
                                      ₹
                                      {this.state.Customquantity != null &&
                                        this.state.Customquantity != ""
                                        ? this.state.CustomQuantity_TotalAmount.toLocaleString(
                                          undefined,
                                          { maximumFractionDigits: 2 }
                                        ) + ".00"
                                        : "0.00"}
                                    </b>
                                  </h4>
                                </div>
                              </div>
                            </div>

                            {/* bulk quanitity */}
                            <div className="col-12 mt-3 px-0">
                              <p className="customInput hide-desktop">
                                For bulk quantity / custom sizes{" "}
                                <b style={{ color: "#59c5d1" }}>
                                  <a href="mailto:info@piopackaging.com">
                                    Click here
                                  </a>
                                </b>
                              </p>
                            </div>
                            <div className="col-12 px-0 mx-0">
                              <h5 className="rightCardtext">Select Shipping</h5>
                              <hr />
                              <h5 className="shippingheading">
                                <b>
                                  How would you like your product to be
                                  delivered to you?
                                </b>
                              </h5>
                              <p className="shippingtext">
                                You can either pick up the product from our
                                factory or we can ship it to you with an added
                                shipping cost calculated on actuals.
                              </p>
                              <div className="row">
                                <div className="col-6 phnview-pickup-main-box pr-1">
                                  {this.state.PickUpTypeData.map((data, i) => (
                                    <div
                                      className="self-pickup-size self-pickup-color px-2"
                                      onClick={(text) => {
                                        this.setState(
                                          {
                                            PickUpType: text.target.value,
                                          },
                                          () => {
                                            if (
                                              this.state.PickUpType ==
                                              "StandardShipping" &&
                                              this.state.Pincode != null
                                            ) {
                                              this.setState(
                                                {
                                                  // orderSummaryOpen: true,
                                                },
                                                () => {
                                                  this.onPostPrice2();
                                                }
                                              );
                                            } else {
                                              if (
                                                this.state.PickUpType !=
                                                "StandardShipping"
                                              ) {
                                                this.setState(
                                                  {
                                                    // orderSummaryOpen: true,
                                                  },
                                                  () => {
                                                    this.onPostPrice2();
                                                  }
                                                );
                                              }
                                            }
                                          }
                                        );
                                      }}
                                    >
                                      {data.label}
                                    </div>
                                  ))}
                                </div>

                                {/*  **** Get Delivery Cost ***** */}
                                <div className="col-6 phnview-pickup-main-box pl-0">
                                  {this.state.PickUpTypeData.map((data, i) => (
                                    <div
                                      className="self-pickup-size"
                                      onClick={() => {
                                        this.setState({
                                          pincodepopup: true,
                                        });
                                      }}
                                    >
                                      Get Delivery cost
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>

                            {/* **** Payment Options **** */}
                            <div className="col-12 px-0">
                              <div class="col-12 px-0 mt-2 py-0">
                                <h5 className="rightCardtext">
                                  Payment Options
                                </h5>
                              </div>
                              <hr />
                              <p className="customInput payment-options-font-size hide-desktop mt-1 pt-0">
                                <ul className="px-3">
                                  <li>Full Payment</li>
                                  <li>Half Payment</li>
                                  <li>Pay Later</li>
                                </ul>
                              </p>
                            </div>

                            {/* **** Terms and condtion and order now btn **** */}
                            <div className="col-md-12 mt-1 px-2">
                              <div className="strength2">
                                <div className="gridcardcheckbox checkbox-phnview pt-0 px-0">
                                  <input
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value={this.state.termscondition}
                                    checked={
                                      this.state.termscondition == "Yes"
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      if (this.state.termscondition == "Yes") {
                                        this.setState({
                                          termscondition: "No",
                                        });
                                      } else {
                                        this.setState({
                                          termscondition: "Yes",
                                        });
                                      }
                                    }}
                                  />
                                  <p for="vehicle1" className="m-0">
                                    {" "}
                                    &nbsp;I agree to the{" "}
                                    <a href="/termsandcondition">
                                      Terms & Conditions
                                    </a>
                                  </p>
                                </div>
                                <button
                                  className="btn designbtn2 mx-0 mb-2 active"
                                  onClick={() => {
                                    if (this.state.PickUpType != "") {
                                      if (
                                        this.state.PickUpType ==
                                        "StandardShipping"
                                      ) {
                                        if (this.state.Pincode != null) {
                                          if (this.state.Pincode.length == 6) {
                                            if (
                                              this.state.Customquantity !=
                                              null &&
                                              this.state.Customquantity != ""
                                            ) {
                                              if (
                                                this.state.termscondition ==
                                                "Yes"
                                              )
                                                if (
                                                  this.state.errormessage ==
                                                  null
                                                ) {
                                                  this.onOrderNow();
                                                } else {
                                                  Notiflix.Notify.failure(
                                                    `${this.state.errormessage}`
                                                  );
                                                }
                                              else {
                                                Notiflix.Notify.failure(
                                                  "Please select terms and condition"
                                                );
                                              }
                                            } else {
                                              Notiflix.Notify.failure(
                                                "Please enter / select the quantity"
                                              );
                                            }
                                          } else {
                                            Notiflix.Notify.failure(
                                              "Please enter valid pin code"
                                            );
                                          }
                                        } else {
                                          Notiflix.Notify.failure(
                                            "Please enter pin code"
                                          );
                                        }
                                      } else {
                                        if (
                                          this.state.Customquantity != null &&
                                          this.state.Customquantity != ""
                                        ) {
                                          if (
                                            this.state.termscondition == "Yes"
                                          ) {
                                            this.onOrderNow();
                                          } else {
                                            Notiflix.Notify.failure(
                                              "Please select terms and condition"
                                            );
                                          }
                                        } else {
                                          Notiflix.Notify.failure(
                                            "Please enter / select the quantity"
                                          );
                                        }
                                      }
                                    } else {
                                      Notiflix.Notify.failure(
                                        "Please Select Pickup type"
                                      );
                                    }
                                  }}
                                >
                                  Order Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ========Quetions======= */}

                  {/* ======Select Shipping */}

                  <div className="get-quote-section hide-desktop-quote desktop-view-shop-order">
                    <button
                      className="btn designbtn2 active go-back m-0"
                      onClick={() => {
                        this.setState(
                          {
                            orderSummaryOpen: true,
                          },
                          () => {
                            this.onPostPrice2();
                          }
                        );
                      }}
                    >
                      Get Quote
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="quantity-icon position-fixed pulse-image">
          <img src={MOQ} alt="" />
        </div>
        <Footer />
        {/* <TabNavigation /> */}
      </React.Fragment>
    );
  }
}

export default ProductDetailPage;
