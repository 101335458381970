import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginDetails: [],
      CustomerName: null,
      showMenu: false,
    };
  }

  componentDidMount() {
    const login = localStorage.getItem("CustomerData");
    const loginDetails = JSON.parse(login);
    // console.log(loginDetails);

    if (loginDetails != null) {
      this.setState({
        loginDetails: loginDetails[0],
        CustomerName: loginDetails[0].fld_name,
      });
    }
  }
  render() {
    return (
      <header class="headershop">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <nav>
                <div id="berger-menu">
                  <div class="menu-bar"></div>
                  <div class="menu-bar"></div>
                  <div class="menu-bar"></div>
                </div>
                {this.state.loginDetails == 0 ? (
                  <ul class="menu">
                    <div id="berger-menu">
                      <div class="menu-bar"></div>
                      <div class="menu-bar"></div>
                      <div class="menu-bar"></div>
                    </div>
                    <div class="logo show-mobile">
                      <img src="assets/img/pio-logo.png" alt="Pio Packaging" />
                    </div>

                    <li>
                      <HashLink to="/cart">
                        <i class="fas fa-shopping-cart"></i>
                      </HashLink>
                    </li>

                    <li>
                      <a href="/login">Login</a>
                    </li>
                    <li>
                      <a href="/register">Sign Up</a>
                    </li>
                    <div class="menu-media show-mobile">
                      <p>Call Now</p>
                      <a href="tel:919677027016" class="menu-enquiry">
                        <i class="fa fa-mobile"></i> +91 98400 31164{" "}
                      </a>
                      <div class="menu-media">
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/PioPackaging"
                              class="facebook"
                              target="_blank"
                            >
                              <i class="fa fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/pio.packaging/"
                              class="instagram"
                              target="_blank"
                            >
                              <i class="fa fa-instagram"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/pio-packaging"
                              class="linkedin"
                              target="_blank"
                            >
                              <i class="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ul>
                ) : (
                  <ul class="menu">
                    <div id="berger-menu">
                      <div class="menu-bar"></div>
                      <div class="menu-bar"></div>
                      <div class="menu-bar"></div>
                    </div>
                    <div class="logo show-mobile">
                      <img src="assets/img/pio-logo.png" alt="Pio Packaging" />
                    </div>

                    <li>
                      <nav class="navbar navbar-light ">
                        <form class="form-inline">
                          <input
                            class="form-control mr-sm-1 searchbar"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                          />
                        </form>
                      </nav>
                    </li>
                    <li>
                      <HashLink to="/cart">
                        <i class="fas fa-shopping-cart"></i>
                      </HashLink>
                    </li>
                    <li>
                      <p class="ml-4 justify-content-end myAccount">
                        <div class="dropdown">
                          <button
                            class="dropbtn"
                            onMouseHover={() =>
                              this.setState({ showMenu: true })
                            }
                          >
                            <i
                              className="fa fa-user"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                window.location.href = "/login";
                              }}
                            />
                            &nbsp;
                            {this.state.CustomerName}
                          </button>
                          <div class="dropdown-content">
                            <a
                              style={{ cursor: "pointer" }}
                              href="/customerprofile"
                            >
                              {" "}
                              <i className="fa fa-user" />
                              &nbsp;Profile
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                localStorage.clear();
                                window.location.reload();
                              }}
                            >
                              <i class="fas fa-sign-out-alt"></i>
                              &nbsp;Logout
                            </a>
                          </div>
                        </div>
                      </p>
                    </li>
                    <div class="menu-media show-mobile">
                      <p>Call Now</p>
                      <a href="tel:919677027016" class="menu-enquiry">
                        <i class="fa fa-mobile"></i> +91 98400 31164{" "}
                      </a>
                      <div class="menu-media">
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/PioPackaging"
                              class="facebook"
                              target="_blank"
                            >
                              <i class="fa fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/pio.packaging/"
                              class="instagram"
                              target="_blank"
                            >
                              <i class="fa fa-instagram"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/pio-packaging"
                              class="linkedin"
                              target="_blank"
                            >
                              <i class="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ul>
                )}
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
