import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
import PostApiCall from "../../components/helper/Api";
import Notiflix from "notiflix";
import moment from "moment";
import Footer from "../../Footer";
import Header from "../../Header";
import "../../CustomerDashboard/Address.css";
import Editicon from "../../CustomerDashboard/images/edit.png";
import TabNavigation from "../../components/tabNavigation/tabNavigation";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePreviewUrl:
        "http://demo.globaltrendz.online/maxxchange/images/User/Shivani-1.jpg",

      ImageApiUrl: "http://api.globaltrendz.online/AddImage",
      Status: "Active",
      ImageData: [],

      UnitofMeasurement: "",
      Price: 0,
      Points: 0,
      DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
      RawMaterialName: "",
      GenderData: [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Others", label: "Others" },
      ],
      Gender: "",
      CountryData: [],
      CityData: [],
      StateData: [],
      CountryId: 0,
      StateId: 0,
      CityId: 0,
      Name: "",
      Mobile: "",
      Email: "",
      DOB: "",
      AnniversaryDate: "",
      Designation: "",
      Department: "",
      UserType: "",
      Address: "",
      Country: "",
      State: "",
      City: "",
      Pincode: "",
      AadharCard: "",
      PANCard: "",
      DrivingLicenses: "",
      BankName: "",
      Branch: "",
      AccountNumber: "",
      IFSCCode: "",
      Salary: "",
      Password: "",
      ConfirmPassword: "",
      UserData: [
        { value: "Admin", label: "Admin" },
        { value: "Super Admin", label: "Super Admin" },
        { value: "Users", label: "Users" },
      ],
      // DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
      NumRegex: /^[0-9]*$/,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(https:\/\/www\.|https:\/\/www\.|https:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      DepartmentData: [],
      AadharData: [],
      AadharUrl: "http://api.globaltrendz.online/AddPdf",
      PanData: [],
      PanUrl: "http://api.globaltrendz.online/AddPdf",
      DrivingData: [],
      DrivingUrl: "http://api.globaltrendz.online/AddPdf",
      DesignationData: [],
      BankData: [],
      isNewPasswordVisible: false,
      isConPasswordVisible: false,
      Details: [],
      Logintime: "",
      CustomerData: [],
      AddressData: [],
      OrderData: [],
    };
  }

  componentDidMount() {
    //   this.props.setclearbrand()
    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);
    // console.log(this.AddressData.map.length)

    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customerid=${CustomerDetails[0].fld_customerid}`,
      },
      "Get_customerData_by_id"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        // console.log(obj.data);
        if (results.status == 200 || results.status == 201) {
          this.setState({
            CustomerData: obj.data,
          });
        }
      })
    );

    // ===============Address Data=================

    PostApiCall.postRequest(
      {
        whereClause: `where fld_customerid=${CustomerDetails[0].fld_customerid}`,
      },
      "Get_Customer_Address"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            AddressData: obj.data,
          });
        }
      })
    );

    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${CustomerDetails[0].fld_customerid}`,
      },
      "GetCustomerOrderDetails"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj.data);
          this.setState({
            OrderData: obj.data,
          });
        }
      })
    );
  }

  render() {
    // console.log(this.state.AddressData.length);
    return (
      <React.Fragment>
        <Header />
        <section style={{ paddingTop: "40px" }}>
          <Container fluid>
            <div className="row"></div>

            <Row className="mt-3">
              <Col xl="12">
                <div className="overflow-hidden">
                  <Row style={{ marginTop: "15px" }}>
                    <Col xl="4">
                      <Card
                        className="overflow-hidden website-card"
                        style={{ height: "300px" }}
                      >
                        <div
                          className="globalTrendzEcomm-bg-soft-pink"
                          style={{ height: "85px", background: "#59c5d1" }}
                        >
                          {this.state.CustomerData.map((data, i) => {
                            if (i === 0) {
                              return (
                                <Row>
                                  <Col xs="8">
                                    <div className="globalTrendzEcomm-text p-3">
                                      <h5
                                        className="globalTrendzEcomm-text"
                                        style={{ display: "flex" }}
                                      >
                                        {data.fld_name}
                                      </h5>
                                      <p className="globalTrendzEcomm-text">
                                        {" "}
                                        Customer Since: {data.register_on}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col xs="4" className="align-self-end">
                                    <img
                                      src=""
                                      alt=""
                                      className="img-fluid customerProfile"
                                    />
                                  </Col>
                                </Row>
                              );
                            }
                          })}
                        </div>

                        <CardBody
                          className="pt-6"
                          style={{ paddingTop: "1rem" }}
                        >
                          <Row>
                            <Col xs="5">
                              <p>Date of Birth</p>
                              {/* <p>Gender</p> */}
                              {/* <p>Marital Status</p> */}
                              <p>Mobile</p>
                              <p>Email</p>
                              <p
                                style={{
                                  display:
                                    this.state.AddressData.length != 0
                                      ? "block"
                                      : "none",
                                }}
                              >
                                Location
                              </p>
                            </Col>

                            {this.state.CustomerData.map((data, i) => {
                              if (i === 0) {
                                return (
                                  <Col xs="7">
                                    <p>
                                      {data.fld_dob == null
                                        ? "N/A"
                                        : moment(data.fld_dob).format(
                                            "DD/MM/YYYY"
                                          )}
                                    </p>
                                    {/* <p>{data.fld_maritalstatus == null ? 'N/A' : data.fld_maritalstatus}</p> */}
                                    {/* <p>{data.fld_doa == null ? 'N/A' : moment(data.fld_doa).format('DD/MM/YYYY')} </p> */}
                                    <p>
                                      {data.fld_phone_number == null
                                        ? "N/A"
                                        : data.fld_phone_number}{" "}
                                    </p>
                                    <p>
                                      {data.fld_email_address == null
                                        ? "N/A"
                                        : data.fld_email_address}{" "}
                                    </p>
                                    <p>
                                      {data.fld_address_1}, {data.fld_address_2}
                                      , {data.CityName}, {data.StateName},{" "}
                                      {data.CountryName}
                                    </p>
                                  </Col>
                                );
                              }
                            })}
                          </Row>
                          {this.state.CustomerData.map((data, i) => {
                            if (i == 0) {
                              return (
                                <Row className="mt-3">
                                  <div class="col-12">
                                    <a>
                                      <img
                                        class="edit-profile-icon"
                                        src={Editicon}
                                        style={{
                                          width: "35px",
                                          float: "right",
                                          cursor: "pointer",
                                          marginTop: "-20px",
                                        }}
                                        onClick={() => {
                                          localStorage.setItem(
                                            "ProfileData",
                                            JSON.stringify(data)
                                          );
                                          window.location.href =
                                            "/edit-profile";
                                        }}
                                      ></img>
                                    </a>
                                  </div>
                                </Row>
                              );
                            }
                          })}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
        <TabNavigation />
      </React.Fragment>
    );
  }
}

export default Profile;
