import React from 'react'
import { store } from './components/context/store';
import { isMobile } from "react-device-detect";

class HomeBanner extends React.Component {
  static contextType = store;
  constructor(props) {
    super(props)
    this.state = {
      onlineStoreBtn: false,
    };
  }

  componentDidMount() {
    const store = this.context;
    {
      isMobile
        ? this.setState({
            onlineStoreBtn: true,
          })
        : this.setState({
            onlineStoreBtn: false,
          });
    }
  }
  render() {
    return (
      <>
        <div
          class="col-md-12 m-show online-ordering"
          id='onilne-store'
          style={{
            display: this.state.onlineStoreBtn == true ? "block" : "none",
          }}
        >
          <a
            class="mx-lg-3 btn shopnowbtn"
            // href="/ordercustomboxes"
            href='/onlinestore'
            style={{
              background: "#59c5d1",
              borderRadius: "3px",
              color: "white",
              width: "85%",
              boxShadow: "0px 0px 12px rgba(0,0,0,.5)",
              fontSize: "22px",
            }}
          >
            Online Store
          </a>
        </div>
        <section class="home-banner" id="home">
          <img
            class="img-fluid d-show"
            src="assets/img/banner.jpeg"
            alt="Home Banner"
          />
          <img
            class="m-show"
            src="assets/img/banner_mobile.jpeg"
            alt="Home Banner"
          />
        </section>
      </>
    );
  }
}

export default HomeBanner;
