import React, { Component } from "react";
import { BackgroundImage } from "react-image-and-background-image-fade";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Banner1 from "../../assets/image/Banner1.webp";
import Banner2 from "../../assets/image/Banner2.webp";
import Banner3 from "../../assets/image/Banner3.webp";
import Banner4 from "../../assets/image/Banner4.webp";
import Banner5 from "../../assets/image/Banner5.JPG";

// Banner for phn view
import MobileBanner2 from "../../assets/image/order-custom-banner/Banner_North.jpeg";
import MobileBanner3 from "../../assets/image/order-custom-banner/Banner_Western.jpeg";
import MobileBanner4 from "../../assets/image/order-custom-banner/biryani.jpeg";
import MobileBanner1 from "../../assets/image/order-custom-banner/meals.jpeg";

import ReactGA from "react-ga4";
import CompanyLink from "../../CompanyLink";
import PostApiCall from "../../components/helper/Api";
import TabNavigation from "../../components/tabNavigation/tabNavigation";
import Footer from "../../Footer";
import Header from "../../Header";
import "./Shop.css";
ReactGA.initialize([
  {
    trackingId: "G-2VDZ0VM2P4",
  },
]);

class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VerticleData: [],
      showShopNowbutton: "",
      activeproduct: [],
    };
  }

  componentDidMount() {
    PostApiCall.postRequest(
      {
        whereClause: "where fld_status = 'Active'",
        recordCount: "*",
      },
      "GetProductMaster"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          VerticleData: obj.data,
        });
      })
    );
  }

  render() {
    var settings = {
      infinite: true,
      arrows: false,
      dots: true,
      autoplay: true,
      autoplaySpeed: 7000,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <React.Fragment>
        <Header />
        <section class="home-banner" id="home">
          <Slider {...settings} className="phone-view-slick-dot-shop-page">
            <div>
              <img
                class="img-fluid d-show desktop-shop-banner"
                src={Banner1}
                alt="Home Banner"
                style={{ marginTop: "34px" }}
              />
              <img
                class="img-fluid d-show mobile-shop-banner"
                src={MobileBanner1}
                alt="Home Banner"
                style={{ marginTop: "34px" }}
              />
            </div>
            <div>
              <img
                class="img-fluid d-show desktop-shop-banner"
                src={Banner2}
                alt="Home Banner"
                style={{ marginTop: "34px" }}
              />
              <img
                class="img-fluid d-show mobile-shop-banner"
                src={MobileBanner2}
                alt="Home Banner"
                style={{ marginTop: "34px" }}
              />
            </div>
            <div>
              <img
                class="img-fluid d-show desktop-shop-banner"
                src={Banner3}
                alt="Home Banner"
                style={{ marginTop: "34px" }}
              />
              <img
                class="img-fluid d-show mobile-shop-banner"
                src={MobileBanner3}
                alt="Home Banner"
                style={{ marginTop: "34px" }}
              />
            </div>
            <div>
              <img
                class="img-fluid d-show desktop-shop-banner"
                src={Banner4}
                alt="Home Banner"
                style={{ marginTop: "34px" }}
              />
              <img
                class="img-fluid d-show mobile-shop-banner"
                src={MobileBanner4}
                alt="Home Banner"
                style={{ marginTop: "34px" }}
              />
            </div>
          </Slider>
        </section>

        <section
          class="section about shop-about-paading-mobile-view"
          id="about-pio-packaging"
        >
          <div class="container">
            <h1
              style={{
                paddingBottom: "20px",
                fontSize: "42px",
                textAlign: "center",
              }}
              className="h-sec h1-shop desktop-shop-banner"
            >
              Place your trust in a{" "}
              <span style={{ color: "#C6AD39" }} className="sp-bold">
                50 year old printing company!
              </span>
            </h1>
            <div class="row">
              <div class="col-lg-5 p-0">
                <div>
                  <div>
                    <div
                      class="overview-txt shop-top-content-heading about-text font-overview"
                      style={{
                        marginRight: "0px",
                        padding: " 20px 30px ",
                        fontWeight: "bold",
                      }}
                    >
                      {/* <p
                        style={{
                          color: "black",
                        }}
                      >
                        Custom Printed boxes with your own design or choose from
                        our options!
                      </p> */}
                      <div style={{ padding: "10px 0px" }}>
                        <h5 style={{ fontWeight: "bold" }} className="how">
                          How it Works..
                        </h5>
                        <p className="pointplaceorder">
                          <ul>
                            <li>Click on the type of box you need</li>
                            <li>
                              Select the variant or size you need in that type
                            </li>
                            <li>
                              Customize by choosing type of board, type of outer
                              coating & inner coating
                            </li>
                            <li>
                              Select quantity or enter your quantity to see unit
                              price
                            </li>
                            <li>Select type of shipping</li>
                            <li>Place order</li>
                          </ul>
                        </p>
                        <p className="pointplaceordernextsteps">
                          <h5
                            style={{ fontWeight: "bold" }}
                            className="how my-2"
                          >
                            Next Steps
                          </h5>
                          <ul>
                            <li>
                              You will receive an email with link to download
                              the Key Line Design which you can share with your
                              designer to create the artwork. Send artwork to us
                              and we will send an exact sample of your box with
                              digital print for approval. Only after you
                              approve, we will proceed with the bulk print.
                            </li>
                            <li>
                              If you require us to create your design, we can
                              help you with our in-house design team.
                            </li>
                            <li>
                              That's it! Your order will be shipped within 7
                              working days from date of approval.
                            </li>
                            <li>
                              If you prefer to see a sample box before placing
                              bulk order click on ‘Order Sample’ and we will
                              send you a generic printed box of your choice with
                              all board and outer coating options within 2 days!
                            </li>
                            <li> We deliver all over India.</li>
                          </ul>
                        </p>

                        {/* <p className="pointplaceorder">
                          You will receive an email with link to download the Key Line Design which you can share with your designer to create the artwork. Send artwork to us and we will send an exact sample of your box with print for approval.
                        </p>
                        <p className="pointplaceorder">
                          That's it! Your order will be ready for shipping in 7 working days.<br />
                          If you'd like to see a sample box before placing bulk order click on  ‘Order Sample’ and we will send you the box of your choice with all board and outer coating options within 2 days!<br />We deliver all over India.
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 p-0">
                <div class="overview-img">
                  <img src={Banner5} alt="why Custom" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section class="section Product" id="products"> */}
        <section class="section Product" id="product">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title text-center">
                  <h2>Choose your custom packaging box</h2>
                </div>
              </div>
            </div>
            <div class="row px-2 px-md-0">
              {this.state.VerticleData.map((data, i) => (
                <div
                  class="col-lg-3 col-sm-6 col-6 px-0 px-md-2"
                  onClick={() => {
                    this.setState({
                      activeproduct: data.fld_productid,
                    });
                  }}
                  onMouseOver={() => {
                    this.setState({
                      showShopNowbutton: data.fld_productid,
                    });
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      showShopNowbutton: "",
                    });
                  }}
                >
                  <div
                    className={
                      this.state.activeproduct == data.fld_productid
                        ? "product-cat-box-active-border product-cat-box mx-1"
                        : "product-cat-box mx-1"
                    }
                  >
                    <div class="product-cat-box-txt">
                      <p className="product-cat-box-name-txt shop-phn-img-heading">
                        {data.fld_verticlename}
                      </p>
                    </div>
                    <div class="product-cat-box-img hide-mobile">
                      <a
                        onClick={() => {
                          ReactGA.event({
                            category: "ProductClick-Home",
                            action: data.fld_itemname,
                            label: data.fld_itemname, // optional
                            nonInteraction: true, // optional, true/false
                            transport: "xhr", // optional, beacon/xhr/image
                          });
                          window.location.href = `${process.env.PUBLIC_URL}/p/${data.fld_route}`;
                        }}
                      >
                        <BackgroundImage
                          src={data.fld_itemimage}
                          width="243px"
                          height="212px"
                          isResponsive
                          className="img-fluid"
                          alt="Retail Food Packaging"
                          style={{
                            backgroundSize: "cover",
                            backgroundPosition: "center top",
                          }}
                          lazyLoad
                        />
                      </a>
                    </div>
                    {/* <div class="product-cat-box-img hide-desktop"> */}
                    <div class="product-cat-box-img hidetabrepeted">
                      <a
                        onClick={() => {
                          ReactGA.event({
                            category: "ProductClick-Home",
                            action: data.fld_itemname,
                            label: data.fld_itemname, // optional
                            nonInteraction: true, // optional, true/false
                            transport: "xhr", // optional, beacon/xhr/image
                          });
                          window.location.href = `${process.env.PUBLIC_URL}/p/${data.fld_route}`;
                        }}
                      >
                        <BackgroundImage
                          src={data.fld_itemimage}
                          width="243px"
                          height="212px"
                          isResponsive
                          className="img-fluid"
                          alt="Retail Food Packaging"
                          style={{
                            backgroundSize: "cover",
                            backgroundPosition: "center top",
                          }}
                          lazyLoad
                        />
                      </a>
                    </div>
                    <div class="product-cat-box-txt">
                      <button
                        type="button"
                        class="btn btn-primary centered hide-mobile"
                        style={{
                          display:
                            this.state.showShopNowbutton == data.fld_productid
                              ? "block"
                              : "none",
                          background: "#9b9c9e",
                          border: "1px solid #9b9c9e",
                        }}
                        onClick={() => {
                          window.location.href = `${process.env.PUBLIC_URL}/p/${data.fld_route}`;
                        }}
                      >
                        Shop Now
                      </button>
                    </div>
                    <a
                      className="btn shopnowbtn2 shop-phn-btn"
                      href={`${process.env.PUBLIC_URL}/p/${data.fld_route}`}
                    >
                      Customize your order
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="disclaimer text-center">
                  <p>
                    <b>Disclaimer:</b> Images are for illustration/inspiration
                    purpose only and copyrighted by their respective authors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Website link */}
        <CompanyLink />
        {/* Website link */}

        <section class="section specialize">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="sub-section-title specialize-title text-center">
                  <h2>Packing Materials We Specialize In</h2>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-2 col-6">
                <div class="specialize-box">
                  <img src="assets/img/icon/01.jpg" alt="Specialize" />
                  <p>Bio Degradable</p>
                </div>
              </div>

              <div class="col-lg-2 col-6">
                <div class="specialize-box">
                  <img src="assets/img/icon/02.jpg" alt="Specialize" />
                  <p>Recyclable</p>
                </div>
              </div>

              <div class="col-lg-2 col-6">
                <div class="specialize-box">
                  <img src="assets/img/icon/003.jpg" alt="Specialize" />
                  <p>Non-Toxic</p>
                </div>
              </div>

              <div class="col-lg-2 col-6">
                <div class="specialize-box">
                  <img src="assets/img/icon/04.jpg" alt="Specialize" />
                  <p>Food Grade</p>
                </div>
              </div>

              <div class="col-lg-2 col-6">
                <div class="specialize-box">
                  <img src="assets/img/icon/05.jpg" alt="Specialize" />
                  <p>100% Eco-friendly</p>
                </div>
              </div>
              <div class="col-lg-2 col-6">
                <div class="specialize-box">
                  <img src="assets/img/icon/006.jpg" alt="Specialize" />
                  <p>Carbon Neutral</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="sectionaffordable" id="about-pio-packaging">
          <div className="container">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title text-center">
                  <h2>Affordable Premium Custom Printed Packaging</h2>
                  <p>
                    We give your brand a fresh look with high quality custom
                    printed packaging solutions at very reasonable prices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <TabNavigation />
      </React.Fragment>
    );
  }
}

export default Shop;
