import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
import PostApiCall from "../../components/helper/Api";
import Footer from "../../Footer";
import Header from "../../Header";
import "../../CustomerDashboard/Address.css";
import CustomerOrderList from "../../CustomerDashboard/CustomerOrderList";
import TabNavigation from "../../components/tabNavigation/tabNavigation";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // console.log(this.state.AddressData.length);
    return (
      <React.Fragment>
        <Header />
        <section style={{ paddingTop: "40px" }}>
          <Container fluid>
            <div className="row"></div>

            <Row className="mt-3">
              <Col xl="12">
                <div className="overflow-hidden">
                  <CustomerOrderList />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
        <TabNavigation />
      </React.Fragment>
    );
  }
}

export default Profile;
