import React from "react";
import Header from "./Header";
import Footer from "./Footer";

class Termscondition extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div class="privacy-title termsconditiontitle container  section-title text-align-center">
          <h1 className="static-h1-tems-font">
            Terms & conditions of Pio Packaging – a unit of Pio Printers Pvt Ltd
          </h1>
          <div class="title-divider"></div>
        </div>

        <div class="section-padding pt-0 mt-0">
          <div class="container">
            <div class="row ">
              <div class="col-lg-12 termsandconditions returnsandrefund-box disclaimer-box mb-5">
                <p>
                  BY USING THIS SITE, YOU INDICATE YOUR UNCONDITIONAL ACCEPTANCE
                  OF THESE TERMS OF USE (THE "TERMS OF USE"). WE RESERVE THE
                  RIGHT, IN OUR SOLE DISCRETION, TO UPDATE OR REVISE THESE TERMS
                  OF USE. THE CONTINUED USE OF THE SITE FOLLOWING THE POSTING OF
                  ANY CHANGES TO THE TERMS OF USE CONSTITUTES ACCEPTANCE OF
                  THOSE CHANGES.
                </p>
                <ol start="1">
                  <li>Use of the Site</li>
                </ol>
                <p>
                  You may use the Site only in accordance with and subject to
                  these Terms of Use and the Site's Privacy Policy (the "Privacy
                  Policy"). Once you complete and submit your registration, you
                  have opted in to receive email communication from us. You may
                  not use the Site for any purpose that is unlawful or
                  prohibited by these Terms of Use, or to solicit the
                  performance of any illegal activity or other activity which
                  infringes the rights of Pio Printers Pvt Ltd or others.
                  Notwithstanding any other rights or restrictions in these
                  Terms of Use, you may not use this Site to: (a) transmit via
                  or through the Site any information, data, text, images,
                  files, links, or software except in connection with your
                  authorized use of this Site or otherwise in response to
                  specific requests for information by us; (b) introduce to the
                  Site or any other computer or web site viruses, worms, Trojan
                  horses and/or harmful code; (c) obtain unauthorized access to
                  any computer system; (d) impersonate any other person,
                  including but not limited to, a registered user of this Site
                  or an employee of Pio Printers Pvt Ltd ; (e) invade the
                  privacy or violate any personal or proprietary right
                  (including intellectual property rights) of any person or
                  entity; (f) misrepresent the identity of a user or use a false
                  e-mail address; (g) tamper with or obtain access to this Site
                  or any component of this Site; (h) conduct fraudulent
                  activities; or (i) collect or harvest information regarding
                  other users of the Site for any reason whatsoever, including,
                  without limitation, for sending such users unsolicited
                  commercial e-mail.
                </p>

                <ol start="2">
                  <li> Accounts</li>
                </ol>
                <p>
                  When you create an account with us, you must provide us
                  information that is accurate, complete, and current at all
                  times. Failure to do so constitutes a breach of the Terms,
                  which may result in immediate termination of your account on
                  our Service.{" "}
                </p>
                <p>
                  You are responsible for maintaining the confidentiality of
                  your account and password. You agree to accept responsibility
                  for all activities that occur under your account or password.
                  You agree to immediately notify us in the event of any
                  unauthorized use of your account or other breach of security.
                </p>

                <ol start="3">
                  <li> Accuracy of Content</li>
                </ol>
                <p>
                  The information on this Site is believed to be complete and
                  reliable; however, the information may contain typographical
                  errors, pricing errors, and other errors or inaccuracies.
                  Errors will be corrected as soon as practicable. We reserve
                  the right to: (i) revoke any stated offer; (ii) correct any
                  errors, inaccuracies or omission; and (iii) make changes to
                  prices, content, promotion offers, product descriptions or
                  specifications, or other information without obligation to
                  issue any notice of such changes (including after an order has
                  been submitted, acknowledged, shipped or received). The
                  inclusion of any products or services on this Site at a
                  particular time does not guarantee that the products or
                  services will be available.
                </p>

                <ol start="4">
                  <li> Credit Card Payment</li>
                </ol>
                <p>
                  In a credit card transaction, you must use your own credit
                  card. Pio Printers Pvt Ltd will not be liable for any credit
                  card fraud. The liability to use a card fraudulently will be
                  on the user and the onus to 'prove otherwise' shall be
                  exclusively on the user.{" "}
                </p>
                <ol start="5">
                  <li> Proprietary Rights</li>
                </ol>
                <p>
                  You acknowledge and agree that the content, materials and
                  other components (including but not limited to logos,
                  graphics, button icons and page headers) available on the Site
                  are the property of Pio Printers Pvt Ltd and are protected by
                  copyrights, trademarks, service marks or other proprietary
                  rights and laws. You agree not to sell, license, rent, modify,
                  distributed, copy, reproduce, transmit, publicly display,
                  publicly perform, publish, adapt, edit or create derivative
                  works from content or materials on the Site. Use of the
                  content and materials for any purpose not expressly permitted
                  in these Terms of Use is prohibited. Pio Printers Pvt Ltd
                  relies upon a network of independent vendors who supply some
                  of the goods advertised on the Site and, in some cases, drop
                  ship them directly to you. We are not liable for any
                  infringement of copyrights, trademarks, trade dress or other
                  proprietary or intellectual property rights arising out of
                  content posted on or transmitted through the Site, or items
                  advertised on the Site, by our vendors.{" "}
                </p>
                <ol start="6">
                  <li> Submissions</li>
                </ol>
                <p>
                  Any comments, ideas, notes, postings, messages, suggestions or
                  other communications or information posted on the Site,
                  submitted to Pio Printers Pvt Ltd, or otherwise communicated
                  in connection with your use of the Site (collectively,
                  "Submissions") shall be and remain the exclusive property of
                  Pio Printers Pvt Ltd with no further compensation to you. As
                  such, we will own exclusively all such rights, titles and
                  interests and shall not be limited in any way in its use,
                  commercial or otherwise, of any Submissions. Under no
                  circumstances shall any disclosure of any submission to Pio
                  Printers Pvt Ltd be subject to any obligation of
                  confidentiality or expectation of compensation or attribution.
                  By submitting any submission to Pio Printers Pvt Ltd, you are
                  waiving any and all rights that you may have in the submission
                  and you agree that any submissions by you will not violate any
                  right of any third party, including copyright trademark,
                  privacy or other personal or proprietary rights. You also
                  agree that no submission by you will be or contain libellous
                  or otherwise unlawful, abusive or obscene material. You are
                  and shall remain solely responsible for the content of any
                  submissions you make. You represent and warrant to Pio
                  Printers Pvt Ltd that Pio printers Pvt Ltd is free to
                  implement the submission if it so desires, as provided or as
                  modified by Pio Printers Pvt Ltd, without obtaining permission
                  or license from any third party. Pio Printers Pvt Ltd takes no
                  responsibility and assumes no liability for any content
                  submitted or posted by you or any third party.{" "}
                </p>

                <ol start="7">
                  <li>
                    {" "}
                    Links to Third-Party Web Sites; No Implied Endorsements
                  </li>
                </ol>
                <p>
                  This Site may contain links to other web sites on the
                  Internet. You acknowledge that we have no control over such
                  web sites and that we are not responsible for the accuracy,
                  content, legality or any other aspect of any linked web site.
                  In no event shall any reference to any third party, third
                  party website or third party product or service be construed
                  as an approval or endorsement by us of that third party, third
                  party website or of any product or service provided by a third
                  party
                </p>
                <ol start="8">
                  <li> Indemnification</li>
                </ol>
                <p>
                  By using this Site, you agree to indemnify, hold harmless and
                  defend Pio Printers Pvt Ltd, its parent, subsidiaries and
                  affiliates, and their respective officers, directors,
                  employees, successors, agents, subsidiaries, partners,
                  contractors, vendors, manufacturers, distributors,
                  representatives and affiliates from any claims, damages,
                  losses, liabilities, and all costs and expenses of defense,
                  including but not limited to, attorneys' fees, resulting
                  directly or indirectly from a claim (including without
                  limitation, claims made by third parties for infringement of
                  intellectual property rights) by a third party that arises in
                  connection with (i) your use or misuse of the Site; (ii) your
                  breach of the Terms of Use; or (iii) your violation of any law
                  or the rights of a third party. You agree to cooperate as
                  fully as reasonably required in the defense of any claim. Pio
                  Printers Pvt Ltd reserves the right to assume the exclusive
                  defense and control of any matter otherwise subject to
                  indemnification by you.
                </p>
                <ol start="9">
                  <li> Disclaimer of Warranty</li>
                </ol>
                <p>
                  The site, its content and all text, images, merchandise and
                  other information on, accessible from or available through
                  this site are provided on an "as available" and "as is" basis
                  without warranty of any kind, either express or implied,
                  including but not limited to the implied warranties of
                  merchantability, fitness for a particular purpose, or
                  non-infringement. Specifically, but without limitation, Pio
                  Printers Pvt Ltd does not warrant that: (i) the information
                  available on this site is free of errors; (ii) the functions
                  contained on this site will be uninterrupted or free of
                  errors; (iii) defects will be corrected, or (iv) this site or
                  the server(s) that makes it available are free of viruses or
                  other harmful components
                </p>
                <ol start="10">
                  <li> Limitation of Liability</li>
                </ol>
                <p>
                  In no event shall Pio Printers Pvt Ltd, its parent,
                  subsidiaries or affiliates or their respective officers,
                  directors, employees, agents, successors, subsidiaries,
                  divisions, distributors, suppliers, affiliates or third
                  parties providing information on this site be liable to any
                  user of the site or any other person or entity for any direct,
                  indirect, special, incidental, punitive, consequential or
                  exemplary damages (including, but not limited to, damages for
                  loss of profits, loss of data or loss of use) arising out of
                  the use or inability to use the site or any information
                  contained thereon or stored or maintained by Pio Printers Pvt
                  Ltd, whether based upon warranty, contract, tort, or
                  otherwise. In no event shall the total liability of Pio
                  Printers Pvt Ltd, its parent, subsidiaries or affiliates or
                  their respective officers, directors, employees, agents,
                  successors, subsidiaries, divisions, distributors, suppliers,
                  affiliates or third parties providing information on this site
                  to you for all damages, losses, and causes of action resulting
                  from your use of this site, whether in contract, tort
                  (including, but not limited to, negligence) or otherwise,
                  exceed the amount you paid to Pio Printers Pvt Ltd in
                  connection with the applicable event, promotion or event
                  giving rise to such liability. Without limiting the foregoing,
                  in no event shall Pio Printers Pvt Ltd, its parent,
                  subsidiaries or affiliates or their respective officers,
                  directors, employees, agents, successors, subsidiaries,
                  divisions, distributors, suppliers, affiliates or third
                  parties providing information on this site have any liability
                  for any damages or losses arising out of or otherwise incurred
                  in connection with the loss of any data or information
                  contained in your account or otherwise stored by or on behalf
                  of Pio Printers Pvt Ltd{" "}
                </p>
                <ol start="11">
                  <li>International Use</li>
                </ol>
                <p>
                  We make no representation that information on this Site is
                  appropriate or available for use outside India. Those who
                  choose to access this Site from outside India do so on their
                  own initiative and are responsible for compliance with local
                  laws, if and to the extent local laws are applicable.
                </p>
                <ol start="12">
                  <li> Risk of Loss or damage in transit</li>
                </ol>
                <p>
                  The items purchased from our Site are shipped by a third party
                  carrier/transporter pursuant to a shipment contract. As a
                  result, risk of loss or damage and title for such items pass
                  to you upon our delivery to the carrier. You are encouraged to
                  insure the goods at your cost.
                </p>
                <ol start="13">
                  <li> Colour Accuracy</li>
                </ol>
                <p>
                  Our prime focus is to deliver colours as accurately as shown
                  on our products on our website, but due to certain conditions
                  like ink used, paper texture, and weather conditions, minor
                  differences may occur. Also note that colours appear
                  differently on different monitors unless they are colour
                  calibrated for Graphic design in CMYK. This is the reason why
                  our products, once ordered, can’t be returned for slight
                  colour variations (if any).
                </p>
                <ol start="14">
                  <li> Cancellations</li>
                </ol>
                <p>
                  You may cancel your order within 24hrs of placing the order.
                  After 24hrs your order will go to production department and
                  you will not be able to cancel it. You can cancel by sending
                  mail to info@piopackaging.com by mentioning the Order number
                  in the Subject along with the word CANCEL.
                </p>
                <ol start="15">
                  <li> Returns & Refunds</li>
                </ol>
                <p>
                  All our products are custom made, because of that we do not
                  allow returns or refunds. If you feel that the product you
                  received does not meet your expectations please contact us. We
                  want to make sure all our clients are happy with their
                  products and we will do our best to provide you with a
                  solution.
                </p>

                <ol start="16">
                  <li>Unavailability of Site; Termination; Fraud</li>
                </ol>
                <p>
                  We may alter, suspend, or discontinue this Site in whole or in
                  part, at any time and for any reason, without notice or cost.
                  We may, in our sole discretion, terminate or suspend your use
                  or access to all or part of the Site or your account or
                  membership, for any reason, including without limitation,
                  breach of these Terms of Use. If at any time, we notify you
                  that your access to and /or use of the Site or your account is
                  terminated, you must cease and desist from all such access
                  and/or use immediately. We reserve the right to cancel, delay,
                  refuse to ship, or recall from the shipper any order if fraud
                  is suspected. In the event these Terms of Use or your
                  membership or account are terminated, the restrictions
                  regarding intellectual property matters, the representations
                  and warranties, indemnities, and limitations of liabilities
                  set forth herein (as well as any other of your obligations
                  which by their nature should survive termination) will survive
                  termination.
                </p>
                <ol start="17">
                  <li>
                    {" "}
                    Copyright Infringement; Notice and Take Down Procedures
                  </li>
                </ol>
                <p>
                  If you believe that any materials on this Site infringe your
                  copyright, you may request that they be removed. This request
                  must bear a signature (or electronic equivalent) of the
                  copyright holder or an authorized representative and must: (a)
                  identify the allegedly infringing materials; (b) indicate
                  where on the Site the infringing materials are located; (c)
                  provide your name and contact information; (d) state that you
                  have a good faith belief that the materials are infringing;
                  (e) state that the information in your claim is accurate; and
                  (f) indicate that "under penalty of perjury" you are the
                  lawful copyright owner or are authorized to act on the owner's
                  behalf. With regards to copyright issues, kindly email to:
                  info@piopackaging.com. Pio Printers Pvt Ltd does not allow its
                  products to be resold under different brand name on other
                  online e-commerce portals without prior written permission.{" "}
                </p>
                <ol start="18">
                  <li>Miscellaneous</li>
                </ol>
                <p>
                  Unless otherwise specified herein, these Terms of Use,
                  together with the Privacy Policy, constitute the entire
                  agreement between you and Pio Printers Pvt Ltd with respect to
                  the Site and supersede all prior or contemporaneous
                  communications and proposals (whether oral, written or
                  electronic) between you and Pio Printers Pvt Ltd with respect
                  to the Site. If any part of these Terms of Use is held invalid
                  or unenforceable, that portion shall be construed in a manner
                  consistent with applicable law to reflect, as nearly as
                  possible, the original intentions of the parties, and the
                  remaining portions shall remain in full force and effect. The
                  failure of Pio Printers Pvt Ltd to act with respect to a
                  breach by you or others does not waive Pio Printers Pvt Ltd
                  right to act with respect to subsequent or similar breaches.
                  Pio Printers Pvt Ltd failure to exercise or enforce any right
                  or provision of these Terms of Use shall not constitute a
                  waiver of such right or provision. The section headings
                  contained in these Terms of Use are included for convenience
                  only and shall not limit or otherwise affect the terms of
                  these Terms of Use.{" "}
                </p>
                <ol start="19">
                  <li>Governing Law</li>
                </ol>
                <ol className="allrightreserved">
                  All disputes are subject to the jurisdiction of courts of
                  Chennai, India.
                </ol>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Termscondition;
