import React from "react";

class Products extends React.Component {
  render() {
    return (
        <section class="section Product bg-gray" id="products">
          <div class="container-fluid px-md-4 px-3">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title text-center mb-md-4 mb-3">
                  <h2>TYPES OF PRODUCTS</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2 col-sm-6">
                <a href="mono-cartons">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/thumb01.jpg"
                        alt="Mono Cartons"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Mono Cartons
                        {/* <span class="pull-right">
                          <i class="fa fa-external-link-square"></i>
                        </span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              {/* <!-- 01 --> */}
              <div class="col-lg-2 col-sm-6">
                <a href="bespoke-packaging">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/bespoke-packaging/thumb01.jpg"
                        alt="Bespoke Packaging"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        {" "}
                        Bespoke Packaging
                        {/* <span class="pull-right">
                          <i class="fa fa-external-link-square"></i>
                        </span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              {/* <!-- 02 --> */}
              <div class="col-lg-2 col-sm-6">
                <a href="creative-packaging">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/creative-packaging/thumb01.jpg"
                        alt="Creative Packaging"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Creative Packaging{" "}
                        {/* <span class="pull-right">
                          <i class="fa fa-external-link-square"></i>
                        </span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              {/* <!-- 03 --> */}
              <div class="col-lg-2 col-sm-6">
                <a href="retail-food-packaging">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/retail-food-packaging/thumb01.jpg"
                        alt="Retail Food Packaging"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Retail Food Packaging{" "}
                        {/* <span class="pull-right">
                          <i class="fa fa-external-link-square"></i>
                        </span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              {/* <!-- 04 --> */}
              <div class="col-lg-2 col-sm-6">
                <a href="product-visibility-packaging">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/product-visibility-packaging/thumb01.jpg"
                        alt="Product Visibility Packaging"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Product Visibility Packaging{" "}
                        {/* <span class="pull-right">
                          <i class="fa fa-external-link-square"></i>
                        </span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              {/* <!-- 05 --> */}
              <div class="col-lg-2 col-sm-6">
                <a href="sleeve-packaging">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/sleeve-packaging/thumb01.jpg"
                        alt="Sleeve Packaging"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Sleeve Packaging{" "}
                        {/* <span class="pull-right">
                          <i class="fa fa-external-link-square"></i>
                        </span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              {/* <!-- 06 --> */}
              <div class="col-lg-2 col-sm-6">
                <a href="hinged-lid-boxes">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/Hinged-lid-boxes/thumb01.jpg"
                        alt="Hinged Lid Boxes"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Hinged Lid Boxes{" "}
                        {/* <span class="pull-right">
                          <i class="fa fa-external-link-square"></i>
                        </span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-sm-6">
                <a href="drawer-type-box">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/Drawer-type-box/thumb01.jpg"
                        alt="Drawer Type Box"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Drawer Type Box{" "}
                        {/* <span class="pull-right">
                          <i class="fa fa-external-link-square"></i>
                        </span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-sm-6">
                <a href="base-and-lid-boxes">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/base-and-lid-boxes/thumb01.jpg"
                        alt="Base &amp; Lid Boxes"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Base &amp; Lid Boxes{" "}
                        {/* <span class="pull-right">
                          <i class="fa fa-external-link-square"></i>
                        </span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-sm-6">
                <a href="display-packaging">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/display-packaging/thumb01.jpg"
                        alt="Display Packaging"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Display Packaging{" "}
                        {/* <span class="pull-right">
                          <i class="fa fa-external-link-square"></i>
                        </span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-sm-6">
                <a href="gusset-packaging">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/gusset-packaging/thumb01.jpg"
                        alt="Gusset Packaging"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Gusset Packaging{" "}
                        {/* <span class="pull-right">
                          <i class="fa fa-external-link-square"></i>
                        </span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-sm-6">
                <a href="blister-cards">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/blister-cards/thumb01.jpg"
                        alt="Blister Cards"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Blister Cards{" "}
                        {/* <span class="pull-right">
                          <i class="fa fa-external-link-square"></i>
                        </span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-sm-6">
                <a href="labels-and-stickers">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/labels-and-stickers/thumb01.jpg"
                        alt="Labels &amp; Stickers"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Labels &amp; Stickers
                        {/* <span class="pull-right"><i
                                                class="fa fa-external-link-square"></i></span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-sm-6">
                <a href="paper-carry-bags">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/paper-bag/thumb01.jpg"
                        alt="Paper Carry Bags"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Paper Carry Bags
                        {/* <span class="pull-right"><i
                                                class="fa fa-external-link-square"></i></span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-sm-6">
                <a href="tags">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/tags/thumb01.jpg"
                        alt="Tags"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Tags
                        {/* <span class="pull-right"><i class="fa fa-external-link-square"></i></span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-sm-6">
                <a href="rigid-boxes">
                  <div class="product-cat-box">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/rigid-boxes/thumb01.jpg"
                        alt="Rigid Boxes"
                      />
                      <div class="over-view-icon">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="product-cat-box-txt text-center">
                      <p>
                        Rigid Boxes
                        {/* <span class="pull-right"><i class="fa fa-external-link-square"></i>
                                                </span> */}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="disclaimer text-center mb-0 pb-0">
                  <p className="mb-0 pb-0">
                    <b>Disclaimer:</b> Images are for illustration/inspiration
                    purpose only and copyrighted by their respective authors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

export default Products;
