import Notiflix from "notiflix";
import React from "react";
import { isMobile } from "react-device-detect";
import { BackgroundImage } from "react-image-and-background-image-fade";
import "react-multi-carousel/lib/styles.css";
import "react-responsive-modal/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PostApiCall from "../../components/helper/Api";
import ReactGA from "react-ga4";
import { Redirect, withRouter } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

ReactGA.initialize([
  {
    trackingId: "G-2VDZ0VM2P4",
  },
]);

class CustomSticker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shapeData: [],
      activeproduct: null,
      redirectToAnotherPage: false,
      stickerdata: "",
      loading: true,
      selectedIndex:0,
    };

    // Binding your method to the component instance
    this.redirectToAnotherPage = this.redirectToAnotherPage.bind(this);
  }
  componentDidMount() {
    PostApiCall.postRequest(
      {
        recordCount: "*",
        WhereClause: ` where fld_status = 'Yes' `,
      },
      "GetStickerShapeMaster"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          shapeData: obj.data,
          loading: false,
        });
      })
    );
  }

  redirectToAnotherPage(data,index) {
    // Update the state to trigger redirection
    this.setState({ redirectToAnotherPage: true, stickerdata: data,selectedIndex:index });
  }

  render() {
    const { shapeData, loading } = this.state;

    if (this.state.redirectToAnotherPage) {
      return (
        <Redirect
          to={{
            pathname: "/ordercustomstickers",
            state: { data: this.state.stickerdata,
              selectedIndex:this.state.selectedIndex
             },
          }}
        />
      );
    }

    // ********* Sticker Label ***********
    const settingsstricker = {
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: false,
      autoplaySpeed: 2000,
      rows: 2,
      // lazyLoad: 'ondemand',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 860,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 700,
          settings: {
            rows: 1,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div class="container-fluid px-md-4 px-3 pt-md-3 pt-3 mt-md-5 mb-md-3 mb-0">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title text-center mb-md-4 mb-3">
              <h2>Choose your custom Stickers</h2>
            </div>
          </div>
        </div>
        {/* **** Desktop view ********** */}
        <Slider
          {...settingsstricker}
          className="home-online-store-products desktop-view-custom-box-item"
        >
          {loading
            ? Array(12)
                .fill()
                .map((_, i) => (
                  <div className="grid-item" key={i}>
                    <Skeleton
                      height={30}
                      width={`98%`}
                      style={{ marginBottom: "12px", paddingRight: "10px" }}
                    />
                    <Skeleton height={295} width={349} />
                    <Skeleton
                      height={30}
                      width={`60%`}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    />
                  </div>
                ))
            : shapeData.map((data,index) => {
                return (
                  <div class="col-12 px-0 mb-2">
                    <div
                      className={
                        this.state.activeproduct == data.fld_id
                          ? "product-cat-box-active-border product-cat-box mx-1 mb-1"
                          : "product-cat-box mx-1 mb-1"
                      }
                      onClick={() => this.redirectToAnotherPage(data,index)}
                    >
                      <div class="product-cat-box-txt">
                        <p className="product-cat-box-name-txt shop-phn-img-heading-home-desktop product-content-align">
                          {data.fld_shape_name}
                        </p>
                      </div>
                      <div class="product-cat-box-img">
                        <a>
                          <BackgroundImage
                            src={data.fld_image}
                            value={data.fld_id}
                            title={data.fld_shape_name}
                            width="243px"
                            height="212px"
                            isResponsive
                            className="img-fluid"
                            alt="Retail Food Packaging"
                            onClick={(e) => {
                              this.setState({
                                selectedShape: e.target.getAttribute("value"),
                                selectedSticker: e.target.getAttribute("value"),
                                selectedShapeName:
                                  e.target.getAttribute("title"),
                                selectedStickerImage:
                                  e.target.getAttribute("src"),
                              });
                            }}
                            style={{
                              backgroundSize: "cover",
                              backgroundPosition: "center top",
                            }}
                            lazyLoad
                          />
                        </a>
                      </div>
                      <a className="btn shopnowbtn2 shop-phn-btn text-white shop-phn-img-heading-home-btn-desktop">
                        Customize your order
                      </a>
                    </div>
                  </div>
                );
              })}
        </Slider>

        {/* ********** Phone view ************ */}
        <div className="vertical-grid-box-sticker white-scrollbar phn-view-custom-box-item">
          {this.state.shapeData.map((data,index) => {
            return (
              <div className="Vertical-wrap px-0 mb-md-3 mb-0 d-flex align-items-center justify-content-center">
                <div>
                  <div
                    className={
                      this.state.activeproduct == data.fld_id
                        ? "product-cat-box-active-border product-cat-box mx-1 mb-0 pb-0"
                        : "product-cat-box mx-1 mb-0 pb-0"
                    }
                    onClick={() => this.redirectToAnotherPage(data,index)}
                  >
                    <div class="product-cat-box-txt">
                      <p className="product-cat-box-name-txt shop-phn-img-heading-phn-sticker-home product-content-align">
                        {data.fld_shape_name}
                      </p>
                    </div>
                    <div class="product-cat-box-img">
                      <BackgroundImage
                        src={data.fld_image}
                        value={data.fld_id}
                        title={data.fld_shape_name}
                        width="243px"
                        height="212px"
                        isResponsive
                        className="img-fluid"
                        alt="Retail Food Packaging"
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center top",
                        }}
                        lazyLoad
                      />
                    </div>
                    <a className="btn shopnowbtn2 shop-phn-img-heading-phn-sticker-home-btn text-white">
                      Customize your order
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(CustomSticker);
