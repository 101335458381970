import React from "react";
import TabNavigationMobile from "../../components/tabNavigationMobile/tabNavigationMobile";

const PioMobile = () => {
  return (
    <>
      <TabNavigationMobile />
    </>
  );
};

export default PioMobile;
