
import React, { useEffect } from 'react'

const Manage = () => {
    useEffect(() => {
        window.location.href = "https://manage.bechofy.in?client=2"


    }, [])
    return (
        <div>

        </div>
    )
}

export default Manage
