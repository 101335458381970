import React, { Component } from "react";
import Footer from "../../Footer";

import Header from "../../Header";
import Notiflix from 'notiflix';
import PostApiCall from "../../components/helper/Api";
import GetApiCall from "../../components/helper/GETAPI";

import registerimg from '../../assets/image/banner_mobile.png'
import borderimg from "../../assets/image/border.png";
import { Link } from "react-router-dom";

class Register extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            NumRegex: /^[0-9]*$/,
            isPasswordVisible: false,
            AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
            EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            UrlRegex: /^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
            open: false,
            EnteredOtp: '',
            MailerOtp: '',
            Email: '',
            openOtp: false,
            openPassword: false,
            Password: '',

            Salt: [],
            isNewPasswordVisible: false,
            isConPasswordVisible: false,
            Name: '',
            MobileNo: '',
            EmailAddress: '',
            RegisterPassword: '',
            ConfirmPassword: '',
            CompanyName: '',
            PasswordRegex: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,




        }
    }
    componentDidMount() {
        Notiflix.Notify.init({
            width: '300px',
            position: 'center-bottom',
            distance: '10px',
            opacity: 1,
        });
    }
    // ========RegistrationSave=========



    SaveRegistration = () => {

            if (this.state.Name != '') {
                if (this.state.EmailAddress != '') {
                    if (this.state.EmailRegex.test(this.state.EmailAddress)) {
                        if (this.state.MobileNo != '') {
                            if (this.state.MobileNo.length == 10) {
                                if (this.state.RegisterPassword != '') {
                                    if (this.state.PasswordRegex.test(this.state.RegisterPassword)) {


                                        this.onPostRegistration()

                                    } else {
                                        Notiflix.Notify.failure('Password must be at least 6 characters  & contains a number and a letter')
                                    }
                                }
                                else {
                                    Notiflix.Notify.failure('Please enter password.')
                                }


                            }
                            else {
                                Notiflix.Notify.failure('Please enter valid mobile number.')
                            }

                        }
                        else {
                            Notiflix.Notify.failure('Please enter mobile number.')
                        }

                    }
                    else {
                        Notiflix.Notify.failure('Please enter valid email address.')
                    }

                }
                else {
                    Notiflix.Notify.failure('Please enter email address.')
                }

            }
            else {
                Notiflix.Notify.failure('Please enter your name.')
            }
    }



    onPostRegistration = () => {

        Notiflix.Loading.circle();

        PostApiCall.postRequest({
            name: this.state.Name,
            email: this.state.EmailAddress,
            mobile: this.state.MobileNo,
            status: 'Website',
            password: this.state.RegisterPassword,
            updatedby: 0,
            companyName: this.state.CompanyName,


        }, "AddCustomer").then((result) =>

            result.json().then(obj => {
                if (result.status == 200 || result.status == 201) {
                    Notiflix.Notify.success('You are Successfully Registered')

                    window.location.href = "/login"
                    // this.onLogin()
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj.data)
                }
            })
        )

    }

    //   ========End RegisterDetails================


    // onLogin = () => {

    //     Notiflix.Loading.Circle();
    //     PostApiCall.postRequest(
    //         {
    //             email: this.state.EmailAddress,
    //             password: this.state.RegisterPassword,
    //             action: 'Login',
    //             actiondate: moment().format('lll'),
    //         }, "AddwebsiteLogin").then((results) =>

    //             // const objs = JSON.parse(result._bodyText)
    //             results.json().then(obj => {
    //                 if ((results.status == 200 || results.status == 201) && (localStorage.getItem("OrderData"))) {


    //                     localStorage.setItem('CustomerData', JSON.stringify(obj.data))
    //                     Notiflix.Loading.Remove()
    //                     // this.onPostMoveCart(obj)


    //                 } else if (results.status == 200 || results.status == 201) {
    //                     Notiflix.Loading.Remove()
    //                     // localStorage.setItem('CustomerData', JSON.stringify(obj.data))
    //                     // window.location.href = "/"
    //                 }
    //                 else {
    //                     Notiflix.Loading.Remove()
    //                     Notiflix.Notify.Failure(obj.data)
    //                 }
    //             })
    //         )


    // }

    render() {
        return (
            <div>
                <Header></Header>
                <section style={{ paddingTop: '65px' }}>

                </section>
                <div class="container">
                <section class="section-padding account-bg">
                        <div class="">
                            <div class=" ">
                                <div class="login">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-8 col-md-12 login-section login-page">
                                            <img src={registerimg} class="img-fluid loginimg2" alt=""></img>
                                            <div class="login-box">
                                                <h4 style={{ textAlign: 'center',marginBottom:"10px" }}> New to Piopackaging.com?

                                                </h4>

                                                {/* <p style={{ marginTop: '10px' }}>Let us Know you</p> */}

                                                <div class="row" onKeyPress={(e) => {
                                                                if (e.key === "Enter") {
                                                                    this.SaveRegistration();
                                                                }
                                                            }}>
                                                    <div class="col-md-12" style={{ marginTop: '20px' }}>

                                                        <input type="text" placeholder="Company Name" class="form-control" autoFocus value={this.state.CompanyName}
                                                            onChange={(name => {
                                                                this.setState({
                                                                    CompanyName: name.target.value
                                                                })
                                                            })}
                                                            
                                                        ></input>
                                                    </div>
                                                    <div class="col-md-12" style={{ marginTop: '10px' }}>

                                                        <input type="text" placeholder="Contact Person Name" class="form-control" value={this.state.Name}
                                                            onChange={(name => {
                                                                this.setState({
                                                                    Name: name.target.value
                                                                })
                                                            })}></input>
                                                    </div>
                                                    <div class="col-md-12" style={{ marginTop: '10px' }}>

                                                        <input type="email" placeholder="Email ID" class="form-control" value={this.state.EmailAddress} onChange={(email) => {
                                                            this.setState({ EmailAddress: email.target.value })
                                                        }}></input>
                                                    </div>
                                                    <div class="col-md-12" style={{ marginTop: '10px' }}>

                                                        <input type="number" placeholder="Mobile Number" class="form-control" value={this.state.MobileNo} onChange=
                                                            {(mobile) => {
                                                                if ((this.state.NumRegex.test(mobile.target.value)) && (mobile.target.value.length <= 10)) {
                                                                    this.setState({
                                                                        MobileNo: mobile.target.value
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div class="col-md-12" style={{ marginTop: '10px' }}>

                                                        <input placeholder="Password" class="form-control" type={this.state.isNewPasswordVisible == true ? 'text' : 'password'}
                                                            value={this.state.RegisterPassword}
                                                            onChange={(pswd) => {
                                                                this.setState({
                                                                    RegisterPassword: pswd.target.value
                                                                })
                                                            }}
                                                        />
                                                        <span class="login-icon-change-pass m-1" >
                                                            {/* <i class="icon-dual" data-feather="lock"></i>*/}
                                                            <i class="fa fa-eye-slash" aria-hidden="true"
                                                            style={{
                                                                display: this.state.isNewPasswordVisible
                                                                    ? "none"
                                                                    : "block",
                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    isNewPasswordVisible:
                                                                        !this.state.isNewPasswordVisible,
                                                                });
                                                            }}></i>
                                                            <i
                                                                style={{
                                                                    display: this.state.isNewPasswordVisible
                                                                        ? "block"
                                                                        : "none",
                                                                }}
                                                                className="fa fa-eye"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        isNewPasswordVisible:
                                                                            !this.state.isNewPasswordVisible,
                                                                    });
                                                                }}
                                                            />
                                                        </span>
                                                    </div>





                                                    {/* </div> */}


                                                    <div class="col-md-12" >
                                                        <a class="btn btn-common mt-30" style={{
                                                        color:"#fff",
                                                        fontWeight:"700"
                                                    }}
                                                            onClick={this.SaveRegistration}
                                                        >Register</a>

                                                    </div>
                                                    <div
                            class=" col-md-12 text-center mt-10"
                            style={{ marginTop: "7px" }}
                          >
                            <img src={borderimg} />
                            <div className="d-lg-flex justify-content-center align-items-center">
                              <p>Already have an account?</p>
                              <Link to="/login">
                              <p
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                  color: "#59c5d1",
                                }}
                                // onClick={() => {
                                //   window.location.href = "/register";
                                // }}
                              >
                                Log in
                              </p>
                              </Link>
                            </div>
                          </div>

                                                    {/* <p className="desclam">By Registering you agree to our Terms & Conditions</p> */}






                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>



                        </div>

                </section >
                </div>
                <Footer></Footer>
            </div >

        )
    }
}

export default Register;