/* eslint-disable no-loop-func */
import React from "react";

import Notiflix from "notiflix";
import PostApiCall from "../../components/helper/Api";
import Footer from "../../Footer";
import Menu from "../../Header";
// import PaymentProcess from "./assets/images/paymentprocessing.png"
import PaymentProcess from "../../assets/image/paymentprocessing.png";

class PaymentFail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ProdData: []
        };
    }

    componentDidMount() {


        Notiflix.Loading.init({
            svgColor: "#ee1b2f",
        });

        this.getOrderData()

    }


    getOrderData = () => {

        PostApiCall.postRequest(
            {
                whereClause: `where fld_payment_trx_num='${this.props.match.params.txnid}/${this.props.match.params.mipid}' `

            },
            "GetWebsiteOrderDetails"
        ).then((results1) =>
            // const objs = JSON.parse(result._bodyText)
            results1.json().then((obj) => {
                if (results1.status == 200 || results1.status == 201) {
                    // console.log(obj.data)
                    this.setState({
                        OrderData: obj.data[0],
                        ProdData: obj.data
                    }, () => {
                        if (obj.data[0].fld_design_type == null) {
                            // this.sendSampleKitMailer()
                            window.location.href = `/paymentsuccess/${this.props.match.params.txnid}/${this.props.match.params.mipid}`;
                        } else {
                            // this.SendMailers()
                            window.location.href = `/paymentsuccess/${this.props.match.params.txnid}/${this.props.match.params.mipid}`;
                        }

                    })
                }
            }))
    }



    render() {
        return (
            <>
                <Menu />
                <div class="container">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-flex justify-content-center text-align-center text-success" style={{ marginTop: "82px" }}>
                                <div class="spinner-border" role="status">

                                </div>
                                <p className="ml-3 mt-1" style={{ color: "#212529" }}>Order Processing...</p>
                            </div>

                            <div class="payment-box" >
                                <img src={PaymentProcess} class="paymentFail img-fluid" alt="payment failed" height="550px" width="550px"></img>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default PaymentFail