import React from 'react'
import Footer from "../../Footer";

import Header from "../../Header";
import PostApiCall from "../../components/helper/Api";

import Notiflix from 'notiflix'
import OtpInput from 'react-otp-input';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import ForgetImg from './forgotpassword.png'
import { Link } from 'react-router-dom';



var otp = Math.floor(100000 + Math.random() * 900000);


class ForgotPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            NumRegex: /^[0-9]*$/,
            isPasswordVisible: false,
            AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
            EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            UrlRegex: /^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
            PasswordRegex: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
            Email: '',
            open: true,
            EnteredOtp: null,
            ShowInputforOtp: false,
            openPassword: false,
            NewPassword: '',
            ConfirmPassword: '',
            Salt: [],
            isNewPasswordVisible: false,
            isConPasswordVisible: false,
            MobileNo: "",


        }
    }
    componentDidMount() {
        Notiflix.Notify.init({
            width: '300px',
            position: 'center-bottom',
            distance: '10px',
            opacity: 1,
        });
    }

    sendConfirmation() {
        if (this.state.Email != '') {
            if (this.state.EmailRegex.test(this.state.Email)) {

                this.onConfirmation();

            }
            else {
                Notiflix.Notify.failure('Please enter valid Email Address.')
            }
        }
        else {
            Notiflix.Notify.failure('Please enter your Email.')
        }
    }


    onConfirmation = () => {

        // //console.log(otp)
        // //console.log(this.state.Email)

        Notiflix.Loading.dots('Please wait...');
        PostApiCall.postRequest({
            OTP: otp,
            email: this.state.Email,

        }, "Mailer_ForgotPassword").then((result) => {
            Notiflix.Loading.remove();
            this.onPostSalt()
            // this.setState({

            //     open: false,
            //     ShowInputforOtp: true

            // })
        }
        )

    }

    onPostSalt() {
        //////console.log(this.state.Email)
        PostApiCall.postRequest(
            {
                email: this.state.Email
            },
            "Get_SaltByEmailId_ForgotPassword"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data!=null){
                       this.setState({
                           Salt: obj.data,
                           open: false,
                           ShowInputforOtp: true
                       }, () => {
                           Notiflix.Notify.success('Your verification OTP is successfully sent to your Email Id.')

                       })
                   }
                   
                    //  //////console.log(this.state.Salt.fld_salt)
                    //////console.log(this.state.Salt[0].fld_salt)


                }
                else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure('Email Id is not Registered')
                }
                

            }

            ))
    }



    VerifyOtp() {

        if (this.state.EnteredOtp != null) {
            if (this.state.EnteredOtp == otp) {
                this.setState({
                    openPassword: true,
                    ShowInputforOtp: false,
                })
            }
            else {
                Notiflix.Notify.failure('Incorrect OTP.')
            }
        }
        else {
            Notiflix.Notify.failure('Please enter OTP.')
        }
    }

    SavePassword() {
        if (this.state.NewPassword != '') {
            if (this.state.PasswordRegex.test(this.state.NewPassword)) {
                if (this.state.ConfirmPassword != '') {
                    if (this.state.ConfirmPassword == this.state.NewPassword) {
                        this.onPostPassword()
                    }
                    else {
                        Notiflix.Notify.failure('New Password and Confirm Password do not match ')
                    }
                }
                else {
                    Notiflix.Notify.failure('Please enter confirm password.')
                }

            } else {
                Notiflix.Notify.failure('Password must be at least 6 characters  & contains a number and a letter')
            }
        }

        else {
            Notiflix.Notify.failure('Please enter new password.')
        }
    }

    onPostPassword = () => {

        Notiflix.Loading.dots('Please wait...');

        PostApiCall.postRequest({

            email: this.state.Email,
            password: this.state.ConfirmPassword,
            salt: this.state.Salt[0].fld_salt,
            updatedby: null,


        }, "ResetPassword").then((result) =>

            result.json().then(obj => {
                if (result.status == 200 || result.status == 201) {

                    Notiflix.Notify.success('Password successfully changed')
                    window.location.href = "/login";
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure('Something went wrong, try again later.')
                }
            })
        )

    }


    render() {
        return (
            <div>
                <Header></Header>
                <section class="section-padding bg-grey mb-5">
                    <div class="container-fluid">
                        {/* <button type="button" class="goback-button" onClick={() => { this.props.history.goBack() }}><i class="fa fa-arrow-left" aria-hidden="true"></i>{" "}Go Back</button> */}


                        <div class="row justify-content-center">

                            <div class="col-md-11 col-lg-8 mt-3">

                                <div class="forgotpasswordbox">

                                    <div class="text-center">
                                        <h5>Forgot your Password? No Worries!</h5>
                                        <p className='mt-1 mb-2'>We want to make sure it's really you. In order to verify your identity tell us your email address to receive a verification code. </p>
                                    </div>

                                    <div class="row m-0">


                                        <div class="col-md-6 col-lg-5 col-xl-6">
                                            <img src={ForgetImg} width="100%" alt='forgot password image'/>
                                        </div>



                                        <div class="mt-4 col-md-6 col-lg-7 col-xl-6">

                                            <p className='mb-2'>Let's verify you!</p>

                                            <div class="row m-0 align-items-center mb-4 mt-2" style={{ display: this.state.open == true ? "flex" : "none" }}>
                                                <div className='col-md-8 col-lg-8 pl-lg-0 mb-lg-0 forget-pass'>
                                                <input type="text" placeholder="Enter your Email Address" class="form-control mb-0" value={this.state.email} onChange={(email) => {
                                                    this.setState({ Email: email.target.value })
                                                }}></input>
                                                </div>
                                                <div className='col-md-4 col-lg-4 px-0'>
                                                <a class=" btn " style={{ background: '#59c5d1', borderRadius: '3px', color: 'white',width:"100%",fontWeight:"700" }}
                                                    onClick=
                                                    {
                                                        this.sendConfirmation.bind(this)
                                                    }
                                                >Get Code</a>
                                                </div>
                                            </div>

                                        <div className='row' 
                                            style={{ display: this.state.ShowInputforOtp == true ? "flex" : "none" }}
                                            >
                                            <div  className='col-lg-5 col-md-12 pl-0 pl-sm-3'
                                            >
                                                <input type={
                                                    this.state.isPasswordVisible
                                                        ? "text"
                                                        : "password"
                                                } placeholder="Enter OTP" class="form-control fs-14"
                                                    value={this.state.EnteredOtp}
                                                    onChange={otp => this.setState({
                                                        EnteredOtp: otp.target.value
                                                    })}
                                                ></input>
                                                <span class="login-icon-change-pass m-1">
                                                    {/* <i class="icon-dual" data-feather="lock"></i>*/}
                                                    <i class="fa fa-eye-slash" aria-hidden="true"
                                                            style={{
                                                                display: this.state.isPasswordVisible
                                                                    ? "none"
                                                                    : "block",
                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    isPasswordVisible:
                                                                        !this.state.isPasswordVisible,
                                                                });
                                                            }}></i>
                                                    <i
                                                        style={{
                                                            display: this.state.isPasswordVisible
                                                                ? "block"
                                                                : "none",
                                                        }}
                                                        className="fa fa-eye"
                                                        onClick={() => {
                                                            this.setState({
                                                                isPasswordVisible:
                                                                    !this.state.isPasswordVisible,
                                                            });
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                            <div className='col-md-6 col-lg-4 pl-0 mb-1 verify-btn'>
                                                <a class=" btn fs-14 " style={{ background: '#59c5d1', borderRadius: '3px', color: 'white',width:"100%",fontWeight:"700" }}
                                                   onClick={this.VerifyOtp.bind(this)}
                                                >Verify Code</a>
                                                </div>
                                                <div className='col-md-6 col-lg-3 pl-0 pl-md-3 pl-lg-0'>
                                                <a class="  btn btn-warning fs-14" style={{background: '#ffc107', borderRadius: '3px', color: 'white',width:"100%",fontWeight:"700" }}
                                                    onClick=
                                                    {
                                                        this.sendConfirmation.bind(this)
                                                    }
                                                >Resend</a>
                                                </div>

                                        </div>             



                                            <div style={{ display: this.state.openPassword == false ? "none" : "block" }}>
                                                <input type={
                                                    this.state.isPasswordVisible
                                                        ? "text"
                                                        : "password"
                                                } placeholder="Enter new passowrd" class="form-control" value={this.state.NewPassword}
                                                    onChange={password => this.setState({
                                                        NewPassword: password.target.value
                                                    })}
                                                ></input>
                                                <span class="login-icon-change-pass m-1">
                              {/* <i class="icon-dual" data-feather="lock"></i>*/}
                              <i class="fa fa-eye-slash" aria-hidden="true"
                                                            style={{
                                                                display: this.state.isPasswordVisible
                                                                    ? "none"
                                                                    : "block",
                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    isPasswordVisible:
                                                                        !this.state.isPasswordVisible,
                                                                });
                                                            }}></i>
                              <i
                                style={{
                                  display: this.state.isPasswordVisible
                                    ? "block"
                                    : "none",
                                }}
                                className="fa fa-eye"
                                onClick={() => {
                                  this.setState({
                                    isPasswordVisible:
                                      !this.state.isPasswordVisible,
                                  });
                                }}
                              />
                            </span>
                                                <input type={
                                                    this.state.isConPasswordVisible
                                                        ? "text"
                                                        : "password"
                                                } placeholder="Confirm new password" class="form-control" value={this.state.ConfirmPassword}
                                                    onChange={password => this.setState({
                                                        ConfirmPassword: password.target.value
                                                    })}
                                                ></input>
                                                <span class="login-icon-change-pass m-1">
                                                    {/* <i class="icon-dual" data-feather="lock"></i>*/}
                                                    <i class="fa fa-eye-slash" aria-hidden="true"
                                                            style={{
                                                                display: this.state.isConPasswordVisible
                                                                ? "none"
                                                                : "block",
                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    isConPasswordVisible:
                                                                        !this.state.isConPasswordVisible,
                                                                });
                                                            }}></i>
                                                    <i
                                                        style={{
                                                            display: this.state.isConPasswordVisible
                                                                ? "block"
                                                                : "none",
                                                        }}
                                                        className="fa fa-eye"
                                                        onClick={() => {
                                                            this.setState({
                                                                isConPasswordVisible:
                                                                    !this.state.isConPasswordVisible,
                                                            });
                                                        }}
                                                    />
                                                </span>
                                            </div>

                                            {this.state.openPassword == false ?
                                                // <div class="row justify-content-between align-items-center" style={{ display: this.state.open == true ? "none" : "flex" }}>
                                                //     <div className='col-6'>
                                                //     <a class="shopping-btn mt-20 text-center"
                                                //         style={{ background: '#59c5d1', borderRadius: '3px', color: 'white' }}
                                                //         onClick={this.VerifyOtp.bind(this)}
                                                //     >Verify Code</a>
                                                //     </div>

                                                //     <div className='col-6'>
                                                //     <a class="mt-2 float-right forgot-pass text-center" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ cursor:'pointer',color: "#59c5d1", }}
                                                //         onClick=
                                                //         {
                                                //             this.sendConfirmation.bind(this)
                                                //         }
                                                //     >Resend Code</a>
                                                //     </div>

                                                // </div>
                                                <></>
                                                :

                                                <div class="row justify-content-between align-items-center" style={{ display: this.state.open == true ? "none" : "block" }}>

                                                    <a class="shopping-btn mt-20"
                                                        style={{ background: '#59c5d1', borderRadius: '3px', color: 'white',cursor:'pointer',marginLeft:"15px" }}
                                                        onClick={this.SavePassword.bind(this)}
                                                    >Save new password</a>

                                                </div>
                                            }



                                            <div class="rgbox-bottom text-center mt-4">
                                                <p><label>Customize your Box</label></p>
                                            </div>

                                            <div class="row justify-content-around mt-3">
                                                <div class="but col-6 justify-content-center">
                                                <Link to="/register">
                                                    <button type="button" class="btn btn-secondary" 
                                                        style={{ background: '#59c5d1', borderRadius: '3px', color: 'white', cursor: 'pointer', border: '1px solid #59c5d1 ',width:"100%",padding:"6px",fontWeight:"700" }}
                                                    // onClick={() => {
                                                    //     window.location.href = "/register"
                                                    // }}
                                                    >
                                                        Sign Up
                                                    </button>
                                                </Link>
                                                </div>
                                                
                                                <div class="but col-6 justify-content-center">
                                                <Link to="/login">
                                                    <button type="button" class="btn btn-secondary" 
                                                        style={{ background: '#59c5d1', borderRadius: '3px', color: 'white', cursor: 'pointer', border:'1px solid #59c5d1 ',width:"100%",padding:"6px",fontWeight:"700"}}
                                                    // onClick={() => {
                                                    //     window.location.href = "/login"
                                                    // }}
                                                    >
                                                        Login
                                                    </button>
                                                    </Link>
                                                </div>

                                            </div>




                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                </section>
                <Footer></Footer>
            </div>
        )

    }
}

export default ForgotPassword
