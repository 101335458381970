import React, { Component } from "react";
import {
	Container, Row, Col, Card, CardBody, Media,
} from "reactstrap";
import PostApiCall from "../components/helper/Api";
import Notiflix from "notiflix";
import moment from 'moment';
import Footer from '../Footer'
import Header from '../Header';
import "./Address.css"
import OrderGraph from './OrderSummary'
import CustomerOrderList from './CustomerOrderList'

import Plusicon from './images/plus_sign.png'
import Editicon from './images/edit.png'

class ChangePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imagePreviewUrl: "http://demo.globaltrendz.online/maxxchange/images/User/Shivani-1.jpg",

			ImageApiUrl: "http://api.globaltrendz.online/AddImage",
			Status: "Active",
			ImageData: [],

			UnitofMeasurement: "",
			Price: 0,
			Points: 0,
			DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
			RawMaterialName: "",
			GenderData: [
				{ value: "Male", label: "Male" },
				{ value: "Female", label: "Female" },
				{ value: "Others", label: "Others" },
			],
			Gender: "",
			CountryData: [],
			CityData: [],
			StateData: [],
			CountryId: 0,
			StateId: 0,
			CityId: 0,
			Name: "",
			Mobile: "",
			Email: "",
			DOB: "",
			AnniversaryDate: "",
			Designation: "",
			Department: "",
			UserType: "",
			Address: "",
			Country: "",
			State: "",
			City: "",
			Pincode: "",
			AadharCard: "",
			PANCard: "",
			DrivingLicenses: "",
			BankName: "",
			Branch: "",
			AccountNumber: "",
			IFSCCode: "",
			Salary: "",
			Password: "",
			ConfirmPassword: "",
			UserData: [
				{ value: "Admin", label: "Admin" },
				{ value: "Super Admin", label: "Super Admin" },
				{ value: "Users", label: "Users" },
			],
			// DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
			NumRegex: /^[0-9]*$/,
			AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
			EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			UrlRegex: /^(https:\/\/www\.|https:\/\/www\.|https:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
			DepartmentData: [],
			AadharData: [],
			AadharUrl: "http://api.globaltrendz.online/AddPdf",
			PanData: [],
			PanUrl: "http://api.globaltrendz.online/AddPdf",
			DrivingData: [],
			DrivingUrl: "http://api.globaltrendz.online/AddPdf",
			DesignationData: [],
			BankData: [],
			isNewPasswordVisible: false,
			isConPasswordVisible: false,
			Details: [],
			Logintime: '',
			CustomerData: [],
			AddressData: [],
			OrderData: [],
		};
	}

	componentDidMount() {


		//   this.props.setclearbrand()
		var customer = localStorage.getItem('CustomerData');
		var CustomerDetails = JSON.parse(customer)
		// console.log(this.AddressData.map.length)



		PostApiCall.postRequest(
			{
				WhereClause: `where fld_customerid=${CustomerDetails[0].fld_customerid}`
			},
			"Get_customerData_by_id"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				// console.log(obj.data);
				if (results.status == 200 || results.status == 201) {

					this.setState({
						CustomerData: obj.data,

					});
				}
			}))

		// ===============Address Data=================




		PostApiCall.postRequest(
			{
				whereClause: `where fld_customerid=${CustomerDetails[0].fld_customerid}`
			},
			"Get_Customer_Address"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {

					this.setState({
						AddressData: obj.data,

					});
				}
			}))




		PostApiCall.postRequest(
			{
				WhereClause: `where fld_customer_id=${CustomerDetails[0].fld_customerid}`
			},
			"GetCustomerOrderDetails"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					// console.log(obj.data)
					this.setState({
						OrderData: obj.data,
					});
				}
			}))

	}

	render() {
		// console.log(this.state.AddressData.length);
		return (
			<React.Fragment>
				<Header />
				<section style={{ paddingTop: '40px' }}>

					<Container fluid>
						<div className="row">


						</div>

						<Row className="mt-3">
							<Col xl="12">
								<div className="overflow-hidden">
									<div
										className=""
									>
										<Row>
											<Col xs="12">
												<div className="section-title">
													<h4>

													</h4>
												</div>
											</Col>

										</Row>
									</div>

									<Row style={{ marginTop: '15px' }}>
										<Col md="6" xl="4" >
											<Card className="overflow-hidden website-card card-border" style={{marginBottom:"16px"}}>
												<div className="globalTrendzEcomm-bg-soft-pink" style={{ height: '85px', background: '#59c5d1' }}
												>
													{this.state.CustomerData.map((data, i) => {
														if (i === 0) {
															return (

																<Row>
																	<Col xs="9">
																		<div className="globalTrendzEcomm-text p-3" >
																			<h5 className="globalTrendzEcomm-text" style={{ display: 'flex',textTransform:"capitalize",fontWeight:"bold" }}>{data.fld_name}</h5>
																			<p className="globalTrendzEcomm-text" style={{fontWeight:"500"}}> Customer Since:  {data.fld_created_on}</p>

																		</div>

																	</Col>
																	<Col xs="3" style={{margin:"22px 0"}} className="text-center">
																		{/* <img src='' alt="" className="img-fluid customerProfile" /> */}
																		{this.state.CustomerData.map((data, i) => {
														if (i == 0) {
															return (
																// <Row className="mt-3">
																	// <div class="col-12">
																		<a  title="Edit Profile">
																			<img class="edit-profile-icon" src={Editicon} style={{ width: '35px', cursor: 'pointer', }}
																				onClick={() => {
																					localStorage.setItem("ProfileData", JSON.stringify(data));
																					window.location.href = "/edit-profile";

																				}}
																			></img>
																		</a>
																	// </div>
																// </Row>
															)
														}
													})}

																	</Col>
																</Row>)
														}
													}
													)}
												</div>


												<CardBody className="pt-6" style={{ paddingTop: '1rem' }}>
													
													<Row>
														<Col xs="5" className="customer-welcome">
															<p>Date of Birth</p>
															{/* <p>Gender</p> */}
															{/* <p>Marital Status</p> */}
															<p>Mobile</p>
															<p>Email</p>
															<p style={{ display: this.state.AddressData.length != 0 ? "block" : "none" }}>Address</p>

														</Col>

														{this.state.CustomerData.map((data, i) => {
															if (i === 0) {
																return (
																	<Col xs="7" className="customer-welcome">
																		<p>{data.fld_dob == null ? 'N/A' : moment(data.fld_dob).format('DD/MM/YYYY')}</p>
																		{/* <p>{data.fld_maritalstatus == null ? 'N/A' : data.fld_maritalstatus}</p> */}
																		{/* <p>{data.fld_doa == null ? 'N/A' : moment(data.fld_doa).format('DD/MM/YYYY')} </p> */}
																		<p>{data.fld_phone_number == null ? 'N/A' : data.fld_phone_number} </p>
																		<p>{data.fld_email_address == null ? 'N/A' : data.fld_email_address} </p>
																		<p>{data.fld_address_2}, {data.CityName}, {data.StateName}, {data.fld_pincode}, {data.CountryName}</p>

																	</Col>
																)
															}
														})
														}

													</Row>
													
												</CardBody>
											</Card>

										</Col>

										<Col md="6" xl="3">
											<Card className="mini-stats-wid website-card card-border">

												<div className="row">
													<div className="col-md-12">
														<div className=" website-card">
															<div className="globalTrendzEcomm-bg-soft-pink" style={{ height: '85px', background: '#59c5d1' }}
															>
																<h4 className="mb-0 productTitle" >Order Summary</h4>
															</div>
														</div>
													</div>

												</div>

												<CardBody>
													<p className="text-muted font-weight-medium">
														Total Orders Value
														{this.state.OrderData.map((data, i) => {
															if (i === 0) {
																return (
																	<span className="text-muted font-weight-medium float-right globalTrendzEcomm-count2 d-flex justify-content-center align-items-center">
																		{data.TotalSalesValue}
																	</span>
																)
															}
														})}
													</p>
													<hr />
													<p className="text-muted font-weight-medium">
														Total Orders
														{this.state.OrderData.map((data, i) => {
															if (i === 0) {
																return (
																	<span className="text-muted font-weight-medium float-right globalTrendzEcomm-count2 d-flex justify-content-center align-items-center">
																		{data.TotalOrder}
																	</span>
																)
															}
														})}
													</p>
													<hr />
													<p className="text-muted font-weight-medium">
														Today's Orders
														{this.state.OrderData.map((data, i) => {
															if (i === 0) {
																return (
																	<span className="text-muted font-weight-medium float-right globalTrendzEcomm-count2 d-flex justify-content-center align-items-center">
																		{data.Todays_order}
																	</span>
																)
															}
														})}
													</p>
													<hr />
													<p className="text-muted font-weight-medium">
														Today's Order Value
														{this.state.OrderData.map((data, i) => {
															if (i === 0) {
																return (
																	<span className="text-muted font-weight-medium float-right globalTrendzEcomm-count2 d-flex justify-content-center align-items-center">
																		{data.Todays_order_value}
																	</span>
																)
															}
														})}
													</p>
													<hr />
													<p className="text-muted font-weight-medium">
														Order this Month
														{this.state.OrderData.map((data, i) => {
															if (i === 0) {
																return (
																	<span className="text-muted font-weight-medium float-right globalTrendzEcomm-count2 d-flex justify-content-center align-items-center">
																		{data.thisMonth_order}
																	</span>
																)
															}
														})}
													</p>

												</CardBody>
											</Card>
										</Col>



										<Col md="12" xl="5" className="graph">


											<OrderGraph style={{ height: '383px' }} />

											{/* <ReactApexChart options={this.state.options} series={this.state.series} type="donut" width={450} /> */}





										</Col>
									</Row>

									{/* Address -book */}
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden mt-3 ">

												<div className="row">
													<div className="col-md-12">
														<div className="overflow-hidden website-card">
															<div className="globalTrendzEcomm-bg-soft-pink" style={{ height: '85px', background: '#59c5d1' }}
															>
																<h4 className="mb-0 productTitle"> Address Book</h4>
															</div>
														</div>
													</div>

												</div>

												<CardBody >
													{/* <div className="section-title">
														<h4>Address Book</h4>
													</div> */}
													<Row className="mt-3">
														{/* {console.log(this.state.AddressData)} */}
														<div class="col-lg-12 col-md-12 col-sm-2 mb-3">

															<div className="row">
																{
																	this.state.AddressData.map((data, index) => {
																		return <>
																			<div className="col-lg-4 col-md-6" style={{ marginBottom: "26px", display: this.state.AddressData.length != 0 ? 'block' : 'none' }}>

																				<Card className="p-3 px-4 address-card website-card">

																					<div class="address-content">
																						<h5>{data.fld_contact_name}</h5>
																						<p>{data.fld_address_2}, <br />
																							{data.CityName}, {data.StateName} <br /> {data.fld_pincode}, {data.CountryName}</p>
																						<p>{data.fld_landmark}</p>
																						<h6>ContactNo.: {data.fld_contact_number}  </h6>
																						<h6>GSTIN : {data.fld_gstin} </h6>
																					</div>
																					<div class="row address-icon-row">
																						<div class="col-6"></div>
																						<div class="col-6">
																							<a class="address-icon" onClick={() => {
																								localStorage.setItem("AddressData", JSON.stringify(data))
																								window.location.href = "/edit-address"
																							}}>
																								<i class="fas fa-edit"
																									style={{
																										float: 'right',
																										fontSize: '20px',
																										cursor: 'pointer'
																									}}
																								>
																								</i>
																							</a>
																							<a class="address-icon" onClick={() => {
																								const details = JSON.parse(localStorage.getItem("CustomerData"))
																								PostApiCall.postRequest({
																									CUSTOMERID: 6,
																									addressid: data.fld_address_id,
																									title: data.fld_address_type,
																									name: data.fld_contact_name,
																									mobileNo: data.fld_contact_number,
																									area: data.fld_address_2,
																									flatno: data.fld_address_1,
																									landmark: data.fld_landmark,
																									country: data.Country,
																									state: data.State,
																									city: data.City,
																									pincode: data.fld_pincode,
																									action: 'DELETE',

																								}, "Add_CustomerAddress").then((results =>
																									results.json().then(obj => {
																										if (results.status == 200 || results.status == 201) {


																											Notiflix.Notify.success('Address Successfully Delete.')
																											window.location.reload()
																										}
																									})
																								))
																							}}>
																								<i class="fas fa-trash"
																									style={{
																										float: 'right',
																										fontSize: '20px',
																										marginRight: '10%',
																										cursor: 'pointer'
																									}}>
																								</i>
																							</a>
																						</div>
																					</div>
																				</Card>

																			</div>
																		</>

																	})
																}
																<div class="col-lg-4 col-md-4 col-sm-2 mb-3">
																	<div class="d-flex align-items-center justify-content-center address-box-profile website-card">
																		<div class="address-plus ">
																			<a class="address-icon" >

																				<img src={Plusicon}
																					alt=""
																					onClick={() => {

																						window.location.href = "/address_book_form";


																					}}
																					style={{ width: '40%' }}

																				></img>
																				{/* <i class="fa fa-plus-circle"
																					style={{
																						fontSize: '60px',
																					}}
																					
																				></i> */}
																			</a>
																		</div>
																	</div>
																</div>

															</div>


														</div>




													</Row>
												</CardBody>

											</Card>
										</Col>
									</Row>

									{/* Address -book */}
									{/* --incart */}
									{/* <Row>
										<Col xl="12">
											<CustomerCardHistory />

										</Col>
									</Row> */}

									{/* ====in order */}
									<Row>
										<Col xl="12">
											<CustomerOrderList />

										</Col>
									</Row>

									{/* ==FRequently= */}
									{/* --incart */}

								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<Footer />
			</React.Fragment>
		)
	}

}

export default ChangePassword