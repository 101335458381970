import React, { Component, useContext } from "react";
import "react-responsive-modal/styles.css";
import { Link } from "react-router-dom";
import { store } from "../context/store";

class tabNavigation extends Component {
  static contextType = store;

  componentDidMount() {
    const store = this.context;
  }

  render() {
    return (
      <>
        <section class="tab-navigation">
          <div class="tab-navigation-wrapper">
            <div class="tab-navigation-list">
              <Link to="/pio-mobile/design">
                <div
                  class={
                    this.context.activateClass == "profile"
                      ? "tab-navigation-item active"
                      : "tab-navigation-item"
                  }
                  onClick={() => {
                    this.context.setactivateClass("profile");
                  }}
                >
                  <i class="fa-regular fa-user"></i>
                  <p class="tab-navigation-item-name">design</p>
                </div>
              </Link>
              <Link to="/pio-mobile/quantity">
                <div
                  class={
                    this.context.activateClass == "orders"
                      ? "tab-navigation-item active"
                      : "tab-navigation-item"
                  }
                  onClick={() => {
                    this.context.setactivateClass("orders");
                  }}
                >
                  <i class="fa-regular fa-rectangle-list"></i>
                  <p class="tab-navigation-item-name">quantity</p>
                </div>
              </Link>

              {/* <div
                class={
                  this.context.activateClass == "logout"
                    ? "tab-navigation-item active"
                    : "tab-navigation-item"
                }
                onClick={() => {
                  this.context.setactivateClass("logout");
                  localStorage.clear();
                  window.location.href = "/ordercustomboxes";
                }}
              >
                <i class="fa-solid fa-arrow-right-from-bracket"></i>
                <p class="tab-navigation-item-name">logout</p>
              </div> */}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default tabNavigation;
