import React, { Component, useContext } from "react";
import "react-responsive-modal/styles.css";
import { Link } from "react-router-dom";
import { store } from "../context/store";

class tabNavigation extends Component {
  static contextType = store;

  componentDidMount() {
    const store = this.context;
    // console.log(window.location.pathname)
    if (window.location.pathname == "/orders") {
      this.context.setactivateClass("orders");
    } else if (window.location.pathname == "/profile") {
      this.context.setactivateClass("profile");
    } else if (window.location.pathname == "/address") {
      this.context.setactivateClass("address");
    }
  }

  render() {
    return (
      <>
        <section
          class="tab-navigation"
          // style={{
          //   display:
          //     localStorage.getItem("CustomerData") == null ? "none" : "visible",
          // }}
          style={{
            display:
              localStorage.getItem("CustomerData") == null ? "none" : "none",
          }}
        >
          <div class="tab-navigation-wrapper">
            <div class="tab-navigation-list">
              <Link
                to="/profile"
                class={
                  this.context.activateClass == "profile"
                    ? "tab-navigation-item active"
                    : "tab-navigation-item"
                }
              >
                <div
                  onClick={() => {
                    this.context.setactivateClass("profile");
                  }}
                >
                  <i class="fa-regular fa-user"></i>
                  <p class="tab-navigation-item-name">profile</p>
                </div>
              </Link>
              <Link
                to="/orders"
                class={
                  this.context.activateClass == "orders"
                    ? "tab-navigation-item active"
                    : "tab-navigation-item"
                }
              >
                <div
                  onClick={() => {
                    this.context.setactivateClass("orders");
                  }}
                >
                  <i class="fa-regular fa-rectangle-list"></i>
                  <p class="tab-navigation-item-name">orders</p>
                </div>
              </Link>
              <Link
                to="/address"
                class={
                  this.context.activateClass == "address"
                    ? "tab-navigation-item active"
                    : "tab-navigation-item"
                }
              >
                <div
                  onClick={() => {
                    this.context.setactivateClass("address");
                  }}
                >
                  <i class="fa-regular fa-address-book"></i>
                  <p class="tab-navigation-item-name">address book</p>
                </div>
              </Link>

              <div
                class={
                  this.context.activateClass == "logout"
                    ? "tab-navigation-item active"
                    : "tab-navigation-item"
                }
                onClick={() => {
                  this.context.setactivateClass("logout");
                  localStorage.clear();
                  window.location.href = "/ordercustomboxes";
                }}
              >
                <i class="fa-solid fa-arrow-right-from-bracket"></i>
                <p class="tab-navigation-item-name">logout</p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default tabNavigation;
