import React, { Component } from "react";
import Footer from "../../Footer";

import moment from "moment";
import Notiflix from "notiflix";
import PostApiCall from "../../components/helper/Api";
import GetApiCall from "../../components/helper/GETAPI";
import Header from "../../Header";

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Viewlargeimage:
        "https://www.piopackaging.com/assets/img/industries/cosmetics-health-beauty.jpg",
      CountryData: [],
      CityData: [],
      StateData: [],
      CountryId: 0,
      StateId: 0,
      CityId: 0,
      BillingCountry: null,
      BillingAddress: "",
      BillingState: null,
      BillingCity: null,
      BillingPincode: null,
      AddressId: null,
      NumRegex: /^[0-9]*$/,
      BillingCompanyName: null,
      ShippingCountry: null,
      ShippingState: null,
      ShippingCity: null,
      ShippingCountryData: [],
      ShippingStateData: [],
      ShippingCityData: [],
      ShippingCityId: 0,
      ShippingCountryId: 0,
      ShippingStateId: 0,
      ShippingPincode: "",
      ShippingContactPerson: "",
      BillingContactPerson: "",
      ProductCart: [],
      AddressData: [],
      ShippingAddress: "",
      ShippingCompanyName: "",
      ShippingGSTIN: "",
      ShippingEmail: "",
      ShippingMobile: "",
      PermanentSame: false,
      CustomQuantity_Deliverycharge: 0,
      CustomQuantity_NetAmount: 0,
      CustomQuantity_GstAmount: 0,
      CustomQuantity_TotalAmount: 0,
      SelectedshippingAddress: null,
      SelectedPincode: null,
      SelectedbillingAddress: null,
      SelectedPermanentSame: false,

      AddNewAddress: "Yes",
      Mobile: null,
    };
  }

  componentDidMount() {
    Notiflix.Notify.init({
      width: "340px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });

    // console.log(localStorage.getItem('Ordertype'))
    localStorage.removeItem("currentPage");

    Notiflix.Loading.circle();

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    PostApiCall.postRequest(
      {
        whereClause: `where fld_customerid=${logindetails[0].fld_customerid}`,
      },
      "Get_Customer_Address"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            this.setState({
              AddressData: obj.data,
              AddNewAddress: "No",
            });
          }
          Notiflix.Loading.remove();
        }
      })
    );

    // console.log(localStorage.getItem('OrderData'))

    GetApiCall.getRequest("GetCountry").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          CountryData: obj.data,
          ShippingCountryData: obj.data,
        });

        if (obj.data.length != 0) {
          this.setState({
            CountryId: 101,
            BillingCountry: obj.data[152].value,
            ShippingCountryId: 101,

            ShippingCountry: obj.data[152].value,
          });
        }
        PostApiCall.postRequest(
          {
            countryid: 101,
          },
          "GetState"
        ).then((results) =>
          results.json().then((objstate) => {
            if (results.status == 200 || results.status == 201) {
              if (objstate.data.length != 0) {
                this.setState({
                  StateId: objstate.data[0].value,
                  ShippingStateId: objstate.data[0].value,
                  ShippingStateData: objstate.data,
                  ShippingState: objstate.data[0].value,
                  StateData: objstate.data,
                  BillingState: objstate.data[0].value,
                });
              }

              PostApiCall.postRequest(
                {
                  stateid: objstate.data[0].value,
                },
                "GetCity"
              ).then((resultscity) =>
                resultscity.json().then((objcity) => {
                  if (resultscity.status == 200 || resultscity.status == 201) {
                    if (objcity.data.length != 0) {
                      this.setState({
                        CityId: objcity.data[0].value,
                        CityData: objcity.data,
                        BillingCity: objcity.data[0].value,
                        ShippingCityData: objcity.data,
                        ShippingCity: objcity.data[0].value,
                        ShippingCityId: objcity.data[0].value,
                      });
                    }
                  }
                })
              );
            }
          })
        );
      })
    );
    this.getCartdetails();
  }

  getCartdetails = () => {
    Notiflix.Loading.circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          Notiflix.Loading.remove();
          // console.log(res1.data);
          this.setState({
            ProductCart: res1.data,
          });
        }
      });
  };

  onChangeCountry(country) {
    this.setState({
      CountryId: country.target.value,
      BillingCountry:
        this.state.CountryData[parseInt(country.target.value) + 1].value,
    });
    PostApiCall.postRequest(
      {
        countryid: country.target.value,
        Country:
          this.state.CountryData[parseInt(country.target.value) + 1].label,
      },
      "GetState"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            StateId: obj.data[0].value,
            BillingState: obj.data[0].value,
          });

          PostApiCall.postRequest(
            {
              stateid: obj.data[0].value,
            },
            "GetCity"
          ).then((resultscity) =>
            resultscity.json().then((objcity) => {
              if (resultscity.status == 200 || resultscity.status == 201) {
                this.setState({
                  CityData: objcity.data,
                  StateData: obj.data,
                });
                if (objcity.data.length > 0) {
                  this.setState({
                    CityId: objcity.data[0].value,
                    BillingCity: objcity.data[0].value,
                  });
                }
              }
            })
          );
        }
      })
    );
  }

  onChangeState(state) {
    this.setState({
      StateId: state.target.value,
    });

    for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {
      if (this.state.StateData[i].value == state.target.value) {
        this.setState({
          BillingState: this.state.StateData[i]?.value,
        });
      }
    }

    PostApiCall.postRequest(
      {
        stateid: state.target.value,
      },
      "GetCity"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length > 0) {
            this.setState({
              CityData: obj.data,
              CityId: obj.data[0].value,
              BillingCity: obj.data[0].value,
            });
          }
        }
      })
    );
  }

  onChangeCity(city) {
    this.setState({
      CityId: city.target.value,
    });

    for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {
      if (this.state.CityData[i].value == city.target.value) {
        this.setState({
          BillingCity: this.state.CityData[i].value,
        });
      }
    }
  }

  onChangeShippingCountry(country) {
    this.setState({
      ShippingCountryId: country.target.value,
      ShippingCountry:
        this.state.ShippingCountryData[parseInt(country.target.value) + 1]
          .value,
    });
    PostApiCall.postRequest(
      {
        countryid: country.target.value,
        Country:
          this.state.ShippingCountryData[parseInt(country.target.value) + 1]
            .label,
      },
      "GetState"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            ShippingStateId: obj.data[0].value,
            ShippingState: obj.data[0].value,
          });

          PostApiCall.postRequest(
            {
              stateid: obj.data[0].value,
            },
            "GetCity"
          ).then((resultscity) =>
            resultscity.json().then((objcity) => {
              if (resultscity.status == 200 || resultscity.status == 201) {
                this.setState({
                  ShippingCityData: objcity.data,
                  ShippingStateData: obj.data,
                });
                if (objcity.data.length > 0) {
                  this.setState({
                    ShippingCityId: objcity.data[0].value,
                    ShippingCity: objcity.data[0].value,
                  });
                }
              }
            })
          );
        }
      })
    );
  }

  onChangeShippingState(state) {
    this.setState({
      ShippingStateId: state.target.value,
    });

    for (var i = 0; i < Object.keys(this.state.ShippingStateData).length; i++) {
      if (this.state.ShippingStateData[i].value == state.target.value) {
        this.setState({
          ShippingState: this.state.StateData[i].value,
        });
      }
    }

    PostApiCall.postRequest(
      {
        stateid: state.target.value,
      },
      "GetCity"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length > 0) {
            this.setState({
              ShippingCityData: obj.data,
              ShippingCityId: obj.data[0].value,
              ShippingCity: obj.data[0].value,
            });
          }
        }
      })
    );
  }

  onChangeShippingCity(city) {
    this.setState({
      ShippingCityId: city.target.value,
    });

    for (var i = 0; i < Object.keys(this.state.ShippingCityData).length; i++) {
      if (this.state.ShippingCityData[i].value == city.target.value) {
        this.setState({
          ShippingCity: this.state.ShippingCityData[i].value,
        });
      }
    }
  }

  AddBillingAddress = () => {
    Notiflix.Loading.circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    PostApiCall.postRequest(
      {
        CUSTOMERID: logindetails[0].fld_customerid,
        addressid: null,
        title: "Billing",
        name: this.state.BillingContactPerson,
        mobileNo: this.state.Mobile,
        area: this.state.BillingAddress,
        gstin: this.state.GSTIN,
        email: this.state.BillingEmail,
        country: this.state.BillingCountry,
        state: this.state.BillingState,
        city: this.state.BillingCity,
        pincode: this.state.BillingPincode,
        action: "INSERT",
        companyname: this.state.BillingCompanyName,
      },
      "Add_CustomerAddress"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.remove();

          this.setState(
            {
              SelectedbillingAddress: obj.data[0].addressId,
            },
            () => {
              if (this.state.ProductCart[0].fld_shipping_charge != 0) {
                this.updateCartPrice();
              } else {
                this.movecarttoOrder();
              }
            }
          );

          // Notiflix.Notify.success('Address Successfully Delete.')
          // window.location.reload()
        }
      })
    );
  };

  updateCartPrice = () => {
    var orderSample = localStorage.getItem("Ordertype");
    var ordertype = JSON.parse(orderSample);

    if (ordertype !== "Ordersample" && ordertype !== "Stickers") {
      // if()

      PostApiCall.postRequest(
        {
          quantity: this.state.ProductCart[0].fld_quantity,
          outercoatingid: this.state.ProductCart[0].fld_outercoatingid,
          innercoatingid: this.state.ProductCart[0].fld_innercoatingid,
          varientid: this.state.ProductCart[0].fld_variantid,
          boardid: this.state.ProductCart[0].fld_boardid,
        },
        "GetPrice"
      ).then((resultdes) =>
        resultdes.json().then((obj) => {
          // window.location.href = this.props.match.url + '#viewPrice'
          this.setState(
            {
              ChangePriceData: obj.data,
            },
            () => {
              PostApiCall.postRequest(
                {
                  destination_pin:
                    this.state.ProductCart[0].fld_delivery_type !=
                      "StandardShipping"
                      ? 0
                      : this.state.SelectedPincode == null
                        ? this.state.ShippingPincode
                        : this.state.SelectedPincode,
                  source_pin: 600001,
                  weight: obj.data[0].weight,
                  invoice_amount: obj.data[0].price,
                  Gst: this.state.ProductCart[0].GstRate,
                },
                "GetDeliveryCharge"
              ).then((resultdes2) =>
                resultdes2.json().then((obj7) => {
                  // console.log(obj7.data)
                  this.setState(
                    {
                      CustomQuantity_Deliverycharge:
                        obj7.data[0].delivery_charges,
                      CustomQuantity_NetAmount: obj7.data[0].total_amount,
                      CustomQuantity_GstAmount: obj7.data[0].gst_amount,
                      CustomQuantity_TotalAmount:
                        obj7.data[0].total_amount_with_gst,
                    },
                    () => {
                      this.updateCartData();
                    }
                  );
                })
              );
            }
          );
        })
      );
    } else {
      this.movecarttoOrder();
    }
  };

  updateCartData = () => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    PostApiCall.postRequest(
      {
        orderdate: moment().format("lll"),
        itemdetails: `
                                          [
                                          {
                                              "Item":${this.state.ProductCart[0].fld_variantid},
                                              "Quantity":${this.state.ProductCart[0].fld_quantity}
                                          }
                                          ]
                                          `,
        orderdescription: this.state.ProductCart[0].fld_order_description,
        shippingcharge: this.state.CustomQuantity_Deliverycharge,
        status: "INCART",
        customerid:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
        createdon: moment().format("lll"),
        updatedon: moment().format("lll"),
        orderid: 0,
        gst: this.state.CustomQuantity_GstAmount,
        totalamount: this.state.CustomQuantity_TotalAmount,
        updatedby:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
        outercoatingid: this.state.ProductCart[0].fld_outercoatingid,
        innercoatingid: this.state.ProductCart[0].fld_innercoatingid,
        boardid: this.state.ProductCart[0].fld_boardid,
        deliverytype: this.state.ProductCart[0].fld_delivery_type,
        designType: this.state.ProductCart[0].fld_design_type,
        CustomItemDescription: this.state.ProductCart[0].fld_custom_item_description,
        designid: this.state.ProductCart[0].fld_design_id
      },
      "AddToCartWebsite"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();

          localStorage.setItem("OrderData", JSON.stringify(obj.data));
          this.setState({}, () => {
            this.movecarttoOrder();
          });
          // window.location.href = '/checkout'
          // }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  movecarttoOrder = () => {
    Notiflix.Loading.circle();
    const det = localStorage.getItem("CustomerData");
    const details = JSON.parse(det);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        customerid: details[0].fld_customerid,
        cartid: Orderdetails[0]?.order_id,
        orderstatus: "IN-PROCESS",
        paymentstaus: "Payment Initiated",
        paymentmode: "OnlinePayment",
        paymenttrxno: "",
        paymentgateway: "",
        billingaddress: this.state.SelectedbillingAddress,
        shippingaddress: this.state.SelectedshippingAddress,
      },
      "PlaceOrder"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          localStorage.removeItem("OrderData");
          localStorage.setItem("OrderDetails", JSON.stringify(obj.data));
          Notiflix.Loading.remove();
          window.location.href = "/checkout";
        }
      })
    );
  };

  AddShippingAddress = () => {
    Notiflix.Loading.circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    PostApiCall.postRequest(
      {
        CUSTOMERID: logindetails[0].fld_customerid,
        addressid: null,
        title: "Shipping",
        name: this.state.ShippingContactPerson,
        mobileNo: this.state.ShippingMobile,
        area: this.state.ShippingAddress,
        gstin: this.state.ShippingGSTIN,
        email: this.state.ShippingEmail,
        country: this.state.ShippingCountry,
        state: this.state.ShippingState,
        city: this.state.ShippingCity,
        pincode: this.state.ShippingPincode,
        action: "INSERT",
        companyname: this.state.ShippingCompanyName,
      },
      "Add_CustomerAddress"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState(
            {
              SelectedshippingAddress: obj.data[0].addressId,
            },
            () => {
              this.AddBillingAddress();
            }
          );
        }
      })
    );
  };

  checkbillingaddress = () => {
    if (this.state.BillingCompanyName != null) {
      if (this.state.BillingContactPerson != "") {
        if (this.state.BillingAddress != "") {
          if (this.state.BillingPincode != null) {
            if (this.state.Mobile != null) {
              if (this.state.Mobile.length == 10) {
                this.AddShippingAddress();
              } else {
                Notiflix.Notify.failure(
                  "Please enter valid contact number for billing"
                );
              }
            } else {
              Notiflix.Notify.failure(
                "Please enter contact number for billing"
              );
            }
          } else {
            Notiflix.Notify.failure("Please enter billing pincode");
          }
        } else {
          Notiflix.Notify.failure("Please enter billing address");
        }
      } else {
        Notiflix.Notify.failure("Please enter contact person name for billing");
      }
    } else {
      Notiflix.Notify.failure("Please enter company name for billing");
    }
  };

  render() {
    return (
      <React.Fragment>
        <Header />

        <section style={{ paddingTop: "70px" }}>
          <div className="container-fluid">
            <div className="section-title rightcarttoptext">
              <h4 className="leftcardText">
                Select your Shipping & Billing Address
              </h4>
              <a
                onClick={() => {
                  this.setState({
                    AddNewAddress: "Yes",
                  });
                }}
                style={{
                  cursor: "pointer",
                  display:
                    this.state.AddressData.length == 0 ? "none" : "block",
                }}
              >
                <span
                  style={{
                    // float: "right",
                    fontSize: "18px",
                    background: "#59c5d1",
                    padding: "5px",
                    color: "white",
                    borderRadius: "5px",
                  }}
                >
                  <i className="fa fa-plus-circle"></i>&nbsp;Add New Address
                </span>
              </a>
              <p
                className="mt-2"
                style={{
                  display: this.state.AddNewAddress == "No" ? "block" : "none",
                }}
              >
                Is your address you like your order to be sent to listed below?
                If so, Select the Shipping and Billing Address. If you dont find
                the address you can add a new address.
              </p>
            </div>

            <div className="row" style={{ marginBotoom: "20px" }}>
              <div
                className="col-md-12"
                style={{
                  display:
                    this.state.AddressData.length == 0 ? "none" : "block",
                }}
              >
                <div
                  className="row"
                  style={{
                    display: this.state.AddNewAddress == "No" ? "" : "none",
                  }}
                >
                  <div class="col-md-12">
                    <h5 class="card-title ml-1">Shipping Address</h5>
                  </div>
                  {this.state.AddressData.filter((data) => {
                    if (data.fld_address_type === "Shipping") {
                      // console.log(data);
                      return data;
                    }
                  }).map((data, i) => {
                    return (
                      <div class="col-md-3">
                        <div class="card mb-2">
                          <div class="card-body">
                            <input
                              type="checkbox"
                              checked={
                                this.state.SelectedshippingAddress ==
                                  data.fld_address_id
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                this.setState({
                                  SelectedshippingAddress: data.fld_address_id,
                                  SelectedPincode: data.fld_pincode,
                                });
                              }}
                            ></input>
                            <p class="card-text">
                              {data.fld_company_name},<br />
                              {data.fld_contact_name},<br />
                              {data.fld_address_2},<br />
                              {data.CityName}, {data.StateName} -{" "}
                              {data.fld_pincode}. {data.fld_CountryName}.<br />
                              Phone: {data.fld_contact_number}
                              <br />
                              Email:{data.fld_email_address}
                              <br />
                              GSTIN:{data.fld_gstin}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* {this.state.AddressData.map((data, index) => (

                    <div class="col-md-3">
                      <div class="card mb-2">
                        <div class="card-body">
                          <input
                            type="checkbox"
                            checked={
                              this.state.SelectedshippingAddress ==
                              data.fld_address_id
                                ? true
                                : false
                            }
                            onChange={() => {
                              this.setState({
                                SelectedshippingAddress: data.fld_address_id,
                                SelectedPincode: data.fld_pincode,
                              });
                            }}
                          ></input>
                          <p class="card-text">
                            {data.fld_company_name},<br />
                            {data.fld_contact_name},<br />
                            {data.fld_address_2},<br />
                            {data.CityName}, {data.StateName} - {data.fld_pincode}.{" "}
                            {data.fld_CountryName}.<br />
                            Phone: {data.fld_contact_number}
                            <br />
                            Email:{data.fld_email_address}
                            <br />
                            GSTIN:{data.fld_gstin}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))} */}
                </div>
              </div>
              <div
                class="col-md-12"
                style={{
                  display:
                    this.state.AddressData.length == 0 ? "none" : "block",
                }}
              >
                <input
                  type="checkbox"
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    display: this.state.AddNewAddress == "No" ? "" : "none",
                  }}
                  checked={this.state.SelectedPermanentSame}
                  onChange={() => {
                    if (this.state.SelectedshippingAddress != null) {
                      if (this.state.SelectedPermanentSame == true) {
                        this.setState({
                          SelectedPermanentSame: false,
                          SelectedbillingAddress: null,
                        });
                      } else {
                        this.setState({
                          SelectedPermanentSame: true,
                          SelectedbillingAddress:
                            this.state.SelectedshippingAddress,
                        });
                      }
                    } else {
                      Notiflix.Notify.failure(
                        "Please select your shipping address"
                      );
                    }
                  }}
                ></input>

                <span
                  style={{
                    display: this.state.AddNewAddress == "No" ? "" : "none",
                  }}
                >
                  <b> Billing address is same as Shipping address</b>
                </span>
              </div>

              <div
                className="col-md-12"
                style={{
                  display:
                    this.state.AddressData.length == 0 ? "none" : "block",
                }}
              >
                <div
                  className="row"
                  style={{
                    display: this.state.AddNewAddress == "No" ? "" : "none",
                  }}
                >
                  <div
                    class="col-md-12"
                    style={{
                      display:
                        this.state.SelectedPermanentSame == true ? "none" : "",
                    }}
                  >
                    <h5 class="card-title ml-1">Billling Address</h5>
                  </div>
                  {this.state.AddressData.filter((data) => {
                    if (data.fld_address_type === "Billing") {
                      // console.log(data);
                      return data;
                    }
                  }).map((data, i) => {
                    return (
                      <div
                        class="col-md-3"
                        style={{
                          display:
                            this.state.SelectedPermanentSame == true
                              ? "none"
                              : "",
                        }}
                      >
                        <div class="card mb-2">
                          <div class="card-body">
                            <input
                              type="checkbox"
                              checked={
                                this.state.SelectedbillingAddress ==
                                  data.fld_address_id
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                if (this.state.SelectedshippingAddress != null) {
                                  this.setState({
                                    SelectedbillingAddress: data.fld_address_id,
                                    SelectedPincode: data.fld_pincode,
                                  });
                                } else {
                                  Notiflix.Notify.failure(
                                    "Please select your shipping address"
                                  );
                                }
                              }}
                            ></input>
                            <p class="card-text">
                              {data.fld_company_name},<br />
                              {data.fld_contact_name},<br />
                              {data.fld_address_2},<br />
                              {data.CityName}, {data.StateName} -{" "}
                              {data.fld_pincode}. {data.fld_CountryName}.<br />
                              Phone: {data.fld_contact_number}
                              <br />
                              Email:{data.fld_email_address}
                              <br />
                              GSTIN:{data.fld_gstin}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* {this.state.AddressData.map((data, index) => (
                      <div
                        class="col-md-3"
                        style={{
                          display:
                            this.state.SelectedPermanentSame == true
                              ? "none"
                              : "",
                        }}
                      >
                        <div class="card mb-2">
                          <div class="card-body">
                            <input
                              type="checkbox"
                              checked={
                                this.state.SelectedbillingAddress ==
                                data.fld_address_id
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                if (this.state.SelectedshippingAddress != null) {
                                  this.setState({
                                    SelectedbillingAddress: data.fld_address_id,
                                    SelectedPincode: data.fld_pincode,
                                  });
                                } else {
                                  Notiflix.Notify.failure(
                                    "Please select your shipping address"
                                  );
                                }
                              }}
                            ></input>
                            <p class="card-text">
                              {data.fld_company_name},<br />
                              {data.fld_contact_name},<br />
                              {data.fld_address_2},<br />
                              {data.CityName}, {data.StateName} -{" "}
                              {data.fld_pincode}. {data.fld_CountryName}.<br />
                              Phone: {data.fld_contact_number}
                              <br />
                              Email:{data.fld_email_address}
                              <br />
                              GSTIN:{data.fld_gstin}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))} */}
                </div>
              </div>
              <div
                class="col-md-12"
                style={{
                  marginTop: "15px",
                  display: this.state.AddNewAddress == "No" ? "block" : "none",
                  textAlign: "center",
                }}
              >
                <button
                  className="btn designbtn2 active"
                  style={{ marginBottom: "10px" }}
                  onClick={() => {
                    if (this.state.SelectedshippingAddress != null) {
                      if (this.state.SelectedbillingAddress != null) {
                        this.updateCartPrice();
                      } else {
                        Notiflix.Notify.failure(
                          "Please select billing address"
                        );
                      }
                    } else {
                      Notiflix.Notify.failure("Please select shipping address");
                    }
                  }}
                >
                  Continue
                </button>
              </div>

              <div
                className="col-md-12"
                style={{
                  display: this.state.AddNewAddress == "Yes" ? "block" : "none",
                }}
              >
                <div class="card pb-3">
                  <div class="heading">
                    <h4>Address Card</h4>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="addresstext">Shipping Address </h4>
                      <div className="row addresscardrow">
                        <div class="col-md-6 shippingAddressinput">
                          <input
                            type="text"
                            class="form-control "
                            id="companyName"
                            placeholder="Company Name"
                            value={this.state.ShippingCompanyName}
                            onChange={(text) => {
                              this.setState({
                                ShippingCompanyName: text.target.value,
                              });
                            }}
                          ></input>
                        </div>
                        <div class="col-md-6 shippingAddressinput">
                          <input
                            type="text"
                            class="form-control "
                            id="contactPerson"
                            placeholder=" Contact Person"
                            value={this.state.ShippingContactPerson}
                            onChange={(text) => {
                              this.setState({
                                ShippingContactPerson: text.target.value,
                              });
                            }}
                          ></input>
                        </div>

                        <div class="col-md-12 shippingAddressinput">
                          <textarea
                            class="form-control"
                            rows="2"
                            placeholder=" Address"
                            required
                            value={this.state.ShippingAddress}
                            onChange={(text) => {
                              this.setState({
                                ShippingAddress: text.target.value,
                              });
                            }}
                          ></textarea>
                        </div>

                        <div class="col-md-4 shippingAddressinput">
                          <select
                            class="form-control"
                            value={this.state.ShippingCountryId}
                            onChange={this.onChangeShippingCountry.bind(this)}
                          >{this.state.ShippingCountryData == [] ? "" :
                            this.state.ShippingCountryData.filter((data) => data.value == 101).map((schedule) => (
                              <option
                                key={schedule.label}
                                value={schedule.value}
                              >
                                {schedule.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-4 shippingAddressinput">
                          <select
                            class="form-control"
                            name="UserType"
                            value={this.state.ShippingStateId}
                            onChange={this.onChangeShippingState.bind(this)}
                          >
                            {this.state.ShippingStateData.map((schedule) => (
                              <option
                                key={schedule.label}
                                value={schedule.value}
                              >
                                {schedule.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-4 shippingAddressinput">
                          <select
                            class="form-control"
                            value={this.state.ShippingCityId}
                            onChange={this.onChangeShippingCity.bind(this)}
                          >
                            {this.state.ShippingCityData.map((schedule) => (
                              <option
                                key={schedule.label}
                                value={schedule.value}
                              >
                                {schedule.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div class="col-md-6 shippingAddressinput">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Pincode"
                            value={this.state.ShippingPincode}
                            onChange={(pin) => {
                              if (
                                this.state.NumRegex.test(pin.target.value) &&
                                pin.target.value.length <= 6
                              ) {
                                this.setState({
                                  ShippingPincode: pin.target.value,
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="col-md-6 shippingAddressinput">
                          <input
                            type="text"
                            placeholder="Mobile Number"
                            className="form-control"
                            value={this.state.ShippingMobile}
                            onChange={(mobile) => {
                              if (
                                this.state.NumRegex.test(mobile.target.value) &&
                                mobile.target.value.length <= 10
                              ) {
                                this.setState({
                                  ShippingMobile: mobile.target.value,
                                });
                              }
                            }}
                          />
                        </div>

                        <div class="col-md-6 shippingAddressinput">
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            placeholder=" Email Id"
                            value={this.state.ShippingEmail}
                            onChange={(text) => {
                              this.setState({
                                ShippingEmail: text.target.value,
                              });
                            }}
                          ></input>
                        </div>
                        <div class="col-md-6 shippingAddressinput">
                          <input
                            type="text"
                            class="form-control"
                            id="gstin"
                            placeholder=" GSTIN"
                            value={this.state.ShippingGSTIN}
                            onChange={(gst) => {
                              this.setState({
                                ShippingGSTIN: gst.target.value,
                              });
                            }}
                          ></input>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <h4 className="addresstext">Billing Address </h4>
                      <div className="row addresscardrow">
                        <div class="col-md-6 shippingAddressinput">
                          <input
                            type="text"
                            class="form-control "
                            id="companyName"
                            placeholder="  Company Name"
                            value={this.state.BillingCompanyName}
                            onChange={(company) => {
                              this.setState({
                                BillingCompanyName: company.target.value,
                              });
                            }}
                            required
                          ></input>
                        </div>
                        <div class="col-md-6 shippingAddressinput">
                          <input
                            type="text"
                            class="form-control "
                            id="contactPerson"
                            placeholder=" Contact Person"
                            value={this.state.BillingContactPerson}
                            onChange={(contactperson) => {
                              this.setState({
                                BillingContactPerson:
                                  contactperson.target.value,
                              });
                            }}
                            required
                          ></input>
                        </div>

                        <div class="col-md-12 shippingAddressinput">
                          <textarea
                            class="form-control"
                            id="address"
                            rows="2"
                            placeholder=" Address"
                            required
                            value={this.state.BillingAddress}
                            onChange={(address) => {
                              this.setState({
                                BillingAddress: address.target.value,
                              });
                            }}
                          ></textarea>
                        </div>

                        <div class="col-md-4 shippingAddressinput">
                          <select
                            class="form-control"
                            value={this.state.CountryId}
                            onChange={this.onChangeCountry.bind(this)}
                          >{this.state.ShippingCountryData == [] ? "" :
                            this.state.CountryData.filter((data) => data.value == 101).map((schedule) => (
                              <option
                                key={schedule.label}
                                value={schedule.value}
                              >
                                {schedule.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div class="col-md-4 shippingAddressinput">
                          <select
                            class="form-control"
                            value={this.state.StateId}
                            onChange={this.onChangeState.bind(this)}
                          >
                            {this.state.StateData.map((schedule) => (
                              <option
                                key={schedule.label}
                                value={schedule.value}
                              >
                                {schedule.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div class="col-md-4 shippingAddressinput">
                          <select
                            class="form-control"
                            value={this.state.CityId}
                            onChange={this.onChangeCity.bind(this)}
                          >
                            {this.state.CityData.map((schedule) => (
                              <option
                                key={schedule.label}
                                value={schedule.value}
                              >
                                {schedule.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div class="col-md-6 shippingAddressinput">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Pincode"
                            value={this.state.BillingPincode}
                            onChange={(pin) => {
                              if (
                                this.state.NumRegex.test(pin.target.value) &&
                                pin.target.value.length <= 6
                              ) {
                                this.setState({
                                  BillingPincode: pin.target.value,
                                });
                              }
                            }}
                          />
                        </div>
                        <div class="col-md-6 shippingAddressinput">
                          <input
                            type="text"
                            placeholder="Mobile Number"
                            className="form-control"
                            value={this.state.Mobile}
                            onChange={(mobile) => {
                              if (
                                this.state.NumRegex.test(mobile.target.value) &&
                                mobile.target.value.length <= 10
                              ) {
                                this.setState({
                                  Mobile: mobile.target.value,
                                });
                              }
                            }}
                          />
                        </div>
                        <div class="col-md-6 shippingAddressinput">
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            placeholder=" Email Id"
                            value={this.state.BillingEmail}
                            onChange={(text) => {
                              this.setState({
                                BillingEmail: text.target.value,
                              });
                            }}
                          ></input>
                        </div>
                        <div class="col-md-6 shippingAddressinput">
                          <input
                            type="text"
                            class="form-control"
                            id="gstin"
                            placeholder=" GSTIN"
                            value={this.state.GSTIN}
                            onChange={(gst) => {
                              this.setState({
                                GSTIN: gst.target.value,
                              });
                            }}
                          ></input>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 ml-2">
                      &nbsp;
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        checked={this.state.PermanentSame}
                        onChange={() => {
                          this.setState(
                            { PermanentSame: !this.state.PermanentSame },
                            () => {
                              if (this.state.PermanentSame) {
                                this.setState({
                                  CountryId: this.state.ShippingCountryId,
                                  StateId: this.state.ShippingStateId,
                                  CityId: this.state.ShippingCityId,
                                  BillingAddress: this.state.ShippingAddress,
                                  BillingCountry: this.state.ShippingCountry,
                                  BillingState: this.state.ShippingState,
                                  BillingCity: this.state.ShippingCity,
                                  BillingPincode: this.state.ShippingPincode,
                                  BillingCompanyName:
                                    this.state.ShippingCompanyName,
                                  Mobile: this.state.ShippingMobile,
                                  BillingEmail: this.state.ShippingEmail,
                                  GSTIN: this.state.ShippingGSTIN,
                                  BillingContactPerson:
                                    this.state.ShippingContactPerson,
                                });
                              } else {
                                this.setState({
                                  BillingCountry: "",
                                  BillingState: "",
                                  BillingCity: "",
                                  CityId: 0,
                                  CountryId: 0,
                                  StateId: 0,
                                  BillingPincode: "",
                                  BillingAddress: "",
                                  BillingCompanyName: null,
                                  GSTIN: "",
                                  BillingEmail: "",
                                  BillingContactPerson: "",
                                  Mobile: "",
                                });
                              }
                            }
                          );
                        }}
                      />
                      <label for="vehicle1">
                        {" "}
                        &nbsp;Billing address is same as Shipping address
                      </label>
                      <div style={{ textAlign: "center" }}>
                        <button
                          className="btn designbtn2 active"
                          style={{ marginBottom: "10px" }}
                          onClick={() => {
                            this.setState({
                              AddNewAddress: "No",
                            });
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn designbtn2 active"
                          style={{ marginBottom: "10px" }}
                          onClick={() => {
                            if (this.state.ShippingCompanyName != "") {
                              if (this.state.ShippingContactPerson != "") {
                                if (this.state.ShippingAddress != "") {
                                  if (this.state.ShippingPincode != "") {
                                    if (this.state.ShippingMobile != "") {
                                      if (
                                        this.state.ShippingMobile.length == 10
                                      ) {
                                        if (
                                          this.state.PermanentSame !== false
                                        ) {
                                          this.AddShippingAddress();
                                        } else {
                                          this.checkbillingaddress();
                                        }
                                      } else {
                                        Notiflix.Notify.failure(
                                          "Please enter valid contact number"
                                        );
                                      }
                                    } else {
                                      Notiflix.Notify.failure(
                                        "Please enter contact number"
                                      );
                                    }
                                  } else {
                                    Notiflix.Notify.failure(
                                      "Please enter shipping pincode"
                                    );
                                  }
                                } else {
                                  Notiflix.Notify.failure(
                                    "Please enter shipping address"
                                  );
                                }
                              } else {
                                Notiflix.Notify.failure(
                                  "Please enter contact person name for shipping"
                                );
                              }
                            } else {
                              Notiflix.Notify.failure(
                                "Please enter company name for shipping"
                              );
                            }
                          }}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer style={{ marginTop: "20px" }} />
      </React.Fragment>
    );
  }
}

export default OrderSummary;
