import Notiflix from "notiflix";
import React from "react";
import { isMobile } from "react-device-detect";
import { BackgroundImage } from "react-image-and-background-image-fade";
import "react-multi-carousel/lib/styles.css";
import "react-responsive-modal/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PostApiCall from "../../components/helper/Api";
import ReactGA from "react-ga4";
import { Redirect, withRouter } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

ReactGA.initialize([
  {
    trackingId: "G-2VDZ0VM2P4",
  },
]);

class CustomSticker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shapeData: [],
      activeproduct: null,
      redirectToAnotherPage: false,
      stickerdata: "",
      loading: true,
    };

    // Binding your method to the component instance
    this.redirectToAnotherPage = this.redirectToAnotherPage.bind(this);
  }
  componentDidMount() {
    PostApiCall.postRequest(
      {
        recordCount: "*",
        WhereClause: ` where fld_status = 'Yes' `,
      },
      "GetStickerShapeMaster"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          shapeData: obj.data,
          loading: false,
        });
      })
    );
  }

  redirectToAnotherPage(data) {
    // Update the state to trigger redirection
    this.setState({ redirectToAnotherPage: true, stickerdata: data });
  }

  render() {
    const { shapeData, loading } = this.state;

    if (this.state.redirectToAnotherPage) {
      return (
        <Redirect
          to={{
            pathname: "/ordercustomstickers",
            state: { data: this.state.stickerdata },
          }}
        />
      );
    }

    return (
      <div class="container-fluid px-md-4 px-3 mt-md-4 mt-3">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title text-center mb-3">
              <h2 className="mb-0 pb-0 mono-font-weight">
                Choose your custom Stickers
              </h2>
            </div>
          </div>
        </div>

        {/* ****** Desktop View ******** */}
        <div className="grid-container desktop-online-custom-box">
          {loading
            ? Array(12)
                .fill()
                .map((_, i) => (
                  <div className="grid-item" key={i}>
                    <Skeleton
                      height={1}
                      style={{ marginBottom: "10px", marginLeft: "10px" }}
                    />
                    <Skeleton
                      height={185}
                      width={190}
                      style={{ marginTop: "10px" }}
                    />
                  </div>
                ))
            : shapeData.map((data, i) => {
                return (
                  <div className="grid-item" key={i}>
                    <div className="item-content px-0">
                      <div
                        className={
                          this.state.activeproduct == data.fld_id
                            ? "product-cat-box-active-border product-cat-box mx-1 mb-2"
                            : "product-cat-box mx-1 mb-2"
                        }
                        onClick={() => this.redirectToAnotherPage(data)}
                      >
                        <div className="product-cat-box-txt">
                          <p className="product-cat-box-name-txt custom-lg-online-heading product-content-align">
                            {data.fld_shape_name}
                          </p>
                        </div>
                        <div className="product-cat-box-img">
                          <BackgroundImage
                            src={data.fld_image}
                            value={data.fld_id}
                            title={data.fld_shape_name}
                            width="243px"
                            height="212px"
                            isResponsive
                            className="img-fluid"
                            alt="Retail Food Packaging"
                            style={{
                              backgroundSize: "cover",
                              backgroundPosition: "center top",
                            }}
                            lazyLoad
                            onClick={(e) => {
                              this.setState({
                                selectedShape: e.target.getAttribute("value"),
                                selectedSticker: e.target.getAttribute("value"),
                                selectedShapeName:
                                  e.target.getAttribute("title"),
                                selectedStickerImage:
                                  e.target.getAttribute("src"),
                              });
                            }}
                          />
                        </div>
                        <a className="text-white btn shopnowbtn2 shop-phn-btn custom-phn-online-btn">
                          Customize your order
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>

        {/* ****** Tab and mobile view ******* */}
        <div className="row phn-online-custom-box">
          {loading
            ? Array(12)
                .fill()
                .map((_, i) => (
                  <div className="grid-item sticker-phn-skelton" key={i}>
                    <Skeleton className="sticker-online-size" />
                  </div>
                ))
            : shapeData.map((data, i) => {
                return (
                  <div className="col-6 col-sm-4 px-0">
                    <div class="px-0">
                      <div
                        className={
                          this.state.activeproduct == data.fld_id
                            ? "product-cat-box-active-border product-cat-box mx-1 mb-2"
                            : "product-cat-box mx-1 mb-2"
                        }
                        onClick={() => this.redirectToAnotherPage(data)}
                      >
                        <div class="product-cat-box-txt">
                          <p className="product-cat-box-name-txt custom-phn-online-heading product-content-align">
                            {data.fld_shape_name}
                          </p>
                        </div>

                        <div class="product-cat-box-img">
                          <BackgroundImage
                            src={data.fld_image}
                            value={data.fld_id}
                            title={data.fld_shape_name}
                            width="243px"
                            height="212px"
                            isResponsive
                            className="img-fluid"
                            alt="Retail Food Packaging"
                            style={{
                              backgroundSize: "cover",
                              backgroundPosition: "center top",
                            }}
                            lazyLoad
                          />
                        </div>
                        <a className="phn-sticker-btn text-white btn shopnowbtn2 custom-phn-online-btn">
                          Customize your order
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    );
  }
}

export default withRouter(CustomSticker);
