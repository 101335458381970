import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Notiflix from "notiflix";
import PostApiCall from "../components/helper/Api";
import GetApiCall from "../components/helper/GETAPI";

import "./Address.css"
import Footer from '../Footer'
import ShopHeader from '../Header'
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { Link } from "react-router-dom";

export class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CompanyName: null,
            Name: null,
            imagePreviewUrl: 'https://static.thenounproject.com/png/629579-200.png',
            Email: null,
            MobileNo: null,
            Gender: '',
            DateOfBirth: null,
            GenderData: [
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
                { label: 'Others', value: 'Others' },

            ],

            Image: "",
            NumRegex: /^[0-9]*$/,
            AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
            MaritalData: [
                { label: 'Married', value: 'Married' },
                { label: 'Widowed', value: 'Widowed' },
                { label: 'Separated', value: 'Separated' },
                { label: 'Divorced', value: 'Divorced' },
                { label: 'Single', value: 'Single' }

            ],
            Anniversary: null,
            MarriedStatus: null,
            EmailRegex:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            GSTIN: null,
            ProfileId: null,

        }
    }

    componentDidMount() {


        var profileData = JSON.parse(localStorage.getItem("ProfileData"))

        this.setState({
            CompanyName: profileData.fld_companyname,
            Name: profileData.fld_name,
            Email: profileData.fld_email_address,
            MobileNo: profileData.fld_phone_number,
            Gender: profileData.fld_gender,

            MarriedStatus: profileData.fld_maritalstatus,
            Anniversary: profileData.fld_doa,
            GSTIN: profileData.fld_gstin,
            DateOfBirth: profileData.fld_dob != null ? profileData.fld_dob : null,
        })



        GetApiCall.getRequest("UserInfo").then((result) =>
            result.json().then((obj) => {
                // console.log(obj.data)
                this.setState({
                    userProfileInfo: obj.data
                })
            }))
    }

    photoUpload = (e) => {
        e.preventDefault();
        const imageFile = e.target.files[0];
        this.setState({
            imagePreviewUrl: URL.createObjectURL(imageFile),
            originalImage: imageFile,
            outputFileName: imageFile.name,
            uploadImage: true
        });
    };

    onSaveData() {
        if (this.state.Name != null) {
            if (this.state.Email != null) {
                if (this.state.EmailRegex.test(this.state.Email)) {
                    if (this.state.MobileNo != null) {
                        if (this.state.MobileNo.length == 10) {
                            if (this.state.Gender != '') {
                                if (this.state.DateOfBirth != null) {
                                    if (this.state.Image != null) {
                                        this.onPost()
                                    } else {
                                        Notiflix.Notify.Failure("Please select an Image.");
                                    }
                                } else {
                                    Notiflix.Notify.Failure("Please enter Date of Birth.");
                                }
                            } else {
                                Notiflix.Notify.Failure("Please enter Gender.");
                            }
                        } else {
                            Notiflix.Notify.Failure("Please enter valid Mobile Number.");
                        }
                    } else {
                        Notiflix.Notify.Failure("Please enter Mobile Number.");
                    }
                } else {
                    Notiflix.Notify.Failure("Please enter valid E-mail.");
                }
            } else {
                Notiflix.Notify.Failure("Please enter email.");
            }
        } else {
            Notiflix.Notify.Failure("Please enter your Name.");
        }
    }

    onPost() {

        var login = localStorage.getItem("CustomerData");
        var details = JSON.parse(login);
        // console.log(details)

        PostApiCall.postRequest({
            customerid: details[0].fld_customerid,
            name: this.state.Name,
            email: this.state.Email,
            mobile: this.state.MobileNo,
            gender: this.state.Gender,
            dateofbirth: this.state.DateOfBirth,
            presentaddress: null,
            presentcountry: null,
            presentstate: null,
            presentcity: null,
            presentpincode: null,
            status: null,
            updatedon: moment().format('lll'),
            updatedby: details[0].fld_userid,
            landmark: null,
            maritalstatus: this.state.MarriedStatus,
            gstin: this.state.GSTIN,


        },
            "AddCustomer"
        ).then((result) =>
            result.json().then((obj) => {
                if (result.status == 200 || result.status == 201) {
                    Notiflix.Notify.success("Profile Information Successfully Added");
                } else {
                    Notiflix.Notify.failure(obj.data);
                }
            }))
    }

    onUpdateData() {
        if (this.state.CompanyName != null && this.state.CompanyName != "") {
            if (this.state.Name != null && this.state.Name != "") {
                if (this.state.Email != null && this.state.Email != "") {
                    if (this.state.EmailRegex.test(this.state.Email)) {
                        if (this.state.MobileNo != null && this.state.MobileNo != "") {
                            if (this.state.MobileNo.length == 10) {
                                if (this.state.Gender != '') {
                                    if (this.state.DateOfBirth != null) {
                                        if (this.state.Image != null) {
                                            this.onPostUpdate()
                                        } else {
                                            Notiflix.Notify.failure("Please select an Image.");
                                        }
                                    } else {
                                        Notiflix.Notify.failure("Please enter Date of Birth.");
                                    }
                                } else {
                                    Notiflix.Notify.failure("Please enter Gender.");
                                }
                            } else {
                                Notiflix.Notify.failure("Please enter valid Mobile Number.");
                            }
                        } else {
                            Notiflix.Notify.failure("Please enter Mobile Number.");
                        }
                    } else {
                        Notiflix.Notify.failure("Please enter valid E-mail.");
                    }
                } else {
                    Notiflix.Notify.failure("Please enter email.");
                }
            } else {
                Notiflix.Notify.failure("Please enter your Name.");
            }
        } else {
            Notiflix.Notify.failure("Please enter your Company Name.");
        }

    }

    onPostUpdate() {

        var login = localStorage.getItem("CustomerData");
        var details = JSON.parse(login);

        PostApiCall.postRequest({
            customerid: details[0].fld_customerid,
            companyname: this.state.CompanyName,
            name: this.state.Name,
            email: this.state.Email,
            mobile: this.state.MobileNo,
            gender: this.state.Gender,
            dateofbirth: new Date(this.state.DateOfBirth).toLocaleDateString(),
            presentaddress: null,
            presentcountry: null,
            presentstate: null,
            presentcity: null,
            presentpincode: null,
            status: null,
            updatedon: moment().format('lll'),
            updatedby: localStorage.getItem('CustomerData') == null ? null : details[0].fld_customerid,
            landmark: null,
            maritalstatus: this.state.MarriedStatus,
            gstin: this.state.GSTIN,
            doa: this.state.Anniversary
        },
            "UpdateCustomer"
        ).then((result) =>
            result.json().then((obj) => {
                if (result.status == 200 || result.status == 201) {
                    Notiflix.Notify.success("Profile Information Successfully Updated");
                    window.location.href = "/customerprofile";
                } else {
                    Notiflix.Notify.failure(obj.data);
                }
            }))
    }

    render() {

        // new Date(this.state.DateOfBirth).toLocaleDateString()
        // console.log(this.state.MarriedStatus, this.state.GSTIN)
        return (
            <React.Fragment>
                <ShopHeader />
                <section style={{ paddingTop: '40px' }}>
                    <Container fluid style={{ backgroundColor: "#F5F5F5", paddingLeft: "none" }}>
                        <div className="row">

                            <div className="col-12">
                                <div className="row">
                                    {/* <button type="button" class="goback-button mt-3 ml-3" onClick={() => { this.props.history.goBack() }}><i class="fa fa-arrow-left" aria-hidden="true"></i>{" "}Go Back</button> */}
                                    <Card className="mt-5 ml-3 mr-3">
                                        <CardBody>
                                            <CardTitle className="sidebar-heading">
                                                <h5>My Profile</h5>
                                                <div class="title-divider"></div>
                                            </CardTitle>

                                            <form >
                                                <div class="col-12">
                                                    <div class="row">

                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xm-12">
                                                            <div className="row">


                                                                <div className="col-lg-4 col-md-12 col-sm-12 col-xm-12 mb-3">
                                                                    <label for="CategoryName">
                                                                        Comapny Name
                                                                        <span className="mandatory ml-1">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="CategoryName"
                                                                        className="form-control"
                                                                        name="CompanyName"
                                                                        value={this.state.CompanyName}
                                                                        onChange={(text) => {
                                                                            this.setState({
                                                                                CompanyName: text.target.value,
                                                                            });
                                                                        }}
                                                                    />

                                                                </div>

                                                                <div className="col-lg-4 col-md-12 col-sm-12 col-xm-12 mb-3">
                                                                    <label for="CategoryName">
                                                                        Name
                                                                        <span className="mandatory ml-1 ">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="CategoryName"
                                                                        className="form-control"
                                                                        name="Name"
                                                                        value={this.state.Name}
                                                                        onChange={(text) => {
                                                                            this.setState({
                                                                                Name: text.target.value,
                                                                            });
                                                                        }}
                                                                    />

                                                                </div>

                                                                <div className="col-lg-4 col-md-6 col-sm-12 col-xm-12 mb-3">
                                                                    <label for="CategoryName">
                                                                        Email
                                                                        <span className="mandatory ml-1 ">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="email"
                                                                        id="CategoryName"
                                                                        className="form-control"
                                                                        name="Email"
                                                                        value={this.state.Email}
                                                                        onChange={(text) => {
                                                                            this.setState({
                                                                                Email: text.target.value,
                                                                            });
                                                                        }}
                                                                    />

                                                                </div>
                                                                <div className="col-lg-4 col-md-6 col-sm-12 col-xm-12 mb-3">
                                                                    <label for="CategoryName">
                                                                        Mobile no
                                                                        <span className="mandatory ml-1 ">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="CategoryName"
                                                                        className="form-control"
                                                                        value={this.state.MobileNo}
                                                                        onChange={(mobile) => {
                                                                            if (
                                                                                this.state.NumRegex.test(
                                                                                    mobile.target.value
                                                                                ) &&
                                                                                mobile.target.value
                                                                                    .length <= 10
                                                                            ) {
                                                                                this.setState({
                                                                                    MobileNo:
                                                                                        mobile.target.value,
                                                                                });
                                                                            }
                                                                        }}
                                                                    />

                                                                </div>

                                                                <div className="col-lg-4 col-md-12 col-sm-12 col-xm-12 mb-3">
                                                                    <label
                                                                        class="my-1 mr-2"
                                                                        for="inlineFormCustomSelectPref"
                                                                    >
                                                                        Gender
                                                                        <span className="mandatory ml-1 ">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <select
                                                                        class="custom-select my-1 mr-sm-2"
                                                                        id="inlineFormCustomSelectPref"
                                                                        value={this.state.Gender}
                                                                        onChange={(text) => {
                                                                            this.setState({
                                                                                Gender: text.target.value,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <option>Select Gender</option>
                                                                        {this.state.GenderData.map(
                                                                            (gender) => (
                                                                                <option
                                                                                    key={gender.value}
                                                                                    value={gender.value}
                                                                                    selected={gender.value == this.state.Gender}

                                                                                >
                                                                                    {gender.label}
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </select>

                                                                </div>

                                                                <div className="col-lg-4 col-md-12 col-sm-12 col-xm-12 mb-3">
                                                                    <label for="CategoryName">
                                                                        Date of Birth
                                                                        <span className="mandatory ml-1 ">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <DatePicker
                                                                        onChange={(date) => {
                                                                            this.setState({
                                                                                DateOfBirth: date
                                                                            })
                                                                        }}
                                                                        value={this.state.DateOfBirth}
                                                                        className="form-control"
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {/* =====col-md-8 end */}

                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xm-12 mb-3">
                                                            <label
                                                                class="my-1 mr-2"
                                                                for="inlineFormCustomSelectPref"
                                                            >
                                                                Marital Status
                                                            </label>
                                                            <select
                                                                class="custom-select my-1 mr-sm-2"
                                                                id="inlineFormCustomSelectPref"
                                                                value={this.state.MarriedStatus}
                                                                onChange={(text) => {
                                                                    this.setState({
                                                                        MarriedStatus: text.target.value,
                                                                    });
                                                                }}
                                                            >
                                                                <option>Select Marital Status</option>
                                                                {this.state.MaritalData.map(
                                                                    (marital) => (
                                                                        <option
                                                                            key={marital.value}
                                                                            value={marital.value}
                                                                        >
                                                                            {marital.label}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>

                                                        </div>


                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xm-12 mb-3">
                                                            <label for="CategoryName">
                                                                GSTIN

                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="CategoryName"
                                                                className="form-control"
                                                                name="gstin"
                                                                value={this.state.GSTIN}
                                                                onChange={(text) => {
                                                                    this.setState({
                                                                        GSTIN: text.target.value,
                                                                    });
                                                                }}
                                                            />

                                                        </div>
                                                        <div className="col-12">
                                                            <button
                                                                type="button"
                                                                style={{ float: "right", marginTop: "5px",marginLeft:"5px" }}
                                                                className="btn align-items-center globalTrendzEcomm-btn "
                                                                onClick={

                                                                    this.onUpdateData.bind(this)}
                                                            >
                                                                Update  {"  "}{" "}
                                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                                            </button>
                                                            <Link to="/customerprofile">
                                                            <button
                                                                type="button"
                                                                style={{ float: "right", marginTop: "5px" }}
                                                                className="btn align-items-center globalTrendzEcomm-btn "
                                                                // onClick={() => {
                                                                //     this.props.history.goBack()
                                                                // }}
                                                                 >
                                                           
                                                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                                                {"  "}{" "}   Go Back 
                                                            </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </CardBody>
                                    </Card>

                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

export default EditProfile;