import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Products from "./Products";
import MOQ from "./MOQ.jpeg";
import { isMobile } from "react-device-detect";
import piologo from "../src/assets/image/pio-logo.png";

class DrawerTypeBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineStoreBtn: false,
    };
  }
  componentDidMount() {
    const store = this.context;
    {
      isMobile
        ? this.setState({
          onlineStoreBtn: true,
        })
        : this.setState({
          onlineStoreBtn: false,
        });
    }
  }
  render() {
    return (
      <div>
        <div
          class="col-md-12 m-show online-ordering"
          id="onilne-store"
          style={{
            display: this.state.onlineStoreBtn == true ? "block" : "none",
          }}
        >
          <a
            class="mx-lg-3 btn shopnowbtn"
            // href="/ordercustomboxes"
            href="/onlinestore"
            style={{
              background: "#59c5d1",
              borderRadius: "3px",
              color: "white",
              width: "85%",
              boxShadow: "0px 0px 12px rgba(0,0,0,.5)",
              fontSize: "22px",
            }}
          >
            Online Store
          </a>
        </div>
        <Header></Header>
        <section class="page-banner">
          <div class="container-fluid px-md-4 px-3">
            <div class="row mt-4">
              <div class="col-md-12">
                <div className="w-100 text-center inner-pio-phn-logo justify-content-center d-md-none d-flex">
                  <img src={piologo} alt="pio-packaging" />
                </div>
                <div class="top-breadcrumb">
                  <ul>
                    <li class="breadcrumb-item">
                      <a href="http://piopackaging.com">Home</a>
                    </li>
                    <li class="breadcrumb-item">
                      <a href="http://piopackaging.com#products">Products</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Drawer Type Box
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section">
          <div class="quantity-icon">
            <img src={MOQ} alt="" />
          </div>
          <div class="container-fluid px-md-4 px-3">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title text-center">
                  {/* <h2>Drawer Type Box</h2> */}
                  <h1 className="mono-font-weight">Drawer Type Boxs</h1>
                </div>
              </div>
              <div class="col-md-12">
                <div class="inner-page-cont">
                  <p>
                    Drawer Type boxes have a novelty of old world charm
                    reminiscent of a Match Box. This comprises of two parts –
                    the open box and the sleeve into which the open box is slid
                    in. This design is very much in vogue even till this day for
                    expensive packaging such as Classic Swiss Watches to retail
                    consumer goods packaging. There are various options
                    available for this type of a box design and we can customize
                    it to suit our requirement and cost.
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="sub-section-title sub-section-title-black">
                  <h2>Sample Products</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                  <div class="product-item-img">
                    <a
                      href="assets/img/Drawer-type-box/Drawer-type-box-01.jpg"
                      data-fancybox="images"
                    >
                      <img
                        class="img-fluid"
                        src="assets/img/Drawer-type-box/thumb01.jpg"
                        alt="Drawer Type Box"
                      />

                      <div class="product-view-btn">
                        <i class="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                  <div class="product-item-img">
                    <a
                      href="assets/img/Drawer-type-box/Drawer-type-box-02.jpg"
                      data-fancybox="images"
                    >
                      <img
                        class="img-fluid"
                        src="assets/img/Drawer-type-box/thumb02.jpg"
                        alt="Drawer Type Box"
                      />

                      <div class="product-view-btn">
                        <i class="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                  <div class="product-item-img">
                    <a
                      href="assets/img/Drawer-type-box/Drawer-type-box-03.jpg"
                      data-fancybox="images"
                    >
                      <img
                        class="img-fluid"
                        src="assets/img/Drawer-type-box/thumb03.jpg"
                        alt="Drawer Type Box"
                      />

                      <div class="product-view-btn">
                        <i class="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                  <div class="product-item-img">
                    <a
                      href="assets/img/Drawer-type-box/Drawer-type-box-04.jpg"
                      data-fancybox="images"
                    >
                      <img
                        class="img-fluid"
                        src="assets/img/Drawer-type-box/thumb04.jpg"
                        alt="Drawer Type Box"
                      />

                      <div class="product-view-btn">
                        <i class="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Products></Products>
        <Footer></Footer>
      </div>
    );
  }
}

export default DrawerTypeBox;
