/* eslint-disable no-loop-func */
import Parser from "html-react-parser";
import Notiflix from "notiflix";
import React from "react";
import Payment_Success from "../../assets/image/Payment_successfull.png";
import Payment_COD from "../../assets/image/cod.png";
import PostApiCall from "../../components/helper/Api";
import Footer from "../../Footer";
import Menu from "../../Header";
// import Order_Success from './assets/images/OrderSuccessful.png';
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { ToWords } from "to-words";
import logo from "../../assets/image/PioLogo.png";

class PaymentSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ShippingAddress: [],
      BillingAddress: [],
      CartData: [],
      SummaryData: [],
      OfferData: [],
      open: true,

      NumRegex: /^0|[0-9]\d*$/,
      MobileRegex: /^[0-9]*$/,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      SpecialRegex: /[-!$%^&*()_+|~=`"{}\[\]:\/;<>?,.@#]/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,

      TxnId: "",
      MerKey: "a6JOJL",
      MerSalt: "cri53U9i",
      CustomerLoginData: [],
      CustomerHash: "",
      MainOrder: [],
      Payment_Mode: "",
      designtype: "",
      AmountInWords: "",
      DeliveryDetails: "",
      Order_Description: "",
      BillingCompanyName: "",
    };
  }

  componentDidMount() {
    Notiflix.Notify.init({
      width: "300px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });

    Notiflix.Loading.dots("");

    PostApiCall.postRequest(
      {
        whereClause: `where  fld_payment_trx_num='${this.props.match.params.txnid}/${this.props.match.params.mipid}'`,
      },
      "GetWebsiteOrderDetails"
    ).then((results1) =>
      // const objs = JSON.parse(result._bodyText)
      results1.json().then((obj) => {
        if (results1.status == 200 || results1.status == 201) {
          Notiflix.Loading.remove();
          this.setState({
            MainOrder: obj.data,
            ProdData: obj.data,
            DeliveryDetails:
              obj.data[0].Shipping_Charg != 0
                ? "Delivery Partner"
                : "Self Pickup",
            designtype: obj.data[0].fld_design_type,
            Payment_Mode: obj.data[0].Payment_Mode,
            Order_Description: obj.data[0].fld_order_description,
            BillingCompanyName: obj.data[0].fld_shipping_company_name,
          });
          this.state.MainOrder.map((data, i) => {
            if (i === 0) {
              const toWords = new ToWords();
              let words = toWords.convert(data.Total_Inclusive_Tax);
              this.setState({
                AmountInWords: words,
              });
            }
          });
        }
      })
    );
  }

  render() {
    //console.log(this.props.match.params.txnid + '/' + this.props.match.params.mipid)
    return (
      <div>
        <Menu></Menu>
        <div class="container">
          <Modal class="modal-content" open={this.state.open} center>
            <div class="modal-content modelcontent2">
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <h5 className="rightCardtext">
                      {" "}
                      <b style={{ visibility: "hidden" }}>Thank You </b>
                      <button
                        className="btn designbtn active"
                        style={{ marginTop: "-7px" }}
                        onClick={() => {
                          this.setState({
                            open: false,
                          });
                        }}
                      >
                        Close
                      </button>
                    </h5>
                    <hr />

                    {this.state.Payment_Mode == "COD" ? (
                      <img src={Payment_COD} alt="Cash on Delivery"></img>
                    ) : (
                      <img src={Payment_Success} alt="payment success"></img>
                    )}

                    {/* <img src={Payment_Success} alt="payment success"></img> */}
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <div class="row mt-5">
            <div class="col-md-12">
              <div class="privacy-box">
                <div class="container checkout-section invoice">
                  <div class="container-box">
                    <div class="table-responsive">
                      <div id="invoiceForm">
                        <table
                          style={{
                            width: "1000px",
                            textAlign: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                            bottom: "0px",
                            borderRightColor: "#000",
                            marginTop: "30px",
                            borderCollapse: "collapse",
                          }}
                          border="1"
                          cellspacing="0"
                          cellpadding="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                colspan="2"
                                style={{
                                  width: "20%",
                                  verticalAlign: "middle",
                                }}
                              >
                                <img
                                  src={logo}
                                  style={{
                                    width: "100%",
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    verticalAlign: "middle",
                                  }}
                                  alt=""
                                />{" "}
                              </td>
                              <td colspan="8" style={{ width: "80%" }}>
                                {" "}
                                <h2
                                  style={{
                                    textAlign: "center",
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  PIO PRINTERS PRIVATE LIMITED
                                </h2>
                                <p style={{ textAlign: "center" }}>
                                  83, East Mada Church Road, Royapuram, Chennai
                                  - 600 013 India.
                                  <br />
                                  Phone: +91 98403 27012 &nbsp;GST No.:
                                  33AAACP6506F1ZT
                                </p>
                                <tr
                                  rowspan="8"
                                  class="success"
                                  style={{
                                    display: "table",
                                    width: "100%",
                                    backgroundColor: "#f7f7f7",
                                  }}
                                >
                                  <td
                                    colspan="8"
                                    style={{
                                      textAlign: "center",
                                      paddingRight: "1%",
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                    }}
                                  >
                                    Customer Order Form
                                  </td>
                                </tr>
                              </td>
                            </tr>

                            {this.state.MainOrder.map((data, i) => {
                              if (i === 0) {
                                return (
                                  <tr>
                                    <td
                                      colspan="1"
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "1%",
                                        paddingTop: "1%",
                                        paddingBottom: "1%",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Order Date
                                      </span>
                                    </td>

                                    <td
                                      colspan="3"
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "1%",
                                        paddingTop: "1%",
                                        paddingBottom: "1%",
                                      }}
                                    >
                                      {data.fld_order_date}
                                    </td>

                                    <td
                                      colspan="3"
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "1%",
                                        paddingTop: "1%",
                                        paddingBottom: "1%",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Customer Order No.
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "1%",
                                        paddingTop: "1%",
                                        paddingBottom: "1%",
                                      }}
                                    >
                                      {data.fld_orderNumber}
                                    </td>
                                  </tr>
                                );
                              }
                            })}

                            <tr class="success">
                              <td
                                colspan="4"
                                style={{
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                Billing Address
                              </td>
                              <td
                                colspan="4"
                                style={{
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                Shipping Address
                              </td>
                            </tr>
                            {this.state.MainOrder.map((data, i) => {
                              if (i === 0) {
                                return (
                                  <tr>
                                    <td
                                      colspan="4"
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "1%",
                                        paddingTop: "1%",
                                        paddingBottom: "1%",
                                      }}
                                    >
                                      <p>
                                        {data.fld_contactperson}
                                        <br />
                                        {data.fld_billing_company_name}
                                        <br />
                                        {data.fld_address}, {data.fld_landmark}
                                        <br />
                                        {data.fld_city}, {data.fld_state}-
                                        {data.fld_Pincode}, {data.fld_country},
                                        <br />
                                        <b> Phone:</b> (+91) {data.fld_mobile}
                                        <br />
                                        <b> Email:</b> {data.fld_billingemail}
                                        <br />
                                        <b
                                          style={{
                                            display:
                                              data.fld_GSTIN == ""
                                                ? "none"
                                                : "inline-block",
                                          }}
                                        >
                                          {" "}
                                          GSTIN:
                                        </b>{" "}
                                        {data.fld_GSTIN}
                                      </p>
                                    </td>

                                    <td
                                      colspan="4"
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "1%",
                                        paddingTop: "1%",
                                        paddingBottom: "1%",
                                      }}
                                    >
                                      <p>
                                        {data.fld_shippingcontactperson}
                                        <br />
                                        {data.fld_shipping_company_name}
                                        <br />
                                        {data.fld_shippingaddress}{" "}
                                        {data.fld_shippinglandmark}
                                        <br /> {data.fld_shippingcity},{" "}
                                        {data.fld_shippingstate}-
                                        {data.fld_shippingPincode} ,{" "}
                                        {data.fld_shippingcountry}.
                                        <br />
                                        <b> Phone: </b> (+91){" "}
                                        {data.fld_shippingmobile}
                                        <br />
                                        <b> Email: </b> {data.fld_email}
                                        <br />
                                        <b
                                          style={{
                                            display:
                                              data.fld_shippingGSTIN == ""
                                                ? "none"
                                                : "inline-block",
                                          }}
                                        >
                                          {" "}
                                          GSTIN:
                                        </b>{" "}
                                        {data.fld_shippingGSTIN}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </table>
                        <table
                          style={{
                            width: "1000px",
                            textAlign: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                            borderRightColor: "#000",
                            borderTop: "hidden",
                          }}
                          border="1"
                          cellspacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr
                              class="success fw-bold"
                              style={{ fontFamily: "Lato, sans-serif" }}
                            >
                              <td
                                style={{
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                  textAlign: "center",
                                  width: "32%",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Product
                                </span>
                              </td>
                              <td
                                style={{
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                  textAlign: "center",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Quantity
                                </span>
                              </td>
                              <td
                                style={{
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                  textAlign: "center",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Unit Price
                                </span>
                              </td>
                              <td
                                style={{
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                  textAlign: "center",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Net Value
                                </span>
                              </td>

                              <td
                                style={{
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                  textAlign: "center",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Shipping cost
                                </span>
                              </td>
                              <td
                                style={{
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                  textAlign: "center",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Total Amount (INR)
                                </span>
                              </td>
                              {/* <!--   <td style="padding-top: 1%; padding-bottom: 1%;text-align:center"><span style={{fontWeight:'bold'}}></span></td> -->
                               */}
                            </tr>

                            {this.state.MainOrder.map((data, i) => (
                              <>
                                {/* <tr>
                                  <td>
                                    {Parser(data.fld_order_description)}
                                  </td>
                                </tr> */}
                                <tr>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      paddingLeft: "1%",
                                      paddingTop: "1%",
                                      paddingBottom: "1%",
                                      width: "32%",
                                    }}
                                  >
                                    {Parser(data.fld_custom_item_description)}
                                    {/* {Parser(data.fld_order_description)} */}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.fld_quantity}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {" "}
                                    &#8377;&nbsp;
                                    {data.fld_unit_price}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {" "}
                                    &#8377;&nbsp;
                                    {parseFloat(
                                      data.fld_netamount_without_GST_ship_amount
                                    ).toFixed(2)}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {" "}
                                    &#8377;&nbsp;
                                    {parseFloat(data.Shipping_Charg).toFixed(2)}
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                      paddingRight: "1%",
                                    }}
                                  >
                                    {" "}
                                    &#8377;&nbsp;
                                    {parseFloat(
                                      data.fld_netamount_without_GSTamount
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>

                        <table
                          style={{
                            width: "1000px",
                            padding: "2%",
                            textAlign: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                            borderRightColor: "#000",
                            borderTop: "hidden",
                          }}
                          border="1"
                          cellspacing="0"
                          cellpadding="0"
                        >
                          <tbody>
                            <tr>
                              <td style={{ width: "30%" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Dispatched by Courier partner
                                </span>
                              </td>
                              {this.state.MainOrder.map((data, i) => {
                                if (i === 0) {
                                  return (
                                    <>
                                      <td style={{ width: "61%" }}>
                                        <tr>
                                          <td
                                            style={{
                                              textAlign: "right",
                                              padding: "1%",
                                              width: "29%",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                fontFamily: "Lato, sans-serif",
                                              }}
                                            >
                                              Sub total
                                            </span>
                                          </td>

                                          <td
                                            style={{
                                              textAlign: "right",
                                              paddingRight: "2%",
                                            }}
                                          >
                                            {" "}
                                            &#8377;&nbsp;
                                            {data.fld_netamount_without_GSTamount.toFixed(
                                              2
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              textAlign: "right",
                                              padding: "1%",
                                              // width: "40%",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                fontFamily: "Lato, sans-serif",
                                              }}
                                            >
                                              GST(12%)
                                            </span>
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "right",
                                              paddingRight: "2%",
                                            }}
                                          >
                                            {" "}
                                            &#8377;&nbsp;
                                            {data.fld_GSTamount.toFixed(2)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              textAlign: "right",
                                              padding: "1%",
                                              // width: "40%",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                fontFamily: "Lato, sans-serif",
                                              }}
                                            >
                                              Total (Inclusive of all Taxes)
                                            </span>
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "right",
                                              paddingRight: "2%",
                                            }}
                                          >
                                            {" "}
                                            &#8377;&nbsp;
                                            {data.Total_Inclusive_Tax.toFixed(
                                              2
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              textAlign: "right",
                                              padding: "1%",
                                              // width: "40%",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                fontFamily: "Lato, sans-serif",
                                              }}
                                            >
                                              Amount Paid
                                            </span>
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "right",
                                              paddingRight: "2%",
                                            }}
                                          >
                                            {" "}
                                            &#8377;&nbsp;
                                            {data.fld_amount_paid.toFixed(2)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              textAlign: "right",
                                              padding: "1%",
                                              // width: "40%",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                fontFamily: "Lato, sans-serif",
                                              }}
                                            >
                                              Payment Mode
                                            </span>
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "right",
                                              paddingRight: "1%",
                                              fontFamily: "Lato, sans-serif",
                                              // width: "20%",
                                            }}
                                          >
                                            {data.Payment_Mode}
                                          </td>
                                        </tr>
                                      </td>
                                    </>
                                    // <td>
                                    //   <tr style={{ width: "100%", display: "table" }}>
                                    //     <td style={{ textAlign: "right", padding: "1%" }}>
                                    //       <span style={{ fontWeight: "bold" }}>
                                    //         Sub total
                                    //       </span>
                                    //     </td>
                                    //     <td
                                    //       style={{
                                    //         textAlign: "right",
                                    //         paddingRight: "1%",
                                    //         width: "35%",
                                    //       }}
                                    //     >
                                    //       {" "}
                                    //       &#8377;
                                    //       {parseFloat(data.Sub_Total_Amnt).toFixed(2)}
                                    //     </td>
                                    //   </tr>

                                    //   <tr style={{ width: "100%", display: "table" }}>
                                    //     <td style={{ textAlign: "right", padding: "1%" }}>
                                    //       <span style={{ fontWeight: "bold" }}>
                                    //         GST(18%)
                                    //       </span>
                                    //     </td>
                                    //     <td
                                    //       style={{
                                    //         textAlign: "right",
                                    //         paddingRight: "1%",
                                    //         width: "35%",
                                    //       }}
                                    //     >
                                    //       {" "}
                                    //       &#8377;
                                    //       {parseFloat(data.fld_GSTamount).toFixed(2)}
                                    //     </td>
                                    //   </tr>

                                    //   <tr style={{ width: "100%", display: "table" }}>
                                    //     <td style={{ textAlign: "right", padding: "1%" }}>
                                    //       <span style={{ fontWeight: "bold" }}>
                                    //         Total (Inclusive of all Taxes)
                                    //       </span>
                                    //     </td>
                                    //     <td
                                    //       style={{
                                    //         textAlign: "right",
                                    //         paddingRight: "1%",
                                    //         width: "35%",
                                    //       }}
                                    //     >
                                    //       {" "}
                                    //       &#8377;
                                    //       {parseFloat(data.Total_Inclusive_Tax).toFixed(2)}
                                    //     </td>
                                    //   </tr>
                                    // </td>
                                  );
                                }
                              })}
                            </tr>

                            {/* <tr>
                  <td
                    colspan="5"
                    style={{
                      textAlign: "left",
                      paddingLeft: "1%",
                      paddingTop: "1%",
                      paddingBottom: "1%",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Amount in Words:</span>{" "}
                    {this.state.AmountInWords} Only
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="5"
                    style={{
                      textAlign: "left",
                      paddingLeft: "1%",
                      paddingTop: "1%",
                      paddingBottom: "1%",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Payment Mode:</span>{" "}
                    {this.state.Payment_Mode == "payu"
                      ? "PAYU"
                      : this.state.Payment_Mode}
                  </td>
                </tr> */}
                          </tbody>
                        </table>
                        <table
                          style={{
                            width: "1000px",
                            textAlign: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                            borderRightColor: "#000",
                            borderTop: "hidden",
                            fontFamily: "Lato, sans-serif",
                            borderCollapse: "collapse",
                            marginBottom: "30px",
                          }}
                          border="1"
                          cellspacing="1"
                          cellpadding="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                colspan="6"
                                style={{
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                  textAlign: "center",
                                  background: "#f7f7f7",
                                }}
                              >
                                Have a Question?
                                <br /> Call us on 96770 27016 / 98403 27012 or
                                Email us at{" "}
                                <a href="mailto:info@piopackaging.com">
                                  info@piopackaging.com
                                </a>
                              </td>
                            </tr>

                            <tr
                              class="success"
                              style={{ backgroundColor: "#f7f7f7" }}
                            >
                              <td
                                colspan="6"
                                style={{
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                  textAlign: "center",
                                  background: "#f7f7f7",
                                }}
                              >
                                Shop online at : &nbsp;{" "}
                                <a href="https://piopackaging.com/onlinestore">
                                  https://piopackaging.com/onlinestore
                                </a>
                                {/* <a href="https://www.piopackaging.com/ordercustomboxes">
                                  https://www.piopackaging.com/ordercustomboxes
                                </a> */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="center-blockPayment">
                  <button
                    className="btn btn-danger"
                    style={{
                      background: "#59c5d1",
                      marginBottom: "60px",
                      border: "1px solid #59c5d1",
                    }}
                    onClick={() => {
                      window.location.href = "/ordercustomboxes";
                    }}
                  >
                    Continue Shopping
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default PaymentSuccess;
