import React from 'react'

class Parallax extends React.Component {
    render () {
      return(
        <section class="section specialize">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="sub-section-title specialize-title text-center">
                        <h2>Packing Materials We Specialize In</h2>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-2 col-sm-4 col-6">
                    <div class="specialize-box">
                        <img src="assets/img/icon/01.jpg" alt="Specialize"/>
                        <p>Bio Degradable</p>
                    </div>
                </div>
               
                <div class="col-lg-2 col-sm-4 col-6">
                    <div class="specialize-box">
                        <img src="assets/img/icon/02.jpg" alt="Specialize"/>
                        <p>Recyclable</p>
                    </div>
                </div>
              
                <div class="col-lg-2 col-sm-4 col-6">
                    <div class="specialize-box">
                        <img src="assets/img/icon/003.jpg" alt="Specialize"/>
                        <p>Non-Toxic</p>
                    </div>
                </div>
               
                <div class="col-lg-2 col-sm-4 col-6">
                    <div class="specialize-box">
                        <img src="assets/img/icon/04.jpg" alt="Specialize"/>
                        <p>Food Grade</p>
                    </div>
                </div>
               
                <div class="col-lg-2 col-sm-4 col-6">
                    <div class="specialize-box">
                        <img src="assets/img/icon/05.jpg" alt="Specialize"/>
                        <p>100% Eco-friendly</p>
                    </div>
                </div>
                      <div class="col-lg-2 col-sm-4 col-6">
                          <div class="specialize-box">
                              <img src="assets/img/icon/006.jpg" alt="Specialize" />
                              <p>Carbon Neutral</p>
                          </div>
                      </div>
              
            </div>
        </div>
    </section>
      )
    }
  }

  export default Parallax;