import React from 'react'

class Products extends React.Component {
    render() {
        return (
            <section className="mb-3">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 text-md-right text-center px-3 mb-4 mb-md-0">
                  <a href="http://www.pioprinters.com/" target="blank">
                    <button className="text-white website-btn shopnowbtn border-0 py-2">
                      <p className="text-white website-name">
                        Pio Printers Pvt. Ltd.
                      </p>
                      <p className="text-white website-subheading">
                        MULTI FACILITY PRINTING COMPANY.
                      </p>
                    </button>
                  </a>
                </div>
                <div className="col-12 col-md-6 text-md-start text-center px-3">
                  <a href="https://piopackaging.com/" target="blank">
                    <button className="mx-md-5 text-white website-btn shopnowbtn border-0 py-2">
                      <p className="text-white website-name">Pio Packaging</p>
                      <p className="text-white website-subheading">
                        CUSTOM PRINTED BOXES & LABELS
                      </p>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        )
    }
}

    export default Products;