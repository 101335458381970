import CryptoJS from "crypto-js";
import Parser from "html-react-parser";
import React from "react";
import logo from "./assets/image/PioLogo.png";
import PostApiCall from "./components/helper/Api";

class DownloadOrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OrderData: [],
      DeliveryDetails: "",
      DesignType: "",
    };
  }
  componentDidMount() {
    this.getOrderData();
  }

  getOrderData = () => {
    //this.props.match.params.orderId.toString()
    var val = this.props.match.params.orderId.toString();
    var dec = val.replaceAll("bech0fy", "/").replaceAll("bechofy", "?");
    const bytes = CryptoJS.AES.decrypt(dec, "becho#654*_987_@!--");
    const decrypt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // console.log("dec " + decrypt)
    PostApiCall.postRequest(
      {
        whereClause: `where fld_order_id=${parseInt(decrypt)}`,
      },
      "GetWebsiteOrderDetails"
    ).then((results1) =>
      // const objs = JSON.parse(result._bodyText)
      results1.json().then((obj) => {
        if (results1.status == 200 || results1.status == 201) {
          this.setState(
            {
              OrderData: obj.data,
              DeliveryDetails: obj.data[0].fld_delivery_type,
              DesignType: obj.data[0].fld_design_type,
            },
            () => {
              this.DownloadInvoicePdf();
            }
          );
        }
      })
    );
  };

  DownloadInvoicePdf() {
    var html2pdf = require("html2pdf.js");
    var element = document.getElementById("orderform");
    var currentDateandTime = new Date().toLocaleString();
    var opt = {
      margin: 1,
      filename: `order_form${currentDateandTime}.pdf`,
      image: { type: "png", quality: 0.98 },
      html2canvas: {
        scale: 1.5,
        useCORS: true,
        allowTaint: true,
        proxy: null,
      },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait",
        compress: true,
      },
      //   pagebreak: {avoid: ‘div’}
    };
    // //console.log(opt)
    // New Promise-based usage:
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        // setTimeout(function () { var ww = window.open("https://www.piopackaging.com/", '_self'); ww.close(); }, 1000)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    // console.log(this.state.DesignType)
    return (
      <>
        {/* <iframe src="https://www.piopackaging.com/downloadorderform/orderId"
                    width={1500}
                    height={1000}
                    sandbox='allow-scripts allow-modal'
                    loading='lazy'
                    title='Custom title'> */}
        <div className="text-center downldorderform">
          <button
            className="shopnowbtn"
            onClick={() => {
              this.DownloadInvoicePdf();
            }}
          >
            Download Order Form
          </button>
        </div>
        <div className="order-form-box">
          <div id="orderform">
            <table
              style={{
                width: "700px",
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
                bottom: "0px",
                borderRightColor: "#000",
                marginTop: "30px",
                borderCollapse: "collapse",
              }}
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tbody>
                <tr>
                  <td
                    colspan="2"
                    style={{ width: "20%", verticalAlign: "middle" }}
                  >
                    <img
                      src={logo}
                      alt="logoorderform"
                      style={{
                        width: "65%",
                        marginRight: "auto",
                        marginLeft: "auto",
                        verticalAlign: "middle",
                      }}
                    />{" "}
                  </td>
                  <td colspan="8" style={{ width: "80%" }}>
                    {" "}
                    <h2
                      style={{
                        textAlign: "center",
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    >
                      PIO PRINTERS PRIVATE LIMITED
                    </h2>
                    <p style={{ textAlign: "center" }}>
                      83, East Mada Church Road, Royapuram,Chennai - 600 013
                      India
                      <br />
                      Phone: +91 98403 27012
                      <br />
                      GST: 33AAACP6506F1ZT
                    </p>
                    <tr
                      rowspan="8"
                      class="success"
                      style={{
                        display: "table",
                        width: "100%",
                        backgroundColor: "#f7f7f7",
                        borderBottom: "none",
                        borderRight: "none",
                        borderLeft: "none",
                      }}
                    >
                      <td
                        colspan="8"
                        style={{
                          textAlign: "right",
                          paddingRight: "1%",
                          fontWeight: "bold",
                          fontSize: "20px",
                          borderBottom: "none",
                          borderRight: "none",
                          borderLeft: "none",
                        }}
                      >
                        Customer Order Form
                      </td>
                    </tr>
                  </td>
                </tr>

                {this.state.OrderData.map((data, i) => {
                  if (i === 0) {
                    return (
                      <tr>
                        <td
                          colspan="1"
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            Order Date
                          </span>
                        </td>

                        <td
                          colspan="3"
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          {data.fld_order_date}
                        </td>

                        <td
                          colspan="3"
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            Customer Order No.
                          </span>
                        </td>
                        <td
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          {data.fld_orderNumber}
                        </td>
                      </tr>
                    );
                  }
                })}

                <tr class="success">
                  <td
                    colspan="6"
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      fontWeight: "bold",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    Billing Address
                  </td>
                  <td
                    colspan="6"
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      fontWeight: "bold",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    Shipping Address
                  </td>
                </tr>
                {this.state.OrderData.map((data, i) => {
                  if (i === 0) {
                    return (
                      <tr>
                        <td
                          colspan="6"
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          <p>
                            {data.fld_contactperson}
                            <br />
                            {data.fld_billing_company_name}
                            <br />
                            {data.fld_address}, {data.fld_landmark}
                            <br />
                            {data.fld_city}, {data.fld_state}-{data.fld_Pincode}
                            , {data.fld_country},
                            <br />
                            <b> Phone:</b> (+91) {data.fld_mobile}
                            <br />
                            <b> Email:</b> {data.fld_billingemail}
                            <br />
                            <b
                              style={{
                                display:
                                  data.fld_GSTIN == ""
                                    ? "none"
                                    : "inline-block",
                              }}
                            >
                              {" "}
                              GSTIN:
                            </b>{" "}
                            {data.fld_GSTIN}
                          </p>
                        </td>

                        <td
                          colspan="6"
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          <p>
                            {data.fld_shippingcontactperson}
                            <br />
                            {data.fld_shipping_company_name}
                            <br />
                            {data.fld_shippingaddress}{" "}
                            {data.fld_shippinglandmark}
                            <br /> {data.fld_shippingcity},{" "}
                            {data.fld_shippingstate}-{data.fld_shippingPincode}{" "}
                            , {data.fld_shippingcountry}.
                            <br />
                            <b> Phone: </b> (+91) {data.fld_shippingmobile}
                            <br />
                            <b> Email: </b> {data.fld_email}
                            <br />
                            <b
                              style={{
                                display:
                                  data.fld_shippingGSTIN == ""
                                    ? "none"
                                    : "inline-block",
                              }}
                            >
                              {" "}
                              GSTIN:
                            </b>{" "}
                            {data.fld_shippingGSTIN}
                          </p>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
            <table
              style={{
                width: "700px",
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
                borderRightColor: "#000",
                borderTop: "hidden",
              }}
              border="1"
              cellspacing="0"
              cellPadding="0"
            >
              <tbody>
                <tr class="success" style={{ fontFamily: "Lato, sans-serif" }}>
                  <td
                    // colSpan={3}
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                      width: "34%",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Product</span>
                  </td>
                  <td
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Quantity</span>
                  </td>
                  <td
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Unit Price</span>
                  </td>
                  <td
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Net Value</span>
                  </td>
                  <td
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Shipping Cost</span>
                  </td>
                  <td
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Total Amount (INR)
                    </span>
                  </td>
                </tr>
                {this.state.OrderData.map((data, i) => (
                  <tr>
                    <td
                      // colSpan={3}
                      style={{
                        textAlign: "center",
                        paddingLeft: "1%",
                        paddingTop: "1%",
                        paddingBottom: "1%",
                      }}
                      // dangerouslySetInnerHTML={{
                      //   __html: data.fld_custom_item_description,
                      // }}
                    >
                      {Parser(data.fld_custom_item_description)}
                    </td>
                    <td style={{ textAlign: "center" }}>{data.fld_quantity}</td>
                    <td style={{ textAlign: "center" }}>
                      &#8377;{parseFloat(data.fld_unit_price).toFixed(2)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      &#8377;&nbsp;
                      {parseFloat(
                        data.fld_netamount_without_GST_ship_amount
                      ).toFixed(2)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      &#8377;&nbsp;{parseFloat(data.Shipping_Charg).toFixed(2)}
                    </td>

                    <td style={{ textAlign: "center", paddingRight: "1%" }}>
                      &#8377;&nbsp;
                      {parseFloat(data.fld_netamount_without_GSTamount).toFixed(
                        2
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table
              style={{
                width: "700px",
                padding: "2%",
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
                borderRightColor: "#000",
                borderTop: "hidden",
              }}
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tbody>
                {/* <tr style={{ width: "100%", background: "red" }} > */}
                {/* <td style={{ textAlign: "center", paddingLeft: "1%", widows: "100%" }}> */}

                <tr>
                  {this.state.DesignType == null ? (
                    <td style={{ width: "30%" }}>
                      <span style={{ fontWeight: "bold" }}>
                        Dispatched by Courier partner
                      </span>
                    </td>
                  ) : (
                    <td style={{ width: "30%" }}>
                      <span style={{ fontWeight: "bold" }}>
                        Dispatch Details : {this.state.DeliveryDetails}
                      </span>
                    </td>
                  )}

                  {this.state.OrderData.map((data, i) => {
                    if (i == 0) {
                      return (
                        <>
                          <td style={{ width: "70%" }}>
                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  padding: "1%",
                                  width: "31%",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontFamily: "Lato, sans-serif",
                                  }}
                                >
                                  Sub total
                                </span>
                              </td>

                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: "2%",
                                  width: "28%",
                                }}
                              >
                                {" "}
                                &#8377;&nbsp;
                                {parseFloat(
                                  data.fld_netamount_without_GSTamount
                                ).toFixed(2)}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  padding: "1%",
                                  // width: "40%",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  GST(12%)
                                </span>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: "2%",
                                  // width: "20%",
                                }}
                              >
                                {" "}
                                &#8377;&nbsp;
                                {parseFloat(data.fld_GSTamount).toFixed(2)}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  padding: "1%",
                                  // width: "40%",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontFamily: "Lato, sans-serif",
                                  }}
                                >
                                  Total (Inclusive of all Taxes)
                                </span>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: "2%",
                                  // width: "20%",
                                }}
                              >
                                {" "}
                                &#8377;&nbsp;
                                {parseFloat(data.Total_Inclusive_Tax).toFixed(
                                  2
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  padding: "1%",
                                  // width: "40%",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontFamily: "Lato, sans-serif",
                                  }}
                                >
                                  Amount Paid
                                </span>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: "2%",
                                  // width: "20%",
                                }}
                              >
                                {" "}
                                &#8377;&nbsp;
                                {parseFloat(data.fld_amount_paid).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  padding: "1%",
                                  // width: "40%",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontFamily: "Lato, sans-serif",
                                  }}
                                >
                                  Balance Due
                                </span>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: "2%",                              
                                  // width: "20%",
                                  fontFamily: "Lato, sans-serif",
                                }}
                              >
                                {parseFloat(
                                  data.Total_Inclusive_Tax -
                                    parseFloat(data.fld_amount_paid)
                                ).toFixed(2)}
                              </td>
                            </tr>
                          </td>
                        </>
                      );
                    }
                  })}
                </tr>
                {/* </td> */}
                {/* </tr> */}
              </tbody>
            </table>
            <table
              style={{
                width: "700px",
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
                borderRightColor: "#000",
                borderTop: "hidden",
                fontFamily: "Lato, sans-serif",
                borderCollapse: "collapse",
                marginBottom: "30px",
              }}
              border="1"
              cellspacing="1"
              cellpadding="0"
            >
              <tbody>
                <tr>
                  <td
                    colspan="6"
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                      background: "#f7f7f7",
                      borderLeft: "none",
                    }}
                  >
                    Have a Question?
                    <br /> Call us on 96770 27016 / 98403 27012 or Email us at{" "}
                    <a href="mailto:info@piopackaging.com">
                      info@piopackaging.com
                    </a>
                  </td>
                </tr>

                <tr class="success" style={{ backgroundColor: "#f7f7f7" }}>
                  <td
                    colspan="6"
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                      background: "#f7f7f7",
                      borderLeft: "none",
                    }}
                  >
                    Shop online at : &nbsp;{" "}
                    {/* <a href="https://www.piopackaging.com/ordercustomboxes">
                      https://www.piopackaging.com/ordercustomboxes
                    </a> */}

                    <a href="https://piopackaging.com/onlinestore">
                      https://piopackaging.com/onlinestore
                    </a>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* </iframe> */}
      </>
    );
  }
}

export default DownloadOrderForm;
