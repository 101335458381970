import React from 'react';
import Header from './Header';
import Footer from './Footer';
// import ReturnsandRefundsBanner from "./assets/img/StaticPagesBanner/2.png"
// import ReturnsandRefundsBannerMobile from "./assets/img/StaticPagesBannerMobile/2.png"





class RefundPolicy extends React.Component {


    render() {
        return (

            <div className="App">
                <Header></Header>
                <div class="privacy-title termsconditiontitle  section-title">
                    <h4>Returns / Refund and Shipping Policy</h4>
                    <div class="title-divider"></div>

                </div>



                <div class="container-fluid">


                    <div class="row marginbtm-240">



                        <div class="col-md-12">
                            <div class="col-md-12 disclaimer-box">
                                <h3>Returns:</h3>
                                <p class="our">You have 5 calendar days to be eligible to return the item you purchased from the date you received it..</p>
                                <p class="such">To be eligible for return of your item,   your item must be in the unused condition and in the same condition you received it. Your item must be in the original packing at any cost. Any packing opened or if the seal is broken will not be eligible for returns.</p>
                                <p class="such">This privacy policy describes the treatment of certain Personal Information provided by you or collected by us on http://www.piopackaging.com where this privacy policy is posted.</p>
                                <p class="such">Used products will not be eligible for return.</p>
                                <p class="such"> Your items of return must have original proof of purchase.</p>
                                <p class="such">Any product complaint (if any) will be subject to a series of tests being conducted at our end before any decision is given on us accepting a return.</p>

                                <p class="such">Batch number and manufacturing date must accompany your complaint along with photographs of specific complaints.</p>

                                <h3 style={{
                                    marginTop: '1rem',
                                    marginBottom: '1rem'
                                }}>Refunds:</h3>

                                <p class="such">Once we receive your item, we will inspect the item and notify you that we have received your item. We will notify you of the status of refund once we inspect the item at our end.</p>
                                <p class="such" tyle={{

                                    marginBottom: '1rem'
                                }}>If your return is approved, we will initiate your refund to your credit card (or original method of payment). You will receive your refund depending upon your card issuer’s policies.</p>

                                <h3 tyle={{
                                    marginTop: '1rem',
                                    marginBottom: '1rem'
                                }}>Shipping:</h3>
                                <p class="such" tyle={{
                                    marginBottom: '1rem'
                                }}>You will be responsible for bearing the shipping cost for return of the item. Shipping costs are not refundable. If you receive a refund the cost of shipping will be deducted from your refund.</p>

                            </div>





                        </div>

                    </div>
                </div>

                <Footer></Footer>
            </div>
        );
    }
}

export default RefundPolicy;
