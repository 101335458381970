import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// import Checkout from './Pages/ShoppingCheckout/ShoppingCheckout';







// **** For New Sticker Modules ****
import App from "./App";

const history = createBrowserHistory();
ReactDOM.render(
  <App />,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
