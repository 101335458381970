import React, { Component } from "react";
import Footer from "../../Footer";

import Parser from "html-react-parser";
import fmt from "indian-number-format";
import Notiflix from "notiflix";
import { ToWords } from "to-words";
import PostApiCall from "../../components/helper/Api";
import GetApiCall from "../../components/helper/GETAPI";
import Header from "../../Header";

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Viewlargeimage:
        "https://www.piopackaging.com/assets/img/industries/cosmetics-health-beauty.jpg",
      CountryData: [],
      CityData: [],
      StateData: [],
      CountryId: 0,
      StateId: 0,
      CityId: 0,
      Country: null,
      BillingAddress: "",
      State: null,
      City: null,
      Pincode: null,
      AddressId: null,
      NumRegex: /^[0-9]*$/,
      CompanyName: null,
      ShippingCountry: "",
      ShippingState: "",
      ShippingCity: "",
      ShippingCountryData: [],
      ShippingStateData: [],
      ShippingCityData: [],
      ShippingCityId: 0,
      ShippingCountryId: 0,
      ShippingStateId: 0,
      ShippingPincode: "",
      ProductCart: [],
      AddressData: [],
      ShippingAddress: "",
      ShippingCompanyName: "",
      ShippingGSTIN: "",
      ShippingEmail: "",
      ShippingMobile: "",
      PermanentSame: false,
      CustomQuantity_Deliverycharge: 0,
      CustomQuantity_NetAmount: 0,
      CustomQuantity_GstAmount: 0,
      CustomQuantity_TotalAmount: 0,
      OrderSummaryData: [],
      MainOrders: [],
      MerKey: "72V9gu",
      MerSalt: "xFgqOOKTdA7TEcqAiwezDtzsHRLyfn2z",
      designid: null,
      orderType: "",
    };
  }

  componentDidMount() {
    var OrderDetails = JSON.parse(localStorage.getItem("OrderDetails"));

    var orderSample = localStorage.getItem("Ordertype");
    var ordertypelocalstorage = JSON.parse(orderSample);
    // console.log(ordertypelocalstorage);
    this.setState({
      orderType: ordertypelocalstorage,
    });

    this.setState({
      TxnId: OrderDetails[0].transaction_id,
    });

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    GetApiCall.getRequest("GetCountry").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          CountryData: obj.data,
          ShippingCountryData: obj.data,
        });

        if (obj.data.length != 0) {
          this.setState({
            CountryId: 101,
            Country: obj.data[102].value,
            ShippingCountryId: 101,

            ShippingCountry: obj.data[152].value,
          });
        }
        PostApiCall.postRequest(
          {
            countryid: 101,
          },
          "GetState"
        ).then((results) =>
          results.json().then((objstate) => {
            if (results.status == 200 || results.status == 201) {
              if (objstate.data.length != 0) {
                this.setState({
                  StateId: objstate.data[0].value,
                  ShippingStateId: objstate.data[0].value,
                  ShippingStateData: objstate.data,
                  ShippingState: objstate.data[0].value,
                  StateData: objstate.data,
                  State: objstate.data[0].value,
                });
              }

              PostApiCall.postRequest(
                {
                  stateid: objstate.data[0].value,
                },
                "GetCity"
              ).then((resultscity) =>
                resultscity.json().then((objcity) => {
                  if (resultscity.status == 200 || resultscity.status == 201) {
                    if (objcity.data.length != 0) {
                      this.setState({
                        CityId: objcity.data[0].value,
                        CityData: objcity.data,
                        City: objcity.data[0].value,
                        ShippingCityData: objcity.data,
                        ShippingCity: objcity.data[0].value,
                        ShippingCityId: objcity.data[0].value,
                      });
                    }
                  }
                })
              );
            }
          })
        );
      })
    );
    this.getOrderdata();
  }

  getOrderdata = () => {
    var OrderDetails = JSON.parse(localStorage.getItem("OrderDetails"));

    PostApiCall.postRequest(
      {
        whereClause: `where fld_order_id=${OrderDetails[0].order_id}`,
      },
      "GetWebsiteOrderDetails"
    ).then((results1) =>
      // const objs = JSON.parse(result._bodyText)
      results1.json().then((obj) => {
        // console.log(obj.data);
        if (results1.status == 200 || results1.status == 201) {
          this.setState({
            OrderSummaryData: obj.data,
            MainOrders: obj.data[0],
          });

          const payu = require("payu-sdk")({
            key: this.state.MerKey,
            salt: this.state.MerSalt, // should be on server side only
          });
          const hash = payu.hasher.generateHash({
            txnid: this.state.TxnId,
            amount: parseFloat(obj.data[0].fld_total).toFixed(2),

            productinfo: "PioPackaging Products",
            firstname: obj.data[0].CustomerName,
            email: obj.data[0].fld_email,
            phone: obj.data[0].fld_mobile,
          });
          const hashPartial = payu.hasher.generateHash({
            txnid: this.state.TxnId,
            amount: (parseFloat(obj.data[0].fld_total).toFixed(2) / 2).toFixed(
              2
            ),
            productinfo: "PioPackaging Products",
            firstname: obj.data[0].CustomerName,
            email: obj.data[0].fld_email,
            phone: obj.data[0].fld_mobile,
          });
          this.setState({ CustomerHash: hash });
          this.setState({ CustomerHashPartial: hashPartial });

          this.state.OrderSummaryData.map((data, i) => {
            if (i === 0) {
              const toWords = new ToWords();
              let words = toWords.convert(data.Total_Inclusive_Tax);
              this.setState({
                AmountInWords: words,
              });
            }
          });
        }
      })
    );
  };

  SendMailers() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        ordernumber: this.state.MainOrders.fld_orderNumber,
        orderdate: this.state.MainOrders.fld_order_date,
        ordervalue: this.state.MainOrders.fld_ordervalue,
        netcost: this.state.MainOrders.fld_netcost,
        paymentmode: this.state.MainOrders.Payment_Mode,
        numofitems: this.state.MainOrders.Total_Item_Quantity,
        customeremail: this.state.MainOrders.fld_email,
        customername: this.state.MainOrders.CustomerName,
        customermobile: this.state.MainOrders.fld_mobile,
        billingaddress: this.state.MainOrders.fld_address,
        deliveryaddress: this.state.MainOrders.fld_shippingaddress,
        shippingname: this.state.MainOrders.fld_shippingcontactperson,
        shippinglandmark: this.state.MainOrders.fld_shippinglandmark,
        shippingcountry: this.state.MainOrders.fld_shippingcountry,
        shippingstate: this.state.MainOrders.fld_shippingstate,
        shippingcity: this.state.MainOrders.fld_shippingcity,
        shippingpincode: this.state.MainOrders.fld_shippingPincode,
        shippingmobile: this.state.MainOrders.fld_shippingmobile,
        billingname: this.state.MainOrders.fld_contactperson,

        billinglandmark: this.state.MainOrders.fld_landmark,
        billingcountry: this.state.MainOrders.fld_country,
        billingstate: this.state.MainOrders.fld_billingstate,
        billingcity: this.state.MainOrders.fld_city,
        billingpincode: this.state.MainOrders.fld_Pincode,
        billingmobile: this.state.MainOrders.fld_billingmobile,
        orderdata: this.state.MainOrders,
        Item_In_GstRate_5: this.state.MainOrders.Item_In_GstRate_5,
        Item_Amnt_In_GstRate_5: this.state.MainOrders.Item_Amnt_In_GstRate_5,
        Item_In_GstRate_18: this.state.MainOrders.Item_In_GstRate_18,
        Item_Amnt_In_GstRate_18: this.state.MainOrders.Item_Amnt_In_GstRate_18,
        Item_In_GstRate_28: this.state.MainOrders.Item_In_GstRate_28,
        Item_Amnt_In_GstRate_28: this.state.MainOrders.Item_Amnt_In_GstRate_28,
        Sub_Total_Amnt: this.state.MainOrders.Sub_Total_Amnt,
        Total_Discount: this.state.MainOrders.Total_Discount,
        Shipping_Charg: this.state.MainOrders.Shipping_Charg,
        COD_Charge: this.state.MainOrders.COD_Charge,
        Total_Inclusive_Tax: this.state.MainOrders.fld_total,
        designimage: this.state.MainOrders.design_4_image,
        ShippingCompanyName: this.state.MainOrders.fld_shipping_company_name,
        BillingCompanyName: this.state.MainOrders.fld_billing_company_name,
      },
      "CustomerOrderMailer"
    ).then((results2) =>
      // const objs = JSON.parse(result._bodyText)
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          localStorage.removeItem("OrderData");

          window.location.href = `/paymentprocess/${this.state.MainOrders.fld_payment_trx_num}`;
        }
      })
    );
  }

  //**Paylater mailer */

  SendMailersPaylater() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        ordernumber: this.state.MainOrders.fld_orderNumber,
        orderdate: this.state.MainOrders.fld_order_date,
        ordervalue: this.state.MainOrders.fld_ordervalue,
        netcost: this.state.MainOrders.fld_netcost,
        paymentmode: this.state.MainOrders.Payment_Mode,
        numofitems: this.state.MainOrders.Total_Item_Quantity,
        customeremail: this.state.MainOrders.fld_email,
        customername: this.state.MainOrders.CustomerName,
        customermobile: this.state.MainOrders.fld_mobile,
        billingaddress: this.state.MainOrders.fld_address,
        deliveryaddress: this.state.MainOrders.fld_shippingaddress,
        shippingname: this.state.MainOrders.fld_shippingcontactperson,
        shippinglandmark: this.state.MainOrders.fld_shippinglandmark,
        shippingcountry: this.state.MainOrders.fld_shippingcountry,
        shippingstate: this.state.MainOrders.fld_shippingstate,
        shippingcity: this.state.MainOrders.fld_shippingcity,
        shippingpincode: this.state.MainOrders.fld_shippingPincode,
        shippingmobile: this.state.MainOrders.fld_shippingmobile,
        billingname: this.state.MainOrders.fld_contactperson,

        billinglandmark: this.state.MainOrders.fld_landmark,
        billingcountry: this.state.MainOrders.fld_country,
        billingstate: this.state.MainOrders.fld_billingstate,
        billingcity: this.state.MainOrders.fld_city,
        billingpincode: this.state.MainOrders.fld_Pincode,
        billingmobile: this.state.MainOrders.fld_billingmobile,
        orderdata: this.state.MainOrders,
        Item_In_GstRate_5: this.state.MainOrders.Item_In_GstRate_5,
        Item_Amnt_In_GstRate_5: this.state.MainOrders.Item_Amnt_In_GstRate_5,
        Item_In_GstRate_18: this.state.MainOrders.Item_In_GstRate_18,
        Item_Amnt_In_GstRate_18: this.state.MainOrders.Item_Amnt_In_GstRate_18,
        Item_In_GstRate_28: this.state.MainOrders.Item_In_GstRate_28,
        Item_Amnt_In_GstRate_28: this.state.MainOrders.Item_Amnt_In_GstRate_28,
        Sub_Total_Amnt: this.state.MainOrders.Sub_Total_Amnt,
        Total_Discount: this.state.MainOrders.Total_Discount,
        Shipping_Charg: this.state.MainOrders.Shipping_Charg,
        COD_Charge: this.state.MainOrders.COD_Charge,
        Total_Inclusive_Tax: this.state.MainOrders.fld_total,
        designimage: this.state.MainOrders.design_4_image,
        ShippingCompanyName: this.state.MainOrders.fld_shipping_company_name,
        BillingCompanyName: this.state.MainOrders.fld_billing_company_name,
      },
      "CustomerOrderPayLaterMailer"
    ).then((results2) =>
      // const objs = JSON.parse(result._bodyText)
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          //localStorage.removeItem("OrderData");
          localStorage.removeItem("OrderDetails");
          window.location.href = `/paymentprocess/${this.state.MainOrders.fld_payment_trx_num}`;
        }
      })
    );
  }

  // **** after payment mailer ******

  SendPaymentMailersToCLient(paymentmode, orderid) {
    var OrderDetails = JSON.parse(localStorage.getItem("OrderDetails"));
    var amount = this.state.MainOrders.fld_total;
    if (paymentmode === "OnlinePartialPayment") {
      amount = (parseFloat(this.state.MainOrders.fld_total) / 2).toFixed(2);
    }

    PostApiCall.postRequest(
      {
        customername: this.state.MainOrders.CustomerName,
        itemname: this.state.MainOrders.item_name,
        email: this.state.MainOrders.fld_email,
        total: amount,
        numofitems: this.state.MainOrders.Total_Item_Quantity,
        customermobile: this.state.MainOrders.fld_mobile,
        paymentmode: paymentmode,
        orderid: OrderDetails[0].order_id,
      },
      "Customer_Order_History_Mailer"
    ).then((results2) =>
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          localStorage.removeItem("OrderDetails");
          Notiflix.Loading.remove();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
    );
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <section style={{ paddingTop: "40px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 section-title rightcarttoptext">
                <h2 className="leftcardText">Order Summary</h2>
              </div>
              <div className="col-md-12">
                <div
                  class="card"
                  style={{
                    borderLeft: "none",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  <div class="heading">
                    <h4>Item Summary</h4>
                  </div>

                  {this.state.orderType !== 'Stickers' ?


                    this.state.OrderSummaryData.map((data, i) => (
                      <div style={{ overflowX: "auto" }}>
                        {Parser(
                          (
                            "<table class='table table-bordered'>" +
                            data.fld_order_description +
                            '<td class="ordersummarylist" style="text-align: right" > ₹ ' +
                            data.Shipping_Charg?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            }) +
                            ".00</td>" +
                            '<td class="ordersummarylist" style="text-align: right">₹ ' +
                            data.fld_GSTamount?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            }) +
                            "</td>" +
                            '<td class="ordersummarylist" style="text-align: right" >₹ ' +
                            data.fld_total?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            }) +
                            ".00</td>" +
                            "</tr>" +
                            "</tbody>" +
                            // '</table>'
                            "</table>"
                          )
                            .replace(/font-family/g, "")
                            .replace(/\n/g, "<br/>")
                        )}
                      </div>
                    ))

                    : this.state.OrderSummaryData.map((data, i) => (
                      <div style={{ overflowX: "auto" }}>
                        {Parser(
                          (
                            "<table class='table table-bordered'>" +
                            data.fld_order_description +
                            "</tr>" +
                            "</tbody>" +
                            // '</table>'
                            "</table>"
                          )
                            .replace(/font-family/g, "")
                            .replace(/\n/g, "<br/>")
                        )}
                      </div>
                    ))}

                  <div></div>
                </div>
              </div>
            </div>



            <div
              className="row"
              style={{ marginBottom: "20px", marginTop: "20px" }}
            >
              <div className="col-md-12">
                <div className="row">
                  <div class="col-md-3">
                    {this.state.OrderSummaryData.map((data, i) => {
                      if (i === 0) {
                        return (
                          <div class="card mb-2">
                            <h5 style={{ padding: "22px 22px 0px 22px" }}>
                              Shipping Address
                            </h5>

                            <div class="card-body">
                              <p class="card-text">
                                <b
                                  style={{
                                    letterSpacing: "1px",
                                  }}
                                >
                                  {data.fld_shipping_company_name}
                                </b>
                                ,<br />
                                {data.fld_shippingcontactperson},<br />
                                {data.fld_shippingaddress},<br />
                                {data.fld_shippingcity} -{" "}
                                {data.fld_shippingPincode}.{" "}
                                {data.fld_shippingcountry}.<br />
                                Phone: {data.fld_shippingmobile}
                                <br />
                                Email:{data.fld_shippingemail}
                                <br />
                                GSTIN:{data.fld_shippingGSTIN}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div class="col-md-3">
                    {this.state.OrderSummaryData.map((data, i) => {
                      if (i === 0) {
                        return (
                          <div class="card mb-2">
                            <h5 style={{ padding: "22px 22px 0px 22px" }}>
                              Billing Address
                            </h5>

                            <div class="card-body">
                              <p class="card-text">
                                <b
                                  style={{
                                    letterSpacing: "1px",
                                  }}
                                >
                                  {data.fld_billing_company_name}
                                </b>
                                ,<br />
                                {data.fld_contactperson},<br />
                                {data.fld_address},<br />
                                {data.fld_city} - {data.fld_Pincode}.{" "}
                                {data.fld_country}.<br />
                                Phone: {data.fld_billingmobile}
                                <br />
                                Email:{data.fld_billingemail}
                                <br />
                                GSTIN:{data.fld_GSTIN}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>

              <div
                className="col-md-12 order-payment-order-display"
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                {this.state.MainOrders.Payment_Mode != "COD" ? (
                  <>
                    <form
                      action="https://secure.payu.in/_payment"
                      id="payment_form"
                      method="Post"
                    >
                      <div style={{ display: "none" }}>
                        <input type="hidden" id="udf5" name="udf5" value="" />

                        <input
                          type="hidden"
                          id="surl"
                          name="surl"
                          value="https://www.piopackaging.com/API/Response"
                        />
                        <input
                          type="hidden"
                          id="furl"
                          name="furl"
                          value="https://www.piopackaging.com/API/Response"
                        />
                        <input
                          type="hidden"
                          id="curl"
                          name="curl"
                          value="https://www.piopackaging.com/API/Response"
                        />
                        <input
                          type="hidden"
                          id="key"
                          name="key"
                          value={this.state.MerKey}
                        />
                        <div class="dv">
                          <span class="text">
                            <label>Transaction/Order ID:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="txnid"
                              name="txnid"
                              placeholder="Transaction ID"
                              value={this.state.TxnId}
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>Amount:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="amount"
                              name="amount"
                              placeholder="Amount"
                              value={parseFloat(
                                this.state.MainOrders.fld_total
                              ).toFixed(2)}
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>Product Info:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="productinfo"
                              name="productinfo"
                              placeholder="Product Info"
                              value={"PioPackaging Products"}
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>First Name:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="firstname"
                              name="firstname"
                              placeholder="First Name"
                              value={this.state.MainOrders.CustomerName}
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>Last Name:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="Lastname"
                              name="Lastname"
                              placeholder="Last Name"
                              value=""
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>Email ID:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="email"
                              name="email"
                              placeholder="Email ID"
                              value={this.state.MainOrders.fld_email}
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>Mobile/Cell Number:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="phone"
                              name="phone"
                              placeholder="Mobile/Cell Number"
                              value={this.state.MainOrders.fld_mobile}
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>Hash:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="hash"
                              name="hash"
                              placeholder="Hash"
                              value={this.state.CustomerHash}
                            />
                          </span>
                        </div>
                        <div class="dv">
                          <span class="text">
                            <label>Service Provider:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="service_provider"
                              name="service_provider"
                              placeholder="service_provider"
                              value={"payu_paisa"}
                            />
                          </span>
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <button
                          style={{
                            marginLeft: "5px",
                            marginBottom: "15px",
                            background: "#59c5d1",
                            borderRadius: "3px",
                            color: "white",
                            width: "auto",
                          }}
                          className="btn"
                          onClick={this.SendPaymentMailersToCLient.bind(
                            this,
                            "OnlinePayment"
                          )}
                        >
                          Pay Full & Place Order <br />₹{" "}
                          {fmt.formatFixed(
                            parseFloat(this.state.MainOrders.fld_total),
                            2
                          )}
                        </button>
                      </div>
                      {/* {console.log(this.state.orderType)} */}
                    </form>
                    <form
                      action="https://secure.payu.in/_payment"
                      id="payment_form"
                      method="Post"
                    >
                      <div style={{ display: "none" }}>
                        <input type="hidden" id="udf5" name="udf5" value="" />

                        <input
                          type="hidden"
                          id="surl"
                          name="surl"
                          value="https://www.piopackaging.com/API/Response"
                        />
                        <input
                          type="hidden"
                          id="furl"
                          name="furl"
                          value="https://www.piopackaging.com/API/Response"
                        />
                        <input
                          type="hidden"
                          id="curl"
                          name="curl"
                          value="https://www.piopackaging.com/API/Response"
                        />
                        <input
                          type="hidden"
                          id="key"
                          name="key"
                          value={this.state.MerKey}
                        />
                        <div class="dv">
                          <span class="text">
                            <label>Transaction/Order ID:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="txnid"
                              name="txnid"
                              placeholder="Transaction ID"
                              value={this.state.TxnId}
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>Amount:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="amount"
                              name="amount"
                              placeholder="Amount"
                              value={(
                                parseFloat(
                                  this.state.MainOrders.fld_total
                                ).toFixed(2) / 2
                              ).toFixed(2)}
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>Product Info:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="productinfo"
                              name="productinfo"
                              placeholder="Product Info"
                              value={"PioPackaging Products"}
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>First Name:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="firstname"
                              name="firstname"
                              placeholder="First Name"
                              value={this.state.MainOrders.CustomerName}
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>Last Name:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="Lastname"
                              name="Lastname"
                              placeholder="Last Name"
                              value=""
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>Email ID:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="email"
                              name="email"
                              placeholder="Email ID"
                              value={this.state.MainOrders.fld_email}
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>Mobile/Cell Number:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="phone"
                              name="phone"
                              placeholder="Mobile/Cell Number"
                              value={this.state.MainOrders.fld_mobile}
                            />
                          </span>
                        </div>

                        <div class="dv">
                          <span class="text">
                            <label>Hash:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="hash"
                              name="hash"
                              placeholder="Hash"
                              value={this.state.CustomerHashPartial}
                            />
                          </span>
                        </div>
                        <div class="dv">
                          <span class="text">
                            <label>Service Provider:</label>
                          </span>
                          <span>
                            <input
                              type="text"
                              id="service_provider"
                              name="service_provider"
                              placeholder="service_provider"
                              value={"payu_paisa"}
                            />
                          </span>
                        </div>
                      </div>

                      <div
                        className="col-12 col-md-4"
                        style={{
                          display:
                            this.state.orderType != "Ordersample" && this.state.orderType !== 'Stickers'
                              ? "block"
                              : "none",
                        }}
                      >
                        <button
                          style={{
                            marginLeft: "5px",
                            marginBottom: "15px",
                            background: "#59c5d1",
                            borderRadius: "3px",
                            color: "white",
                            width: "auto",
                          }}
                          className="btn"
                          onClick={this.SendPaymentMailersToCLient.bind(
                            this,
                            "OnlinePartialPayment"
                          )}
                        >
                          Pay Half & Place Order <br />₹
                          {fmt.formatFixed(
                            parseFloat(this.state.MainOrders.fld_total).toFixed(
                              2
                            ) / 2,
                            2
                          )}
                        </button>
                      </div>
                    </form>
                    <div
                      className="col-12 col-md-2"
                      style={{
                        display:
                          this.state.orderType != "Ordersample" && this.state.orderType !== 'Stickers'
                            ? "block"
                            : "none",
                      }}
                    >
                      <button
                        style={{
                          marginLeft: "5px",
                          marginBottom: "15px",
                          background: "#59c5d1",
                          borderRadius: "3px",
                          color: "white",
                          width: "auto",
                        }}
                        onClick={this.SendMailersPaylater.bind(this)}
                        className="btn"
                      >
                        Place Order & Pay Later <br />₹ 0
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="col-12 col-md-2">
                    <button
                      style={{
                        width: "80%",
                        marginBottom: "15px",

                        background: "#59c5d1",
                        borderRadius: "3px",
                        color: "white",
                      }}
                      onClick={this.SendMailers.bind(this)}
                      className="btn"
                    >
                      Pay & Place Order
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <Footer style={{ marginTop: "20px" }} />
      </React.Fragment>
    );
  }
}

export default OrderSummary;
