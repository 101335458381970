import React, { Component } from "react";
import PostApiCall from "../components/helper/Api";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Row, Col, Card, CardBody } from "reactstrap";
import Searchicon from './images/search.png'
import Header from "../Header";
import Footer from "../Footer";

class Chart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderHistoryData: [],
            searchInput: "",
            DeliveryDetails: ''
        };
    }

    componentDidMount() {

        var customer = localStorage.getItem('CustomerData');
        var CustomerDetails = JSON.parse(customer);


        PostApiCall.postRequest(
            {
                WhereClause: `where fld_customer_id=${CustomerDetails[0].fld_customerid} order by fld_order_id desc`
            },
            "GetCustomerOrderDetails"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    this.setState({
                        orderHistoryData: obj.data,

                    });
                }
            }))
    }
    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };

    DownloadInvoicePdf() {

        var html2pdf = require('html2pdf.js')
        var element = document.getElementById('invoice');
        var opt = {
            margin: 1,
            filename: 'invoice.pdf',
            image: { type: 'png', quality: 0.98 },
            html2canvas: {
                scale: 1.5, useCORS: true,
                allowTaint: true, proxy: null
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait',compress: true, },
            //   pagebreak: {avoid: ‘div’}
        };
        // //console.log(opt)
        // New Promise-based usage:
        html2pdf().from(element).set(opt).save().then(() => {
        });

    }

    render() {
        const data = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 75,
                },
                {
                    label: "Order Number",
                    field: "number",
                    sort: "disabled",
                    width: 600,
                },

                {
                    label: "Order Date",
                    field: "date",
                    sort: "disabled",
                    width: 600,
                },

                {
                    label: "Total items",
                    field: "item",
                    sort: "disabled",
                    width: 300,
                },

                {
                    label: "Order Value",
                    field: "ordervalue",
                    sort: "disabled",
                    width: 300,
                },
                {
                    label: "Order Status",
                    field: "status",
                    sort: "disabled",
                    width: 300,
                },
                {
                    label: "Payment Status",
                    field: "paystatus",
                    sort: "disabled",
                    width: 300,
                }
                // ,
                // {
                //     label: "Invoice",
                //     field: "invoice",
                //     sort: "disabled",
                //     width: 200,
                // },



                // {
                //     label: "Action",
                //     field: "action",
                //     sort: "disabled",
                //     width: 100,
                // },
            ],
            rows: this.state.orderHistoryData
                .filter((data) => {
                    if (this.state.searchInput == "") {
                        return data;
                    }
                    if (
                        this.state.searchInput !== "" &&
                        (data.fld_order_status
                            .toLowerCase()
                            .includes(this.state.searchInput.toLowerCase())
                        )
                    ) {
                        return data;
                    }
                    if (
                        this.state.searchInput !== "" &&
                        (data.fld_order_number
                            .toLowerCase()
                            .includes(this.state.searchInput.toLowerCase())
                        )
                    ) {
                        return data;
                    }
                    if (
                        this.state.searchInput !== "" &&
                        (data.fld_payment_status
                            .toLowerCase()
                            .includes(this.state.searchInput.toLowerCase())
                        )
                    ) {
                        return data;
                    }
                })
                .map((data, i) => {
                    return {
                        serial: (i + 1),
                        number: (data.fld_order_number),
                        date: (data.fld_order_date),

                        item: (data.total_items),
                        ordervalue: (data.fld_order_amount),
                        status: (data.fld_order_status),
                        paystatus: (data.fld_payment_status),
                        // invoice:
                        //     <button
                        //         onClick={this.DownloadInvoicePdf}
                        //     >
                        //         View Invoice    <i class="fas fa-file-invoice"></i>
                        //     </button>,
                        // action:
                        //     <button
                        //         onClick={this.DownloadInvoicePdf}
                        //     >
                        //         Download Invoice    <i class="fas fa-file-invoice"></i>
                        //     </button>,
                    }
                })
        }


        return (
            <React.Fragment>
                <Header />
                <section style={{ paddingTop: '40px' }}>
                    <div className="container-fluid">
                        <div className="row">
                            <Card className="mini-stats-wid mt-3">
                                <CardBody>
                                    {/* <div id="invoice" style={{ background: "transparent", display: "none" }}><InvoiceForm /></div> */}
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="section-title">
                                                <h4>Order History</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="title-divider"></div>
                                    <Row>
                                        <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                                            <div class="input-group mb-3">


                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1">
                                                        <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                </div>
                                                <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                    onChange={(e) => this.seachBarHandler(e)}
                                                    value={this.state.searchInput}
                                                />
                                            </div>
                                        </MDBCol>

                                        <Col md="12">
                                        

                                            {this.state.orderHistoryData.length != 0 ?
                                                <MDBDataTable
                                                    hover
                                                    // scrollY
                                                    striped
                                                    bordered
                                                    data={data}
                                                    seachTop={false}
                                                    entriesOptions={[10, 25, 50, 100]}

                                                >

                                                    <MDBTableHead columns={data.columns} />
                                                    <MDBTableBody rows={data.rows} />
                                                </MDBDataTable>
                                                :
                                                <p>No Data Found</p>}
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </section>

                <Footer />

            </React.Fragment>


        );
    }
}

export default Chart;
