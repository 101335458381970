import React from 'react'

class AboutUs extends React.Component {
    render () {
      return (
        <section class="section about bg-gray" id="about-pio-packaging">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="middle-box">
                        <div class="middle">
                            <div class="overview-txt about-text">
                                <h2>ABOUT US</h2>
                                <p>Our company Pio Printers Pvt Ltd has been in existence since 1972 having all its sophisticated machines housed in one self-owned premises right in the heart of Chennai city. We have all the necessary equipment and infrastructure to produce very high quality packaging products right from conventional multicolour Offset to UV Offset printing, catering to every possible printed packaging requirement of our clients.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="overview-img">
                        <img src="assets/img/about.jpg" alt="Overview"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
      )
    }
  }

  export default AboutUs;