import React, { Component } from "react";
import Footer from "../../Footer";

import Header from "../../Header";
import PostApiCall from "../../components/helper/Api";
import GetApiCall from "../../components/helper/GETAPI";
import loginimg from "../../assets/image/banner_mobile.png";
import borderimg from "../../assets/image/border.png";
import Notiflix from "notiflix";
import moment from "moment";
import { Link } from "react-router-dom";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      NumRegex: /^[0-9]*$/,
      isPasswordVisible: false,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      open: false,
      EnteredOtp: "",
      MailerOtp: "",
      Email: "",
      openOtp: false,
      openPassword: false,
      Password: "",

      Salt: [],
      isNewPasswordVisible: false,
      isConPasswordVisible: false,
      Name: "",
      MobileNo: "",
    };
  }

  componentDidMount() {
    Notiflix.Notify.init({
      width: "300px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });
  }

  saveLogin = () => {
    if (this.state.Email != "") {
      if (this.state.EmailRegex.test(this.state.Email)) {
        if (this.state.Password != "") {
          this.onLogin();
        } else {
          Notiflix.Notify.failure("Please enter your password.");
        }
      } else {
        Notiflix.Notify.failure("Please enter valid email.");
      }
    } else {
      Notiflix.Notify.failure("Please enter your email.");
    }
  };

  onLogin = () => {
    Notiflix.Loading.dots("Please wait...");
    PostApiCall.postRequest(
      {
        email: this.state.Email,
        password: this.state.Password,
        action: "Login",
        actiondate: moment().format("lll"),
      },
      "AddwebsiteLogin"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.remove();
          localStorage.setItem("CustomerData", JSON.stringify(obj.data));
          if (localStorage.getItem("currentPage") != null) {
            localStorage.getItem("currentPage") == "Cart"
              ? (window.location.href = "/selectaddress")
              : (window.location.href = "/ordercustomboxes");
          } else {
            localStorage.getItem("currentPage") == "Header"
              ? this.props.history.goBack()
              : (window.location.href = "/ordercustomboxes");
          }

          if (localStorage.getItem("OrderData") != null) {
            this.onPostMoveCart(obj);
          } else if (localStorage.getItem("downloadkeyline") != null) {
            localStorage.setItem("CustomerData", JSON.stringify(obj.data));
            window.location.href = `/downloadkeyline/${localStorage.getItem(
              "downloadkeyline"
            )}`;
          } else {
            localStorage.setItem("CustomerData", JSON.stringify(obj.data));
            window.location.href = "/ordercustomboxes";
            if (localStorage.getItem("currentPage") != null) {
              localStorage.getItem("currentPage") == "Cart"
                ? (window.location.href = "/selectaddress")
                : localStorage.getItem("currentPage") == "Header"
                ? this.props.history.goBack()
                : (window.location.href = "/ordercustomboxes");
            }
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };
  onPostMoveCart(obj) {
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    Notiflix.Loading.circle();

    PostApiCall.postRequest(
      {
        customerid: JSON.stringify(obj.data[0].fld_customerid),
        cartid: Orderdetails[0].order_id,
      },
      "MoveTmpCrtToMain"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        localStorage.removeItem("OrderData");
        if (results.status == 200 || results.status == 201) {
          localStorage.setItem("OrderData", JSON.stringify(obj.data));
          if (localStorage.getItem("currentPage") != null) {
            localStorage.getItem("currentPage") == "Cart"
              ? (window.location.href = "/selectaddress")
              : (window.location.href = "/");
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  }
  
  render() {
    return (
      <div>
        <Header></Header>
        <section style={{ paddingTop: "65px" }}></section>
        <div class="container">
        <section class="section-padding account-bg">
            <div class="">
              <div class="">
                <div class="login ">
                  <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-12 login-section login-page">
                      <img
                        src={loginimg}
                        class="img-fluid loginimg"
                        alt=""
                      ></img>

                      <div class="login-box">
                        <h4 style={{ textAlign: "center" }}>
                          Customize Packaging Solution!
                        </h4>
                        <p style={{ textAlign: "center" }}>
                          Login to Customize your Boxes
                        </p>
                        <p style={{ marginTop: "20px" }}>
                          Use your Email ID to Sign in
                        </p>
                        <form onSubmit={this.saveLogin} autoComplete="off">
                        <div class="row">
                          <div class="col-md-12" style={{ marginTop: "10px" }}>
                            <input
                              type="text"
                              placeholder="Email ID"
                              class="form-control"
                              autoFocus
                              autoComplete="false"
                              value={this.state.Email}
                              onChange={(email) => {
                                this.setState({ Email: email.target.value });
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    this.saveLogin();
                                }
                            }}
                            ></input>
                          </div>
                          <div class="col-md-12" style={{ marginTop: "10px" }}>
                            <input
                              type={
                                this.state.isPasswordVisible
                                  ? "text"
                                  : "password"
                              }
                              placeholder="Password"
                              class="form-control"
                              value={this.state.Password}
                              onChange={(password) => {
                                this.setState({
                                  Password: password.target.value,
                                });
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    this.saveLogin();
                                }
                            }}
                            ></input>
                            <span class="login-icon-change-pass m-1">
                              {/* <i class="icon-dual" data-feather="lock"></i>*/}
                              <i class="fa fa-eye-slash" aria-hidden="true"
                                                            style={{
                                                                display: this.state.isPasswordVisible
                                                                    ? "none"
                                                                    : "block",
                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    isPasswordVisible:
                                                                        !this.state.isPasswordVisible,
                                                                });
                                                            }}></i>
                              <i
                                style={{
                                  display: this.state.isPasswordVisible
                                    ? "block"
                                    : "none",
                                }}
                                className="fa fa-eye"
                                onClick={() => {
                                  this.setState({
                                    isPasswordVisible:
                                      !this.state.isPasswordVisible,
                                  });
                                }}
                              />
                            </span>
                          </div>

                          <div className="col-md-12 d-lg-flex justify-content-between">
                            {/* <a href="/forgetpassword"> */}
                              {" "}
                              <Link to='/forgetpassword'>
                              <p className="mt-lg-3 mt-0" style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                  color: "#59c5d1",
                                }}>Forgot Password?</p>
                              </Link>
                            {/* </a> */}
                            <a
                            type="button"
                              class="btn btn-common mt-20 login-btn"
                              onClick={this.saveLogin}
                            >
                              Login
                            </a>
                          </div>

                          <div
                            class="col-md-12 text-center mt-10"
                            style={{ marginTop: "7px" }}
                          >
                            <img src={borderimg} />
                            <div className="d-lg-flex justify-content-center align-items-center">
                              <p>New to Piopackaging.com?</p>
                              <Link to="/register">
                              <p
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                  color: "#59c5d1",
                                }}
                                // onClick={() => {
                                //   window.location.href = "/register";
                                // }}
                              >
                                Register Now!
                              </p>
                              </Link>
                            </div>
                          </div>
                          {/* <div class="but">
                                                        <button type="button" class="btn btn-secondary" onClick={() => {
                                                            window.location.href = "/register"
                                                        }}>
                                                            Create your GreensWorth account
                                                        </button>
                                                    </div> */}
                        </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="cardes">
                            <img src={boxShadow} class="classed" />
                        </div> */}
        </section>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}

export default Login;
