import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Collapsible from "react-collapsible";
import FAQImage from "./assets/image/piofaqimage.jpg";

class Faq extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div class="privacy-title termsconditiontitle container  section-title text-align-center">
          {/* <h4>Frequently Asked Questions</h4> */}
          <h1 className="static-h1-tems-font">Frequently Asked Questions</h1>
        </div>
        <div class="container">
          <div class="row section-padding disclaimer-box mb-5">
            <div class="col-md-12">
              <Collapsible trigger="How many years of experience does your company have?">
                <p>
                  We have completed 50 years in this business having started in
                  1972. We have all the latest equipment and technology to
                  turnout world-class products at very competitive costs. Our
                  success is due to customer satisfaction and honesty.
                </p>
              </Collapsible>

              <Collapsible trigger="Do you offer Free Samples?">
                <p>
                  No. Everything is custom made to order. You can order generic
                  samples of the same shape and size of the box you want. The
                  kit will contain two sample boxes, one made using Folding Box
                  Board and the other made using White Backed Duplex board
                  having generic offset print without aby coating to show you
                  the quality of our printing. The sample kit will also contain
                  sample of the various types of coating such as gloss aqueous,
                  gloss lamination, matt lamination, inner acrylic food grade
                  coating so that you can choose the type of finish you prefer.
                </p>
              </Collapsible>
              <Collapsible trigger="How do I make sure what would my finished product look alike?">
                <p>
                  We will email you digital proofs with flat and 3d view for
                  approval so that you will be able to see how exactly the
                  product will look like after printing and assembling. After
                  you approve digital proof we will also be sending you a
                  physical sample box, digital printed (the colours will be
                  approximately close to Offset printing) so that you will get
                  to see and feel the product.
                </p>
              </Collapsible>
              <Collapsible trigger="Which printing method you use?">
                <p>
                  We print in high quality offset printing in CMYK. Special
                  colours such as pantone shades, silver, gold and other
                  metallic shades can also be printed against special request at
                  additional cost.
                </p>
              </Collapsible>
              <Collapsible trigger="Is there any specific quantity that I can order?">
                <p>
                  We cater to all kinds of clients and their diverse printing
                  needs. Our E-com site gives you the freedom to key in your
                  required quantity, type of board, type of coating and the
                  rates can be viewed LIVE. You will see the prices become
                  cheaper as you increase your quantity
                </p>
                <p>
                  If you require bulk quantities, send mail to{" "}
                  <a href="mailto:info@piopackaging.com">
                    info@piopackaging.com
                  </a>{" "}
                  for special rock bottom prices.
                </p>
              </Collapsible>
              <Collapsible trigger="What file type you require for printing?">
                <p>
                  We require high resolution print quality PDF files in CMYK
                  having all bleeds and cut marks, assembled on the Key Line
                  Artwork as a separate layer.
                </p>
              </Collapsible>
              <Collapsible trigger="What kind of Boards you use for printing?">
                <p>
                  Currently we offer two types of Board Stock:
                  <ul>
                    <li>
                      Folding Box Board (FBB) made by 'A' Grade mills (Food
                      grade) which is best recommended for Boxes
                    </li>
                    <li>
                      White Backed Duplex Boards (WBDB) from 'A' Grade mills
                      which is an economy option
                    </li>
                  </ul>
                </p>
              </Collapsible>
              <Collapsible trigger="How can I create my design?">
                <p>
                  While ordering click “I HAVE MY OWN DESIGN”. When you
                  check-out after placing order, you can download the ‘Key Line
                  Design (KLD)’ of the box which you have ordered. You can pass
                  on this KLD to your designer to adapt the artwork on this KLD
                  and send back to us by mail as a high-res PDF file.
                </p>
              </Collapsible>
              <Collapsible trigger="Do you charge extra based on the number of colors and ink coverage?">
                <p>
                  No. All products are printed in full color at no extra charge.
                </p>
              </Collapsible>
              <Collapsible trigger=" Do you charge for plates and dies? Are there any hidden costs?">
                <p>
                  No. There are no hidden fees of any kind. What you see is what
                  you pay.
                </p>
              </Collapsible>
              <Collapsible trigger="Where are your products manufactured?">
                <p>
                  We manufacture all our products in our plant based in Chennai,
                  India.{" "}
                </p>
              </Collapsible>
              <Collapsible trigger="Do you check my artwork for technical issues like alignment and image resolution?">
                <p>
                  Yes. We inspect all artworks to make sure they are perfect for
                  printing. If we find a problem, we either fix it, or get in
                  touch with you for further instruction. We are not responsible
                  for misspelling and wrong content.
                </p>
              </Collapsible>
              <Collapsible trigger="How can I reorder?">
                <p>
                  Simply go to Your Account and click on the REORDER button next
                  to your desired item. Proceed to checkout and place your
                  order.{" "}
                </p>
              </Collapsible>
              <Collapsible trigger="Can you print illustrations made by me?">
                <p>
                  Yes, because we are specialized in all types of personalized
                  printing you can have your own illustrations printed in our
                  products.
                </p>
              </Collapsible>
              <Collapsible trigger="What is your standard turnaround time?">
                <p>
                  Our standard turnout time is 8 to 10 business days after the
                  final approval of artwork.
                </p>
              </Collapsible>
              <Collapsible trigger="Do you assist in designing custom boxes?">
                <p>
                  Yes, we do have a dedicated design team who will assist you
                  with the design you desire. You will have to email us at{" "}
                  <a href="mailto:info@piopackaging.com">
                    info@piopackaging.com
                  </a>{" "}
                  for rates etc.
                </p>
              </Collapsible>
              <Collapsible trigger="Where would the questions about my order be answered?">
                <p>
                  We assure you of the availability of our team to solve all
                  questions related to your order. In addition, our customer
                  consulting services allow you to discuss the design, style,
                  and correction of your custom boxes. You can email us at{" "}
                  <a href="mailto:info@piopackaging.com">
                    info@piopackaging.com
                  </a>{" "}
                  or call us directly on +91 96770 27016 between 9.00am to
                  6.00pm on Monday to Saturday (except public holidays){" "}
                </p>
              </Collapsible>
              <Collapsible trigger="What form are the folding boxes shipped in?">
                <p>
                  All boxes are shipped flat. Can be formed to shape easily in
                  10 to 30 seconds.
                </p>
              </Collapsible>
              <Collapsible trigger="What is a Die Cut Line (DCL) or Key Line Design (KLD)?">
                <p>
                  A die-cut line or Key Line Design serves as a packaging
                  template that ensures a proper layout for a printed item. This
                  is a diagram that shows all the cut lines and folds of a
                  package in flattened form..{" "}
                </p>
              </Collapsible>
              <Collapsible trigger="Can you print inside the box">
                <p>
                  Yes, we also print inside the boxes. You will have to make a
                  special request by mail before you place an order and the
                  additional charges will be conveyed to you.
                </p>
              </Collapsible>
              <Collapsible trigger="How can I talk to a Customer Service Agent?">
                <p>
                  You can contact us through mobile or WhatsApp on +91
                  9677027016 or 9840327012 or email us to{" "}
                  <a href="mailto:info@piopackaging.com">
                    info@piopackaging.com
                  </a>{" "}
                  you will get an instant reply within 2 hours during weekdays
                  (Monday to Saturday) 9.00 am to 6.00 pm. If you mail us or
                  send us a message on Sundays or holidays or after office
                  hours, you will get a reply on the next working day.
                </p>
              </Collapsible>
              <Collapsible trigger="Once I Place My Order, How Do I Track It?">
                <p>
                  Once you have placed your order, you will receive an email
                  with all the details regarding the stage of production and
                  date of dispatch. Once the goods are handed over to the
                  carriers you will automatically receive a tracking number from
                  the carriers.
                </p>
              </Collapsible>
              <Collapsible trigger="Do You Offer Box Styles That Are Not Listed on Your Website?">
                <p>
                  Since we deal in both commercial and custom printing and
                  packaging, we can tailor-make any type of box for your
                  business. Kindly email us to{" "}
                  <a href="mailto:info@piopackaging.com">
                    info@piopackaging.com
                  </a>{" "}
                  asking for details and by sharing your artwork / images.
                </p>
              </Collapsible>
              <Collapsible trigger="How to measure a box size?">
                <p>
                  <img src={FAQImage} /> <br />
                  To measure a box, you will need to use a ruler or measuring
                  tape to measure the length, width and height of the box.
                  Kindly mention the size in Centimeters or Millimeters as
                  LxWxH.
                  <ul style={{ listStyle: "auto", paddingLeft: "30px" }}>
                    <li>
                      The first dimension to measure is length. Length is always
                      the longest side of the box that has a flap.
                    </li>
                    <li>
                      The next dimension is width. The width side is always the
                      side shorter than the length.
                    </li>
                    <li>
                      Lastly measure the height of the package. Measure the
                      standing side of the box from top to bottom.
                    </li>
                  </ul>
                  <b>NOTE:</b> Acceptable manufacturing variance is +/- 2mm
                </p>
              </Collapsible>
              <Collapsible trigger="Can I cancel the order after placing order and making payment?">
                <p>
                  You may cancel your order within 24hrs of placing the order.
                  After 24hrs your order will go to production department and
                  you will not be able to cancel it. You can cancel by sending
                  mail to info@piopackaging.com by mentioning the Order number
                  in the Subject along with the word CANCEL.
                </p>
              </Collapsible>
              <Collapsible trigger="Can I return the goods after receiving them?">
                <p>
                  All our products are custom made for you with your branding,
                  because of that we do not allow returns. If you feel that the
                  product you received does not match up to what we have
                  committed please contact us. We want to make sure all our
                  clients are happy with their products and we will do our best
                  to provide you with a solution.
                </p>
              </Collapsible>
              <Collapsible
                trigger="Can you guarantee Colour Accuracy?"
                className="mb-5"
              >
                <p>
                  Our prime focus is to deliver colours as accurately as shown
                  on our products on our website, but due to certain conditions
                  like ink density, paper texture and weather conditions, minor
                  differences may occur ranging from 5 to 10%. Also note colours
                  appear differently on different monitors unless they are
                  colour calibrated for Graphic design in CMYK. We recommend
                  that you view our website, our soft copy proofs and your
                  designs on a Apple computer. This is the reason why our
                  products, once ordered, can’t be returned for slight colour
                  variations (if any).
                </p>
              </Collapsible>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Faq;
