import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Notiflix from "notiflix";
import PostApiCall from "../components/helper/Api";
import GetApiCall from "../components/helper/GETAPI";

import "./Address.css"
import Footer from '../Footer'
import ShopHeader from '../Header'
import { Link } from "react-router-dom";

export class EditAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Name: null,
            Title: null,
            Mobile: null,
            Area: null,
            FlatNo: null,
            Landmark: null,
            CountryData: [],
            CityData: [],
            StateData: [],
            CountryId: 0,
            StateId: 0,
            CityId: 0,
            Country: null,
            State: null,
            City: null,
            Pincode: null,
            AddressId: null,
            NumRegex: /^[0-9]*$/,
            Email: null,
            GSTIN: null,
            Companyname: null
        }
    }

    componentDidMount() {



        const addressData = JSON.parse(localStorage.getItem("AddressData"))

        this.setState({
            Name: addressData.fld_contact_name,
            Title: addressData.fld_address_type,
            Mobile: addressData.fld_contact_number,
            Area: addressData.fld_address_2,
            FlatNo: addressData.fld_address_1,
            Landmark: addressData.fld_landmark,
            Country: addressData.CountryName,
            State: addressData.StateName,
            City: addressData.CityName,
            Pincode: addressData.fld_pincode,
            Companyname: addressData.fld_company_name,
            GSTIN: addressData.fld_gstin,
            Email: addressData.fld_email_address,

        })


        // navigator.geolocation.getCurrentPosition((position) => {
        //     var Latitude = position.coords.latitude
        //     var Longitude = position.coords.longitude
        //     this.setState({
        //         Latitude: Latitude,
        //         Longitude: Longitude,
        //     })



        // });







        GetApiCall.getRequest("GetCountry").then(resultdes =>
            resultdes.json().then(obj => {

                this.setState({
                    CountryData: obj.data,

                })

                if (obj.data.length != 0) {

                    this.setState({
                        CountryId: obj.data.filter(
                            (value) => value.label == addressData.CountryName
                        )[0].value,
                        Country: obj.data[152].value,
                    });
                }
                PostApiCall.postRequest({

                    countryid: obj.data.filter(
                        (value) => value.label == addressData.CountryName
                    )[0].value,

                }, "GetState").then((results) =>
                    results.json().then(objstate => {


                        if (results.status == 200 || results.status == 201) {


                            if (objstate.data.length != 0) {
                                this.setState({
                                    StateId: objstate.data.filter(
                                        (value) => value.label == addressData.StateName
                                    )[0].value,

                                    StateData: objstate.data,
                                    State: objstate.data[0].value

                                })
                            }

                            PostApiCall.postRequest({

                                stateid: objstate.data.filter(
                                    (value) => value.label == addressData.StateName
                                )[0].value,

                            }, "GetCity").then((resultscity) =>
                                resultscity.json().then(objcity => {


                                    if (resultscity.status == 200 || resultscity.status == 201) {

                                        if (objcity.data.length != 0) {
                                            this.setState({
                                                CityId: objcity.data.filter(
                                                    (value) => value.label == addressData.CityName
                                                )[0].value,
                                                CityData: objcity.data,
                                                City: objcity.data[0].label

                                            })
                                        }

                                    }
                                }))

                        }
                    }))




            })
        );
    }
    onChangeCountry(country) {

        this.setState({
            CountryId: country.target.value,
            Country: this.state.CountryData[parseInt(country.target.value) + 1].label
        })



        PostApiCall.postRequest(
            {
                countryid: country.target.value,
                Country: this.state.CountryData[parseInt(country.target.value) + 1].label
            },
            "GetState"
        ).then(results =>

            results.json().then(obj => {
                if (results.status == 200 || results.status == 201) {
                    this.setState({
                        StateId: obj.data[0].value,
                        State: obj.data[0].label
                    })

                    PostApiCall.postRequest(
                        {
                            stateid: obj.data[0].value
                        },
                        "GetCity"
                    ).then(resultscity =>

                        resultscity.json().then(objcity => {
                            if (resultscity.status == 200 || resultscity.status == 201) {

                                this.setState({
                                    CityData: objcity.data,
                                    StateData: obj.data
                                })
                                if (objcity.data.length > 0) {

                                    this.setState({

                                        CityId: objcity.data[0].value,
                                        City: objcity.data[0].label
                                    })
                                }


                            }
                        })
                    );

                }
            })
        );
    }

    onChangeState(state) {
        this.setState({
            StateId: state.target.value
        })



        for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {

            if (this.state.StateData[i].value == state.target.value) {
                this.setState({
                    State: this.state.StateData[i].label
                })
            }
        }

        PostApiCall.postRequest(
            {
                stateid: state.target.value
            },
            "GetCity"
        ).then(results =>
            results.json().then(obj => {
                if (results.status == 200 || results.status == 201) {

                    if (obj.data.length > 0) {
                        this.setState({
                            CityData: obj.data,
                            CityId: obj.data[0].value,
                            City: obj.data[0].label
                        })
                    }

                }
            })
        );
    }

    onChangeCity(city) {
        this.setState({
            CityId: city.target.value
        })

        for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {

            if (this.state.CityData[i].value == city.target.value) {
                this.setState({
                    City: this.state.CityData[i].label
                })
            }
        }
    }

    onSaveData() {
        if (this.state.Companyname != null) {
            if (this.state.Name != "") {
                if (this.state.Mobile != "") {
                    if (this.state.Mobile.length == 10) {

                        if (this.state.Area != "") {
                            if (this.state.Country != "") {
                                if (this.state.State != "") {
                                    if (this.state.City != "") {
                                        if (this.state.Pincode != "") {
                                            if (this.state.Pincode.length == 6) {
                                                this.onPost()
                                            } else {
                                                Notiflix.Notify.failure(
                                                    "Please enter valid pincode"
                                                );
                                            }
                                        } else {
                                            Notiflix.Notify.failure("Please enter pincode");
                                        }
                                    } else {
                                        Notiflix.Notify.failure("Please enter city");
                                    }
                                } else {
                                    Notiflix.Notify.failure("Please enter state");
                                }
                            } else {
                                Notiflix.Notify.failure("Please select country");
                            }
                        } else {
                            Notiflix.Notify.failure("Please enter your address");
                        }

                    } else {
                        Notiflix.Notify.failure("Please enter valid mobile number");
                    }
                } else {
                    Notiflix.Notify.failure("Please enter mobile number");
                }
            } else {
                Notiflix.Notify.failure("Please enter your Full Name");
            }
        } else {
            Notiflix.Notify.failure("Please enter company name");
        }

    }

    onPost() {


        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);
        var addressData = JSON.parse(localStorage.getItem("AddressData"))
        //console.log(addressData.fld_address_id);

        PostApiCall.postRequest({
            CUSTOMERID: logindetails[0].fld_customerid,
            addressid: addressData.fld_address_id,
            title: this.state.Title,
            name: this.state.Name,
            mobileNo: this.state.Mobile,
            area: this.state.Area,
            gstin: this.state.GSTIN,
            email: this.state.Email,
            country: this.state.CountryId,
            state: this.state.StateId,
            city: this.state.CityId,
            pincode: this.state.Pincode,
            action: 'Update',
            companyname: this.state.Companyname

        },
            "Add_CustomerAddress"
        ).then((result) =>
            result.json().then((obj) => {

                if (result.status == 200 || result.status == 201) {
                    Notiflix.Notify.success("Address Successfully Updated");
                    localStorage.removeItem("AddressData")
                    // this.props.history.goBack()
                    window.location.href = "/customerprofile"
                } else {
                    Notiflix.Notify.failure(obj.data);
                }
            }))
    }

    render() {
        //         var history = require('browser-history')
        //         // history('/')
        // function goBack(e){
        //     history(-1)
        //     console.log(e)
        // }
        return (
            <React.Fragment>
                <ShopHeader />
                <section style={{ paddingTop: '40px' }}>
                    <Container fluid style={{ backgroundColor: "#F5F5F5", paddingLeft: "none" }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <Card className="mt-3">
                                        <CardBody>
                                            <CardTitle className="sidebar-heading">
                                                <h5>Add or Edit Address</h5>
                                                <div class="title-divider"></div>
                                            </CardTitle>
                                            <form >
                                                <div class="col-12">
                                                    <div class="row">

                                                        <div class="col-md-6  mb-3">
                                                            <label for="validationCustom01">
                                                                Company Name<span class="mandatory">*</span>
                                                            </label>
                                                            <br />
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                value={this.state.Companyname}
                                                                onChange={(text) => {
                                                                    this.setState({
                                                                        Companyname: text.target.value,
                                                                    });
                                                                }}
                                                            />

                                                        </div>
                                                        <div class="col-md-6  mb-3">
                                                            <label for="validationCustom01">
                                                                Full Name<span class="mandatory">*</span>
                                                            </label>
                                                            <br />
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                value={this.state.Name}
                                                                onChange={(text) => {
                                                                    this.setState({
                                                                        Name: text.target.value,
                                                                    });
                                                                }}
                                                            />

                                                        </div>
                                                        <div class="col-md-6  mb-3">
                                                            <label for="validationCustom01">
                                                                Mobile Number<span class="mandatory">*</span>
                                                            </label>
                                                            <br />
                                                            <input
                                                                type="text"
                                                                id="CategoryName"
                                                                className="form-control"
                                                                value={this.state.Mobile}
                                                                onChange={(mobile) => {
                                                                    if (this.state.NumRegex.test(mobile.target.value) && (mobile.target.value.length <= 10)) {
                                                                        this.setState({
                                                                            Mobile:
                                                                                mobile.target.value,
                                                                        });
                                                                    }

                                                                }}
                                                            />

                                                        </div>
                                                        <div class="col-md-6  mb-3">
                                                            <label for="validationCustom01">
                                                                Email
                                                            </label>
                                                            <br />
                                                            <input
                                                                type="text"
                                                                id="CategoryName"
                                                                className="form-control"
                                                                value={this.state.Email}
                                                                onChange={(email) => {
                                                                    this.setState({
                                                                        Email: email.target.value,
                                                                    });


                                                                }}
                                                            />

                                                        </div>
                                                        <div class="col-lg-12 md-12 mb-3">
                                                            <label for="validationCustom01">
                                                                Address<span class="mandatory">*</span>
                                                            </label><br />
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                value={this.state.Area}
                                                                onChange={(text) => {
                                                                    this.setState({
                                                                        Area: text.target.value,
                                                                    });
                                                                }}
                                                            />

                                                        </div>


                                                        <div class="col-md-3 mb-3">
                                                            <label for="validationCustom01">
                                                                Country<span class="mandatory">*</span>
                                                            </label>
                                                            <select
                                                                class="custom-select my-1 mr-sm-2"
                                                                id="inlineFormCustomSelectPref"
                                                                name="UserType"
                                                                value={this.state.CountryId}
                                                                onChange={this.onChangeCountry.bind(
                                                                    this
                                                                )}
                                                            >{this.state.ShippingCountryData == [] ? "" :
                                                                this.state.CountryData.filter((data) => data.value == 101).map(
                                                                    (schedule) => (
                                                                        <option
                                                                            key={schedule.label}
                                                                            value={schedule.value}
                                                                        >
                                                                            {schedule.label}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>

                                                        <div class="col-md-3 mb-3">
                                                            <label for="validationCustom01">
                                                                State<span class="mandatory">*</span>
                                                            </label><br />
                                                            <select
                                                                class="custom-select my-1 mr-sm-2"
                                                                id="inlineFormCustomSelectPref"
                                                                name="UserType"
                                                                value={this.state.StateId}
                                                                onChange={this.onChangeState.bind(
                                                                    this
                                                                )}
                                                            >
                                                                {this.state.StateData.map(
                                                                    (schedule) => (
                                                                        <option
                                                                            key={schedule.label}
                                                                            value={schedule.value}
                                                                        >
                                                                            {schedule.label}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>

                                                        </div>
                                                        <div class="col-md-3 mb-3">
                                                            <label for="validationCustom01">
                                                                City<span class="mandatory">*</span>
                                                            </label>
                                                            <select
                                                                class="custom-select my-1 mr-sm-2"
                                                                id="inlineFormCustomSelectPref"
                                                                name="UserType"
                                                                value={this.state.CityId}
                                                                onChange={this.onChangeCity.bind(
                                                                    this
                                                                )}
                                                            >
                                                                {this.state.CityData.map(
                                                                    (schedule) => (
                                                                        <option
                                                                            key={schedule.label}
                                                                            value={schedule.value}
                                                                        >
                                                                            {schedule.label}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>

                                                        </div>
                                                        <div class="col-md-3 mb-3">
                                                            <label for="validationCustom01">
                                                                Pincode<span class="mandatory">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="CategoryName"
                                                                className="form-control my-1 mr-sm-2"
                                                                value={this.state.Pincode}
                                                                onChange={(pincode) => {
                                                                    if (pincode.target.value.length <= 6) {
                                                                        this.setState({
                                                                            Pincode:
                                                                                pincode.target.value,
                                                                        });
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                        <div class="col-md-3 mb-3">
                                                            <label for="validationCustom01">
                                                                GSTIN
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="CategoryName"
                                                                className="form-control my-1 mr-sm-2"
                                                                value={this.state.GSTIN}
                                                                onChange={(GSTIN) => {
                                                                    this.setState({
                                                                        GSTIN:
                                                                            GSTIN.target.value,
                                                                    });

                                                                }}
                                                            />

                                                        </div>
                                                        <div className="col-12">
                                                            <button
                                                                type="button"
                                                                style={{ float: "right", marginTop: "5px", marginLeft: "5px" }}
                                                                className="btn align-items-center globalTrendzEcomm-btn "
                                                                onClick={

                                                                    this.onSaveData.bind(this)}
                                                            >
                                                                Update Address  {"  "}{" "}
                                                                <i class="fa fa-bookmark" aria-hidden="true"></i>
                                                            </button>
                                                            <Link to="/customerprofile">
                                                                <button
                                                                    type="button"
                                                                    style={{ float: "right", marginTop: "5px" }}
                                                                    className="btn align-items-center globalTrendzEcomm-btn "
                                                                // onClick={() => {
                                                                //     goBack()
                                                                // }} 
                                                                >

                                                                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                                                    {"  "}{" "}   Go Back
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </CardBody>
                                    </Card>

                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

export default EditAddress;