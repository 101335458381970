import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Notiflix from "notiflix";
import PostApiCall from "../components/helper/Api";
import GetApiCall from "../components/helper/GETAPI";

import "./Address.css"
import Footer from '../Footer'
import ShopHeader from '../Pages/ShopHeader/ShopHeader'
import { Link } from "react-router-dom";

export class AddressBookForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: null,
      Title: null,
      Mobile: "",
      Area: null,
      FlatNo: null,
      Landmark: null,
      CountryData: [],
      CityData: [],
      StateData: [],
      CountryId: 0,
      StateId: 0,
      CityId: 0,
      Country: null,
      State: null,
      City: null,
      Pincode: null,
      AddressId: null,
      NumRegex: /^[0-9]*$/,
      Companyname: null,
      Email: null,
    }
  }

  componentDidMount() {
    //

    navigator.geolocation.getCurrentPosition((position) => {
      var Latitude = position.coords.latitude
      var Longitude = position.coords.longitude
      this.setState({
        Latitude: Latitude,
        Longitude: Longitude,
      })


      //console.log("Latitude is :", Latitude);
      //console.log("Longitude is :", Longitude);
    });





    GetApiCall.getRequest("GetCountry").then(resultdes =>
      resultdes.json().then(obj => {

        this.setState({
          CountryData: obj.data,

        })

        if (obj.data.length != 0) {
          // this.props.setcountry(obj.data[100].label)

          this.setState({
            CountryId: 101,
            Country: obj.data[152].label

          })
        }
        PostApiCall.postRequest({

          countryid: 101,

        }, "GetState").then((results) =>

          // const objs = JSON.parse(result._bodyText)
          results.json().then(objstate => {


            if (results.status == 200 || results.status == 201) {


              if (objstate.data.length != 0) {
                // this.props.setstate()

                this.setState({
                  StateId: objstate.data[0].value,

                  StateData: objstate.data,
                  State: objstate.data[0].label

                })
              }

              // console.log(objstate.data[0].value)
              PostApiCall.postRequest({

                stateid: objstate.data[0].value,

              }, "GetCity").then((resultscity) =>

                // const objs = JSON.parse(result._bodyText)
                resultscity.json().then(objcity => {


                  if (resultscity.status == 200 || resultscity.status == 201) {

                    // console.log(objcity.data[0].value)
                    if (objcity.data.length != 0) {
                      // this.props.setcity(objcity.data[0].label)
                      this.setState({
                        CityId: objcity.data[0].value,
                        CityData: objcity.data,
                        City: objcity.data[0].label

                      })
                    }

                  }
                }))

            }
          }))




      })
    );
  }
  onChangeCountry(country) {

    // console.log(typeof(country.target.value));


    //  console.log(this.state.CountryData[parseInt(country.target.value) +1])
    this.setState({
      CountryId: country.target.value,
      Country: this.state.CountryData[parseInt(country.target.value) + 1].label
    })
    //  this.props.setcountry(this.state.CountryData[country.target.value - 1].label);



    PostApiCall.postRequest(
      {
        countryid: country.target.value,
        Country: this.state.CountryData[parseInt(country.target.value) + 1].label
      },
      "GetState"
    ).then(results =>

      results.json().then(obj => {
        if (results.status == 200 || results.status == 201) {

          //  this.props.setstate(obj.data[0].label)
          // console.log(obj);
          this.setState({
            StateId: obj.data[0].value,
            State: obj.data[0].label
          })

          PostApiCall.postRequest(
            {
              stateid: obj.data[0].value
            },
            "GetCity"
          ).then(resultscity =>

            resultscity.json().then(objcity => {
              if (resultscity.status == 200 || resultscity.status == 201) {

                this.setState({
                  CityData: objcity.data,
                  StateData: obj.data
                })
                if (objcity.data.length > 0) {
                  // this.props.setcity(objcity.data[0].label)

                  this.setState({

                    CityId: objcity.data[0].value,
                    City: objcity.data[0].label
                  })
                }


              }
            })
          );

        }
      })
    );
  }

  onChangeState(state) {
    //  this.props.setstate(state.target.value)
    this.setState({
      StateId: state.target.value
    })



    for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {

      if (this.state.StateData[i].value == state.target.value) {

        // this.props.setstate();
        this.setState({
          State: this.state.StateData[i].label
        })
      }
    }

    PostApiCall.postRequest(
      {
        stateid: state.target.value
      },
      "GetCity"
    ).then(results =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then(obj => {
        if (results.status == 200 || results.status == 201) {

          if (obj.data.length > 0) {
            // this.props.setcity(obj.data[0].label)
            this.setState({
              CityData: obj.data,
              CityId: obj.data[0].value,
              City: obj.data[0].label
            })
          }
          // this.props.cityData(obj.data)

        }
      })
    );
  }

  onChangeCity(city) {
    // this.props.setcity(city.target.value)
    this.setState({
      CityId: city.target.value
    })

    for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {

      if (this.state.CityData[i].value == city.target.value) {
        this.setState({
          City: this.state.CityData[i].label
        })
        // this.props.setcity(this.state.CityData[i].label);
      }
    }
  }

  onSaveData() {
    if (this.state.Title != null) {
      if (this.state.Name != null) {
        if (this.state.Mobile != "") {
          if (this.state.Mobile.length == 10) {
            if (this.state.FlatNo != null) {
              if (this.state.Area != null) {
                if (this.state.Country != null) {
                  if (this.state.State != null) {
                    if (this.state.City != null) {
                      if (this.state.Pincode != null) {
                        if (this.state.Pincode.length == 6) {
                          this.onPost()
                        } else {
                          Notiflix.Notify.failure(
                            "Please enter valid pincode"
                          );
                        }
                      } else {
                        Notiflix.Notify.failure("Please enter pincode");
                      }
                    } else {
                      Notiflix.Notify.failure("Please enter city");
                    }
                  } else {
                    Notiflix.Notify.failure("Please enter state");
                  }
                } else {
                  Notiflix.Notify.failure("Please select country");
                }
              } else {
                Notiflix.Notify.failure("Please enter Area/Colony/Street/Sector/Village");
              }
            } else {
              Notiflix.Notify.failure("Please enter Flat/House/Company/Building/Apartment No.");
            }
          } else {
            Notiflix.Notify.failure("Please enter valid mobile number");
          }
        } else {
          Notiflix.Notify.failure("Please enter mobile number");
        }
      } else {
        Notiflix.Notify.failure("Please enter your Full Name");
      }
    } else {
      Notiflix.Notify.failure("Please select Address Title");
    }
  }

  onPost() {


    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    PostApiCall.postRequest({
      CUSTOMERID: logindetails[0].fld_customerid,
      addressid: null,
      title: this.state.Title,
      name: this.state.Name,
      mobileNo: this.state.Mobile,
      area: this.state.Area,
      flatno: this.state.FlatNo,
      landmark: this.state.Landmark,
      country: this.state.CountryId,
      state: this.state.StateId,
      city: this.state.CityId,
      pincode: this.state.Pincode,
      gstin: null,
      email: this.state.Email,
      action: 'INSERT',
      longitude: this.state.Longitude,
      latitude: this.state.Latitude,
      companyname: this.state.Companyname
    },
      "Add_CustomerAddress"
    ).then((result) =>
      result.json().then((obj) => {

        if (result.status == 200 || result.status == 201) {
          Notiflix.Notify.success("Address Successfully Added");
          // this.props.history.goBack();
          window.location.href = "/customerprofile"
        } else {
          Notiflix.Notify.failure(obj.data);
        }
      }))
  }

  render() {
    return (
      <React.Fragment>
        {/* <ShopHeader /> */}
        <div className="page-content">
          <Container fluid style={{ backgroundColor: "#F5F5F5", paddingLeft: "none" }}>

            <div className="row">
              <div className="col-12">
                <div className="row">
                  <Link to="/customerprofile">
                    <button type="button" class="goback-button mt-3 ml-3"
                    // onClick={() => { this.props.history.goBack() }}
                    ><i class="fa fa-arrow-left" aria-hidden="true"></i>{" "}Go Back</button>
                  </Link>
                  <Card className="mt-3">
                    <CardBody>
                      <CardTitle className="sidebar-heading">
                        <h5>Add or Edit Address</h5>
                        <div class="title-divider"></div>
                      </CardTitle>
                      <form >
                        <div class="col-12">
                          <div class="row ">
                            <div class="col-md-12 mb-3 center">

                              <label for="validationCustom01">
                                Address Title<span class="mandatory">*</span>
                              </label>
                              <br />
                              <label class="radio-inline">
                                <input
                                  type="radio"
                                  name="optradio"
                                  checked={this.state.Title === "Office" ? true : false}
                                  onChange={() => {
                                    this.setState({
                                      Title: "Office",
                                    });
                                  }}
                                />{" "}
                                Office
                              </label>
                              <label
                                class="radio-inline"
                                style={{ marginLeft: "10px" }}
                              >
                                <input
                                  type="radio"
                                  name="optradio"
                                  checked={
                                    this.state.Title === "Home" ? true : false
                                  }
                                  onChange={() => {
                                    this.setState({
                                      Title: "Home",
                                    });
                                  }}
                                />{" "}
                                Home
                              </label>

                            </div>

                            <div class="col-md-6  mb-3">
                              <label for="validationCustom01">
                                Company Name<span class="mandatory">*</span>
                              </label>
                              <br />
                              <input
                                type="text"
                                class="form-control"
                                value={this.state.Companyname}
                                onChange={(text) => {
                                  this.setState({
                                    Companyname: text.target.value,
                                  });
                                }}
                              />

                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="validationCustom01">
                                Full Name<span class="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={this.state.Name}
                                onChange={(text) => {
                                  this.setState({
                                    Name: text.target.value,
                                  });
                                }}
                              />

                            </div>
                            <div class="col-md-6 mb-3 ">

                              <label for="validationCustom01">
                                Mobile Number<span class="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="CategoryName"
                                className="form-control"
                                value={this.state.Mobile}
                                onChange={(mobile) => {
                                  if (
                                    this.state.NumRegex.test(
                                      mobile.target.value
                                    ) &&
                                    mobile.target.value
                                      .length <= 10
                                  ) {

                                    this.setState({
                                      Mobile: mobile.target.value,
                                    });
                                  }
                                }}
                              />

                            </div>
                            <div class="col-md-6  mb-3">
                              <label for="validationCustom01">
                                Email
                              </label>
                              <br />
                              <input
                                type="text"
                                id="CategoryName"
                                className="form-control"
                                value={this.state.Email}
                                onChange={(email) => {
                                  this.setState({
                                    Email: email.target.value,
                                  });


                                }}
                              />

                            </div>
                            <div class="col-lg-4 md-12 mb-3">

                              <label for="validationCustom01">
                                Flat/House/Building No.<span class="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={this.state.FlatNo}
                                onChange={(text) => {
                                  this.setState({
                                    FlatNo: text.target.value,
                                  });
                                }}
                              />

                            </div>
                            <div class="col-lg-4 md-12 mb-3">

                              <label for="validationCustom01">
                                Area/Street/Sector/Village<span class="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={this.state.Area}
                                onChange={(text) => {
                                  this.setState({
                                    Area: text.target.value,
                                  });
                                }}
                              />

                            </div>
                            <div class="col-lg-4 md-12 mb-3 ">

                              <label for="validationCustom01">
                                Landmark
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={this.state.Landmark}
                                onChange={(text) => {
                                  this.setState({
                                    Landmark: text.target.value,
                                  });
                                }}
                              />

                            </div>
                            <div class="col-md-3 mb-3 ">

                              <label for="validationCustom01">
                                Country<span class="mandatory">*</span>
                              </label>
                              <select
                                class="custom-select my-1 mr-sm-2"
                                id="inlineFormCustomSelectPref"
                                name="UserType"
                                value={this.state.CountryId}
                              // onChange={this.onChangeCountry.bind(
                              //   this
                              // )}
                              >{this.state.ShippingCountryData == [] ? "" :
                                this.state.CountryData.filter((data) => data.value == 101).map(
                                  (schedule) => (
                                    <option
                                      key={schedule.label}
                                      value={schedule.value}
                                    >
                                      {schedule.label}
                                    </option>
                                  )
                                )}
                              </select>

                            </div>
                            <div class="col-md-3 mb-3">

                              <label for="validationCustom01">
                                State<span class="mandatory">*</span>
                              </label>
                              <select
                                class="custom-select my-1 mr-sm-2"
                                id="inlineFormCustomSelectPref"
                                name="UserType"
                                value={this.state.StateId}
                                onChange={this.onChangeState.bind(
                                  this
                                )}
                              >
                                {this.state.StateData.map(
                                  (schedule) => (
                                    <option
                                      key={schedule.label}
                                      value={schedule.value}
                                    >
                                      {schedule.label}
                                    </option>
                                  )
                                )}
                              </select>

                            </div>
                            <div class="col-md-3 mb-3">

                              <label for="validationCustom01">
                                City<span class="mandatory">*</span>
                              </label>
                              <select
                                class="custom-select my-1 mr-sm-2"
                                id="inlineFormCustomSelectPref"
                                name="UserType"
                                value={this.state.CityId}
                                onChange={this.onChangeCity.bind(
                                  this
                                )}
                              >
                                {this.state.CityData.map(
                                  (schedule) => (
                                    <option
                                      key={schedule.label}
                                      value={schedule.value}
                                    >
                                      {schedule.label}
                                    </option>
                                  )
                                )}
                              </select>

                            </div>
                            <div class="col-md-3 mb-3">

                              <label for="validationCustom01">
                                Pincode<span class="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="CategoryName"
                                className="form-control my-1 mr-sm-2"
                                value={this.state.Pincode}
                                onChange={(mobile) => {
                                  if (
                                    this.state.NumRegex.test(
                                      mobile.target.value
                                    ) &&
                                    mobile.target.value
                                      .length <= 6
                                  ) {
                                    this.setState({
                                      Pincode:
                                        mobile.target.value,
                                    });
                                  }
                                }}
                              />

                            </div>
                            <div className="col-12">
                              <button
                                type="button"
                                style={{ float: "right", marginTop: "5px" }}
                                className="btn align-items-center globalTrendzEcomm-btn "
                                onClick={

                                  this.onSaveData.bind(this)}
                              >
                                Save Address  {"  "}{" "}
                                <i class="fa fa-bookmark" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </CardBody>
                  </Card>

                </div>
              </div>
            </div>
          </Container>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default AddressBookForm;