import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
import PostApiCall from "../../components/helper/Api";
import Footer from "../../Footer";
import Header from "../../Header";
import "../../CustomerDashboard/Address.css";
import Plusicon from "../../CustomerDashboard/images/plus_sign.png";
import Notiflix from "notiflix";
import TabNavigation from "../../components/tabNavigation/tabNavigation";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CustomerData: [],
      AddressData: [],
      OrderData: [],
    };
  }

  componentDidMount() {
    //   this.props.setclearbrand()
    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);
    // console.log(this.AddressData.map.length)

    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customerid=${CustomerDetails[0].fld_customerid}`,
      },
      "Get_customerData_by_id"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        // console.log(obj.data);
        if (results.status == 200 || results.status == 201) {
          this.setState({
            CustomerData: obj.data,
          });
        }
      })
    );

    // ===============Address Data=================

    PostApiCall.postRequest(
      {
        whereClause: `where fld_customerid=${CustomerDetails[0].fld_customerid}`,
      },
      "Get_Customer_Address"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            AddressData: obj.data,
          });
        }
      })
    );

    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${CustomerDetails[0].fld_customerid}`,
      },
      "GetCustomerOrderDetails"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj.data);
          this.setState({
            OrderData: obj.data,
          });
        }
      })
    );
  }

  render() {
    // console.log(this.state.AddressData.length);
    return (
      <React.Fragment>
        <Header />
        <section style={{ paddingTop: "40px" }}>
          <Container fluid>
            <div className="row"></div>

            <Row className="mt-3">
              <Col xl="12">
                <Card className="overflow-hidden mt-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="overflow-hidden website-card">
                        <div
                          className="globalTrendzEcomm-bg-soft-pink"
                          style={{ height: "85px", background: "#59c5d1" }}
                        >
                          <h4 className="mb-0 productTitle"> Address Book</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <CardBody>
                    <div className="overflow-hidden">
                      <Row className="mt-3">
                        {/* {console.log(this.state.AddressData)} */}
                        <div class="col-lg-12 col-md-5 col-sm-2 mb-3">
                          <div className="row">
                            {this.state.AddressData.map((data, index) => {
                              return (
                                <>
                                  <div
                                    className="col-lg-4 col-md-6"
                                    style={{
                                      marginBottom: "26px",
                                      display:
                                        this.state.AddressData.length != 0
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <Card className="p-3 px-lg-4 address-card website-card">
                                      <div class="address-content">
                                        <h5>{data.fld_contact_name}</h5>
                                        <p>
                                          {data.fld_address_2}, <br />
                                          {data.CityName}, {data.StateName}{" "}
                                          <br /> {data.fld_pincode},{" "}
                                          {data.CountryName}
                                        </p>
                                        <p>{data.fld_landmark}</p>
                                        <h6>
                                          ContactNo.: {data.fld_contact_number}{" "}
                                        </h6>
                                        <h6>GSTIN : {data.fld_gstin} </h6>
                                      </div>
                                      <div class="row address-icon-row">
                                        <div class="col-6"></div>
                                        <div class="col-6">
                                          <a
                                            class="address-icon"
                                            onClick={() => {
                                              localStorage.setItem(
                                                "AddressData",
                                                JSON.stringify(data)
                                              );
                                              window.location.href =
                                                "/edit-address";
                                            }}
                                          >
                                            <i
                                              class="fas fa-edit"
                                              style={{
                                                float: "right",
                                                fontSize: "20px",
                                                cursor: "pointer",
                                              }}
                                            ></i>
                                          </a>
                                          <a
                                            class="address-icon"
                                            onClick={() => {
                                              const details = JSON.parse(
                                                localStorage.getItem(
                                                  "CustomerData"
                                                )
                                              );
                                              PostApiCall.postRequest(
                                                {
                                                  CUSTOMERID: 6,
                                                  addressid:
                                                    data.fld_address_id,
                                                  title: data.fld_address_type,
                                                  name: data.fld_contact_name,
                                                  mobileNo:
                                                    data.fld_contact_number,
                                                  area: data.fld_address_2,
                                                  flatno: data.fld_address_1,
                                                  landmark: data.fld_landmark,
                                                  country: data.Country,
                                                  state: data.State,
                                                  city: data.City,
                                                  pincode: data.fld_pincode,
                                                  action: "DELETE",
                                                },
                                                "Add_CustomerAddress"
                                              ).then((results) =>
                                                results.json().then((obj) => {
                                                  if (
                                                    results.status == 200 ||
                                                    results.status == 201
                                                  ) {
                                                    Notiflix.Notify.success(
                                                      "Address Successfully Delete."
                                                    );
                                                    window.location.reload();
                                                  }
                                                })
                                              );
                                            }}
                                          >
                                            <i
                                              class="fas fa-trash"
                                              style={{
                                                float: "right",
                                                fontSize: "20px",
                                                marginRight: "10%",
                                                cursor: "pointer",
                                              }}
                                            ></i>
                                          </a>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                </>
                              );
                            })}
                            <div class="col-lg-4 col-md-4 col-sm-2 mb-3">
                              <div class="d-flex align-items-center justify-content-center address-box-profile website-card">
                                <div class="address-plus ">
                                  <a class="address-icon">
                                    <img
                                      src={Plusicon}
                                      alt=""
                                      onClick={() => {
                                        window.location.href =
                                          "/address_book_form";
                                      }}
                                      style={{ width: "40%" }}
                                    ></img>
                                    {/* <i class="fa fa-plus-circle"
																					style={{
																						fontSize: '60px',
																					}}
																					
																				></i> */}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
        <TabNavigation />
      </React.Fragment>
    );
  }
}

export default Profile;
