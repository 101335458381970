/* eslint-disable no-loop-func */
import React from "react";
import Footer from "../../Footer";
import Header from "../../Header";

import FailPayment from '../../assets/image/Payment_unsuccessfull.png';

class PaymentFail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    componentDidMount() {


        localStorage.removeItem('AccessAdmin')
        localStorage.removeItem('WeightData')
        localStorage.removeItem('OldPassword')
        localStorage.removeItem('OrderDetails')
        localStorage.removeItem('LoginDetail')
        //console.log(this.props.match.params.txnid)


    }

    render() {
        // //console.log(this.props.match.params.txnid)
        return (
            <div>
                <Header></Header>
                <div class="container">

                    <div class="row marginbtm-240">



                        <div class="col-md-12">

                            <div class="privacy-box" style={{
                                background: ' #fff',
                                padding: '15px', fontWeight: '700',

                                color: '#6b6b6b',

                                letterSpacing: '.01rem',

                                textAlign: 'justify',
                                marginTop: '80px'
                            }}>
                                <div class="payment-box mt-2 mb-4">
                                    <img src={FailPayment}
                                        class="paymentFail img-fluid" alt="payment failed" height="550px" width="550px"></img>

                                </div>
                                <div class="center-block" style={{ display: "flex", justifyContent: "center", marginBottom: "60px" }}>
                                    <a href="/ordercustomboxes" class="btn btn-primary" style={{
                                        background: '#59c5d1',
                                        color: '#fff',
                                        marginRight: '4px',
                                        border: '1px solid #59c5d1'

                                    }}> Continue Shopping</a>
                                    <a href="/" class="btn btn-primary" style={{
                                        background: '#59c5d1',
                                        color: '#fff',
                                        border: '1px solid #59c5d1'
                                    }}>Go to Home Page</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default PaymentFail;
