import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import PostApiCall from "../../components/helper/Api";
import Footer from "../../Footer";
import ShopHeader from "../ShopHeader/ShopHeader";

class ShopCart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loginData: [],
            ProductCart: [],
        }
    }

    componentDidMount() {
        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);

        if (logindetails != null) {
            this.setState({
                loginData: logindetails,
            })
        }



        Notiflix.Loading.init({
            svgColor: "#ee1b2f",
        });
        this.setState({
            StateId: localStorage.getItem('ChangedPincode')

        })

        this.getCartdetails()
    }

    getCartdetails = () => {



        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);
        //console.log(logindetails)

        var Order = localStorage.getItem("OrderData");
        var Orderdetails = JSON.parse(Order);


        // console.log('cart')
        PostApiCall.postRequest(
            {
                orderid:
                    localStorage.getItem("OrderData") == null
                        ? null
                        : Orderdetails[0].order_id,
                id:
                    localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0].fld_customerid,
                stateid: this.state.StateId,
            },
            "GetCartWebsite"
        )
            .then((res) => res.json())
            .then((res1) => {
                if (res1.data) {
                    Notiflix.Loading.remove();


                    this.setState({
                        ProductCart: res1.data,
                    });


                }
            });
    };

    render() {
        return (
            <React.Fragment>
                <ShopHeader />
                <section>
                    <div class="container-fluid section-padding">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="section-title mt-5">
                                    <h4>My Cart</h4>
                                </div>
                            </div>
                        </div>

                        <div class="cart-box-section py-3 mb-5">
                            <div class="row">
                                {this.state.ProductCart.length != 0 ?
                                    <div class="col-md-8">
                                        <div class="cart-header section">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <h5>Product</h5>
                                                </div>
                                                <div class="col-md-4 ">
                                                    <h5></h5>
                                                </div>
                                                <div class="col-md-2 ">
                                                    <h5>Price</h5>
                                                </div>
                                                <div class="col-md-2">
                                                    <h5>Quantity</h5>
                                                </div>
                                                <div class="col-md-2">
                                                    <h5>Subtotal</h5>
                                                </div>

                                            </div>
                                        </div>
                                        {this.state.ProductCart.map((product, i) => (
                                            <div class="cart-body">

                                                <div class="row">

                                                    <div class="col-md-2">
                                                        <a href={`${process.env.PUBLIC_URL}/productdetails/${product.fld_categoryid}/${product.fld_categoryname}/${product.fld_variantid}`}>
                                                            <img
                                                                src={product.VariantImage}
                                                                class="cart-product-img"
                                                                width={"100%"}
                                                                alt=""
                                                            ></img>
                                                        </a>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <p>{product.fld_categoryname}</p>
                                                        <a href={`${process.env.PUBLIC_URL}/productdetails/${product.fld_categoryid}/${product.fld_categoryname}/${product.fld_variantid}`}>
                                                            <h5 class="cart-product-title">
                                                                {product.fld_variantname}
                                                            </h5>
                                                        </a>


                                                        <div class="cart-offer-box">You Save ₹ {parseFloat(product.you_save_amount).toFixed(2)} ({product.you_save_per}%)</div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <h5 class="cart-price">
                                                            <i class="fa fa-inr" aria-hidden="true"></i> {parseFloat(product.fld_selling_price).toFixed(2)}{" "}
                                                        </h5>
                                                    </div>

                                                    <div class="col-md-2">
                                                        <div class="quantity-selector detail-info-entry" style={{ display: "flex" }}>
                                                            <div class="entry number-minus" onClick={() => {
                                                                var login =
                                                                    localStorage.getItem(
                                                                        "CustomerData"
                                                                    );
                                                                var logindetails = JSON.parse(login);

                                                                var Order =
                                                                    localStorage.getItem("OrderData");
                                                                var Orderdetails = JSON.parse(Order);

                                                                if (product.fld_quantity > 1) {
                                                                    Notiflix.Loading.dots(
                                                                        "Please wait..."
                                                                    );
                                                                    PostApiCall.postRequest(
                                                                        {
                                                                            orderdate:
                                                                                moment().format("lll"),
                                                                            itemdetails: `
                                         [
                                           {
                                             "Item":${product.fld_variantid},
                                             "Quantity":${-1}
                                           }
                                         ]
                                         `,
                                                                            status: "INCART",
                                                                            customerid:
                                                                                localStorage.getItem(
                                                                                    "CustomerData"
                                                                                ) == null
                                                                                    ? null
                                                                                    : logindetails[0]
                                                                                        .fld_customerid,
                                                                            createdon:
                                                                                moment().format("lll"),
                                                                            updatedon:
                                                                                moment().format("lll"),
                                                                            orderid:
                                                                                localStorage.getItem(
                                                                                    "OrderData"
                                                                                ) == null
                                                                                    ? 0
                                                                                    : Orderdetails[0].order_id,

                                                                            updatedby:
                                                                                localStorage.getItem(
                                                                                    "CustomerData"
                                                                                ) == null
                                                                                    ? null
                                                                                    : logindetails[0]
                                                                                        .fld_userid,
                                                                        },
                                                                        "AddToCartWebsite"
                                                                    ).then((result) =>
                                                                        result.json().then((obj3) => {
                                                                            // console.log(obj3.data);

                                                                            if (
                                                                                result.status == 200 ||
                                                                                result.status == 201
                                                                            ) {
                                                                                Notiflix.Loading.remove();
                                                                                Notiflix.Notify.success(
                                                                                    "Product quantity updated."
                                                                                );

                                                                                if (
                                                                                    localStorage.getItem(
                                                                                        "OrderData"
                                                                                    ) == null
                                                                                ) {
                                                                                    // console.log('datastore')
                                                                                    localStorage.setItem(
                                                                                        "OrderData",
                                                                                        JSON.stringify(obj3.data)
                                                                                    );
                                                                                }
                                                                                this.getCartdetails();
                                                                            } else {
                                                                                Notiflix.Loading.remove();
                                                                                Notiflix.Notify.failure(
                                                                                    obj3.data
                                                                                );
                                                                            }
                                                                        })
                                                                    );
                                                                }
                                                            }}>&nbsp;</div>
                                                            <div class="entry number">{product.fld_quantity}</div>
                                                            <div class="entry number-plus" onClick={() => {
                                                                var login =
                                                                    localStorage.getItem(
                                                                        "CustomerData"
                                                                    );
                                                                var logindetails = JSON.parse(login);

                                                                var Order =
                                                                    localStorage.getItem("OrderData");
                                                                var Orderdetails = JSON.parse(Order);

                                                                Notiflix.Loading.dots(
                                                                    "Please wait..."
                                                                );
                                                                PostApiCall.postRequest(
                                                                    {
                                                                        orderdate: moment().format("lll"),
                                                                        itemdetails: `
                                                         [
                                                           {
                                                             "Item":${product.fld_variantid
                                                                            },
                                                             "Quantity":${+1}
                                                           }
                                                         ]
                                                         `,
                                                                        status: "INCART",
                                                                        customerid:
                                                                            localStorage.getItem(
                                                                                "CustomerData"
                                                                            ) == null
                                                                                ? null
                                                                                : logindetails[0]
                                                                                    .fld_customerid,
                                                                        createdon: moment().format("lll"),
                                                                        updatedon: moment().format("lll"),
                                                                        orderid:
                                                                            localStorage.getItem(
                                                                                "OrderData"
                                                                            ) == null
                                                                                ? 0
                                                                                : Orderdetails[0].order_id,

                                                                        updatedby:
                                                                            localStorage.getItem(
                                                                                "CustomerData"
                                                                            ) == null
                                                                                ? null
                                                                                : logindetails[0].fld_userid,
                                                                    },
                                                                    "AddToCartWebsite"
                                                                ).then((result) =>
                                                                    result.json().then((obj3) => {
                                                                        // console.log(obj3.data);

                                                                        if (
                                                                            result.status == 200 ||
                                                                            result.status == 201
                                                                        ) {
                                                                            Notiflix.Loading.remove();
                                                                            Notiflix.Notify.success(
                                                                                "Product quantity updated."
                                                                            );

                                                                            if (
                                                                                localStorage.getItem(
                                                                                    "OrderData"
                                                                                ) == null
                                                                            ) {
                                                                                // console.log('datastore')
                                                                                localStorage.setItem(
                                                                                    "OrderData",
                                                                                    JSON.stringify(obj3.data)
                                                                                );
                                                                            }
                                                                            this.getCartdetails();
                                                                        } else {
                                                                            Notiflix.Loading.remove();
                                                                            Notiflix.Notify.failure(
                                                                                obj3.data
                                                                            );
                                                                        }
                                                                    })
                                                                );
                                                            }}>&nbsp;</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <h5 class="cart-price">
                                                            <i class="fa fa-inr" aria-hidden="true"></i> {parseFloat(product.Basevalue).toFixed(2)}{" "}
                                                        </h5>
                                                        <div class="cart-close">
                                                            <i class="fas fa-trash" aria-hidden="true" onClick={() => {

                                                                var login = localStorage.getItem("CustomerData");
                                                                var logindetails = JSON.parse(login);

                                                                PostApiCall.postRequest(
                                                                    {
                                                                        cartID: product.fld_cart_id,
                                                                        cartlineid: product.fld_cart_dtl_id,
                                                                        customerid: localStorage.getItem("CustomerData") == null
                                                                            ? null
                                                                            : logindetails[0].fld_customerid,
                                                                    },
                                                                    "DeleteCart"
                                                                ).then((result) =>
                                                                    result.json().then((obj3) => {
                                                                        // console.log(obj3.data);

                                                                        if (
                                                                            result.status == 200 ||
                                                                            result.status == 201
                                                                        ) {
                                                                            // window.location.reload()


                                                                            Notiflix.Loading.remove();
                                                                            Notiflix.Notify.success(
                                                                                "Product is successfully deleted."
                                                                            );
                                                                            this.getCartdetails();
                                                                        } else {
                                                                            Notiflix.Loading.remove();
                                                                            Notiflix.Notify.failure(
                                                                                // obj3.data
                                                                            );
                                                                        }
                                                                    })
                                                                );
                                                                Notiflix.Loading.remove();
                                                            }}
                                                                style={{ cursor: 'pointer' }}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div class="row">
                                            <div class="col-md-12">
                                                <a
                                                    class="shopping-btn"
                                                    href={`${process.env.PUBLIC_URL}/`}
                                                >
                                                    Continue Shopping
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div class="row">
                                        <div class="col-md-12" style={{ marginLeft: "50px" }}>
                                            <img src="assets/img/empty_cart.svg" height="200px" width="200px"></img>
                                            <h4 style={{ marginTop: "50px", textDecoration: "none" }}>Your Pio Packaging cart is empty.</h4>
                                            <hr />

                                            <a
                                                style={{ marginTop: "75px" }}
                                                class="shopping-btn"
                                                href={`${process.env.PUBLIC_URL}/shop`}
                                            >
                                                Continue Shopping
                                            </a>

                                        </div>
                                    </div>
                                }
                                <div class="col-md-4" style={{ display: this.state.ProductCart.length != 0 ? "block" : "none" }}>
                                    <div class="order-summary">
                                        <h5>Order Summary</h5>

                                        {this.state.ProductCart.map((product, i) => {
                                            if (i == 0) {
                                                return (
                                                    <div>
                                                        <p>
                                                            <span className="subTotaltxt">Subtotal</span>
                                                            <span className="subTotalprice">₹ {parseFloat(product.netvalue).toFixed(2)}</span>
                                                        </p>
                                                        <p>
                                                            <span className="subTotaltxt">Discount </span>
                                                            <span className="subTotalprice">₹ {parseFloat(product.offer_discount).toFixed(2)}</span>
                                                        </p>
                                                        <p>
                                                            <span className="subTotaltxt">GST Amount</span>
                                                            <span className="subTotalprice">₹ {parseFloat(product.GstAmount).toFixed(2)}</span>
                                                        </p>
                                                        <hr />
                                                        <p>
                                                            <span className="Totaltxt">Total</span>
                                                            <span className="Totalprice">₹ {parseFloat(product.fld_cart_amount).toFixed(2)}</span>
                                                        </p>
                                                    </div>

                                                )
                                            }
                                        }

                                        )}
                                    </div>
                                    <a
                                        style={{ cursor: "pointer" }}
                                        class="checkout-btn d-flex justify-content-center align-items-center"
                                        onClick={() => {
                                            if (localStorage.getItem("CustomerData")) {
                                                window.location.href = "/checkout"
                                            } else {
                                                window.location.href = "/login"
                                            }
                                        }}

                                    >
                                        Proceed to Checkout
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>

            </React.Fragment>
        )
    }
}

export default ShopCart;