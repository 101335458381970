import React from "react";
import TabNavigationMobile from "../../components/tabNavigationMobile/tabNavigationMobile";

const Design = () => {
  return (
    <>
      <div>1</div>
      <TabNavigationMobile />
    </>
  );
};

export default Design;
