import React, { Component } from "react";
import PostApiCall from "./components/helper/Api";
import Notiflix from "notiflix";
import "./MonoCartonForm.css";

class MonoCartonForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCartons: [],
      name: "",
      organsation: "",
      pincode: "",
      mobile: "",
      email: "",
      quantity: "",
      length: "",
      width: "",
      height: "",
      errorMessage: "",
    };
  }

  

  // handleCartonSelect = (type) => {
  //   const { selectedCartons } = this.state;
  //   if (selectedCartons.includes(type)) {
  //     this.setState({
  //       selectedCartons: selectedCartons.filter((carton) => carton !== type),
  //     });
  //   } else {
  //     this.setState({
  //       selectedCartons: [...selectedCartons, type],
  //     });
  //   }
  // };

  handleCartonSelect = (type) => {
    this.setState({
      selectedCartons: [type], // Set the selected carton to an array containing only the chosen type
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });

    // For quantity, minimum 1000
    if (name === "quantity") {
      if (value < 1000) {
        this.setState({ errorMessage: "Quantity must be at least 1000" });
      } else {
        this.setState({ errorMessage: "" });
      }
    }
  };

  resetForm = () => {
    this.setState({
      selectedCartons: [],
      name: "",
      organsation: "",
      pincode: "",
      mobile: "",
      email: "",
      quantity: "",
      length: "",
      width: "",
      height: "",
      errorMessage: "",
    });
  };

  handleSubmit = () => {
    const {
      name,
      email,
      mobile,
      organsation,
      pincode,
      selectedCartons,
      quantity,
      length,
      width,
      height,
    } = this.state;

    // Simple email regex validation
    const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (name !== "") {
      if (organsation !== "") {
        if (mobile !== "") {
          if (mobile.length === 10) {
            if (email !== "" && EmailRegex.test(email)) {
              if (pincode !== "") {
                if (selectedCartons.length === 0) {
                  Notiflix.Notify.failure(
                    "Please select the type of monocarton"
                  );
                  return;
                }

                if (quantity !== "" && quantity >= 1000) {
                  if (length !== "") {
                    if (width !== "") {
                      if (height !== "") {
                        Notiflix.Loading.dots();

                        PostApiCall.postRequest(
                          {
                            name: name,
                            email: email,
                            mobile: mobile,
                            organsation_name: organsation,
                            pincode: pincode,
                            carton_type: selectedCartons.join(", "),
                            required_quantity: quantity,
                            length: length,
                            width: width,
                            height: height,
                          },
                          "monocartonsenquiry"
                        )
                          .then((response) => {
                            Notiflix.Loading.remove();
                            return response.json();
                          })
                          .then((data) => {
                            if (data.Result == 200 || data.Result == 201) {
                              Notiflix.Notify.success(
                                "Thank you, our team will contact you shortly!"
                              );
                              this.resetForm();
                            } else {
                              this.resetForm();
                              Notiflix.Notify.success(
                                "Thank you, our team will contact you shortly!"
                              );
                            }
                          })
                          .catch((error) => {
                            Notiflix.Loading.remove();
                            Notiflix.Notify.failure(
                              "An error occurred. Please try again."
                            );
                          });
                      } else {
                        Notiflix.Notify.failure("Please enter a height");
                      }
                    } else {
                      Notiflix.Notify.failure("Please enter a width");
                    }
                  } else {
                    Notiflix.Notify.failure("Please enter a length");
                  }
                } else {
                  Notiflix.Notify.failure("Quantity must be at least 1000");
                }
              } else {
                Notiflix.Notify.failure("Please enter a pincode");
              }
            } else {
              Notiflix.Notify.failure("Please enter a valid email");
            }
          } else {
            Notiflix.Notify.failure("Please enter a valid mobile number");
          }
        } else {
          Notiflix.Notify.failure("Please enter your mobile number");
        }
      } else {
        Notiflix.Notify.failure("Please enter an organisation name");
      }
    } else {
      Notiflix.Notify.failure("Please enter your name");
    }
  };

  renderCarton(type, imageSrc) {
    const { selectedCartons } = this.state;

    return (
      <>
        <div
          className={`carton-card ${
            selectedCartons.includes(type)
              ? "selected mono-carton-box"
              : "mono-carton-box"
          }`}
          onClick={() => this.handleCartonSelect(type)}
        >
          <img src={imageSrc} alt={type} />
          <p className="mono-type-content text-white">{type}</p>
        </div>
      </>
    );
  }

  render() {
    const {
      name,
      organsation,
      pincode,
      mobile,
      email,
      quantity,
      length,
      width,
      height,
    } = this.state;

    return (
      <div className="mono-carton-form">
        {/* <button className="open-btn border-0" onClick={this.handleShow}>
          Click here for Quotes
        </button> */}

        <div className={`offcanvas ${this.props.showCanvas ? "show" : ""}`}>
          <div className="offcanvas-header">
            <h5>ENQUIRY</h5>
            <span className="close-btn" onClick={this.props.handleClose}>
              &times;
            </span>
          </div>
          <div className="offcanvas-body">
            <div className="enquiry-form">
              <div className="form-section">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label className="mb-3">
                      Name: <span className="text-danger">*</span>
                      <input
                        className="mt-2"
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        value={name}
                        onChange={this.handleChange}
                      />
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="mb-3">
                      Organisation Name: <span className="text-danger">*</span>
                      <input
                        className="mt-2"
                        type="text"
                        name="organsation"
                        placeholder="Enter organisation name"
                        value={organsation}
                        onChange={this.handleChange}
                      />
                    </label>
                  </div>

                  <div className="col-12 col-md-6">
                    <label className="mb-3">
                      Mobile:<span className="text-danger">*</span>
                      <div className="w-100 d-flex">
                        <span className="me-2 mt-2 d-flex align-items-center bg-dark text-white p-2 mobilenum-start-design">
                          +91
                        </span>
                        <input
                          className="mobilenum-input-design mt-2"
                          type="text"
                          name="mobile"
                          maxLength="10"
                          placeholder="Enter mobile number"
                          value={mobile}
                          onChange={this.handleChange}
                        />
                      </div>
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="mb-3">
                      E-mail: <span className="text-danger">*</span>
                      <input
                        className="mt-2"
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={this.handleChange}
                      />
                    </label>
                  </div>
                  <div className="col-12">
                    <label className="mb-3">
                      Pincode: <span className="text-danger">*</span>
                      <input
                        className="mt-2"
                        type="text"
                        name="pincode"
                        placeholder="Enter pincode"
                        value={pincode}
                        onChange={this.handleChange}
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-section">
                <h3 className="mono-form-heading-font">
                  Type of Monocarton Required
                </h3>
                <p>
                  (Click on the type you want)
                  <span className="text-danger">*</span>
                </p>

                <div className="carton-selection">
                  {this.renderCarton(
                    "Straight Tuck",
                    "/assets/img/mono-cartons/form/Straight-Tuck.jpeg"
                  )}
                  {this.renderCarton(
                    "Reverse Tuck",
                    "/assets/img/mono-cartons/form/Reverse-Tuck.jpeg"
                  )}
                  {this.renderCarton(
                    "Auto Lock Bottom",
                    "/assets/img/mono-cartons/form/Auto-Lock-Bottom.jpeg"
                  )}
                  {this.renderCarton(
                    "Snap Lock Bottom",
                    "/assets/img/mono-cartons/form/Snap-Lock-Bottom.jpeg"
                  )}
                  {this.renderCarton(
                    "Seal Type",
                    "/assets/img/mono-cartons/form/Seal-Type.jpeg"
                  )}
                </div>
              </div>

              <div className="form-section">
                <h3 className="mono-form-heading-font">Quantity Required</h3>
                <p>
                  (MoQ 1000 Nos.)<span className="text-danger">*</span>
                </p>

                <input
                  className="mt-2"
                  type="number"
                  name="quantity"
                  placeholder="Enter quantity"
                  value={quantity}
                  onChange={this.handleChange}
                  min="1000"
                />
                {this.state.errorMessage && (
                  <p className="text-danger">{this.state.errorMessage}</p>
                )}
              </div>

              <div className="form-section flex-column carton-size">
                <h3 className="mono-form-heading-font">Size of carton in mm</h3>
                <div className="d-flex">
                  <label className="mb-3">
                    Length (L): <span className="text-danger">*</span>
                    <input
                      className="mt-2"
                      type="number"
                      name="length"
                      placeholder="Length"
                      value={length}
                      onChange={this.handleChange}
                    />
                  </label>
                  <label className="mb-3">
                    Width (W): <span className="text-danger">*</span>
                    <input
                      className="mt-2"
                      type="number"
                      name="width"
                      placeholder="Width"
                      value={width}
                      onChange={this.handleChange}
                    />
                  </label>
                  <label className="mb-3">
                    Height (H): <span className="text-danger">*</span>
                    <input
                      className="mt-2"
                      type="number"
                      name="height"
                      placeholder="Height"
                      value={height}
                      onChange={this.handleChange}
                    />
                  </label>
                </div>
                <div className="bottom-images">
                  <img
                    src="assets/img/mono-cartons/form/bottom-left.jpeg"
                    alt=""
                    className="img-fluid"
                  />
                  <img
                    src="assets/img/mono-cartons/form/bottom-center.jpeg"
                    alt=""
                    className="img-fluid"
                  />
                  <img
                    src="assets/img/mono-cartons/form/bottom-right.jpeg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>

              <div className="form-section">
                <button
                  type="submit"
                  className="btn btn-cont-w3"
                  onClick={this.handleSubmit}
                >
                  Submit Enquiry
                </button>
              </div>
            </div>
          </div>
        </div>

        {this.props.showCanvas && (
          <div className="overlay" onClick={this.props.handleClose}></div>
        )}
      </div>
    );
  }
}

export default MonoCartonForm;
